import { FC, JSX, useCallback, useEffect, useState } from 'react'
import { Box, Container, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { Layout } from '../../components'
import { useReduxSelector } from '../../hooks'
import { trackerCalendar2024, trackerDescription } from '../../api'
import { Calendar } from '../../types'

type Cards = {
  description: string
  primary_media: PrimaryMedia
}

type PrimaryMedia = {
  src_link: string
  is_image: boolean
  thumb_src_link: string
  small_src_link: string
  middle_src_link: string
}

const { REACT_APP_START_DATE_CHALLENGE: START_CHALLENGE } = process.env

export const CardsPage: FC = (): JSX.Element => {
  const classes = useStyles()

  const { data: constants } = useReduxSelector((state) => state.constants)
  const { data: user } = useReduxSelector((state) => state.auth)

  const { NOW = 0 } = constants || {}

  const [cards, setCards] = useState<Cards[]>()
  const [startChallenge, setStartChallenge] = useState<boolean>(false)

  const uploadCards = useCallback(() => {
    trackerDescription({ habit_type: 'card-2024' }).then((res) => {
      setCards(res.data)
    })
  }, [])

  const uploadCalendar = useCallback(() => {
    trackerCalendar2024().then((res) => {
      if (res.status === 1) {
        const calendar: Calendar[] = Object.values(res.data)

        if (calendar.length > 0) setStartChallenge(true)
      }
    })
  }, [])

  useEffect(() => {
    uploadCards()
    uploadCalendar()
  }, [])

  return (
    <Layout>
      <Box className={classes.page}>
        <Container maxWidth="md">
          <Box className={classes.title}>Карточки</Box>
          {user?.target_current === null || moment(NOW).isBefore(moment(Number(START_CHALLENGE))) ? (
            <Grid container justifyContent="center" alignItems="center" height="calc(100vh - 200px)">
              <Grid item>
                <Box className={classes.subTitle}>Карточек пока нет</Box>
              </Grid>
            </Grid>
          ) : (
            <>
              <Box className={classes.subTitle}>
                Моя цель:{' '}
                <span style={{ color: '#4A5967' }}>
                  хочу добавить в свою жизнь больше…{' '}
                  <span style={{ textTransform: 'uppercase' }}>{user?.target_current?.target.name}</span>
                </span>
              </Box>

              <Box className={classes.img}>
                <img src={user?.target_current?.target.primary_media.src_link} alt="" />
              </Box>

              {startChallenge &&
                cards?.map((k: Cards, i: number) => (
                  <Box key={`card-${i}`}>
                    <Box className={classes.subTitle}>{k?.description}</Box>
                    <Box className={classes.img}>
                      <img src={k?.primary_media.src_link} alt="" />
                    </Box>
                  </Box>
                ))}
            </>
          )}
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    padding: '20px 0 100px',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 20,
    color: palette.warning.main,
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 10,
    color: palette.warning.main,
  },
  img: {
    marginBottom: 20,
    '& img': {
      width: '100%',
      borderRadius: 12,
    },
  },
}))
