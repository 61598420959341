import React from 'react'
import { Box, Button, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Modal, Support } from '../../../components'
import { img } from '../../../imgs'

interface IBenefitModal {
  show: boolean
  onClose: () => void
  startMarathon: boolean
  onOpenSelectHabitsModal: () => void
}

type Stage = {
  id: number
  img: string
  text: string
}

const stage: Stage[] = [
  {
    id: 1,
    img: img.benefitStageOne,
    text:
      'Начнем прямо сейчас! Постарайтесь следить за метаболизмом, потребляя достаточно воды, поддерживать ' +
      'нормальную физическую активность и здоровый график сна.',
  },
  {
    id: 2,
    img: img.benefitStageTwo,
    text: 'Выберите еще одну или две полезные привычки, которые будете развивать на протяжении 30 дней.',
  },
  {
    id: 3,
    img: img.benefitStageThree,
    text:
      'Отслеживайте привычки в трекере и старайтесь ежедневно использовать эфирные масла. В разделе «Материалы» ' +
      'вы найдете ежедневный трекер. ',
  },
  {
    id: 4,
    img: img.benefitStageFour,
    text: 'Выполняйте ежедневные задания.',
  },
]

export const BenefitModal: React.FC<IBenefitModal> = ({
  show = false,
  onClose = () => {},
  startMarathon = false,
  onOpenSelectHabitsModal = () => {},
}): JSX.Element => {
  const classes = useStyles()

  return (
    <Modal open={show} maxWidth="md" onClose={onClose}>
      <Box className={classes.title}>
        Чем полезен марафон
        <br />
        30-Day Challenge?
      </Box>
      <Box className={classes.textTitle}>
        <Box mb={2}>
          Часто бывает так, что мы пытаемся изменить слишком многое. Как итог — не выдерживаем такой нагрузки и опускаем
          руки. Знайте, что к масштабным и устойчивым переменам ведут маленькие шаги. Если начать с малого, вам станет
          на удивление легко заставить тело и разум привыкнуть к чему-то новому.
        </Box>
        <Box mb={2}>
          Как же формируется привычки? Сначала мы чувствуем стимул, затем совершаем действие, а в конце получаем
          награду. Эти ежедневные действия настолько прочно входят в нашу жизнь, что уже не замечаем стимулов, которые
          их запускают. Есть и хорошая новость: вы можете сознательно использовать определенные стимулы, чтобы
          сформировать желаемую привычку, а затем наслаждаться наградой.
        </Box>
        <Box mb={2}>Вы можете начать марафон в любой день в период с 13 мая по 15 июля 2023 года.</Box>
      </Box>
      <Box className={classes.titleStage}>Как это работает</Box>
      {stage.map((k: Stage, i: number) => {
        return (
          <Grid key={`${i}`} container mb={3}>
            <Grid item width={64}>
              <img src={k.img} alt="" width={64} height={64} />
            </Grid>
            <Grid item width="calc(100% - 64px)">
              <Box className={classes.titleStages}>Шаг {k.id}</Box>
              <Box className={classes.textStages}>{k.text}</Box>
            </Grid>
          </Grid>
        )
      })}
      {!startMarathon && (
        <Box maxWidth={345} width="100%" m="40px auto 0">
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={() => {
              onClose()
              // setTimeout(() => {
              //   onOpenSelectHabitsModal()
              // }, 400)
            }}
          >
            Перейти к привычкам
          </Button>
        </Box>
      )}
      <Support type="menu" />
    </Modal>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  title: {
    fontSize: 24,
    width: '100%',
    margin: '20px 0',
    fontWeight: 700,
    color: palette.primary.contrastText,
  },
  textTitle: {
    fontSize: 15,
    width: '100%',
    fontWeight: 500,
    color: palette.primary.contrastText,
  },
  titleStage: {
    fontSize: 22,
    width: '100%',
    fontWeight: 700,
    margin: '30px 0',
    color: palette.primary.contrastText,
  },
  titleStages: {
    fontSize: 20,
    width: '100%',
    marginLeft: 12,
    fontWeight: 700,
    marginBottom: 10,
    color: palette.primary.contrastText,
  },
  textStages: {
    fontSize: 15,
    marginLeft: 12,
    fontWeight: 500,
    color: palette.primary.contrastText,
  },
}))
