import React from 'react'
import { Box, Button, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Moment from 'react-moment'
import { Modal } from '../../../components'
import { Calendar } from '../../../types'
import { MoreBackIcon } from '../../../imgs'
import { definitionTheme } from '../../../utils'

interface ICalendarModal {
  show: boolean
  onClose: () => void
  calendarList: Calendar[]
  onSelectDay: (currentDay: Calendar) => void
}

export const CalendarModal: React.FC<ICalendarModal> = ({
  show = false,
  onClose = () => {},
  onSelectDay = () => {},
  calendarList = [],
}): JSX.Element => {
  const classes = useStyles()
  // @ts-ignore
  return (
    <Modal maxWidth="md" open={show} onClose={onClose}>
      <Box className={classes.calendar}>
        <Box className={classes.title}>Календарь</Box>
        {calendarList.map((k: Calendar, i: number) => {
          return (
            <Button fullWidth sx={{ padding: 0 }} key={`item-calendar-${i}`} onClick={() => onSelectDay(k)}>
              <Box className={classes.contentBtnCurrent}>
                <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Box className={classes.contentBtnTitleCurrent}>
                      <Grid container wrap="nowrap" justifyContent="flex-start" alignItems="center">
                        <Grid item>
                          <Moment format="DD MMMM YYYY">{k.date_at.valueOf()}</Moment>
                        </Grid>
                        <Grid item>
                          <Grid container wrap="nowrap" justifyContent="flex-start" alignItems="center" mt="-4px">
                            <Grid item>
                              {k.result.progress > 0 && (
                                <Box ml={1} width={24} height={18}>
                                  <svg
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      fill={
                                        definitionTheme({ challenge: 'rgba(219, 88, 15, 0.2)' }, '#F5EAF1') as string
                                      }
                                    />
                                    <path
                                      d="M8.46973 11.706L11.0187 14.3531L16.1168 9.05896"
                                      stroke={definitionTheme({ challenge: '#DB580F' }, '#D4B5D5') as string}
                                      strokeWidth="1.5"
                                    />
                                  </svg>
                                </Box>
                              )}
                              {k.result.progress === 0 && (
                                <Box ml={1} width={24} height={18}>
                                  <svg
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle cx="12" cy="12" r="10" fill="#DDE0E3" />
                                    <path
                                      d="M8.46973 11.706L11.0187 14.3531L16.1168 9.05896"
                                      stroke="#4A5967"
                                      strokeWidth="1.5"
                                    />
                                  </svg>
                                </Box>
                              )}
                            </Grid>
                            {k.now && (
                              <Grid item>
                                <Box className={classes.now}>Сегодня</Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className={classes.contentBtnTextCurrent}>{k.num} день</Box>
                  </Grid>
                  <Grid item>
                    <Box className={classes.moreIcon}>
                      <MoreBackIcon />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Button>
          )
        })}
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  calendar: {
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    padding: '15px 0 20px',
    color: definitionTheme({ challenge: palette.warning.main }, palette.primary.contrastText) as string,
    [down(350)]: { fontSize: 23 },
  },
  now: {
    fontSize: 17,
    paddingLeft: 8,
    fontWeight: 600,
    color: definitionTheme({ challenge: 'rgba(219, 88, 15, 0.4)' }, '#E3D2E4') as string,
    transform: 'translate(0, 3px)',
    [down(350)]: { fontSize: 15 },
  },
  contentBtnCurrent: {
    width: '100%',
    marginBottom: 20,
    borderRadius: 12,
    padding: '12px 15px',
    border: '1px solid #F0F1F8',
    boxShadow: '0px 4px 12px #EBEDF4',
  },
  contentBtnTitleCurrent: {
    fontSize: 17,
    fontWeight: 700,
    lineHeight: 1.2,
    textAlign: 'left',
    textTransform: 'none',
    color: definitionTheme({ challenge: palette.warning.main }, palette.primary.dark) as string,
    [down(350)]: { fontSize: 16 },
  },
  contentBtnTextCurrent: {
    fontSize: 14,
    marginTop: 5,
    lineHeight: 1.2,
    fontWeight: 600,
    color: '#939CA4',
    textAlign: 'left',
    textTransform: 'none',
    [down(350)]: { fontSize: 13 },
  },
  moreIcon: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
