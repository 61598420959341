import React, { FC, forwardRef, ReactElement, Ref } from 'react'
import { Box, Container, Dialog, Grid, Slide, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { colorTheme } from '../../../../utils'
import { img } from '../../../../imgs'
import { useReduxSelector } from '../../../../hooks'

interface IProgressModal {
  open: boolean
  onClose: () => void
}

const Transition: React.ForwardRefExoticComponent<any> = forwardRef(
  (props: any & { children: ReactElement<any, any> }, ref: Ref<unknown>): JSX.Element => (
    <Slide {...props} ref={ref} direction="up" easing="ease-out" timeout={{ enter: 300, exit: 300 }}>
      {props.children}
    </Slide>
  )
)

export const ProgressModal: FC<IProgressModal> = ({ open, onClose }) => {
  const classes = useStyles()

  const { data: user } = useReduxSelector((state) => state.auth)

  return (
    <Dialog open={open} maxWidth="md" sx={modal} TransitionComponent={Transition}>
      <Container maxWidth="md">
        <Grid container justifyContent="space-between" alignItems="center" spacing={2} mb="15px">
          <Grid item>
            <Box color="warning.main" fontWeight={700} fontSize={20}>
              Общий прогресс
            </Box>
          </Grid>
          <Grid item>
            <Box sx={btn} onClick={onClose}>
              Закрыть
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box className={classes.content}>
        <Container maxWidth="md" sx={{ height: '100%' }}>
          <Grid container wrap="nowrap" direction="column" justifyContent="center" alignItems="center" height="100%">
            <Grid item>
              <Box className={classes.boxProgress}>
                <Box
                  className={classes.progress}
                  sx={({ breakpoints: { down } }) => ({
                    height: 341 * ((user?.progress.value || 0) / 100),
                    [down(380)]: { height: 341 * ((user?.progress.value || 0) / 100) },
                  })}
                />
                <img src={img.challengeProgress} alt="" />
                <Box className={classes.progressValue}>
                  <Grid container justifyContent="center" alignItems="center" height="100%">
                    <Grid item>{user?.progress.value}%</Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.text}>
                Постарайтесь
                <br />
                наполнить <span style={{ color: '#DB580F' }}>суперсилой</span> свою бутылочку на 100% за всё время
                Челленджа.
                <Box mt="12px">У вас обязательно всё получится!</Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.bgProgressChallenge})`,
  },
  boxProgress: {
    width: 146,
    height: 341,
    position: 'relative',
    '& img': {
      bottom: 0,
      width: 146,
      height: 341,
      position: 'absolute',
      [down(370)]: {
        width: 120,
        height: 280,
      },
    },
    [down(370)]: {
      width: 120,
      height: 280,
    },
  },
  progress: {
    width: 146,
    bottom: 0,
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.bgChallengeProgress})`,
    [down(370)]: {
      width: 120,
    },
  },
  progressValue: {
    bottom: 0,
    width: 146,
    height: 300,
    fontSize: 50,
    fontWeight: 700,
    position: 'absolute',
    color: palette.primary.main,
    [down(370)]: {
      width: 120,
      height: 240,
      fontSize: 45,
    },
  },
  text: {
    fontSize: 20,
    marginTop: 60,
    fontWeight: 700,
    color: palette.primary.main,
    [down(370)]: {
      marginTop: 30,
      fontSize: 18,
    },
  },
}))

const btn = {
  fontSize: 16,
  fontWeight: 600,
  color: colorTheme,
  '-webkit-tap-highlight-color': 'transparent',
}

const modal = {
  '& .MuiDialog-paper': {
    p: '15px 0 0!important',
    height: '100vh!important',
    borderBottomLeftRadius: '0px!important',
    borderBottomRightRadius: '0px!important',
    maxHeight: 'calc(100vh - 30px)!important',
  },
  '& .MuiDialog-container': {
    alignItems: 'flex-end!important',
  },
}
