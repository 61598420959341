import { ChangeEvent, FC, useState } from 'react'
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Skeleton } from '@mui/material'
import { Modal } from '../../components'
import { TestAnswer, TestQuestion } from '../test/test.types'
import { UseImagePreload, useReduxActions } from '../../hooks'

interface ITest2Modal {
  show: boolean
  stage: number
  onClose: () => void
  data?: TestQuestion[]
  onOpenRecommendation: (answer?: number) => void
}

const RatioCustom: JSX.Element = <Radio color="secondary" sx={{ width: 24, height: 24, mx: '10px' }} />

const LabelCustom: FC<{ text: string; src: string; selected: boolean }> = ({ src, text, selected }): JSX.Element => {
  return (
    <Box
      width="100%"
      overflow="hidden"
      borderRadius="12px"
      sx={{ transition: '0.15s ease-out' }}
      border={`2px solid ${selected ? '#5A3624' : '#DDE0E3'}`}
    >
      <Box p="8px 15px" fontSize={15} fontWeight={500} color="#4A5967">
        {text}
      </Box>
      <Box width="100%" height={128} display="flex" alignItems="flex-end" overflow="hidden">
        <img src={src} alt="" width="100%" style={{ display: 'block' }} />
      </Box>
    </Box>
  )
}

export const Test2Modal: FC<ITest2Modal> = ({
  stage = 1,
  data = [],
  show = false,
  onClose = () => {},
  onOpenRecommendation = () => {},
}): JSX.Element => {
  const preload = UseImagePreload(data[stage - 1]?.primary_media?.src_link || '')
  const { setAlert } = useReduxActions()
  const [value, setValue] = useState<number>(0)

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(Number((event.target as HTMLInputElement).value))
  }

  return (
    <Modal maxWidth="md" open={show} onClose={onClose}>
      <Box width="100%" m="17px 0 10px" borderRadius="12px" overflow="hidden">
        {preload ? (
          <Skeleton variant="rectangular" sx={{ bgcolor: '#d6d1ca' }} width="100%" height={225} />
        ) : (
          <img src={data[stage - 1]?.primary_media?.src_link} alt="test2" width="100%" style={{ display: 'block' }} />
        )}
      </Box>
      <Box fontSize={18} fontWeight={600} color="secondary.main">
        Вопрос {stage}/{data.length}
      </Box>
      <Box fontSize={16} fontWeight={600} mt="10px" mb="20px" color="#4A5967">
        {data[stage - 1]?.text}
      </Box>
      <Box sx={{ mb: '50px', width: '100%', '& .MuiTypography-root': { width: '100%!important' } }}>
        <RadioGroup value={value} onChange={onChangeValue} sx={{ width: '100%' }}>
          {data[stage - 1]?.test_answers.map((k: TestAnswer, i: number) => (
            <FormControlLabel
              key={i}
              value={k.id}
              control={RatioCustom}
              sx={{ mb: '10px', width: '100%' }}
              label={<LabelCustom src={`${k.primary_media.src_link}`} selected={k.id === value} text={k.text} />}
            />
          ))}
        </RadioGroup>
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => {
          if (value === 0) {
            setAlert({ alert: { message: { info: ['Выберите ответ'] } } })
          } else {
            onOpenRecommendation(value)
            if (value !== 0) setValue(0)
          }
        }}
      >
        Далее
      </Button>
    </Modal>
  )
}
