import React, { FC, JSX, useCallback, useEffect, useState } from 'react'
import { Box, Button, Checkbox, Grid, Link, SxProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker'
import moment from 'moment'
import { getPlan, storePlan } from '../../api'
import { colorTheme, definitionTheme } from '../../utils'
import { img } from '../../imgs'
import { Modal } from '../modal'

type Reminder = {
  link: any
  id: number
  slug: string
  name: string
  type_link: any
  description: string
  task_type_id: number
  plan: { notify_at: string } | null
}

export const CheckIcon: FC = (): JSX.Element => (
  <Box
    width={24}
    height={24}
    borderRadius="50%"
    overflow="hidden"
    bgcolor={definitionTheme({ challenge: '#DB580F' }, colorTheme) as string}
  >
    <Grid container justifyContent="center" alignItems="center" mt="-1px">
      <Grid item>
        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 5.26264L4.73738 8.00001L10.7374 2"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Grid>
    </Grid>
  </Box>
)

export const NoCheckIcon: FC = (): JSX.Element => (
  <Box
    width={20}
    height={20}
    borderRadius="50%"
    sx={{ border: `2px solid ${definitionTheme({ challenge: '#DB580F' }, colorTheme)}` }}
  />
)

export const Reminder: FC = (): JSX.Element => {
  const classes = useStyles()

  const [reminder, setReminder] = useState<Reminder[]>()
  const [time, setTime] = useState<{ id: number; value: string } | undefined>(undefined)
  const [timeModal, setTimeModal] = useState<boolean>(false)
  const [processed, setProcessed] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean[]>([false, false, false, false])

  const onOpenTimeModal = (id: number, t: string) => {
    if (!processed) {
      setTimeModal(true)
      setTime({ id, value: t })
    }
  }

  const onSaveTime = (value: string | null) => {
    if (time && value) {
      setProcessed(true)
      storePlan({ plan: [{ task_id: time?.id, notify_at: moment(value).format('HH:mm') }] }).then(() => {
        setProcessed(false)
        setTime(undefined)
        uploadPlan()
      })
    }
  }

  const onCloseTimeModal = () => {
    setTimeModal(false)
    setTime(undefined)
  }

  const uploadPlan = useCallback(() => {
    getPlan({ task_type: '2024' }).then((res) => {
      setReminder(res.data)

      const check: boolean[] = []

      res.data.forEach((k: Reminder, i: number) => {
        if (k.plan?.notify_at) {
          check.push(true)
        } else {
          check.push(false)
        }
      })

      setChecked(check)
    })
  }, [])

  useEffect(() => {
    uploadPlan()
  }, [])

  return (
    <Box mt="15px">
      <Modal open={timeModal} closeButton={false} onClose={onCloseTimeModal}>
        <StaticTimePicker
          sx={timePicker}
          disabled={processed}
          onClose={onCloseTimeModal}
          displayStaticWrapperAs="mobile"
          onAccept={(value: string | null) => onSaveTime(value)}
          // defaultValue={moment(time?.value || '').format('HH:mm')}
          localeText={{ okButtonLabel: 'Сохранить', cancelButtonLabel: 'Отмена', toolbarTitle: 'Выберите время' }}
        />
      </Modal>

      <Box color="warning.main" fontSize={24} fontWeight={700} mb="20px">
        Напоминания
      </Box>

      {reminder?.map((k: Reminder, i: number) => (
        <Box key={`reminder-${i}`} className={classes.item}>
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>
                  <Checkbox
                    color="warning"
                    onChange={() => {
                      setProcessed(true)

                      storePlan({ plan: [{ task_id: k.id, notify_at: null }] }).then(() => {
                        uploadPlan()
                        setProcessed(false)
                      })
                    }}
                    checked={checked[i]}
                    disabled={!checked[i]}
                    checkedIcon={<CheckIcon />}
                    icon={<NoCheckIcon />}
                  />
                </Grid>
                <Grid item>
                  <Box color="text.primary" fontSize={14} fontWeight={700}>
                    {k.name}
                  </Box>
                  {/* <Link sx={{ fontSize: 14, fontWeight: 700, color: '#DB580F', textDecoration: 'underline' }}> */}
                  {/*  Подробнее */}
                  {/* </Link> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button sx={{ p: 0 }} disabled={processed} onClick={() => onOpenTimeModal(k.id, k.plan?.notify_at || '')}>
                <Box className={classes.time}>
                  <Grid container wrap="nowrap" justifyContent="space-around" alignItems="center" height="100%">
                    <Grid item>
                      <Box sx={{ transform: 'translate(0, 4px)' }}>
                        <img src={img.reminder} alt="" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box>{k.plan?.notify_at ? moment(k.plan?.notify_at || '').format('HH:mm') : '-:-'}</Box>
                    </Grid>
                  </Grid>
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Box>
      ))}
      {/* <Box bgcolor="#EFF2F3" borderRadius="18px"> */}
      {/*  <Box p="15px 20px"> */}
      {/*    <Box color="#000000" fontSize={16} fontWeight={500}> */}
      {/*      Вопросы по продукции Вы можете направить на: */}
      {/*    </Box> */}
      {/*  </Box> */}
      {/* </Box> */}
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  item: {
    padding: 10,
    marginBottom: 10,
    borderRadius: '12px',
    border: '1px solid #F0F1F8',
    boxShadow: '0px 4px 12px 0px #EBEDF4',
  },
  time: {
    width: 75,
    height: 25,
    fontSize: 18,
    fontWeight: 700,
    borderRadius: 8,
    padding: '6px 8px',
    backgroundColor: '#EFF2F3',
    color: palette.warning.main,
    [down(330)]: {
      height: 25,
      padding: 6,
    },
  },
}))

const timePicker: SxProps = {
  display: 'block!important',
  minWidth: '100%!important',
  color: '#4A5967!important',
  '& .MuiTimePickerToolbar': {
    color: '#4A5967!important',
  },
  '& .MuiPickersLayout-toolbar': {
    '& .MuiTypography-overline': {
      fontWeight: 700,
      fontSize: 20,
      textTransform: 'none',
      color: '#4A5967!important',
    },
  },
  '& .MuiPickersToolbar-root': {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center',
  },
  '& .MuiClock-squareMask': {
    color: '#4A5967!important',
  },
  '& .MuiPickersToolbar-content': {
    justifyContent: 'center',
  },
  '& .MuiPickersToolbarButton': {
    color: '#4A5967!important',
  },
  '& .MuiPickersToolbarText-root': {
    color: '#4A5967!important',
  },
  '& .MuiButton-root': {
    color: '#4A5967!important',
  },
  '& .MuiClock-pin': {
    backgroundColor: '#4A5967',
  },
  '& .MuiClockPointer-root': {
    backgroundColor: '#4A5967',
  },
  '& .MuiClockPointer-thumb': {
    // backgroundColor: '#B786B9',
  },
  '& .MuiClockPointer': {
    backgroundColor: colorTheme,
  },
  '& .MuiPickersArrowSwitcher-button': {
    color: colorTheme,
    display: 'none',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    '& .MuiButton-root': {
      fontSize: 15,
      minWidth: 120,
      fontWeight: 700,
      borderRadius: 30,
      textTransform: 'none',
      color: '#FFFFFF!important',
      backgroundColor: colorTheme,
    },
  },
  '& .MuiTimeClock-root': {
    width: '100%!important',
  },
  '& .MuiClock-root': {
    marginLeft: 0,
    marginRight: 0,
  },
}
