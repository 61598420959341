import React, { FC, useCallback, useState } from 'react'
import { Box, Button, Grid, Theme } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { makeStyles } from '@mui/styles'
import * as process from 'process'
import { formLocale, Modal, SubmitButton, TextInput } from '../../components'
import { Survey, SurveyQuestion } from './test.types'
import { FormProps } from '../../types'
import { useReduxActions, useReduxDispatch, useReduxSelector } from '../../hooks'
import { testSurveyStore } from '../../api'
import { aHref } from '../../utils'
import { getUser } from '../../store/auth/auth.service'

interface ISurveyModal {
  id: number
  show: boolean
  onClose: () => void
  data: SurveyQuestion[]
}

const schema = yup.object().shape({
  note1: yup.string().required(),
  note2: yup.string().required(),
  note3: yup.string().required(),
})

export const SurveyModal: FC<ISurveyModal> = ({ id, data = [], show = false, onClose = () => {} }): JSX.Element => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()

  const { serverError } = formLocale

  const { REACT_APP_STIKERPACK: STIKERPACK } = process.env

  const { data: dataUser } = useReduxSelector((state) => state.auth)

  const hookForm = useForm<any>({
    defaultValues: { note1: '', note2: '', note3: '' },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [stiker, setStiker] = useState<boolean>(false)

  const defaultData = { note1: '', note2: '', note3: '' }

  const [formProps, setFormProps] = useState<FormProps<any>>({
    data: defaultData,
    processed: false,
  })

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
  }, [])

  const onCloseStiker = () => {
    onClose()
    setFormProps({ ...formProps, data: defaultData })
    setStiker((prev) => !prev)
  }

  const onSubmitForm = async (dataForm: any) => {
    const dataFetch: Survey = { test_id: id, questions: [] }

    Object.values(dataForm).forEach((k, i) => {
      dataFetch.questions.push({ id: data[i].id, note: `${k}` })
    })

    setFormProps({ ...formProps, processed: true })

    testSurveyStore(dataFetch)
      .then(({ status, message }) => {
        if (status === 1) {
          uploadUserInfo()
          setFormProps({ ...formProps, processed: false })
          setStiker((prev) => !prev)
        } else {
          setAlert({
            alert: {
              message: { error: [`${message}`] },
            },
          })
        }
      })
      .catch((err) =>
        setAlert({
          alert: {
            message: { error: [`${serverError}`] },
          },
        })
      )
  }

  const onUploadStikerPack = () => {
    aHref(`${dataUser?.test_result?.link || STIKERPACK}`)
    onCloseStiker()
  }

  return (
    <>
      <Modal maxWidth="md" open={stiker} onClose={onCloseStiker}>
        <Box className={classes.content}>
          <Grid container direction="column" justifyContent="space-between" height="70vh">
            <Grid item>
              <Box className={classes.title}>
                Спасибо!
                <br />
                Благодаря вам полезные интерактивные сервисы doTERRA будут еще лучше!
              </Box>
              <Box className={classes.text}>Скачайте ваш стикерпак</Box>
            </Grid>
            <Grid item>
              <Box maxWidth={335} m="30px auto 0">
                <Button fullWidth variant="contained" color="secondary" onClick={onUploadStikerPack}>
                  Скачать стикерпак
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={show}
        maxWidth="md"
        onClose={() => {
          onClose()
          setFormProps({ ...formProps, data: defaultData })
        }}
      >
        <Box className={classes.content}>
          <Box className={classes.titleForm}>
            Предлагаем ответить на 3 коротких вопроса о прошедшем 30-Day Challenge и получить подарок
          </Box>
          <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
            {data.length > 0 &&
              data.map((k: SurveyQuestion, i: number) => (
                <Box width="100%" minHeight={145}>
                  <TextInput
                    rows={4}
                    label=""
                    multiline
                    form={hookForm}
                    variant="outlined"
                    placeholder={k.text}
                    name={`note${i + 1}`}
                    disabled={formProps.processed}
                  />
                </Box>
              ))}
            <Box maxWidth={335} m="30px auto 0">
              <SubmitButton
                fullWidth
                title="Сохранить"
                disabled={formProps.processed}
                processed={formProps.processed}
              />
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  content: {
    width: '100%',
    padding: '30px 0',
    '& .MuiOutlinedInput-input': {
      padding: '0px!important',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    margin: '0 0 25px',
    color: palette.primary.contrastText,
  },
  titleForm: {
    fontSize: 18,
    fontWeight: 700,
    margin: '0 0 20px',
    color: palette.primary.contrastText,
  },
  text: {
    fontSize: 18,
    fontWeight: 500,
    margin: '0 0 20px',
    color: palette.primary.contrastText,
  },
}))
