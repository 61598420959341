import React, { forwardRef, ReactElement, Ref } from 'react'
import {
  Box,
  Grid,
  Theme,
  Slide,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  DialogProps as DialogPropsDefault,
} from '@mui/material'
import { colorTheme, str } from '../../utils'
import { themeGl } from '../../configs'

export interface DialogProps {
  name?: string
  open?: boolean
  hidden?: boolean
  processed?: boolean
  onOpen?: () => void
  onBack?: () => void
  onClose?: () => void
  backButton?: boolean
  closeButton?: boolean
  actionButtonText?: string
}

const Transition: React.ForwardRefExoticComponent<any> = forwardRef(
  (props: any & { children: ReactElement<any, any> }, ref: Ref<unknown>): JSX.Element => (
    <Slide {...props} ref={ref} direction="up" easing="ease-out" timeout={{ enter: 300, exit: 300 }}>
      {props.children}
    </Slide>
  )
)

export const Modal: React.FC<DialogProps & DialogPropsDefault> = (props): JSX.Element => {
  const {
    name = 'default',
    hidden = false,
    onBack = () => {},
    children,
    processed = false,
    fullScreen = false,
    backButton = false,
    closeButton = true,
    actionButtonText = '',
    maxWidth = 'sm',
    title = undefined,
    onClose = () => {},
  } = props

  const dialogTitleId = `${name}-dialog-title`
  const dialogBodyId = `${name}-dialog-body`
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  return (
    <Dialog
      {...props}
      scroll="body"
      hidden={hidden}
      title={undefined}
      maxWidth={maxWidth}
      fullScreen={fullScreen || sm}
      aria-labelledby={dialogTitleId}
      TransitionComponent={Transition}
    >
      {title && <DialogTitle id={dialogTitleId}>{str.normalize(title)}</DialogTitle>}

      {backButton && (
        <Box position="absolute" top={20} left={20} style={{ zIndex: 2 }}>
          <Box sx={btn} onClick={onBack}>
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 2L2 9C2 9 8.36321 15.3632 9 16"
                    stroke={colorTheme}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Grid>
              <Grid item>
                <Box ml="3px" position="relative" bottom={1}>
                  Назад
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      {closeButton && (
        <Box position="absolute" top={20} right={20} style={{ zIndex: 2 }}>
          {/* <IconButton color="secondary" disabled={processed} onClick={onClose} style={{ padding: 0 }}> */}
          {/*  <CloseIcon /> */}
          {/* </IconButton> */}
          <Box sx={btn} onClick={onClose}>
            Закрыть
          </Box>
        </Box>
      )}

      <DialogContent id={dialogBodyId}>{children}</DialogContent>

      {actionButtonText.length > 0 && (
        <DialogActions>
          <Box mx="auto" mt={2}>
            <Button autoFocus onClick={onClose} variant="contained" color="primary">
              {actionButtonText}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

const btn = {
  fontSize: 16,
  fontWeight: 600,
  color: colorTheme,
  '-webkit-tap-highlight-color': 'transparent',
}
