/* eslint-disable max-len */
import { definitionTheme } from '../../../../utils'

const color = definitionTheme({
  marathon: '#B786B9',
  test: '#BB2F2B',
  calendar: '#006266',
  test2: '#5A3624',
  challenge: '#A1AAAD',
}) as string

export const InfoDrawerIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.5 5H10.5C9.83696 5 9.20107 5.27393 8.73223 5.76152C8.26339 6.24912 8 6.91044 8 7.6V28.4C8 29.0896 8.26339 29.7509 8.73223 30.2385C9.20107 30.7261 9.83696 31 10.5 31H25.5C26.163 31 26.7989 30.7261 27.2678 30.2385C27.7366 29.7509 28 29.0896 28 28.4V12.8L20.5 5Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20 5V13H28" stroke={color} />
    <path d="M23 19H13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23 24H13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 13H14H13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
