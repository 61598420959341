import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { formLocale, PasswordInput, SubmitButton } from '../../components'
import { FormProps } from '../../types'
import { resetPasswordUser } from '../../api'
import { errorFormToast } from '../../toast'
import { useReduxActions } from '../../hooks'

type RepeatProps = { password: string; password_confirmation: string }

const schema = yup.object().shape({
  password: yup.string().required().min(8).max(20),
  password_confirmation: yup
    .string()
    .required()
    .min(8)
    .max(20)
    .oneOf([yup.ref('password')], 'Пароли не совпадают'),
})

export const RepeatForm: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()
  const [searchParams, setSearchParams] = useSearchParams()

  const { serverError } = formLocale

  const [token, setToken] = useState<string | null>(searchParams.get('token'))
  const [email, setEmail] = useState<string | null>(searchParams.get('email'))

  const hookForm = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<RepeatProps>>({
    data: { password: '', password_confirmation: '' },
    processed: false,
  })

  const onSubmitForm = async (data: RepeatProps) => {
    setFormProps({ ...formProps, data: { ...formProps.data, ...data }, processed: true })

    return await resetPasswordUser({ ...data, email, token })
      .then((res) => {
        setFormProps({ ...formProps, processed: false })

        if (res.status === 1) {
          setAlert({
            alert: {
              message: { info: ['Ваш пароль сохранен.'] },
              onClose: () => {
                navigate(`../signin?email=${email}`)
              },
            },
          })
        } else {
          errorFormToast(res.message)
        }
      })
      .catch((err) => {
        setFormProps({ ...formProps, processed: false })

        if (err.response.data.status === -1 || err.response.data.status === 0) {
          errorFormToast(err.response.message)
        } else {
          errorFormToast(serverError)
        }
      })
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      <Box width="100%" height={72}>
        <PasswordInput variant="outlined" name="password" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box width="100%" height={72}>
        <PasswordInput
          form={hookForm}
          variant="outlined"
          label="Повторите пароль"
          name="password_confirmation"
          disabled={formProps.processed}
        />
      </Box>
      <Box width="100%" mt="30px">
        <SubmitButton fullWidth disabled={formProps.processed} processed={formProps.processed} title="Сохранить" />
      </Box>
    </form>
  )
}
