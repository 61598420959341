import { FC, JSX, useCallback, useEffect, useState, MouseEvent } from 'react'
import { Box, Button, Container, Grid, IconButton, Popover, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Moment from 'react-moment'
import moment from 'moment'
import { CalendarIcon, img, MoreBackIcon } from '../../../imgs'
import { useReduxSelector } from '../../../hooks'
import {
  Calendar,
  ControlTrackerList,
  JournalResult,
  JournalTrackersList,
  MainTrackerList,
  MeasurementTrackerList,
} from '../../../types'
import { colorTheme, definitionTheme } from '../../../utils'
import { BackIcon, InfoIcon } from '../icons'
import { trackerNow, trackerNow2024 } from '../../../api'
import { MyTracker, MyJournal, MyMeasurements, MyWell, MyAchievement } from './current'
import { CardModal, ProgressModal } from './modals'

enum ETrackers {
  MY_TRACKER = 'Мой трекер',
  MY_DIARY = 'Мой дневник',
  GENERAL_PROGRESS = 'Общий прогресс',
  MY_MEASURES = 'Мои обмеры',
  MY_WELL = 'Мое самочувствие',
  MY_ACHIEVEMENTS = 'Мои достижения',
}

interface IButtonTrackers {
  name: string
  className: any
  onOpen: () => void
  filled: boolean | undefined
}

interface ITrackerChallenge {
  calendarNow: Calendar
  onOpenCalendar: () => void
  updateCalendar: () => void
}

export type JournalTrackerList = {
  open: boolean
  result: JournalResult
  trackers: JournalTrackersList[]
}

const filledMeasure = (measure: MeasurementTrackerList[]): boolean => {
  return !measure[measure.length - 1]?.trackers.find((k) => k.note === null)
}

const filledWell = (wells: ControlTrackerList[]): boolean => {
  return !wells[wells.length - 1]?.trackers.find((k) => k.note === null)
}

const ButtonTrackers: FC<IButtonTrackers> = ({ name, className, onOpen, filled }): JSX.Element => (
  <Button fullWidth className={className.btn} sx={{ textTransform: 'none' }} onClick={onOpen}>
    <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing={2}>
      <Grid item width="100%">
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item className={className.btnContent}>
            {name}
          </Grid>
          {!!filled && (
            <Grid item>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>
                  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.26263 5.26264L5.00002 8.00001L11 2"
                      stroke="#004438"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid item sx={{ color: 'secondary.main', fontWeight: 600, fontSize: 14 }}>
                  заполнен
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Box className={className.moreIcon}>
          <MoreBackIcon />
        </Box>
      </Grid>
    </Grid>
  </Button>
)

export const TrackerChallenge: FC<ITrackerChallenge> = ({
  calendarNow,
  onOpenCalendar,
  updateCalendar,
}): JSX.Element => {
  const classes = useStyles()

  const { data: user } = useReduxSelector((state) => state.auth)

  const [open, setOpen] = useState<ETrackers | undefined>(undefined)
  const [mainTrackersList, setMainTrackersList] = useState<MainTrackerList>()
  const [journalTrackersList, setJournalTrackersList] = useState<JournalTrackerList>()
  const [measurementTrackersList, setMeasurementTrackersList] = useState<MeasurementTrackerList[]>()
  const [controlTrackersList, setControlTrackersList] = useState<ControlTrackerList[]>()
  const [info, setInfo] = useState<ETrackers | undefined>(undefined)
  const [progress, setProgress] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const onOpenInfo = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseInfo = () => {
    setAnchorEl(null)
  }

  const openInfo = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const openTracker = () => {
    if (!open) uploadNowMainTrackers(calendarNow.date_at)
    setOpen(ETrackers.MY_TRACKER)
    setInfo(ETrackers.MY_TRACKER)
  }

  const openDiary = () => {
    setOpen(ETrackers.MY_DIARY)
  }

  const openProgress = () => {
    setProgress((prev) => !prev)
  }

  const openMeasurement = () => {
    setOpen(ETrackers.MY_MEASURES)
  }

  const openWell = () => {
    setOpen(ETrackers.MY_WELL)
    setInfo(ETrackers.MY_WELL)
  }

  const openAchievement = () => {
    setOpen(ETrackers.MY_ACHIEVEMENTS)
  }

  const uploadNowMainTrackers = useCallback((date: string) => {
    trackerNow({ date: moment(date).format('YYYY-MM-DD'), habit_type: 'main-2024' }).then((res) => {
      setMainTrackersList(res.data)
    })
  }, [])

  const uploadNowJournalTrackers = useCallback((date: string) => {
    trackerNow({ date: moment(date).format('YYYY-MM-DD'), habit_type: 'journal-2024' }).then((res) => {
      setJournalTrackersList(res.data)
    })
  }, [])

  const uploadNowMeasurementTrackers = useCallback(() => {
    trackerNow2024({ habit_type: 'measurement' }).then((res) => {
      setMeasurementTrackersList(res.data)
    })
  }, [])

  const uploadNowControlTrackers = useCallback(() => {
    trackerNow2024({ habit_type: 'control' }).then((res) => {
      setControlTrackersList(res.data)
    })
  }, [])

  useEffect(() => {
    uploadNowMainTrackers(calendarNow.date_at)
    uploadNowJournalTrackers(calendarNow.date_at)
    uploadNowMeasurementTrackers()
    uploadNowControlTrackers()
  }, [calendarNow])

  return (
    <Box className={classes.trackerChallenge}>
      <CardModal />

      <Box height={open ? 105 : 320} overflow="hidden" sx={{ transition: 'all 0.3s ease-in-out' }}>
        <Box className={classes.progressBar}>
          <Container maxWidth="md">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <IconButton sx={{ p: 0 }} onClick={onOpenCalendar}>
                  <CalendarIcon />
                </IconButton>
              </Grid>
              <Grid item className={classes.title}>
                <Moment format="DD MMMM YYYY">{calendarNow.date_at}</Moment>
                <Box className="day">День {calendarNow.num}</Box>
              </Grid>
              <Grid item>
                <Box width={38} height={38} />
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between" alignItems="center" mt="20px">
              <Grid item>
                <Box className={classes.target}>
                  <Box color="warning.main">Моя цель:</Box>
                  <Box>
                    Хочу добавить
                    <br />в свою жизнь больше…
                  </Box>
                  <Box mt="4px" textTransform="uppercase">
                    {user?.target_current?.target.name.trim()}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.boxProgress}>
                  <Box className={classes.progress} height={184 * (calendarNow.result.progress / 100)} />
                  <img src={img.bottleTracker} width={79} height={184} alt="" />
                  <Box className={classes.progressValue}>
                    <Grid container justifyContent="center" alignItems="center" height="100%">
                      <Grid item>{calendarNow.result.progress}%</Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box className={classes.trackers} minHeight={`calc(100vh - ${open ? 75 : 310}px)`}>
        <Container
          maxWidth="md"
          sx={({ breakpoints: { down } }: Theme) => ({
            pt: '20px!important',
            pb: '100px!important',
            [down(350)]: { pt: '20px!important', pb: '100px!important' },
          })}
        >
          {open ? (
            <>
              <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" mb="20px">
                <Grid item>
                  <IconButton
                    sx={{ p: 0 }}
                    color="warning"
                    onClick={() => {
                      setInfo(undefined)
                      setOpen(undefined)
                    }}
                  >
                    <Box width={32} height={32} display="flex" alignItems="center" justifyContent="center">
                      <BackIcon />
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item>
                  <Box className={classes.titleTracker}>{open}</Box>
                </Grid>
                <Grid item>
                  <Box width={32} height={32}>
                    {info && (
                      <IconButton sx={{ p: 0 }} color="primary" onClick={onOpenInfo}>
                        <InfoIcon />
                      </IconButton>
                    )}
                  </Box>

                  <Popover
                    id={id}
                    open={openInfo}
                    anchorEl={anchorEl}
                    onClose={onCloseInfo}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {info === ETrackers.MY_TRACKER && (
                      <Box>
                        <Box color="warning.main">Ежедневно регистрируйте свои действия. Отмечайте:</Box>
                        <ul>
                          <li>Прием MetaPWR и LLV</li>
                          <li>Физическую активность</li>
                          <li>Придерживались ли вы своего плана питания</li>
                          <li>Выполнение ежедневного задания на Get Course</li>
                        </ul>
                      </Box>
                    )}

                    {info === ETrackers.MY_WELL && (
                      <Box>
                        Остановитесь.
                        <br />
                        Сделайте три глубоких вдоха и сфокусируйтесь на том, как вы себя чувствуете — прямо сейчас,
                        здесь, в этом моменте. Посвятите себе и только себе эти пять минут! Это очень полезно — так вы
                        учитесь слышать себя и то, что происходит с вашим телом.
                      </Box>
                    )}
                  </Popover>
                </Grid>
              </Grid>

              {mainTrackersList && open === ETrackers.MY_TRACKER && (
                <MyTracker
                  calendarNow={calendarNow}
                  targetList={mainTrackersList}
                  readonly={!mainTrackersList.open}
                  update={() => {
                    updateCalendar()
                    uploadNowMainTrackers(calendarNow.date_at)
                    setOpen(undefined)
                  }}
                />
              )}

              {journalTrackersList && open === ETrackers.MY_DIARY && (
                <MyJournal
                  journalList={journalTrackersList}
                  readonly={!journalTrackersList.open || journalTrackersList.trackers[0].note !== null}
                  update={() => {
                    updateCalendar()
                    uploadNowJournalTrackers(calendarNow.date_at)
                    setOpen(undefined)
                  }}
                />
              )}

              {measurementTrackersList && open === ETrackers.MY_MEASURES && (
                <MyMeasurements
                  className={classes}
                  trackerList={measurementTrackersList}
                  update={() => {
                    updateCalendar()
                    uploadNowMeasurementTrackers()
                  }}
                />
              )}

              {controlTrackersList && open === ETrackers.MY_WELL && (
                <MyWell
                  className={classes}
                  trackerList={controlTrackersList}
                  update={() => {
                    uploadNowControlTrackers()
                    setOpen(undefined)
                  }}
                />
              )}

              {measurementTrackersList && controlTrackersList && open === ETrackers.MY_ACHIEVEMENTS && (
                <MyAchievement measures={measurementTrackersList} wells={controlTrackersList} />
              )}
            </>
          ) : (
            <>
              <ProgressModal open={progress} onClose={openProgress} />

              <Box mb="10px">
                <ButtonTrackers
                  className={classes}
                  onOpen={openTracker}
                  name={ETrackers.MY_TRACKER}
                  filled={(mainTrackersList?.result.completed || 0) > 0}
                />
              </Box>

              <Box mb="10px">
                <ButtonTrackers
                  onOpen={openDiary}
                  className={classes}
                  name={ETrackers.MY_DIARY}
                  filled={!!journalTrackersList && journalTrackersList?.trackers[0].note !== null}
                />
              </Box>

              <Box mb="10px">
                <ButtonTrackers
                  className={classes}
                  onOpen={openMeasurement}
                  name={ETrackers.MY_MEASURES}
                  filled={measurementTrackersList && filledMeasure(measurementTrackersList || [])}
                />
              </Box>

              <Box mb="10px">
                <ButtonTrackers
                  onOpen={openWell}
                  className={classes}
                  name={ETrackers.MY_WELL}
                  filled={controlTrackersList && filledWell(controlTrackersList || [])}
                />
              </Box>

              <Box mb="10px">
                <ButtonTrackers
                  className={classes}
                  onOpen={openAchievement}
                  name={ETrackers.MY_ACHIEVEMENTS}
                  filled={false}
                />
              </Box>

              <Box mb="10px">
                <ButtonTrackers
                  className={classes}
                  onOpen={openProgress}
                  name={ETrackers.GENERAL_PROGRESS}
                  filled={false}
                />
              </Box>
            </>
          )}
        </Container>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  trackerChallenge: {
    minHeight: '100vh',
  },
  progressBar: {
    height: 507,
    width: '100%',
    paddingTop: 15,
    backgroundSize: 'cover',
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.bgMain})`,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    '& .day': {
      fontSize: 16,
      textAlign: 'center',
      color: palette.warning.main,
    },
  },
  boxProgress: {
    width: 79,
    height: 184,
    position: 'relative',
    '& img': {
      bottom: 0,
      position: 'absolute',
    },
  },
  progress: {
    width: 79,
    bottom: 0,
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.bottleTrackerBg})`,
  },
  progressValue: {
    bottom: 0,
    width: 79,
    height: 135,
    fontSize: 27,
    fontWeight: 700,
    position: 'absolute',
  },
  target: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1.4,
    [down(350)]: { fontSize: 18 },
  },
  trackers: {
    marginTop: -30,
    position: 'relative',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: '#F9F9FB',
    transition: 'all 0.3s ease-in-out',
  },
  btn: {
    fontSize: '16px!important',
    fontWeight: '700!important',
    borderRadius: '12px!important',
    padding: '8px 20px!important',
    color: `${palette.warning.main}!important`,
    boxShadow: '0px 4px 12px 0px #EBEDF4!important',
    backgroundColor: `${palette.background.paper}!important`,
    [down(350)]: { fontSize: '15px!important', padding: '8px 10px!important' },
  },
  btnContent: {
    lineHeight: 1.2,
    textAlign: 'left',
  },
  moreIcon: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleTracker: {
    fontSize: 24,
    fontWeight: 700,
    color: definitionTheme({ challenge: palette.warning.main }, colorTheme) as string,
    [down(350)]: {
      fontSize: 20,
    },
  },
}))
