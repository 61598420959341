import React from 'react'
import { size } from '../../configs'

interface ICloseIcon {
  width?: number
  height?: number
}

export const CloseIcon: React.FC<ICloseIcon> = ({ width = 80, height = 80 }): JSX.Element => (
  <svg
    width={`${width / size.scale}`}
    height={`${height / size.scale}`}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="80" height="80" fill="#F4F4F4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        'M39.9999 42.1133L53.8866 56L55.9999 53.8867L42.1132 40L55.9999 26.1133L53.8866 24L39.9999 ' +
        '37.8867L26.1132 24L23.9999 26.1133L37.8866 40L23.9999 53.8867L26.1132 56L39.9999 42.1133Z'
      }
      fill="black"
    />
  </svg>
)
