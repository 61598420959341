import React from 'react'
import { Box, Grid, Fade, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useReduxSelector } from '../../hooks'
import { MenuApp } from './menu'
import { themeGl } from '../../configs'

interface ILayout {
  children: React.ReactNode
}

export const Layout: React.FC<ILayout> = ({ children }): JSX.Element => {
  const classes = useStyles()

  const { auth } = useReduxSelector((state) => state.auth)

  return (
    <Fade in timeout={{ enter: 300 }}>
      <Box className={classes.body}>
        <Box className={classes.header}>
          <MenuApp />
        </Box>
        <Box className={classes.body}>{children}</Box>
      </Box>
    </Fade>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  body: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: themeGl === 'calendar' ? palette.background.default : palette.primary.main,
  },
  header: {
    zIndex: 101,
  },
  main: {
    zIndex: 99,
    flex: '1 0 auto',
    overflow: 'hidden',
    position: 'relative',
    minHeight: 'auto',
  },
  footer: {
    zIndex: 100,
    flexShrink: 0,
  },
}))
