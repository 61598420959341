import React, { useState } from 'react'
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HtmlReactParser from 'html-react-parser'
import { AddHabit, Habits } from '../types'
import { img } from '../../../../../imgs'
import { useReduxActions } from '../../../../../hooks'

interface IStageTwo {
  className: any
  items: AddHabit[]
  addHabitAnswers: number[][]
  onChangeStage: (answers: number[][]) => void
}

export const StageTwo: React.FC<IStageTwo> = ({
  className,
  items = [],
  addHabitAnswers = [],
  onChangeStage = () => {},
}): JSX.Element => {
  const classes = useStyles()
  const { setAlert } = useReduxActions()

  const [answer, setAnswer] = useState<number[][]>(addHabitAnswers)
  const [disable, setDisable] = useState<boolean>(false)

  const handleChange = (data: { id: number; i: number; j: number }) => {
    const answerCurrent = JSON.parse(JSON.stringify(answer))
    answerCurrent[data.i][data.j] = Number(data.id)

    let counter = 0

    for (let i = 0; answerCurrent.length > i; i++) {
      for (let j = 0; answerCurrent[i].length > j; j++) {
        if (answerCurrent[i][j] !== 0) {
          counter++
        }
      }
    }

    if (counter <= 2) {
      setAnswer([...answerCurrent])
    } else {
      setAlert({ alert: { message: { info: ['Для выбора доступно не более 2 привычек.'] } } })
    }
  }

  const imgSrc = (slug: string) => {
    switch (slug) {
      case 'healthy_eating':
        return img.healthyEating
      case 'exercise_stress':
        return img.exerciseStress
      case 'rest_stress_ management':
        return img.restStressManagement
      case 'reduced_toxic_load':
        return img.reducedToxicLoad
      case 'health_care':
        return img.healthCare
      default:
        return ''
    }
  }

  const onDescriptionHabit = (title: string, description: string) => {
    setAlert({ alert: { title, message: { info: [`${description}`] } } })
  }

  const check = {
    icon: <span className={classes.iconCheck} />,
    checked: (
      <span className={classes.iconCheck}>
        <Box className={classes.check}>
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 5.26264L4.73738 8.00001L10.7374 2"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </span>
    ),
  }

  return (
    <Box className={classes.content}>
      <Box className={className.title}>Выберите еще одну или две привычки</Box>
      <Box className={className.textTitle}>
        <Box mb="10px">
          Надеемся, что вы настроены позитивно и готовы за 30 дней сформировать три привычки: потреблять достаточно
          воды, ежедневно поддерживать нормальную физическую активность и уделять достаточно времени сну.
        </Box>
        Не нужно на этом останавливаться! Выберите еще одну или две привычки, над которыми будете работать в течение
        нашего 30-дневного марафона.
      </Box>
      {items.length > 0 &&
        items.map((k: AddHabit, i: number) => {
          return (
            <Box key={`add-habit-${i}`} className={className.item}>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                  <img src={imgSrc(k.slug)} alt="" className={classes.img} />
                </Grid>
                <Grid item>
                  <Box className={className.itemTitle}>{k.name}</Box>
                  <Box className={className.itemDescription} onClick={() => onDescriptionHabit(k.name, k.description)}>
                    Подробнее о привычке
                  </Box>
                </Grid>
              </Grid>
              <Box mt="10px">
                {answer.length > 0 && (
                  <RadioGroup>
                    <Grid container direction="column">
                      {k.habits.map((kHabits: Habits, iHabits: number) => {
                        return (
                          <Grid key={`list${iHabits}`} item>
                            <FormControlLabel
                              name={`${kHabits.id}`}
                              value={`${kHabits.id}`}
                              disabled={disable}
                              control={
                                <Radio
                                  color="secondary"
                                  icon={check.icon}
                                  disabled={disable}
                                  checkedIcon={check.checked}
                                  checked={answer[i][iHabits] === kHabits.id}
                                  onClick={() => {
                                    handleChange({ id: answer[i][iHabits] === 0 ? kHabits.id : 0, i, j: iHabits })
                                  }}
                                />
                              }
                              label={HtmlReactParser(kHabits.name)}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </RadioGroup>
                )}
              </Box>
            </Box>
          )
        })}
      <Box className={className.textTitle} mt="30px">
        <Box mb="10px">Для прочного закрепления новых привычек их можно связать с уже имеющимися.</Box>
        Помните, если вы совместите новые привычки со старыми, придерживаться их будет намного проще.
      </Box>
      <Box mt="15px" className={className.textNote}>
        <Grid container wrap="nowrap" justifyContent="flex-start" spacing={1}>
          <Grid item>
            <Box fontSize={20}>*</Box>
          </Grid>
          <Grid item>
            Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, находящимся под
            наблюдением врача, перед применением необходимо проконсультироваться с врачом.
          </Grid>
        </Grid>
      </Box>
      <Box m="30px auto 0" maxWidth={350}>
        <Button fullWidth variant="contained" color="secondary" onClick={() => onChangeStage(answer)}>
          Подтвердить
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  content: {},
  img: {
    width: 40,
    height: 40,
  },
  iconCheck: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: `1px solid ${palette.primary.dark}`,
  },
  check: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${palette.primary.dark}`,
  },
}))
