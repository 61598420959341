import React from 'react'
import { Box, Container, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment/moment'
import { img } from '../../imgs'
import { Timer } from '../../components'

interface IStartPage {
  startTime: string
}

export const StartPage: React.FC<IStartPage> = ({ startTime = 0 }): JSX.Element => {
  const classes = useStyles()
  return (
    <Box className={classes.startTracker}>
      <Box className={classes.startTrackerBg}>
        <Container maxWidth="sm" sx={{ height: '100%' }}>
          <Grid container justifyContent="center" alignItems="center" height="100%">
            <Grid item>
              <Box className={classes.startTrackerTitle}>Ожидайте!</Box>
              <Box className={classes.startTrackerText}>
                Скоро вы сможете принять участие
                <br />в марафоне 30-Day Challenge!
              </Box>
              <Box sx={{ opacity: 0, margin: '0 auto' }}>
                <Timer endTime={moment(startTime).valueOf()} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  startTracker: {
    width: '100%',
    height: '100Vh',
    backgroundSize: '345px 341px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: palette.primary.main,
    backgroundImage: `url(${img.bgSign})`,
    backgroundPosition: 'bottom -40px left -120px',
  },
  startTrackerBg: {
    width: '100%',
    height: '100Vh',
    backgroundSize: '234px 235px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.bgStop})`,
    backgroundPosition: 'top -40px right -130px',
  },
  startTrackerTitle: {
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    color: palette.primary.dark,
  },
  startTrackerText: {
    fontSize: 16,
    marginTop: 15,
    fontWeight: 700,
    textAlign: 'center',
    color: palette.primary.contrastText,
  },
}))
