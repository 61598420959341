/* eslint-disable max-len */
import { definitionTheme } from '../../../../utils'

const color = definitionTheme({
  marathon: '#B786B9',
  test: '#BB2F2B',
  calendar: '#006266',
  test2: '#5A3624',
  challenge: '#DB580F',
}) as string

export const ChallengeDrawerIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8457 18.7243C11.272 18.0764 12.2499 18.0764 12.6761 18.7243L20.3505 30.3876C20.8101 31.086 20.2913 32 19.4352 32H4.08658C3.23056 32 2.71178 31.086 3.17136 30.3876L10.8457 18.7243Z"
      fill={color}
    />
    <path
      d="M20.0328 4.66667C20.0328 4.29848 20.3414 4 20.7221 4C21.1028 4 21.4114 4.29848 21.4114 4.66667V13.3333H20.0328V4.66667Z"
      fill={color}
    />
    <path d="M20.4063 4.525H26.3729V8.19709H20.4063V4.525Z" fill={color} />
    <path d="M25.2867 5.57374H29.626L28.27 7.40979L29.626 9.24583H25.2867V5.57374Z" fill={color} />
    <path
      d="M21.6549 12.186C21.2346 11.5013 20.2097 11.5013 19.7893 12.186L15.8834 18.5483L24.1624 32H31.9135C32.7545 32 33.2757 31.1146 32.8463 30.4152L21.6549 12.186Z"
      fill={color}
    />
  </svg>
)
