import bgGreet from './bg-greet.svg'
import bgGreetCalendar from './bg-greet-calendar.png'
import bgSign from './bg-sign.svg'
import benefitStageOne from './benefit-stage-1.png'
import benefitStageTwo from './benefit-stage-2.png'
import benefitStageThree from './benefit-stage-3.png'
import benefitStageFour from './benefit-stage-4.png'
import habitMainOne from './habit_main_1.png'
import habitMainTwo from './habit_main_2.png'
import habitMainThree from './habit_main_3.png'
import healthyEating from './healthy_eating.png'
import exerciseStress from './exercise_stress.png'
import restStressManagement from './rest_stress_management.png'
import reducedToxicLoad from './reduced_toxic_load.png'
import healthCare from './health_care.png'
import tg from './tg.png'
import bgMain from './bg-main.png'
import bgPercent from './bg-percent.png'
import percent from './percent.png'
import bgProgress from './bg-progress.png'
import bgPercentProgress from './bg-percent-progress.png'
import percentProgress from './percent-progress.png'
import noti from './noti.png'
import bgStop from './bg-stop.png'
import stageInstallIos1 from './stage-install-ios-1.png'
import stageInstallIos2 from './stage-install-ios-2.png'
import stageInstallIos3 from './stage-install-ios-3.png'
import advent from './advent.png'
import test2 from './test2.png'
import startChallenge from './start-challenge.png'
import bottleTracker from './challenge-2024/bottle-tracker.png'
import bottleTrackerBg from './challenge-2024/bottle-tracker-bg.png'
import bgProgressChallenge from './challenge-2024/bg-progress-challenge.png'
import bgChallengeProgress from './challenge-2024/bg-challenge-progress.png'
import challengeProgress from './challenge-2024/challenge-progress.png'
import reminder from './challenge-2024/reminder.png'

export * from './svg'

export const img = {
  tg,
  noti,
  test2,
  advent,
  bgStop,
  bgSign,
  bgMain,
  bgGreet,
  percent,
  reminder,
  bgPercent,
  bgProgress,
  healthCare,
  habitMainOne,
  habitMainTwo,
  healthyEating,
  bottleTracker,
  habitMainThree,
  startChallenge,
  exerciseStress,
  bottleTrackerBg,
  bgGreetCalendar,
  benefitStageOne,
  percentProgress,
  benefitStageTwo,
  benefitStageFour,
  reducedToxicLoad,
  stageInstallIos1,
  stageInstallIos2,
  stageInstallIos3,
  bgPercentProgress,
  benefitStageThree,
  challengeProgress,
  bgProgressChallenge,
  bgChallengeProgress,
  restStressManagement,
}
