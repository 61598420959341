import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Grid,
  Radio,
  Theme,
  Button,
  Accordion,
  IconButton,
  RadioGroup,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  CircularProgress,
} from '@mui/material'
import HtmlReactParser from 'html-react-parser'
import { OilTrackerList, OilTrackers, OilTrackersList } from '../../../types'
import { trackerStore } from '../../../api'
import { useReduxActions } from '../../../hooks'
import { img } from '../../../imgs'
import { colorTheme } from '../../../utils'

interface IOilTracker {
  now: boolean
  ready: boolean
  onClose: () => void
  loadTrackers: () => void
  trackersList: OilTrackerList
}

const Icon: React.FC<{ className: any; image: any }> = ({ className, image = [] }): JSX.Element => {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.5L7 7.5C7 7.5 12.4542 2.04582 13 1.5"
        stroke="#B786B9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const OilTracker: React.FC<IOilTracker> = ({
  now = false,
  ready = false,
  trackersList,
  onClose = () => {},
  loadTrackers = () => {},
}): JSX.Element => {
  const { setAlert } = useReduxActions()
  const classes = useStyles()

  const [answer, setAnswer] = useState<number[][]>([])
  const [disable, setDisable] = useState<boolean>(false)
  const [change, setChange] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean[]>([true, true, true])

  const handleChange = (data: { id: number; i: number; j: number }) => {
    const answerCurrent = JSON.parse(JSON.stringify(answer))
    answerCurrent[data.i][data.j] = Number(data.id)
    setAnswer([...answerCurrent])
  }

  const handleChangeEx = (id: number, show: boolean) => {
    const expandedCurrent = JSON.parse(JSON.stringify(expanded))
    expandedCurrent[id] = show
    setExpanded([...expandedCurrent])
  }

  const onSubmit = () => {
    setDisable(true)

    const trackers = []

    for (let i = 0; answer.length > i; i++) {
      for (let j = 0; answer[i].length > j; j++) {
        if (answer[i][j] !== 0) {
          trackers.push({ id: answer[i][j] })
        }
      }
    }

    if (trackers.length > 0) {
      trackerStore({ trackers })
        .then((res) => {
          setDisable(false)

          if (res.status === 1) {
            loadTrackers()
            onClose()
          } else {
            setAlert({ alert: { message: { info: res.message.alert } } })
          }
        })
        .catch((err) => {
          setDisable(false)

          setAlert({ alert: { message: { error: err.response.data.message } } })
        })
    } else {
      setDisable(false)

      setAlert({
        alert: {
          message: { info: ['Не выбрано ни одного действия.'] },
        },
      })
    }
  }

  useEffect(() => {
    if (trackersList.trackers.length > 0) {
      const answerDefault = []
      let changes = 0
      let trackLength = 0

      for (let i = 0; trackersList.trackers.length > i; i++) {
        const answerLine = []
        for (let j = 0; trackersList.trackers[i].trackers.length > j; j++) {
          trackLength++
          answerLine.push(0)
          if (trackersList.trackers[i].trackers[j].change_at) changes++
        }
        answerDefault.push(answerLine)
      }

      setAnswer(answerDefault)
      setChange(trackLength > changes)
    }
  }, [trackersList])

  const check = {
    icon: <span className={classes.iconCheck} />,
    iconReady: <span className={classes.iconCheckReady} />,
    checked: (
      <span className={classes.iconCheck}>
        <Box className={classes.check}>
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 5.26264L4.73738 8.00001L10.7374 2"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </span>
    ),
    checkedReady: (
      <span className={classes.iconCheckReady}>
        <Box className={classes.checkReady}>
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 5.26264L4.73738 8.00001L10.7374 2"
              stroke="#4A5967"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </span>
    ),
  }

  return (
    <Box className={classes.tracker}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton color="primary" onClick={onClose}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 2L2 9C2 9 8.36321 15.3632 9 16"
                stroke={colorTheme}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <Box className={classes.header}>План использования эфирных масел</Box>
        </Grid>
        <Grid item>
          <Box width={34} height={34} />
        </Grid>
      </Grid>
      <Box className={classes.title}>План использования эфирных масел в течение дня</Box>
      {trackersList && (
        <RadioGroup>
          <Grid container direction="column">
            {trackersList.trackers.map((k: OilTrackers, i: number) => {
              return (
                <Grid key={`list-${i}`} item>
                  <Accordion
                    square={false}
                    key={`list${i}`}
                    expanded={expanded[i]}
                    onChange={() => handleChangeEx(i, !expanded[i])}
                  >
                    <AccordionSummary expandIcon={<Icon className={classes} image={img} />}>{k.name}</AccordionSummary>
                    <AccordionDetails>
                      {answer.length > 0 &&
                        k.trackers.map((kSub: OilTrackersList, iSub: number) => {
                          return (
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              name={`${kSub.habit.id}`}
                              value={`${kSub.habit.id}`}
                              disabled={disable || ready || kSub.change_at}
                              control={
                                <Radio
                                  color="secondary"
                                  disabled={disable || ready || kSub.change_at}
                                  checked={answer[i][iSub] === kSub.id || kSub.change_at}
                                  icon={disable || ready || kSub.change_at ? check.iconReady : check.icon}
                                  checkedIcon={disable || ready || kSub.change_at ? check.checkedReady : check.checked}
                                  onClick={() => {
                                    handleChange({ id: answer[i][iSub] === 0 ? kSub.id : 0, i, j: iSub })
                                  }}
                                />
                              }
                              label={HtmlReactParser(kSub.habit.name)}
                            />
                          )
                        })}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )
            })}
          </Grid>
        </RadioGroup>
      )}
      <Box mt="15px" className={classes.textNote}>
        <Grid container wrap="nowrap" justifyContent="flex-start" spacing={1}>
          <Grid item>
            <Box fontSize={20}>*</Box>
          </Grid>
          <Grid item>
            Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, находящимся под
            наблюдением врача, перед применением необходимо проконсультироваться с врачом.
          </Grid>
        </Grid>
      </Box>
      <Box maxWidth={350} width="100%" m="30px auto 115px">
        {((!ready && !now) || (!ready && change && now)) && (
          <Button fullWidth variant="contained" color="secondary" disabled={disable} onClick={onSubmit}>
            Сохранить
            {disable && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  tracker: {
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .MuiAccordionSummary-root': {
      paddingLeft: 0,
      justifyContent: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {
      paddingRight: 10,
      flexGrow: '0!important',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  header: {
    fontSize: 16,
    width: '100%',
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    color: palette.primary.dark,
    transform: 'translate(0, 2px)',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: '20px 0 15px',
    color: palette.primary.contrastText,
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    margin: '0 0 20px',
    color: palette.primary.contrastText,
  },
  iconCheck: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: `1px solid ${palette.primary.dark}`,
  },
  iconCheckReady: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: '1px solid #DDE0E3',
  },
  check: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${palette.primary.dark}`,
  },
  checkReady: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DDE0E3',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: `${palette.primary.main}!important`,
    marginTop: spacing(-3 / 2),
    marginLeft: spacing(-3 / 2),
  },
  textNote: {
    fontSize: 13,
    width: '100%',
    fontWeight: 500,
    color: palette.primary.contrastText,
  },
}))
