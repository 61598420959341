import * as ls from 'local-storage'
import axios, { AxiosRequestConfig } from 'axios'
import { objectToFormData } from 'object-to-formdata'
import { encode } from 'js-base64'
import { ApiAnswerStatus, TOKEN } from '../types'

const {
  REACT_APP_API_URL = '/',
  REACT_APP_API_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_API_AUTH_CLIENT_SECRET: CLIENT_SECRET,
} = process.env

const axiosClient = axios.create({
  baseURL: `${REACT_APP_API_URL.replace(/^\/+/, '')}/`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Basic ${encode(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
  },
})

const apiFetchData = async (config: AxiosRequestConfig) => {
  return await axiosClient
    .request(config)
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 400) {
        if (e.response.data.error === 'invalid_grant') {
          throw e
        }
        return e.response.data
      }
      throw e
    })
}

const reFetchPrivateAPIToken = (refreshToken: string) =>
  apiFetchData({
    url: '/oauth/token',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    data: objectToFormData({
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    }),
  })

const fetchPrivateAPIToken = async (username: string, password: string) => {
  return await apiFetchData({
    url: '/oauth/token',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    data: objectToFormData({
      grant_type: 'password',
      username,
      password,
    }),
  })
    .then((res) => {
      ls.set(TOKEN.PRIVATE, res)
    })
    .catch((e) => {
      throw e
    })
}

const withPrivateAPIToken = async (config: AxiosRequestConfig) => {
  const token = ls.get<{ access_token?: string; refresh_token?: string }>(TOKEN.PRIVATE)

  if (!(token && token.access_token)) {
    return apiFetchData(config)
  } else {
    return await apiFetchData({
      ...config,
      headers: { Authorization: `Bearer ${token.access_token}` },
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        if (e.response.status === ApiAnswerStatus.UNAUTHENTICATED) {
          console.log('токен нужно обновить - рефреш токен')
          return reFetchPrivateAPIToken(token.refresh_token || '')
            .then((result) => {
              ls.remove(TOKEN.PRIVATE)
              ls.set(TOKEN.PRIVATE, result)

              return apiFetchData({
                ...config,
                headers: {
                  Authorization: `Bearer ${result.access_token}`,
                },
              })
            })
            .catch((err) => {
              if (err.response.status === ApiAnswerStatus.UNAUTHENTICATED) {
                ls.remove(TOKEN.PRIVATE)
                throw err
              } else {
                return apiFetchData({ ...config })
              }
            })
        } else if (e.response.status === ApiAnswerStatus.NEED_FULL_REGISTER) {
          throw e.response
        } else {
          throw e
        }
      })
  }
}

const fetchPublicAPIToken = async () => {
  return await apiFetchData({
    url: '/oauth/token',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    data: objectToFormData({
      grant_type: 'client_credentials',
    }),
  })
    .then((res) => {
      ls.set(TOKEN.PUBLIC, res)
      return res
    })
    .catch((e) => {
      console.error('ошибка запроса токена', e)
      throw e
    })
}

const withPublicAPIToken = async (config: AxiosRequestConfig) => {
  const token = ls.get<{ access_token?: string; refresh_token?: string }>(TOKEN.PUBLIC)

  if (token && token.access_token) {
    return await apiFetchData({
      ...config,
      headers: { Authorization: `Bearer ${token.access_token}` },
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        switch (e.response.status) {
          case ApiAnswerStatus.UNAUTHENTICATED:
            console.log('токен нужно запросить новый public-токен')
            ls.remove(TOKEN.PUBLIC)
            return fetchPublicAPIToken().then((r: any) => {
              return apiFetchData({
                ...config,
                headers: {
                  Authorization: `Bearer ${r.access_token}`,
                },
              })
            })
          case ApiAnswerStatus.BAD_REQUEST:
            return e.response.data
          case ApiAnswerStatus.UNPROCESSABLE:
            return e.response.data
          default:
            return {
              status: 0,
              message: { error: ['Сервис временно не доступен. Пожалуйста, попробуйте позже'] },
            }
        }
      })
  }
  return fetchPublicAPIToken().then((res: any) => {
    return apiFetchData({
      ...config,
      headers: {
        Authorization: `Bearer ${res.access_token}`,
      },
    })
      .then((r) => {
        return r
      })
      .catch((e) => {
        throw e
      })
  })
}

export { apiFetchData, withPrivateAPIToken, withPublicAPIToken, fetchPrivateAPIToken }
