import { FC, JSX } from 'react'
import { ToastContainer } from 'react-toastify'
import { DetectDevice, Install } from '../components'
import { AppPwa } from './app-pwa'
import { TOAST } from '../types'
import { DesktopPage } from '../pages'

export const App: FC = (): JSX.Element => {
  return (
    <DetectDevice desktopPage={DesktopPage}>
      <ToastContainer containerId={TOAST.GLOBAL_CONTAINER_ID} />
      <Install />
      <AppPwa />
    </DetectDevice>
  )
}
