import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Button, Container, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HtmlReactParser from 'html-react-parser'
import * as process from 'process'
import { VKShareButton } from 'react-share'
import { useSearchParams } from 'react-router-dom'
import { Layout } from '../../components'
import { TestModal } from './test.modal'
import { Pyramid, Test } from './test.types'
import { getTest, getTestResult, getTestSurveyQuestion } from '../../api'
import { useReduxSelector } from '../../hooks'
import { aHref, colorTheme, definitionTheme } from '../../utils'
import { img, VkIcon } from '../../imgs'
import { SurveyModal } from './survey.modal'
import { themeGl } from '../../configs'
import { TestResult, TestTypes } from '../../types'

const pyramid: Pyramid[] = [
  {
    id: 1,
    width: 220,
    bgcolor: '#00B3CE',
    text: `Проактивная<br />медицинская помощь`,
  },
  {
    id: 2,
    width: 240,
    bgcolor: '#C77CCE',
    text: `Информированная<br />забота о здоровье`,
  },
  {
    id: 3,
    width: 265,
    bgcolor: '#C15549',
    text: 'Снижение токсической нагрузки',
  },
  {
    id: 4,
    width: 290,
    bgcolor: '#4E70B0',
    text: 'Отдых и управление стрессом',
  },
  {
    id: 5,
    width: 310,
    bgcolor: '#F5A449',
    text: 'Физическая активность и обмен веществ',
  },
  {
    id: 6,
    width: 335,
    bgcolor: '#3B8643',
    text: 'Полноценное питание и пищеварение',
  },
]

export const TestPage: FC = (): JSX.Element => {
  const classes = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: dataUser } = useReduxSelector((state) => state.auth)

  const { REACT_APP_STIKERPACK: STIKERPACK } = process.env

  const textShare = 'Я знаю, что такое пирамида здорового образа жизни и как взобраться на самый верх! Хочешь со мной?'
  const urlShare = 'https://vk.com/share.php?url=https://doterra-challenge.ru'

  const [test, setTest] = useState<Test | undefined>(undefined)
  const [survey, setSurvey] = useState<any | undefined>(undefined)
  const [testModal, setTestModal] = useState<boolean>(false)
  const [surveyModal, setSurveyModal] = useState<boolean>(false)
  const [testResult, setTestResult] = useState<TestResult | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)

  const [completed, setCompleted] = useState<boolean>(false)

  const uploadTestResult = useCallback(() => {
    getTestResult(TestTypes.TEST_2023).then(({ data, status }) => {
      if (data && status === 1) setTestResult(data)
      setLoading(false)
      setSearchParams({})
    })
  }, [])

  const uploadTest = useCallback(() => {
    getTest(TestTypes.TEST_2023).then(({ data, status }) => {
      if (status === 1) {
        setTest(data)
      }
    })
  }, [])

  const uploadSurvey = useCallback(() => {
    getTestSurveyQuestion().then(({ data, status }) => {
      if (status === 1) {
        setSurvey(data)
      }
    })
  }, [])

  const onUploadStikerPack = () => {
    aHref(`${testResult?.link || STIKERPACK}`)
  }

  const onOpenSurvey = () => {
    setSurveyModal((prev) => !prev)
  }

  const onCloseSurvey = () => {
    setSurveyModal((prev) => !prev)
  }

  useEffect(() => {
    if (searchParams.get('completed')) {
      setCompleted(!!searchParams.get('completed'))
    }
  }, [searchParams])

  useEffect(() => {
    uploadTestResult()
    uploadTest()
    uploadSurvey()
  }, [])

  return (
    <Layout>
      {test && (
        <TestModal
          id={test.id}
          show={testModal}
          pyramid={pyramid}
          dataSurvey={survey}
          data={test.test_questions}
          onClose={() => setTestModal((prev) => !prev)}
        />
      )}

      {survey && test && <SurveyModal show={surveyModal} onClose={onCloseSurvey} id={test?.id} data={survey} />}

      <Box className={classes.page}>
        <Container maxWidth="sm">
          {testResult ? (
            <>
              <Box className={classes.title}>
                Поздравляем!
                <br />
                Вы ответили на все вопросы.
              </Box>
              <Box className={classes.textResult}>{testResult.recommendation}</Box>
              <Box className={classes.imgResult}>
                {testResult.test_level?.primary_media?.src_link && (
                  <img src={testResult.test_level?.primary_media?.src_link} alt="" />
                )}
              </Box>
              <Box className={classes.titleResult}>{testResult.test_level.description}</Box>
              <Box className={classes.textResult}>
                {testResult && HtmlReactParser(testResult.test_level.content)}
                <Box mt="15px">
                  Узнайте{' '}
                  <a
                    href="https://media.doterra.com/ru-otg/ru/brochures/live-guide.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    больше
                  </a>{' '}
                  о пирамиде здорового образа жизни!
                </Box>
              </Box>
              {!completed && (
                <>
                  <Grid container justifyContent="center" mb="20px">
                    <Grid item width={335}>
                      {/* <Button */}
                      {/*  fullWidth */}
                      {/*  color="primary" */}
                      {/*  component={Link} */}
                      {/*  variant="contained" */}
                      {/*  sx={{ border: '2px solid #B786B9' }} */}
                      {/* eslint-disable-next-line max-len */}
                      {/*  href={`${urlShare}${textShare}&image=${dataUser.test_result.test_level.primary_media.src_link}`} */}
                      {/* > */}
                      {/* eslint-disable-next-line max-len */}
                      {/*  <Grid container justifyContent="center" alignItems="center" mt="-17px" height={40} spacing={1}> */}
                      {/*    <Grid item> */}
                      {/*      <Box mt="-5px">Поделиться</Box> */}
                      {/*    </Grid> */}
                      {/*    <Grid item height={54}> */}
                      {/*      <VkIcon /> */}
                      {/*    </Grid> */}
                      {/*  </Grid> */}
                      {/* </Button> */}
                      <VKShareButton
                        title={textShare}
                        style={{ width: '100%' }}
                        url="https://doterra-challenge.ru/share/vk/test-share.html"
                        image={testResult.test_level?.primary_media?.src_link || ''}
                      >
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          sx={{ border: definitionTheme({ calendar: 'none' }, `2px solid ${colorTheme}`) as string }}
                        >
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            mt="-17px"
                            height={40}
                            spacing={1}
                          >
                            <Grid item>
                              <Box mt="-5px">Поделиться</Box>
                            </Grid>
                            <Grid item height={54}>
                              <VkIcon />
                            </Grid>
                          </Grid>
                        </Button>
                      </VKShareButton>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item width={335}>
                      {testResult.link ? (
                        <Button fullWidth color="secondary" variant="contained" onClick={onUploadStikerPack}>
                          Скачать стикерпак
                        </Button>
                      ) : (
                        <Button fullWidth color="secondary" variant="contained" onClick={onOpenSurvey}>
                          Получить подарок
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ) : (
            !loading && (
              <>
                <Box className={classes.title}>
                  Ваш навигатор
                  <br />
                  по образу жизни
                </Box>
                <Box className={classes.text}>
                  Пирамида здорового образа жизни dōTERRA — это ваша лестница к хорошему самочувствию. Узнайте, на какой
                  ступени вы сейчас, и получите наши персональные рекомендации, чтобы быстрее добраться до самого верха!
                </Box>
                <Grid container justifyContent="center">
                  <Grid item width={335}>
                    {pyramid.map((k: Pyramid, i: number) => {
                      const { width, bgcolor, text } = k

                      return (
                        <Box key={`row-${i}`} sx={{ width, bgcolor }} className={classes.row}>
                          {HtmlReactParser(text)}
                        </Box>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" mt="45px">
                  <Grid item width={335}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => setTestModal((prev) => !prev)}
                    >
                      Пройти тест
                    </Button>
                  </Grid>
                </Grid>
              </>
            )
          )}
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    width: '100%',
    padding: '30px 0 120px',
    backgroundRepeat: 'no-repeat',
    // minHeight: 'calc(100vh - 30px)',
    backgroundSize: themeGl === 'calendar' ? '85vw 200vw' : '345px 341px',
    backgroundImage: themeGl === 'calendar' ? `url(${img.bgGreetCalendar})` : 'none',
    backgroundPosition: themeGl === 'calendar' ? 'left top' : 'bottom -40px left -120px',
    backgroundColor: themeGl === 'calendar' ? palette.background.default : palette.primary.main,
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    margin: '0 0 20px',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  text: {
    fontSize: 15,
    fontWeight: 500,
    margin: '0 0 45px',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  row: {
    height: 40,
    fontSize: 12,
    fontWeight: 800,
    display: 'flex',
    lineHeight: 1.3,
    borderRadius: 50,
    textAlign: 'center',
    margin: '0 auto 4px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
  imgResult: {
    width: '100%',
    minHeight: 250,
    margin: '30px 0 20px',
    '& img': { width: '100%' },
  },
  titleResult: {
    fontSize: 18,
    fontWeight: 700,
    margin: '20px 0 10px',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  textResult: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 30,
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
    '& a': { color: themeGl === 'calendar' ? palette.primary.main : palette.primary.dark },
  },
}))
