import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Grid, IconButton, Theme } from '@mui/material'
import { useForm } from 'react-hook-form'
import { FormProps, JournalList, JournalTrackersList } from '../../../types'
import { trackerStore } from '../../../api'
import { useReduxActions } from '../../../hooks'
import { SubmitButton, TextInput } from '../../../components'
import { colorTheme } from '../../../utils'

interface IJournalTracker {
  now: boolean
  ready: boolean
  onClose: () => void
  loadTrackers: () => void
  trackersList: JournalList
}

type Answer = { id: number; note: string }

export const JournalTracker: React.FC<IJournalTracker> = ({
  now = false,
  ready = false,
  trackersList,
  onClose = () => {},
  loadTrackers = () => {},
}): JSX.Element => {
  const classes = useStyles()
  const { setAlert } = useReduxActions()

  const hookForm = useForm<any>({
    defaultValues: {},
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<any>>({
    data: {},
    processed: false,
  })

  const [change, setChange] = useState<boolean>(false)
  const [empty, setEmpty] = useState<boolean>(true)

  const onSubmitForm = (data: any) => {
    setFormProps({ ...formProps, processed: true })

    const trackers: Answer[] = []

    const keys = Object.keys(data)

    keys.forEach((key) => {
      if (data[key].length !== 0) trackers.push({ id: Number(key), note: data[key] })
    })

    if (trackers.length > 0) {
      trackerStore({ trackers })
        .then((res) => {
          setFormProps({ ...formProps, processed: false })

          if (res.status === 1) {
            loadTrackers()
            onClose()
          } else {
            setAlert({ alert: { message: { info: res.message.alert } } })
          }
        })
        .catch((err) => {
          setFormProps({ ...formProps, processed: false })
          setAlert({ alert: { message: { error: err.response.data.message } } })
        })
    } else {
      setFormProps({ ...formProps, processed: false })

      setAlert({
        alert: {
          message: { info: ['Не заполнено ни одного сообщения.'] },
        },
      })
    }
  }

  const check = {
    icon: <span className={classes.iconCheck} />,
    checked: (
      <span className={classes.iconCheck}>
        <Box className={classes.check}>
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 5.26264L4.73738 8.00001L10.7374 2"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </span>
    ),
  }

  useEffect(() => {
    let changes = 0
    let trackLength = 0

    for (let i = 0; trackersList.trackers[0].trackers.length > i; i++) {
      trackLength++
      if (trackersList.trackers[0].trackers[i].note) {
        setEmpty(false)
        changes++
      }
    }

    setChange(trackLength > changes)
  }, [])

  return (
    <Box className={classes.tracker}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton color="primary" onClick={onClose}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 2L2 9C2 9 8.36321 15.3632 9 16"
                stroke={colorTheme}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Grid>
        <Grid item>
          <Box className={classes.header}>Мой дневник</Box>
        </Grid>
        <Grid item>
          <Box width={34} height={34} />
        </Grid>
      </Grid>
      <Box mt={3}>
        <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
          {trackersList &&
            trackersList.trackers[0].trackers.map((k: JournalTrackersList, i: number) => {
              return (
                <>
                  {ready || k.note ? (
                    <>
                      {k.note && (
                        <Box className={classes.ready}>
                          <Box className={classes.readyTitle}>{k.habit.name}</Box>
                          <Box className={classes.readyText}>{k.note}</Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <Box className={classes.label}>{k.habit.name}</Box>
                      <TextInput
                        label=""
                        rows={3}
                        multiline
                        form={hookForm}
                        required={false}
                        name={`${k.id}`}
                        placeholder="Ваше сообщение"
                        disabled={formProps.processed || ready}
                      />
                    </>
                  )}
                </>
              )
            })}
          {ready && empty && (
            <Grid container justifyContent="center" alignItems="center" height={200}>
              <Grid item>
                <Box className={classes.readyText} fontWeight={600} textAlign="center">
                  Дневник не был заполнен
                  <br />в выбранный вами день
                </Box>
              </Grid>
            </Grid>
          )}
          <Box maxWidth={350} width="100%" m="30px auto 115px">
            {((!ready && !now) || (!ready && change && now)) && (
              <SubmitButton
                fullWidth
                title="Сохранить"
                disabled={formProps.processed}
                processed={formProps.processed}
              />
            )}
          </Box>
        </form>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  tracker: {
    '& .MuiOutlinedInput-input': {
      padding: '0px!important',
    },
  },
  header: {
    fontSize: 16,
    fontWeight: 600,
    color: palette.primary.dark,
    transform: 'translate(0, 2px)',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: '20px 0 15px',
    color: palette.primary.contrastText,
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    margin: '0 0 20px',
    color: palette.primary.contrastText,
  },
  iconCheck: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: `1px solid ${colorTheme}`,
  },
  check: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${colorTheme}`,
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    margin: '15px 0',
    color: palette.primary.contrastText,
  },
  ready: {
    padding: 15,
    marginBottom: 10,
    borderRadius: 12,
    border: '1px solid #F0F1F8',
    boxShadow: '0px 4px 12px #EBEDF4',
  },
  readyTitle: {
    fontSize: 17,
    fontWeight: 700,
    margin: '0 0 10px',
    color: palette.primary.contrastText,
  },
  readyText: {
    fontSize: 15,
    fontWeight: 500,
    color: palette.primary.contrastText,
  },
}))
