import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Grid,
  Radio,
  Theme,
  Button,
  IconButton,
  RadioGroup,
  CircularProgress,
  FormControlLabel,
} from '@mui/material'
import HtmlReactParser from 'html-react-parser'
import { Trackers, MainTrackerList } from '../../../types'
import { trackerStore } from '../../../api'
import { useReduxActions } from '../../../hooks'
import { colorTheme } from '../../../utils'

interface IMainTracker {
  ready: boolean
  now: boolean
  onClose: () => void
  loadTrackers: () => void
  trackersList: MainTrackerList
}

export const MainTracker: React.FC<IMainTracker> = ({
  now = false,
  ready = false,
  trackersList,
  onClose = () => {},
  loadTrackers = () => {},
}): JSX.Element => {
  const { setAlert } = useReduxActions()
  const classes = useStyles()

  const [answer, setAnswer] = useState<number[]>([])
  const [disable, setDisable] = useState<boolean>(false)
  const [change, setChange] = useState<boolean>(false)

  const handleChange = (data: { id: number; index: number }) => {
    const answerCurrent = JSON.parse(JSON.stringify(answer))
    answerCurrent[data.index] = Number(data.id)
    setAnswer([...answerCurrent])
  }

  const onSubmit = () => {
    setDisable(true)

    const trackers = []

    for (let i = 0; answer.length > i; i++) {
      if (answer[i] !== 0) {
        trackers.push({ id: answer[i] })
      }
    }

    if (trackers.length > 0) {
      trackerStore({ trackers })
        .then((res) => {
          setDisable(false)

          if (res.status === 1) {
            loadTrackers()
            onClose()
          } else {
            setAlert({ alert: { message: { info: res.message.alert } } })
          }
        })
        .catch((err) => {
          setDisable(false)
          setAlert({ alert: { message: { error: err.response.data.message } } })
        })
    } else {
      setDisable(false)

      setAlert({
        alert: {
          message: { info: ['Не выбрано ни одной привычки.'] },
        },
      })
    }
  }

  useEffect(() => {
    if (trackersList.trackers.length > 0) {
      const answerDefault = []
      let changes = 0

      for (let i = 0; trackersList.trackers.length > i; i++) {
        answerDefault.push(0)
        if (trackersList.trackers[i].change_at) changes++
      }

      setAnswer(answerDefault)
      setChange(answerDefault.length > changes)
    }
  }, [trackersList])

  const check = {
    icon: <span className={classes.iconCheck} />,
    iconReady: <span className={classes.iconCheckReady} />,
    checked: (
      <span className={classes.iconCheck}>
        <Box className={classes.check}>
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 5.26264L4.73738 8.00001L10.7374 2"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </span>
    ),
    checkedReady: (
      <span className={classes.iconCheckReady}>
        <Box className={classes.checkReady}>
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 5.26264L4.73738 8.00001L10.7374 2"
              stroke="#4A5967"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </span>
    ),
  }

  return (
    <Box className={classes.tracker}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton color="primary" onClick={onClose}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 2L2 9C2 9 8.36321 15.3632 9 16"
                stroke={colorTheme}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Grid>
        <Grid item>
          <Box className={classes.header}>Трекер привычек</Box>
        </Grid>
        <Grid item>
          <Box width={34} height={34} />
        </Grid>
      </Grid>
      <Box className={classes.text}>Постарайтесь выполнить хотя бы три пункта из списка в течение дня.</Box>
      <Box className={classes.title}>Сегодня у меня получилось:</Box>
      {trackersList && (
        <RadioGroup>
          <Grid container direction="column">
            {trackersList.trackers.map((k: Trackers, i: number) => {
              return (
                <Grid key={`list${i}`} item>
                  <FormControlLabel
                    name={`${k.habit.id}`}
                    value={`${k.habit.id}`}
                    disabled={disable || ready || !!k.change_at}
                    control={
                      <Radio
                        color="secondary"
                        disabled={disable || ready}
                        checked={answer[i] === k.id || !!k.change_at}
                        icon={disable || ready || k.change_at ? check.iconReady : check.icon}
                        checkedIcon={disable || ready || k.change_at ? check.checkedReady : check.checked}
                        onClick={() => {
                          handleChange({ id: answer[i] === 0 ? k.id : 0, index: i })
                        }}
                      />
                    }
                    label={HtmlReactParser(k.habit.name)}
                  />
                </Grid>
              )
            })}
          </Grid>
        </RadioGroup>
      )}
      <Box maxWidth={350} width="100%" m="30px auto 115px">
        {((!ready && !now) || (!ready && change && now)) && (
          <Button fullWidth variant="contained" color="secondary" disabled={disable} onClick={onSubmit}>
            Сохранить
            {disable && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  tracker: {},
  header: {
    fontSize: 16,
    fontWeight: 600,
    color: palette.primary.dark,
    transform: 'translate(0, 2px)',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: '0 0 15px',
    color: palette.primary.contrastText,
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    margin: '20px 0 15px',
    color: palette.primary.contrastText,
  },
  iconCheck: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: `1px solid ${palette.primary.dark}`,
  },
  iconCheckReady: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: '1px solid #DDE0E3',
  },
  check: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${palette.primary.dark}`,
  },
  checkReady: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DDE0E3',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: `${palette.primary.main}!important`,
    marginTop: spacing(-3 / 2),
    marginLeft: spacing(-3 / 2),
  },
}))
