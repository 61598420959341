/* eslint-disable max-len */
import React from 'react'
import { colorTheme } from '../../utils'

interface IArrowBackIcon {
  width?: number
  height?: number
}

export const ArrowBackIcon: React.FC<IArrowBackIcon> = ({ width = 32, height = 32 }): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill={colorTheme} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2071 10.2929C19.5976 10.6834 19.5976 11.3166 19.2071 11.7071L14.9142 16L19.2071 20.2929C19.5976 20.6834 19.5976 21.3166 19.2071 21.7071C18.8166 22.0976 18.1834 22.0976 17.7929 21.7071L12.7929 16.7071C12.4024 16.3166 12.4024 15.6834 12.7929 15.2929L17.7929 10.2929C18.1834 9.90237 18.8166 9.90237 19.2071 10.2929Z"
      fill="white"
    />
  </svg>
)
