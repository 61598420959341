import React, { useState } from 'react'
import { Box, Button, Container, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HtmlReactParser from 'html-react-parser'
import * as process from 'process'
import { Layout } from '../../components'
import { img, MoreBackIcon } from '../../imgs'
import { assets } from './assets'
import { ListUseful, Advice, Products } from './type'
import { InfoDetailModal, InfoModal } from './modals'
import { aHref, definitionTheme } from '../../utils'
import { themeGl } from '../../configs'

type ModalProps = {
  show: boolean
  item: ListUseful | undefined
}

type ModalDetailProps = {
  show: boolean
  info: Advice | Products | undefined
  type: 'advice' | 'products' | undefined
}

export const InfoPage: React.FC = (): JSX.Element => {
  const classes = useStyles()

  const { REACT_APP_API_LINK: URL } = process.env

  const [infoModal, setInfoModal] = useState<ModalProps>({
    show: false,
    item: undefined,
  })

  const [infoDetailModal, setInfoDetailModal] = useState<ModalDetailProps>({
    show: false,
    type: undefined,
    info: undefined,
  })

  const onOpenInfo = (item: ListUseful) => {
    setInfoModal({ item, show: true })
  }

  const onCloseInfo = () => {
    setInfoModal({ ...infoModal, show: false })
  }

  const onOpenInfoDetail = (info: Advice | Products, type: 'advice' | 'products', text: string) => {
    setInfoModal({ ...infoModal, show: false })
    setTimeout(() => setInfoDetailModal({ info: { ...info, title: `${text}: ${info.name}` }, type, show: true }), 400)
  }

  const onBackInfoDetail = () => {
    setInfoDetailModal({ ...infoDetailModal, show: false })
    setTimeout(() => setInfoModal({ ...infoModal, show: true }), 400)
  }

  const onCloseInfoDetail = () => {
    setInfoDetailModal({ ...infoDetailModal, show: false })
  }

  const onPdf = (link: string) => {
    aHref(`${URL}${link}`)
  }

  return (
    <Layout>
      <Box className={classes.page}>
        {infoModal.item && (
          <InfoModal
            className={classes}
            item={infoModal.item}
            show={infoModal.show}
            onClose={onCloseInfo}
            onOpenInfoDetail={(info: Advice | Products, type: 'advice' | 'products', text: string) =>
              onOpenInfoDetail(info, type, text)
            }
          />
        )}

        {infoDetailModal.info && (
          <InfoDetailModal
            type={infoDetailModal.type}
            show={infoDetailModal.show}
            info={infoDetailModal.info}
            onClose={onCloseInfoDetail}
            onBackInfoDetail={onBackInfoDetail}
          />
        )}

        <Container maxWidth="sm">
          <Box className={classes.title}>Полезная информация</Box>
          {listUseful.map((k: ListUseful, i: number) => {
            return (
              <Button fullWidth key={`info-${i}`} onClick={() => onOpenInfo(k)} sx={{ padding: 0 }}>
                <Box className={classes.contentBtnCurrent}>
                  <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Box
                        color={themeGl === 'challenge' && i === 0 ? 'warning.main' : undefined}
                        className={classes.contentBtnTitleCurrent}
                      >
                        {HtmlReactParser(k.text)}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className={classes.moreIcon}>
                        <MoreBackIcon />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Button>
            )
          })}
          <Button fullWidth sx={{ padding: 0 }} onClick={() => onPdf('/media/doc/useful_products')}>
            <Box className={classes.contentBtnCurrent}>
              <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Box className={classes.contentBtnTitleCurrent}>Полезная продукция</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.moreIcon}>
                    <MoreBackIcon />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Button>
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    width: '100%',
    padding: '40px 0 100px',
    backgroundRepeat: 'no-repeat',
    minHeight: 'calc(100vh - 140px)',
    backgroundSize: definitionTheme({ calendar: '85vw 200vw' }, '345px 341px') as string,
    backgroundImage: definitionTheme({ calendar: `url(${img.bgGreetCalendar})` }, 'none') as string,
    backgroundPosition: definitionTheme({ calendar: 'left top' }, 'bottom -40px left -120px') as string,
    backgroundColor: definitionTheme({ calendar: palette.background.default }, palette.primary.main) as string,
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 20,
    color: definitionTheme(
      { calendar: palette.primary.main, challenge: palette.warning.main },
      palette.primary.contrastText
    ) as string,
  },
  contentBtnCurrent: {
    width: '100%',
    marginBottom: 8,
    borderRadius: 12,
    padding: '12px 15px',
    border: definitionTheme({ calendar: 'none' }, '1px solid #F0F1F8') as string,
    backgroundColor: definitionTheme({ calendar: '#006266' }, 'transparent') as string,
    boxShadow: definitionTheme({ calendar: '0px 4px 12px #006266' }, '0px 4px 12px #EBEDF4') as string,
  },
  contentBtnCurrentModal: {
    width: '100%',
    marginBottom: 8,
    borderRadius: 12,
    padding: '12px 15px',
    border: '1px solid #F0F1F8',
    boxShadow: '0px 4px 12px #EBEDF4',
  },
  contentBtnTitleCurrent: {
    fontSize: 17,
    fontWeight: 700,
    lineHeight: 1.2,
    textAlign: 'left',
    textTransform: 'none',
    color: definitionTheme({ calendar: palette.primary.main, challenge: '#A1AAAD' }, palette.primary.dark) as string,
  },
  contentBtnTitleCurrentModal: {
    fontSize: 17,
    fontWeight: 700,
    lineHeight: 1.2,
    textAlign: 'left',
    textTransform: 'none',
    color: definitionTheme({ challenge: '#A1AAAD' }, palette.primary.dark) as string,
  },
  contentBtnTextCurrent: {
    fontSize: 14,
    marginTop: 5,
    lineHeight: 1.2,
    fontWeight: 600,
    textAlign: 'left',
    textTransform: 'none',
    color: definitionTheme({ calendar: palette.primary.main }, '#939CA4') as string,
  },
  contentBtnTextCurrentModal: {
    fontSize: 14,
    marginTop: 5,
    lineHeight: 1.2,
    fontWeight: 600,
    color: '#939CA4',
    textAlign: 'left',
    textTransform: 'none',
  },
  moreIcon: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const listUseful: ListUseful[] = [
  {
    slug: 'challenge',
    text: 'Материалы челленджа',
    pdf: [
      {
        title: 'Что такое Челлендж?',
        link: '/media/doc/how_go_challenge',
      },
      {
        title: 'Продукты для Челленджа',
        link: '/media/doc/products_challenge',
      },
    ],
  },
  {
    slug: 'food',
    text: 'Полноценное питание и пищеварение',
    advice: {
      name: 'Советы',
      title: 'Советы здорового питания',
      text:
        'Здоровое питание наполнит нас энергией для занятий спортом и восстановления сил. Оно поможет соблюдать ' +
        'правильный режим отдыха, регулировать воздействие стресса, снижать уровень токсинов и сохранять здоровье на ' +
        'протяжении долгих лет*.<br /><ul><li>Ешьте как можно больше разноцветных фруктов и овощей.</li>' +
        '<li>Уберите из рациона полуфабрикаты.</li><li>Пейте воду в течение дня.</li>' +
        '<li>Добавляйте в воду цитрусовые эфирные масла для яркого вкуса.</li>' +
        '<li>В воскресенье составьте план питания и список покупок на неделю.</li></ul>',
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, находящимся под ' +
        'наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    products: {
      name: 'Продукты',
      title: 'Лучшие продукты для здорового питания',
      data: [
        {
          img: assets.foodMeta,
          title: 'Смесь MetaPWR™',
          text:
            'Уникальная композиция эфирных масел грейпфрута, лимона, мяты, имбиря и корицы в MetaPWR™ поддержит ' +
            'здоровый метаболизм, помогая контролировать аппетит и заряжая вас энергией для формирования полезных ' +
            'привычек.',
        },
        {
          img: assets.foodVitality,
          title: 'Комплекс dōTERRA<br />Lifelong Vitality® (LLV)',
          text:
            'Появление LLV в вашем рационе гарантирует получение всех необходимых витаминов и минералов, которые ' +
            'требуются организму для нормальной работы. Комплекс поддерживает иммунную, нервную и ' +
            'сердечно-сосудистую системы, способствует здоровому росту, функционированию и восстановлению клеток.',
        },
        {
          img: assets.foodLemon,
          title: 'Лимон',
          text: 'Эфирное масло лимона способствует расслаблению и поддерживает здоровое пищеварение*.',
        },
        {
          img: assets.foodTurmeric,
          title: 'Куркума',
          text:
            'Благотворно воздействует на иммунную систему и поддерживает ее правильную работу. Очищает, ' +
            'разглаживает кожу и придает ей сияние*.',
        },
        {
          img: assets.foodCardamom,
          title: 'Кардамон',
          text: 'Поддерживает здоровую работу желудочно-кишечного тракта и дыхательной системы*.',
        },
        {
          img: assets.foodPrime,
          title: 'Клеточный комплекс<br />dōTERRA DDR Prime®',
          text:
            'Уникальная смесь эфирных масел с природными антиоксидантами, которые поддерживают здоровье и ' +
            'целостность клеток*.',
        },
      ],
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    pdf: [
      {
        title: 'Рецепты',
        link: '/media/doc/food_receipt',
      },
      {
        title: 'Польза воды',
        link: '/media/doc/food_water',
      },
    ],
  },
  {
    slug: 'physical',
    text: 'Физическая активность и хороший обмен веществ',
    advice: {
      name: 'Советы',
      title: 'Советы физической нагрузки',
      text:
        'Независимо от того, насколько серьезно вы занимаетесь спортом, ежедневные физические нагрузки важны для ' +
        'организма. Они поддерживают сердечно-сосудистую и костно-мышечную системы, а также психологическое ' +
        'благополучие, помогая вам оставаться активными и сильными*.<br /><ul><li>Выделяйте время в графике для ' +
        'регулярных занятий спортом.</li><li>Занимайтесь вместе с другом или группой.</li>' +
        '<li>Расскажите о своих целях тем, кому доверяете.</li>' +
        '<li>Не бойтесь пробовать новые виды упражнений.</li></ul>',
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    products: {
      name: 'Продукты',
      title: 'Лучшие продукты для физической нагрузки',
      data: [
        {
          img: assets.physicalStickDeepBlue,
          title: 'Стик Deep Blue®',
          text: 'Нанесите до или после тренировки (или по необходимости) для охлаждения и расслабления мышц*.',
        },
        {
          img: assets.physicalLemon,
          title: 'Лимон',
          text: 'Добавьте каплю в воду и пейте ее после тренировки, чтобы поддерживать водно-солевой баланс*.',
        },
        {
          img: assets.physicalDeepBlue,
          title: 'Полифенольный комплекс<br />dōTERRA Deep Blue®',
          text:
            'Для успокаивающего эффекта при мышечном дискомфорте принимайте одну капсулу утром и одну ' +
            'вечером во время еды*.',
        },
      ],
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    pdf: [
      {
        title: 'Рецепты',
        link: '/media/doc/physical_receipt',
      },
      {
        title: 'Упражнения',
        link: '/media/doc/physical_exercises',
      },
    ],
  },
  {
    slug: 'stress',
    text: 'Отдых и управление стрессом',
    advice: {
      name: 'Советы',
      title: 'Советы отдыха и управления стрессом',
      text:
        'Регулярный крепкий сон помогает вашему телу и мозгу отдохнуть. Благодаря сну организм восстанавливает силы ' +
        'перед следующим днем. Кроме того, практики медитации, позитивного мышления и саморазвития благотворно ' +
        'сказываются на психическом, эмоциональном и физическом здоровье*.<br /><ul><li>Создайте благоприятную ' +
        'атмосферу перед сном. В комнате должно быть темно, тихо и прохладно.</li><li>Не употребляйте алкоголь, ' +
        'никотин и кофеин в течение дня — они могут помешать вам заснуть.</li>' +
        '<li>Подготовьте комфортное место для сна. При необходимости замените подушку, матрас или одеяло.</li>' +
        '<li>Регулярно занимайтесь спортом (но не перед сном). Тренировки способствуют улучшению качества сна.</li>' +
        '<li>Избавьтесь от раздражителей, отключите телефон и другие устройства со светящимися экранами за 1–2 часа ' +
        'до сна.</li><li>Почитайте книгу перед сном, помедитируйте или сделайте запись в дневнике.</li>' +
        '<li>Примите перед сном теплую ванну или выпейте чашку успокаивающего чая.</li>' +
        '<li>Ложитесь и вставайте в одно и то же время, чтобы настроить внутренние часы (даже по выходным).</li></ul>',
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    products: {
      name: 'Продукты',
      title: 'Лучшие продукты для<br />отдыха и управления стрессом',
      data: [
        {
          img: assets.stressLavender,
          title: 'Лаванда',
          text:
            'Перед сном создайте расслабляющую атмосферу с помощью масла лаванды:<br /><ul><li>капните каплю ' +
            'масла на подушку;</li><li>нанесите на ступни;</li><li>добавьте несколько капель в диффузор*.</li></ul>',
        },
        {
          img: assets.stressSerenity,
          title: 'dōTERRA Serenity™ в капсулах',
          text:
            'Этот комплекс поможет сделать сон крепким и спокойным, снимая ощущение вялости по утрам:<br />' +
            '<ul><li>принимайте 1–2 капсулы перед сном*.</li></ul>',
        },
        {
          img: assets.stressBalance,
          title: 'dōTERRA Balance®',
          text:
            'Используйте смесь масел dōTERRA Balance®, чтобы настроиться на продуктивный день:<br />' +
            '<ul><li>нанесите масло на ступни сразу после пробуждения или душа;</li><li>капните масло на ладонь и ' +
            'массирующими движениями нанесите на заднюю часть шеи перед началом рабочего дня;</li><li>добавьте масло ' +
            'в диффузор, чтобы создать умиротворяющую атмосферу во время напряженного периода*.</li></ul>',
        },
      ],
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    pdf: [
      {
        title: 'Рецепты',
        link: '/media/doc/stress_receipt',
      },
    ],
  },
  {
    slug: 'toxicity',
    text: 'Снижение токсической нагрузки',
    advice: {
      name: 'Советы',
      title: 'Советы токсической нагрузки',
      text:
        'Токсическая нагрузка — это общий объем накопленных в теле токсичных веществ и их нагрузка на организм. ' +
        'Снизить ее и поддержать здоровье внутренних органов помогают правильное питание, физическая активность и ' +
        'регулярная детоксикация*.<br /><ul><li>Замените привычные чистящие средства на натуральные, такие как ' +
        'концентрат ' +
        '<a href="https://www.doterra.com/RU/ru_RU/p/on-guard-oil" target="_blank" rel="noopener noreferrer">dōTERRA ' +
        'On Guard®</a></li><li>Вы можете самостоятельно приготовить ' +
        // eslint-disable-next-line max-len
        '<a href="https://www.doterra.com/RU/ru_RU/blog/wellness-topics-natural-cleaning-with-essential-oils" target="_blank" rel="noopener noreferrer">' +
        'универсальный чистящий спрей</a> на основе эфирных масел.</li>' +
        '<li>Замените привычные уходовые продукты на натуральные, например, очищающий гель для лица ' +
        '<a href="https://www.doterra.com/RU/ru_RU/p/facial-cleanser" target="_blank" rel="noopener noreferrer">' +
        'Facial Cleanser</a>.</li><li>Используйте вместо ароматических свечей эфирные масла дикого апельсина и ' +
        'лимона.</li><li>Составьте список любимых натуральных продуктов и найдите рецепты, в которых их можно ' +
        'использовать.</li><li>Ежедневно занимайтесь спортом, чтобы помочь организму избавиться от токсинов.</li></ul>',
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    products: {
      name: 'Продукты',
      title: 'Лучшие продукты для токсической нагрузки',
      data: [
        {
          img: assets.cleanHand,
          title: 'Набор abōde: чистые руки',
          text:
            'Концентрированное мыло для рук с помощью диспенсера превращается в воздушную пену. ' +
            'Бережно очищает кожу рук.',
        },
        {
          img: assets.cleanHome,
          title: 'Набор abode: чистый дом',
          text:
            'Концентрат для очистки поверхностей и диспенсер-распылитель помогут сделать ваш дом ' +
            'ослепительно чистым. Без токсинов.',
        },
        {
          img: assets.cleanDish,
          title: 'Набор abode: чистая посуда',
          text:
            'Жидкость для мытья посуды с формулой, обогащённой эфирным маслом грейпфрута, справится с ' +
            'любым загрязнением. С диспенсером удобнее.',
        },
        {
          img: assets.toxicityGrapefruit,
          title: 'Грейпфрут',
          text: 'Это эфирное масло хорошо известно своими очищающими свойствами*.',
        },
        {
          img: assets.toxicityZendocrine,
          title: 'Смесь dōTERRA Zendocrine®',
          text:
            'Поддерживает естественные процессы детоксикации в организме, фильтрационные функции печени, почек, ' +
            'толстой кишки, легких и кожи*.',
        },
        {
          img: assets.toxicityAssist,
          title: 'Комплекс GX Assist®',
          text:
            'Поддерживает нормальную работу пищеварительного тракта и защищает его от угроз, которые могут вызвать ' +
            'расстройство пищеварения*.',
        },
        {
          img: assets.toxicityDigestzen,
          title: 'Смесь DigestZen®',
          text:
            'Помогает снизить чувство дискомфорта в желудке и поддерживает естественные процессы в ' +
            'желудочно-кишечном тракте*.',
        },
      ],
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    pdf: [
      {
        title: 'Рецепты',
        link: '/media/doc/toxicity_receipt',
      },
    ],
  },
  {
    slug: 'awareness',
    text: 'Информированная забота о здоровье',
    advice: {
      name: 'Советы',
      title: 'Советы осознанной заботы о здоровье',
      text:
        'Осознанное отношение к организму может стать прочной основой для хорошего самочувствия. Воспользуйтесь ' +
        'нашими идеями проактивной заботы о здоровье, которые помогут вам всегда быть в отличной форме*.<br />' +
        '<ul><li>Посещайте врача хотя бы раз в год. Запишите своих детей на прием, если вы этого еще не сделали.' +
        '</li><li>Регулярно мойте руки и приучайте к этому ваших детей. Соблюдать гигиену приятнее с пеной для мытья ' +
        'рук <a href="https://www.doterra.com/RU/ru_RU/p/on-guard-oil" target="_blank" rel="noopener noreferrer">' +
        'dōTERRA On Guard®</a>.</li>' +
        '<li>Ежедневно принимая комплекс dōTERRA Lifelong Vitality®, вы снабдите организм мощными антиоксидантами, ' +
        'которые помогут поддерживать хорошее самочувствие*.</li>' +
        '<li>Неважно, сколько вам лет и как часто вы занимаетесь спортом: регулярные прогулки на свежем воздухе ' +
        'благотворно влияют на психическое и физическое здоровье.</li></ul>',
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    products: {
      name: 'Продукты',
      title: 'Лучшие продукты для осознанной заботы о здоровье',
      data: [
        {
          img: assets.awarenessGuard,
          title:
            '<a href="https://www.doterra.com/RU/ru_RU/p/on-guard-oil" target="_blank" rel="noopener noreferrer">' +
            'Смесь dōTERRA On Guard®</a>',
          text:
            'Используйте ' +
            '<a href="https://www.doterra.com/RU/ru_RU/p/on-guard-oil" target="_blank" rel="noopener noreferrer">' +
            'dōTERRA On Guard®</a> для поддержания иммунной и сердечно-сосудистой систем в течение всего года. ' +
            'Эту смесь можно принимать внутрь ежедневно. При распылении она тонизирует и улучшает настроение, а ' +
            'заодно очищает воздух*.',
        },
        {
          img: assets.awarenessIncense,
          title: 'Ладан',
          text:
            'Используйте масло ладана для поддержания клеток. Оно известно как одно из самых благородных и ' +
            'драгоценных эфирных масел. Его успокаивающие и омолаживающие свойства помогали бороться со старением ' +
            'кожи и смягчать ее дефекты еще во времена Древнего Египта*.',
        },
        {
          img: assets.awarenessLifelong,
          title:
            '<a href="https://www.doterra.com/RU/ru_RU/p/on-guard-oil" target="_blank" rel="noopener noreferrer">' +
            'Комплекс dōTERRA<br />Lifelong Vitality®</a>',
          text:
            'Используйте комплекс ' +
            '<a href="https://www.doterra.com/RU/ru_RU/p/on-guard-oil" target="_blank" rel="noopener noreferrer">' +
            'dōTERRA Lifelong Vitality®</a> для проактивной заботы о здоровье всего организма. Он содержит полезные ' +
            'питательные соединения, компоненты, стимулирующие обмен веществ, и мощные антиоксиданты, которые ' +
            'способствуют хорошему самочувствию, здоровью и долголетию*.',
        },
      ],
      footnote:
        'Продукт не является лекарственным средством, средством профилактики и диагностики. Лицам, ' +
        'находящимся под наблюдением врача, перед применением необходимо проконсультироваться с врачом.',
    },
    pdf: [
      {
        title: 'Комплексная защита<br />с dōTERRA On Guard®',
        link: '/media/doc/awareness_guard',
      },
    ],
  },
]
