import { FC, JSX } from 'react'
import { colorTheme, definitionTheme } from '../../../utils'

export const BackIcon: FC = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 2L2 9C2 9 8.36321 15.3632 9 16"
      stroke={definitionTheme({ challenge: '#DB580F' }, colorTheme) as string}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
