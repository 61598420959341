import React from 'react'
import { Grid, Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LogoWhiteIcon } from '../../imgs'

export const ErrorFallback: React.FC = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.page}>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <LogoWhiteIcon />
          </Grid>
        </Grid>
        <Box width="100%" textAlign="center" mt={3} mb={2} className={classes.title}>
          Что-то пошло не так...
        </Box>
        <Box width="100%" textAlign="center" className={classes.text}>
          Перезапустите приложение
          <br />
          для продолжения работы.
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    height: '100vh',
    padding: '0 20px',
  },
  title: {
    fontSize: 20,
    marginTop: 30,
    fontWeight: 600,
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.3,
    textAlign: 'center',
  },
}))
