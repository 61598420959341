import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Button, Container, Grid, IconButton, CircularProgress, Theme } from '@mui/material'
import Moment from 'react-moment'
import clsx from 'clsx'
import * as process from 'process'
import { animateScroll as scroll } from 'react-scroll'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { Layout, StopAction, Timer, TrackEvent } from '../../components'
import { BenefitModal, SelectHabitsModal, CalendarModal } from './modals'
import { aHref } from '../../utils'
import { useReduxDispatch, useReduxSelector } from '../../hooks'
import { CalendarIcon, img, MoreBackIcon } from '../../imgs'
import { JournalTracker, MainTracker, OilTracker } from './current'
import { trackerNowMain, trackerNowOil, trackerNowJournal, trackerCalendar } from '../../api'
import { OilTrackerList, MainTrackerList, JournalList, Calendar } from '../../types'
import { getUser } from '../../store/auth/auth.service'
import { getConst } from '../../store/constants/constants.service'

export const MainPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const [completed, setCompleted] = useState<boolean>(false)

  const { REACT_APP_API_LINK: URL } = process.env

  const {
    auth: { data: userData },
    constants: { data: constants },
  } = useReduxSelector((state) => state)

  const { NOW = '' } = constants || {}
  const { progress = { all: 0, completed: 0, value: 0 } } = userData || {}

  const [loadDataMain, setLoadDataMain] = useState<boolean>(false)
  const [loadDataOil, setLoadDataOil] = useState<boolean>(false)
  const [loadDataJournal, setLoadDataJournal] = useState<boolean>(false)

  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [openEditMainTracker, setOpenEditMainTracker] = useState<boolean>(false)
  const [openEditOilTracker, setOpenEditOilTracker] = useState<boolean>(false)
  const [openEditJournal, setOpenEditJournal] = useState<boolean>(false)
  const [benefitModal, setBenefitModal] = useState<boolean>(false)
  const [calendarModal, setCalendarModal] = useState<boolean>(false)
  const [selectHabitsModal, setSelectHabitsModal] = useState<boolean>(false)

  const [currentDate, setCurrentDate] = useState<string>(NOW)

  const [mainTrackersList, setMainTrackersList] = useState<MainTrackerList>()
  const [oilTrackersList, setOilTrackersList] = useState<OilTrackerList>()
  const [journalList, setJournalList] = useState<JournalList>()
  const [oilListLength, setOilListLength] = useState<number>(0)
  const [calendarList, setCalendarList] = useState<Calendar[]>([])
  const [calendarNow, setCalendarNow] = useState<Calendar>({
    date_at: '',
    num: 0,
    now: false,
    result: {
      all: 0,
      progress: 0,
      completed: 0,
    },
  })

  const onOpenSelectHabitsModal = () => {
    setSelectHabitsModal(true)
  }

  const onCloseBenefitModal = () => {
    setBenefitModal(false)
  }

  const onCloseSelectHabitsModal = () => {
    setSelectHabitsModal(false)
  }

  const onManual = () => {
    aHref(`${URL}/media/doc/useful_products`)
  }

  const onOpenMainTrackerEdit = () => {
    setOpenEdit(true)
    setOpenEditMainTracker(true)
    scroll.scrollTo(0)
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'btnclick',
      eventLabel: 'Трекер привычек',
    })
  }

  const onOpenOilTrackerEdit = () => {
    setOpenEdit(true)
    setOpenEditOilTracker(true)
    scroll.scrollTo(0)
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'btnclick',
      eventLabel: 'План использования эфирных масел',
    })
  }

  const onOpenJournalEdit = () => {
    setOpenEdit(true)
    setOpenEditJournal(true)
    scroll.scrollTo(0)
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'btnclick',
      eventLabel: 'Мой дневник',
    })
  }

  const onSelectDay = (currentDay: Calendar) => {
    setCalendarNow(currentDay)
    loadOilTrackers(currentDay.date_at)
    loadMainTrackers(currentDay.date_at)
    loadJournalTrackers(currentDay.date_at)
    setCalendarModal(false)
  }

  const loadMainTrackers = useCallback((date: string) => {
    trackerNowMain({ date }).then((res) => {
      if (res.status === 1) {
        setMainTrackersList(res.data)
        setLoadDataMain(true)
      }
    })
  }, [])

  const loadOilTrackers = useCallback((date: string) => {
    trackerNowOil({ date }).then((res) => {
      if (res.status === 1) {
        setOilTrackersList(res.data)

        let count = 0

        for (let i = 0; res.data.trackers.length > i; i++) {
          for (let j = 0; res.data.trackers[i].trackers.length > j; j++) {
            count++
          }
        }

        setOilListLength(count)
        setLoadDataOil(true)
      }
    })
  }, [])

  const loadJournalTrackers = useCallback((date: string) => {
    trackerNowJournal({ date }).then((res) => {
      if (res.status === 1) {
        setJournalList(res.data)
        setLoadDataJournal(true)
      }
    })
  }, [])

  const loadCalendar = useCallback(() => {
    trackerCalendar().then((res) => {
      if (res.status === 1) {
        const arrayCalendar: Calendar[] = Object.values(res.data)

        setCalendarList(arrayCalendar)

        arrayCalendar.forEach((day: Calendar) => {
          if (day.now) {
            setCalendarNow(day)
          }
        })

        if (!calendarNow.now) {
          const { date_at } = arrayCalendar[arrayCalendar.length - 1]
          setCalendarNow(arrayCalendar[arrayCalendar.length - 1])
          loadOilTrackers(date_at)
          loadMainTrackers(date_at)
          loadJournalTrackers(date_at)
          setSearchParams({})
        }
      }
    })
  }, [])

  useEffect(() => {
    if (searchParams.get('completed')) {
      setCompleted(!!searchParams.get('completed'))
    }
  }, [searchParams])

  useEffect(() => {
    dispatch(getUser())
    if (userData?.start_tracker_at === null && currentDate.length > 0) {
      setBenefitModal(true)
    } else {
      loadCalendar()
      if (!userData?.end_tracker) {
        loadMainTrackers(currentDate)
        loadOilTrackers(currentDate)
        loadJournalTrackers(currentDate)
      }
    }
  }, [])

  return (
    <Layout>
      <Box display="none">
        <Timer
          endTime={moment().valueOf() + (moment(NOW).endOf('day').valueOf() - moment(NOW).valueOf())}
          onLoadData={() => {
            dispatch(getConst())
            loadCalendar()
            if (!userData?.end_tracker) {
              loadOilTrackers(currentDate)
              loadMainTrackers(currentDate)
              loadJournalTrackers(currentDate)
            }
          }}
        />
      </Box>

      {userData && (
        <StopAction>
          <BenefitModal
            show={benefitModal}
            onClose={onCloseBenefitModal}
            onOpenSelectHabitsModal={onOpenSelectHabitsModal}
            startMarathon={userData?.start_tracker_at !== null}
          />
        </StopAction>
      )}
      <SelectHabitsModal
        updateInfo={() => {
          loadCalendar()
          loadOilTrackers(currentDate)
          loadMainTrackers(currentDate)
          loadJournalTrackers(currentDate)
        }}
        show={selectHabitsModal}
        onClose={onCloseSelectHabitsModal}
      />

      {calendarList && (
        <CalendarModal
          show={calendarModal}
          calendarList={calendarList}
          onClose={() => setCalendarModal(false)}
          onSelectDay={(currentDay: Calendar) => {
            onSelectDay(currentDay)
            setOpenEdit(false)
            setOpenEditMainTracker(false)
            setOpenEditOilTracker(false)
            setOpenEditJournal(false)
          }}
        />
      )}

      <Box className={classes.page}>
        <StopAction
          completed={completed}
          stopText={
            <Box className={classes.stopTracker}>
              <Box className={classes.stopTrackerBg}>
                <Container maxWidth="sm">
                  <Grid container justifyContent="center" alignItems="center" height="100vh">
                    <Grid item>
                      <Box className={classes.stopTrackerTitle}>Марафон завершен!</Box>
                      <Box className={classes.stopTrackerText}>
                        Развивайте ваши привычки и не останавливайтесь на достигнутом. Пусть здоровый образ жизни
                        помогает вам добиваться большего, к чему бы вы ни стремились. eslint-disable-next-line max-len
                        <Box mt={2}>Вас ждет еще много интересных активностей от dōTERRA, оставайтесь с нами!</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
          }
        >
          {userData?.start_tracker_at === null ? (
            <Container maxWidth="sm">
              <Box className={classes.start}>
                <Grid container direction="column" minHeight="calc(100vh - 50px)" justifyContent="space-between">
                  <Grid item>
                    <Box className={classes.titleStart}>Начнем прямо сейчас!</Box>
                    <Box className={classes.textStart}>
                      Готовы ли вы поддерживать хорошее самочувствие? Если вы хотите испытывать меньше стресса,
                      избавиться от постоянной сонливости и вялости, измените свои привычки.
                    </Box>
                    <Box maxWidth={350} margin="0 auto">
                      <Button fullWidth variant="contained" color="secondary" onClick={onOpenSelectHabitsModal}>
                        Начать марафон
                      </Button>
                    </Box>
                    <Box className={classes.textStart}>
                      Перед началом марафона мы хотим порекомендовать несколько продуктов, которые помогут вам успешно
                      стартовать, достичь успеха и закрепить результат.
                    </Box>
                    <Box maxWidth={350} margin="0 auto">
                      <Button fullWidth variant="contained" color="primary" onClick={onManual}>
                        Полезная продукция
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className={classes.textPeriodStart}>
                      Вы можете начать марафон в любой день в период с 13 мая по 15 июля 2023 года.
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          ) : (
            <>
              {userData?.end_tracker && !completed ? (
                <Box className={classes.stopTracker}>
                  <Box className={classes.stopTrackerBg}>
                    <Container maxWidth="sm">
                      <Grid container justifyContent="center" alignItems="center" height="100vh">
                        <Grid item>
                          <Box className={classes.stopTrackerTitle}>Поздравляем с&nbsp;завершением марафона!</Box>
                          <Box className={classes.stopTrackerText}>
                            Продолжайте развивать ваши новые привычки и не останавливайтесь на достигнутом. Пусть
                            здоровый образ жизни помогает вам добиваться большего, к чему бы вы ни стремились.
                            <Box mt={2}>
                              Сейчас самое время подумать, как еще вы можете изменить свою жизнь к лучшему и
                              позаботиться о своем организме. Может быть, пора освоить новую полезную привычку? В любом
                              случае dōTERRA всегда рада предложить вам чистые натуральные продукты, которые помогут
                              быстрее достичь желанной цели.
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Box>
              ) : (
                <Box className={classes.current}>
                  <Box className={classes.progress}>
                    <Grid container justifyContent="space-between" alignItems="center" height={75}>
                      <Grid item>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            TrackEvent({
                              eventCategory: 'Click',
                              eventAction: 'btnclick',
                              eventLabel: 'Календарь',
                            })
                            setCalendarModal(true)
                          }}
                        >
                          <CalendarIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Box className={classes.date}>
                          <Moment format="DD MMMM YYYY">
                            {calendarNow.date_at ? calendarNow?.date_at : constants?.NOW}
                          </Moment>
                          <Box id="day">День {calendarNow.num}</Box>
                        </Box>
                        <Box className={classes.msk}>по московскому времени</Box>
                      </Grid>
                      <Grid item>
                        <Box width={54} height={54} />
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Box className={classes.percentContainer}>
                          <Box height={225 * (calendarNow.result.progress / 100)} className={classes.bgPercent} />
                          <Box className={classes.percent}>
                            <Grid container justifyContent="center" alignItems="center" height="100%">
                              <Grid item>
                                <Box mt="60px">{calendarNow.result.progress.toFixed(0)}%</Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={clsx(classes.contentCurrent, { [classes.contentCurrentOpen]: openEdit })}>
                    <Container maxWidth="sm">
                      {openEdit ? (
                        <>
                          {openEditMainTracker && mainTrackersList && (
                            <MainTracker
                              trackersList={mainTrackersList}
                              ready={!calendarNow.now}
                              now={calendarNow.now}
                              loadTrackers={() => {
                                dispatch(getUser())
                                loadMainTrackers(currentDate)
                                loadCalendar()
                              }}
                              onClose={() => {
                                setOpenEdit(false)
                                setOpenEditMainTracker(false)
                              }}
                            />
                          )}
                          {openEditOilTracker && oilTrackersList && (
                            <OilTracker
                              trackersList={oilTrackersList}
                              ready={!calendarNow.now}
                              now={calendarNow.now}
                              loadTrackers={() => {
                                dispatch(getUser())
                                loadOilTrackers(currentDate)
                                loadCalendar()
                              }}
                              onClose={() => {
                                setOpenEdit(false)
                                setOpenEditOilTracker(false)
                              }}
                            />
                          )}
                          {openEditJournal && journalList && (
                            <JournalTracker
                              trackersList={journalList}
                              ready={!calendarNow.now}
                              now={calendarNow.now}
                              loadTrackers={() => {
                                dispatch(getUser())
                                loadJournalTrackers(currentDate)
                                loadCalendar()
                              }}
                              onClose={() => {
                                setOpenEdit(false)
                                setOpenEditJournal(false)
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Button fullWidth sx={{ padding: 0 }} onClick={onOpenMainTrackerEdit}>
                            <Box className={classes.contentBtnCurrent}>
                              <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                                <Grid item>
                                  <Box className={classes.contentBtnTitleCurrent}>Трекер привычек</Box>
                                  <Box className={classes.contentBtnTextCurrent}>
                                    {mainTrackersList ? mainTrackersList?.trackers.length : '0'} привычек
                                  </Box>
                                </Grid>
                                <Grid item>
                                  {loadDataMain ? (
                                    <>
                                      {mainTrackersList?.result.completed !== 0 ? (
                                        <Box sx={{ width: 60, height: 60, position: 'relative' }}>
                                          <Box className={classes.trackerProgress}>
                                            {mainTrackersList ? mainTrackersList?.result.completed : '0'}
                                            <span>/{mainTrackersList ? mainTrackersList?.result.all : '0'}</span>
                                          </Box>
                                          {mainTrackersList && (
                                            <CircularProgress
                                              value={
                                                (mainTrackersList.result.completed / mainTrackersList.result.all) * 100
                                              }
                                              color="secondary"
                                              variant="determinate"
                                              sx={{
                                                top: 0,
                                                left: 0,
                                                position: 'absolute',
                                                width: '60px!important',
                                                height: '60px!important',
                                                color: '#5D883F!important',
                                              }}
                                            />
                                          )}
                                        </Box>
                                      ) : (
                                        <Box className={classes.moreIcon}>
                                          <MoreBackIcon />
                                        </Box>
                                      )}
                                    </>
                                  ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', height: 60 }}>
                                      <CircularProgress
                                        color="secondary"
                                        sx={{ width: '28px!important', height: '28px!important' }}
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Button>
                          <Button fullWidth sx={{ padding: 0 }} onClick={onOpenOilTrackerEdit}>
                            <Box className={classes.contentBtnCurrent}>
                              <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                                <Grid item>
                                  <Box className={classes.contentBtnTitleCurrent}>
                                    План использования
                                    <br />
                                    эфирных масел
                                  </Box>
                                  <Box className={classes.contentBtnTextCurrent}>{oilListLength} действий</Box>
                                </Grid>
                                <Grid item>
                                  {loadDataOil ? (
                                    <>
                                      {oilTrackersList?.result.completed !== 0 ? (
                                        <Box sx={{ width: 60, height: 60, position: 'relative' }}>
                                          <Box className={classes.trackerProgress}>
                                            {oilTrackersList ? oilTrackersList?.result.completed : '0'}
                                            <span>/{oilTrackersList ? oilTrackersList?.result.all : '0'}</span>
                                          </Box>
                                          {oilTrackersList && (
                                            <CircularProgress
                                              value={
                                                (oilTrackersList.result.completed / oilTrackersList.result.all) * 100
                                              }
                                              color="secondary"
                                              variant="determinate"
                                              sx={{
                                                top: 0,
                                                left: 0,
                                                position: 'absolute',
                                                width: '60px!important',
                                                height: '60px!important',
                                                color: '#5D883F!important',
                                              }}
                                            />
                                          )}
                                        </Box>
                                      ) : (
                                        <Box className={classes.moreIcon}>
                                          <MoreBackIcon />
                                        </Box>
                                      )}
                                    </>
                                  ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', height: 60 }}>
                                      <CircularProgress
                                        color="secondary"
                                        sx={{ width: '28px!important', height: '28px!important' }}
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Button>
                          <Button fullWidth sx={{ padding: 0 }} onClick={onOpenJournalEdit}>
                            <Box className={classes.contentBtnCurrent}>
                              <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                                <Grid item>
                                  <Box className={classes.contentBtnTitleCurrent}>Мой дневник</Box>
                                  <Box className={classes.contentBtnTextCurrent}>
                                    {journalList ? journalList?.trackers[0]?.trackers.length : '0'} действия
                                  </Box>
                                </Grid>
                                <Grid item>
                                  {loadDataJournal ? (
                                    <>
                                      {journalList?.result.completed !== 0 ? (
                                        <Box sx={{ width: 60, height: 60, position: 'relative' }}>
                                          <Box className={classes.trackerProgress}>
                                            {journalList ? journalList?.result.completed : '0'}
                                            <span>/{journalList ? journalList?.result.all : '0'}</span>
                                          </Box>
                                          {journalList && (
                                            <CircularProgress
                                              value={(journalList.result.completed / journalList.result.all) * 100}
                                              color="secondary"
                                              variant="determinate"
                                              sx={{
                                                top: 0,
                                                left: 0,
                                                position: 'absolute',
                                                width: '60px!important',
                                                height: '60px!important',
                                                color: '#5D883F!important',
                                              }}
                                            />
                                          )}
                                        </Box>
                                      ) : (
                                        <Box className={classes.moreIcon}>
                                          <MoreBackIcon />
                                        </Box>
                                      )}
                                    </>
                                  ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', height: 60 }}>
                                      <CircularProgress
                                        color="secondary"
                                        sx={{ width: '28px!important', height: '28px!important' }}
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Button>
                        </>
                      )}
                    </Container>
                  </Box>
                </Box>
              )}
            </>
          )}
        </StopAction>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    width: '100%',
  },
  start: {
    marginTop: 40,
    minHeight: 600,
    '& .MuiButton-root': {
      border: `2px solid ${palette.primary.dark}`,
    },
  },
  titleStart: {
    fontSize: 24,
    fontWeight: 700,
    margin: '15px 0 20px',
    color: palette.primary.contrastText,
  },
  textStart: {
    fontSize: 15,
    fontWeight: 500,
    margin: '20px 0',
    color: palette.primary.contrastText,
  },
  textPeriodStart: {
    fontSize: 15,
    fontWeight: 600,
    textAlign: 'center',
    margin: '30px 0 120px',
    color: palette.primary.contrastText,
  },
  stopTracker: {
    width: '100%',
    height: '100Vh',
    backgroundSize: '345px 341px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.bgSign})`,
    backgroundPosition: 'bottom -40px left -120px',
  },
  stopTrackerBg: {
    width: '100%',
    height: '100Vh',
    backgroundSize: '234px 235px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.bgStop})`,
    backgroundPosition: 'top -40px right -130px',
  },
  stopTrackerTitle: {
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    color: palette.primary.dark,
  },
  stopTrackerText: {
    fontSize: 16,
    marginTop: 15,
    fontWeight: 600,
    textAlign: 'center',
    color: palette.primary.contrastText,
  },
  current: {
    width: '100%',
  },
  progress: {
    height: 507,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.bgMain})`,
  },
  date: {
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    color: palette.primary.main,
    '& #day': {
      fontSize: 17,
      fontWeight: 600,
      color: palette.primary.dark,
    },
  },
  msk: {
    marginTop: 2,
    fontSize: 10,
    fontWeight: 700,
    textAlign: 'center',
    color: palette.primary.main,
  },
  percentContainer: {
    width: 97,
    height: 225,
    marginTop: 30,
    position: 'relative',
  },
  bgPercent: {
    width: 97,
    bottom: 0,
    transition: '0.5s',
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundImage: `url(${img.bgPercent})`,
  },
  percent: {
    width: 97,
    bottom: 0,
    height: 225,
    fontSize: 34,
    fontWeight: 700,
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.percent})`,
  },
  contentCurrent: {
    zIndex: 100,
    width: '100%',
    minHeight: 370,
    marginTop: -130,
    transition: '0.3s',
    position: 'relative',
    padding: '15px 0 5px',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: palette.primary.main,
  },
  contentCurrentOpen: {
    marginTop: -425,
    minHeight: '510px!important',
  },
  contentBtnCurrent: {
    width: '100%',
    marginBottom: 5,
    borderRadius: 12,
    padding: '12px 15px',
    border: '1px solid #F0F1F8',
    boxShadow: '0px 4px 12px #EBEDF4',
  },
  contentBtnTitleCurrent: {
    fontSize: 17,
    fontWeight: 700,
    lineHeight: 1.2,
    textAlign: 'left',
    textTransform: 'none',
    color: palette.primary.dark,
  },
  contentBtnTextCurrent: {
    fontSize: 14,
    marginTop: 5,
    lineHeight: 1.2,
    fontWeight: 600,
    color: '#939CA4',
    textAlign: 'left',
    textTransform: 'none',
  },
  trackerProgress: {
    width: 50,
    height: 50,
    fontSize: 17,
    fontWeight: 600,
    display: 'flex',
    color: '#5D883F',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    // border: '5px solid #F7F7F7',
    border: '5px solid rgba(93, 136, 63, 0.2)',
    '& span': {
      fontSize: 13,
      fontWeight: 500,
      color: 'rgba(93, 136, 63, 0.34)',
    },
  },
  moreIcon: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
