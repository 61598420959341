import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { SubmitButton, TextInput } from '../../components'
import { FormProps } from '../../types'

interface ISignupOneForm {
  onChangeStage: () => void
  onChangeDataForm: (data: SignupOneProps) => void
}

type SignupOneProps = { last_name: string; first_name: string; external_id: string }

const schema = yup.object().shape({
  external_id: yup.string(),
  last_name: yup
    .string()
    .required()
    .matches(/^[а-яёА-ЯЁa\s-]*$/i, 'Только буквенные символы кириллицы'),
  first_name: yup
    .string()
    .required()
    .matches(/^[а-яёА-ЯЁa\s-]*$/i, 'Только буквенные символы кириллицы'),
})

export const SignupOneForm: React.FC<ISignupOneForm> = ({
  onChangeStage = () => {},
  onChangeDataForm = () => {},
}): JSX.Element => {
  const hookForm = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<SignupOneProps>>({
    data: { external_id: '', first_name: '', last_name: '' },
    processed: false,
  })

  const onSubmitForm = async (data: SignupOneProps) => {
    setFormProps({ ...formProps, processed: true })
    onChangeDataForm(data)
    onChangeStage()
    setFormProps({ ...formProps, processed: false })
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      <Box width="100%" height={72}>
        <TextInput label="Фамилия" name="last_name" variant="outlined" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box width="100%" height={72}>
        <TextInput variant="outlined" label="Имя" name="first_name" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box width="100%" height={72}>
        <TextInput
          form={hookForm}
          name="external_id"
          variant="outlined"
          label="ID сотрудника*"
          disabled={formProps.processed}
        />
      </Box>
      <Box width="100%" mt="30px">
        <SubmitButton fullWidth disabled={formProps.processed} processed={formProps.processed} title="Продолжить" />
      </Box>
    </form>
  )
}
