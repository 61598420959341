import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { useLocation } from 'react-router-dom'
import * as ls from 'local-storage'
import { InstallModal } from './install.modal'
import { useAddToHomeScreenPrompt, useDetectDevice } from '../../hooks'
import { DetectDevice } from '../../types'

export const Install: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const location = useLocation()
  const detectDevice = useDetectDevice()
  const [prompt, promptToInstall] = useAddToHomeScreenPrompt()

  const [modalInstall, setModalInstall] = useState<boolean>(false)

  const onInstallApp = () => {
    promptToInstall()
  }

  const onCloseInstallModal = () => {
    setModalInstall(false)
    if (detectDevice === DetectDevice.IOS) ls.set('__install', true)
  }

  useEffect(() => {
    if (prompt && location.pathname === '/greet' && detectDevice === DetectDevice.ANDROID) {
      setModalInstall(true)
    }
    window.addEventListener('appinstalled', () => {
      setModalInstall(false)
    })
  }, [prompt, location])

  useEffect(() => {
    // @ts-ignore
    if (detectDevice === DetectDevice.IOS && !navigator.standalone && location.pathname === '/greet') {
      if (!ls.get('__install')) setModalInstall(true)
    }
  }, [detectDevice, location])

  return (
    <InstallModal className={classes} show={modalInstall} onInstallApp={onInstallApp} onClose={onCloseInstallModal} />
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  titleInstall: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 15,
    textAlign: 'center',
    color: palette.primary.contrastText,
  },
  textInstall: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    margin: '5px 0 30px',
    color: palette.primary.contrastText,
  },
  primaryBtnModal: {
    '& .MuiButton-root': {
      backgroundColor: 'rgba(237, 224, 235, 0.5)!important',
    },
  },
}))
