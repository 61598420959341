import createPalette from '@mui/material/styles/createPalette'
import { themeGl } from '../configs'
import { colorTheme, definitionTheme } from '../utils'

const palette = createPalette({
  mode: 'light',

  primary: {
    main: '#FFFFFF',
    dark: colorTheme,
    contrastText: '#4A5967',
  },
  secondary: {
    main: colorTheme,
    dark: '#FFFFFF',
    contrastText: '#4A5967',
  },

  // error: { main: themeGl === 'calendar' ? '#FFFFFF' : themeGl === 'test2' ? '#BB2F2B' : '#B786B9' },
  error: {
    main: definitionTheme({ calendar: '#FFFFFF', test2: '#BB2F2B', challenge: '#BB2F2B' }, '#B786B9') as string,
  },
  warning: { main: '#DB580F' },
  info: { main: '#123A6B' },
  success: { main: '#287C48' },

  background: {
    paper: '#FFFFFF',
    default: colorTheme,
  },

  text: {
    primary: '#4A5967',
    secondary: colorTheme,
    disabled: 'rgba(0, 0, 0, .2)',
  },

  action: {
    active: colorTheme,
    hover: colorTheme,
    focus: colorTheme,
    selected: colorTheme,
    disabled: 'rgba(255, 255, 255, .4)',
    disabledBackground: '#C9C9C9',
  },

  divider: 'rgba(18, 58, 107, .25)',
})

export default palette
