export enum DisplayMode {
  BROWSER = 'browser',
  STANDALONE = 'standalone',
}

export enum DetectDevice {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  OTHER = 'OTHER',
}
