import React from 'react'
import { Box, Button, Container, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { img, LogoWhiteIcon } from '../../imgs'
import { definitionTheme } from '../../utils'

export const GreetPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const classes = useStyles()

  const onOpenSignup = () => {
    navigate('../signup')
  }

  const onOpenSignin = () => {
    navigate('../signin')
  }

  return (
    <Box className={classes.page}>
      <Container maxWidth="sm" sx={{ height: '100%' }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <LogoWhiteIcon />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box className={classes.title}>
              {definitionTheme(
                {
                  challenge: (
                    <>
                      Добро пожаловать
                      <br />
                      на 30-дневный Челлендж
                      <Box mt="15px">«Метаболическое здоровье с MetaPWR»</Box>
                    </>
                  ),
                },
                'Добро пожаловать!'
              )}
            </Box>
            <Box className={classes.text}>
              {definitionTheme({
                marathon: (
                  <>
                    Пришло время сформировать новые привычки, которые помогут поддержать здоровье. Речь идет не об
                    обещаниях, которые забудутся через неделю. Мы говорим о достижимых изменениях в образе жизни,
                    которые отразятся на вашем самочувствии.
                    <Box mt="15px">
                      Если вы готовы действовать, присоединяйтесь к марафону 30-Day Challenge и начинайте вести тот
                      образ жизни, о котором всегда мечтали. Мы расскажем вам о простых шагах, которые нужно будет
                      выполнять каждый день. Вы и сами не заметите, как эти несложные привычки прочно войдут в вашу
                      жизнь!
                    </Box>
                    <Box mt="15px">
                      Самых целеустремленных участников ждет годовой запас doTERRA Lifelong Vitality® и MetaPWR™. Мы
                      разыграем его 15 июня среди тех, кто успел пройти марафон на 100%.
                    </Box>
                  </>
                ),
                test: (
                  <>
                    ТЕСТ! ТЕСТ! ТЕСТ! Что нужно для хорошего самочувствия? Отвечайте на вопросы и получайте персональные
                    рекомендации от dōTERRA.
                  </>
                ),
                calendar: (
                  <Box m="15px auto 0" maxWidth={335}>
                    <Box mb="10px">
                      Наш адвент-календарь скрасит ожидание главного праздника года. Открывайте каждый день окошки
                      календаря, выполняйте задания и заряжайтесь праздничным настроением!
                    </Box>
                    15 дней, 15 мотивирующих пожеланий, 15 заданий от карьерного эксперта и специалистов по эфирным
                    маслам ждут каждого, кто готов снова поверить в то, что чудеса случаются.
                  </Box>
                ),
                test2: (
                  <>
                    Свободно ли дышится у вас дома? Сейчас и проверим! Ответьте на 10 простых вопросов и узнайте, можно
                    ли назвать ваш дом свободным от токсинов (и что делать, если всё же нельзя).
                  </>
                ),
                challenge: (
                  <Box fontSize={18} fontWeight={700}>
                    Измените свою жизнь!
                  </Box>
                ),
              })}
            </Box>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onOpenSignup}
                  color={
                    definitionTheme({ calendar: 'secondary', challenge: 'secondary' }, 'primary') as
                      | 'primary'
                      | 'secondary'
                  }
                >
                  Зарегистрироваться
                </Button>
              </Grid>
            </Grid>
            <Box className={classes.textSignin}>Уже зарегистрированы?</Box>
            <Grid container justifyContent="center">
              <Grid item>
                <Box className={classes.signin} onClick={onOpenSignin}>
                  Авторизоваться
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    width: '100%',
    padding: '55px 0',
    backgroundRepeat: 'no-repeat',
    minHeight: 'calc(100vh - 110px)',
    backgroundSize: definitionTheme({ calendar: '85vw 200vw' }, '369px 534px') as string,
    backgroundImage: `url(${definitionTheme({ calendar: img.bgGreetCalendar, challenge: 'none' }, img.bgGreet)})`,
    backgroundPosition: definitionTheme({ calendar: 'left top' }, 'bottom -120px left -140px') as string,
    backgroundColor: definitionTheme(
      { calendar: palette.background.default, challenge: palette.warning.main },
      palette.primary.dark
    ) as string,
  },
  title: {
    fontSize: 24,
    fontWeight: 800,
    textAlign: 'center',
    margin: '40px 0 20px',
  },
  text: {
    fontSize: definitionTheme({ calendar: 17 }, 15) as number,
    fontWeight: 500,
    marginBottom: 40,
    textAlign: 'center',
  },
  textSignin: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    margin: '20px 0 5px',
  },
  signin: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    textDecoration: 'underline',
    '-webkit-tap-highlight-color': 'transparent',
  },
  textInstall: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    margin: '5px 0 30px',
    color: palette.primary.contrastText,
  },
  primaryBtnModal: {
    '& .MuiButton-root': {
      backgroundColor: 'rgba(237, 224, 235, 0.5)!important',
    },
  },
}))
