/* eslint-disable max-len */
import { definitionTheme } from '../../../../utils'

const color = definitionTheme({
  marathon: '#B786B9',
  test: '#BB2F2B',
  calendar: '#006266',
  test2: '#5A3624',
  challenge: '#A1AAAD',
}) as string

export const UserIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.7983 6.9278C29.7983 5.02192 28.2554 3.47906 26.3495 3.47906H9.65037C7.74449 3.47906 6.20163 5.02192 6.20163 6.9278V29.0723C6.20163 30.9782 7.74449 32.5211 9.65037 32.5211H26.3495C28.2554 32.5211 29.7983 30.9782 29.7983 29.0723V6.9278ZM27.9831 29.0723C27.9831 29.9799 27.2571 30.706 26.3495 30.706H9.65037C8.74281 30.706 8.01676 29.9799 8.01676 29.0723V6.9278C8.01676 6.02024 8.74281 5.29419 9.65037 5.29419H26.3495C27.2571 5.29419 27.9831 6.02024 27.9831 6.9278V29.0723Z"
      fill={color}
    />
    <path
      d="M12.4638 19.8152H23.1731C23.4453 19.8152 23.7176 19.7245 23.8991 19.4522C24.0806 19.2707 24.1714 18.9984 24.0806 18.7261C23.7176 16.548 22.3562 14.8236 20.5411 14.0068C21.0857 13.3715 21.4487 12.5547 21.4487 11.5564C21.4487 9.55976 19.8151 7.92615 17.8184 7.92615C15.8218 7.92615 14.1882 9.55976 14.1882 11.5564C14.1882 12.464 14.5512 13.2808 15.0957 14.0068C13.2806 14.9144 11.8285 16.6388 11.5562 18.7261C11.5562 18.9984 11.5562 19.2707 11.7378 19.4522C11.9193 19.7245 12.1915 19.8152 12.4638 19.8152ZM16.0033 11.6472C16.0033 10.6488 16.8201 9.83203 17.8184 9.83203C18.8168 9.83203 19.6336 10.6488 19.6336 11.6472C19.6336 12.6455 18.8168 13.4623 17.8184 13.4623C16.8201 13.4623 16.0033 12.6455 16.0033 11.6472ZM17.8184 15.2774C19.6336 15.2774 21.2672 16.3665 21.9932 18.0001H13.6436C14.3697 16.3665 16.0033 15.2774 17.8184 15.2774ZM25.2604 22.5379H10.7394C10.1949 22.5379 9.83188 22.9009 9.83188 23.4455C9.83188 23.99 10.1949 24.353 10.7394 24.353H25.2604C25.805 24.353 26.168 23.99 26.168 23.4455C26.168 22.9009 25.805 22.5379 25.2604 22.5379ZM25.2604 26.1682H10.7394C10.1949 26.1682 9.83188 26.5312 9.83188 27.0757C9.83188 27.6203 10.1949 27.9833 10.7394 27.9833H25.2604C25.805 27.9833 26.168 27.6203 26.168 27.0757C26.168 26.5312 25.805 26.1682 25.2604 26.1682Z"
      fill={color}
    />
  </svg>
)
