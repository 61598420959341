import React from 'react'
import { Box, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HtmlReactParser from 'html-react-parser'
import { Modal } from '../../../components'
import { Product } from '../type'
import { definitionTheme } from '../../../utils'

interface IInfoDetailModal {
  info: any
  show: boolean
  onClose: () => void
  onBackInfoDetail: () => void
  type: 'advice' | 'products' | undefined
}

export const InfoDetailModal: React.FC<IInfoDetailModal> = ({
  info,
  show = false,
  type,
  onClose = () => {},
  onBackInfoDetail = () => {},
}): JSX.Element => {
  const classes = useStyles()

  return (
    <Modal maxWidth="md" backButton open={show} onBack={onBackInfoDetail} onClose={onClose}>
      <Box className={classes.infoModal}>
        <Box className={classes.title}>{HtmlReactParser(info.title)}</Box>
        {type === 'advice' && (
          <>
            <Box className={classes.adviceText}>{HtmlReactParser(info?.text)}</Box>
            {info?.footnote && (
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                  <Box fontSize={26}>*</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.adviceFootNote}>{info?.footnote}</Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {type === 'products' && (
          <>
            {info.data.map((k: Product, i: number) => {
              return (
                <Box key={`products-${i}`} className={classes.productsItem}>
                  <Box className={classes.productsItemImg}>
                    <img src={k.img} alt="" />
                  </Box>
                  <Box className={classes.productsItemTitle}>{HtmlReactParser(k.title)}</Box>
                  <Box className={classes.productsItemText}>{HtmlReactParser(k.text)}</Box>
                </Box>
              )
            })}
            <Grid container wrap="nowrap" mt={2} spacing={1}>
              <Grid item>
                <Box fontSize={26}>*</Box>
              </Grid>
              <Grid item>
                <Box className={classes.adviceFootNote}>{info?.footnote}</Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  infoModal: {
    minHeight: 'calc(100vh - 100px)',
    '& ul': {
      paddingLeft: 30,
    },
    '& li': {
      paddingBottom: 5,
    },
  },
  title: {
    fontSize: 20,
    maxWidth: 350,
    fontWeight: 700,
    margin: '30px 0 20px',
    color: definitionTheme({ challenge: palette.warning.main }, palette.primary.contrastText) as string,
  },
  adviceText: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.3,
    color: palette.primary.contrastText,
    '& a': {
      fontWeight: 600,
      color: `${palette.primary.dark}!important`,
    },
  },
  adviceFootNote: {
    fontSize: 13,
    fontWeight: 500,
    color: palette.primary.contrastText,
  },
  productsItem: {
    padding: 20,
    marginBottom: 8,
    borderRadius: 12,
    border: '1px solid #F0F1F8',
    boxShadow: '0px 4px 12px #EBEDF4',
  },
  productsItemImg: {
    height: 180,
    marginBottom: 20,
    textAlign: 'center',
    '& img': {
      height: '100%',
    },
    [down(350)]: {
      height: 170,
    },
  },
  productsItemTitle: {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: 8,
    lineHeight: 1.3,
    color: palette.primary.contrastText,
    '& a': {
      fontWeight: 600,
      color: `${palette.primary.contrastText}!important`,
    },
  },
  productsItemText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.3,
    color: palette.primary.contrastText,
    '& a': {
      fontWeight: 600,
      color: `${palette.primary.dark}!important`,
    },
  },
}))
