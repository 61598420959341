import { FC, JSX } from 'react'
import { Box, Button, Grid, Link } from '@mui/material'
import moment from 'moment'
import { Modal } from '../../components'
import { PdfIcon } from '../../imgs'
import { useReduxSelector } from '../../hooks'
import { dateConfig } from '../../configs'

interface IParticipateModal {
  open: boolean
  className: any
  onClose: () => void
  openTarget?: () => void
}

const { REACT_APP_API_LINK: API_LINK, REACT_APP_START_DATE_CHALLENGE: START_CHALLENGE } = process.env

export const ParticipateModal: FC<IParticipateModal> = ({ open, className, openTarget, onClose }): JSX.Element => {
  const { data: constants } = useReduxSelector((state) => state.constants)

  const { NOW = 0 } = constants || {}

  return (
    <Modal open={open} maxWidth="md" onClose={onClose}>
      <Box className={className.title} mt="15px">
        {moment(NOW).isAfter(moment(Number(START_CHALLENGE))) ? 'Правила' : 'Как участвовать'}
      </Box>

      {dateConfig.challengeDateStart >= moment(NOW).valueOf() ? (
        <>
          <Box mt="12px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 1.</span> Выберите цель на Челлендж — или на весь
            год
          </Box>
          <Box mt="15px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 2.</span> Ознакомьтесь с материалами Челленджа,
            расположенными ниже
          </Box>
          <Box mt="15px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 3.</span> Закажите необходимые для прохождения
            Челленджа продукты dōTERRA
          </Box>
          {moment(NOW).isAfter(moment(Number(START_CHALLENGE))) ? (
            <>
              <Box mt="15px" className={className.linkTarget}>
                <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 4.</span> Подпишитесь на{' '}
                <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  sx={{ fontSize: '15px!important' }}
                  href="https://t.me/doterra_challenge_bot"
                >
                  чат-бот
                </Link>{' '}
                и настройте уведомления для своевременного приёма БАД и выполнения заданий. Бот предложит ввести ваш
                e-mail и подписаться на уведомления, после чего вам станет доступна настройка времени для каждого
                напоминания в разделе “Меню” приложения.
              </Box>
              <Box mt="15px" className={className.linkTarget}>
                <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 5.</span> Проходите уроки и выполняйте домашние
                задания на{' '}
                <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  sx={{ fontSize: '15px!important' }}
                  href="https://doterratraining.ru/30-day-support"
                >
                  платформе Get Course
                </Link>
                . Следите за тем, чтобы номер урока на GetCourse совпадал с номером дня, указанным в приложении. Так вы
                сможете проходить Челлендж в правильном темпе.
              </Box>
              <Box mt="15px">
                <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 6.</span> Ежедневно отмечайте пункты трекера,
                ведите дневник и следите за тем, как меняются объемы вашего тела и ваше состояние
              </Box>
              <Box mt="15px">
                <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 7.</span> Наберите 100% прогресса в Челлендже,
                принимая БАДы, отмечая ежедневно в течение 30 дней пункты трекера и заполняя дневник. Те, кто начал свой
                Челлендж с 1 по 5 июня включительно, станут участниками розыгрыша годового запаса системы dōTERRA
                MetaPWR!
              </Box>
              <Box mt="15px">
                <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 8.</span> Наслаждайтесь полной, энергичной
                жизнью!
              </Box>
            </>
          ) : (
            <>
              <Box mt="15px">
                <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 4.</span> Настройтесь на изменения и ждите
                старта Челленджа 1 июня!
              </Box>
              <Box mt="40px" fontSize={16} fontWeight={700}>
                Ежедневно выполняя задания, заполняя дневник и отмечая пункты трекера с 1 по 30 июня, наберите 100%
                прогресса в Челлендже и примите участие в розыгрыше ГОДОВОГО ЗАПАСА системы dōTERRA MetaPWR!
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box mt="12px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 1.</span> Выберите цель на Челлендж
          </Box>
          <Box mt="15px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 2.</span> Узнайте{' '}
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href={`${API_LINK}/media/doc/how_go_challenge`}
              sx={{ fontSize: '15px!important', textDecorationColor: '#004438' }}
            >
              как проходит челлендж
            </Link>
          </Box>
          <Box mt="15px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 3.</span> Закажите необходимые для прохождения
            Челленджа{' '}
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href={`${API_LINK}/media/doc/products_challenge`}
              sx={{ fontSize: '15px!important', textDecorationColor: '#004438' }}
            >
              продукты dōTERRA
            </Link>
          </Box>
          <Box mt="15px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 4.</span> Ежедневно отмечайте пункты трекера, ведите
            дневник и следите за тем, как меняются объемы вашего тела и ваше состояние
          </Box>
          <Box mt="15px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 5.</span> Проходите уроки и выполняйте задания на
            платформе GetCourse – авторизуйтесь на платформе,{' '}
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href="https://doterratraining.ru/30-day-support"
              sx={{ fontSize: '15px!important', textDecorationColor: '#004438' }}
            >
              нажмите
            </Link>{' '}
            на кнопку «Вступить в Челлендж» и ищите материалы в личном кабинете
          </Box>
          <Box mt="15px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 6.</span> Наберите 100% прогресса в Челлендже,
            отмечая ежедневно пункты трекера и заполняя дневник - и участвуйте в розыгрыше годового запаса системы
            dōTERRA MetaPWR!
          </Box>
          <Box mt="15px">
            <span style={{ fontWeight: 700, color: '#004438' }}>Шаг 7.</span> Наслаждайтесь полной, энергичной жизнью!
          </Box>
        </>
      )}

      {dateConfig.challengeDateStart >= moment(NOW).valueOf() ? (
        <Grid container wrap="nowrap" alignItems="center" spacing={1} className={className.link} mt="40px">
          <Grid item>
            <PdfIcon />
          </Grid>
          <Grid item>
            <Link href={`${API_LINK}/media/doc/rules`} target="_blank" rel="noreferrer noopener">
              Полные правила Челленджа
            </Link>
          </Grid>
        </Grid>
      ) : (
        <Grid container wrap="nowrap" alignItems="center" spacing={1} className={className.link} mt="40px">
          <Grid item>
            <PdfIcon />
          </Grid>
          <Grid item>
            <Link href={`${API_LINK}/media/doc/products_challenge`} target="_blank" rel="noreferrer noopener">
              Продукты для Челленджа
            </Link>
          </Grid>
        </Grid>
      )}

      <Grid container wrap="nowrap" alignItems="center" spacing={1} className={className.link}>
        <Grid item>
          <PdfIcon />
        </Grid>
        <Grid item>
          <Link href={`${API_LINK}/media/doc/how_go_challenge`} target="_blank" rel="noreferrer noopener">
            {dateConfig.challengeDateStart >= moment(NOW).valueOf()
              ? 'Узнайте, как проходит Челлендж!'
              : 'Что такое Челлендж?'}
          </Link>
        </Grid>
      </Grid>

      {dateConfig.challengeDateStart >= moment(NOW).valueOf() && (
        <Grid container wrap="nowrap" alignItems="center" spacing={1} className={className.link}>
          <Grid item>
            <PdfIcon />
          </Grid>
          <Grid item>
            <Link href={`${API_LINK}/media/doc/products_challenge`} target="_blank" rel="noreferrer noopener">
              Что нужно для Челленджа?
            </Link>
          </Grid>
        </Grid>
      )}

      {openTarget && (
        <Box mt="40px" mb="20px">
          <Button fullWidth variant="contained" color="secondary" onClick={openTarget}>
            Продолжить
          </Button>
        </Box>
      )}
    </Modal>
  )
}
