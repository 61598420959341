import { FC, useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, Theme } from '@mui/material'
import HtmlReactParser from 'html-react-parser'
import { makeStyles } from '@mui/styles'
import { Modal } from '../../components'
import { Answer, Pyramid, SurveyQuestion, TestAnswer, TestQuestion, TestStore } from './test.types'
import { RecommendationModal } from './recomenation.modal'
import { useReduxActions, useReduxDispatch } from '../../hooks'
import { testStore } from '../../api'
import { SurveyModal } from './survey.modal'
import { getUser } from '../../store/auth/auth.service'
import { themeGl } from '../../configs'

interface ITestModal {
  id: number
  show: boolean
  pyramid: Pyramid[]
  onClose: () => void
  data: TestQuestion[]
  dataSurvey: SurveyQuestion[]
}

export const TestModal: FC<ITestModal> = ({
  id = 0,
  data = [],
  show = false,
  pyramid = [],
  onClose = () => {},
  dataSurvey = [],
}): JSX.Element => {
  const classes = useStyles()

  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()

  const [num, setNum] = useState<number>(0)
  const [select, setSelect] = useState<number>(0)
  const [answers, setAnswers] = useState<Answer>({ test_id: id, answers: [] })
  const [storeTest, setStoreTest] = useState<TestStore | undefined>(undefined)
  const [colors, setColors] = useState<string[]>(['#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2'])
  const [recommendation, setRecommendation] = useState<boolean>(false)
  const [survey, setSurvey] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const onChangeStage = () => {
    setColors((prev) => {
      switch (true) {
        case num >= 0 && num <= 1:
          prev[prev.length - 1] = pyramid[prev.length - 1].bgcolor
          return prev
        case num >= 2 && num <= 3:
          prev[prev.length - 2] = pyramid[prev.length - 2].bgcolor
          return prev
        case num >= 4 && num <= 5:
          prev[prev.length - 3] = pyramid[prev.length - 3].bgcolor
          return prev
        case num >= 6 && num <= 7:
          prev[prev.length - 4] = pyramid[prev.length - 4].bgcolor
          return prev
        case num === 8:
          prev[prev.length - 5] = pyramid[prev.length - 5].bgcolor
          return prev
        default:
          return prev
      }
    })
  }

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
  }, [])

  const onNextAnswer = () => {
    if (select === 0) {
      setAlert({
        alert: {
          message: { info: [`Не выбрано<br />ни одного варианта ответа`] },
        },
      })
    } else {
      const currentAnswers = answers
      currentAnswers.answers.push({ id: data[num].test_answers[select - 1].id })
      setAnswers({ ...answers, answers: [...currentAnswers.answers] })

      if (num < 9) {
        setRecommendation((prev) => !prev)
      } else if (num === 9) {
        setDisabled(true)

        testStore(currentAnswers)
          .then(({ data: storeTestData, status, message }) => {
            if (status === 1) {
              setDisabled(false)
              setStoreTest(storeTestData)
              setRecommendation((prev) => !prev)
            } else {
              setAlert({
                alert: {
                  message: { error: [`${message}`] },
                },
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const onCloseRecommendation = () => {
    setSelect(0)
    if (num < 9) {
      onChangeStage()
      setNum((prev) => prev + 1)
      setRecommendation((prev) => !prev)
    } else if (num === 9) {
      uploadUserInfo()
      setNum(0)
      setAnswers({ test_id: id, answers: [] })
      setSurvey((prev) => !prev)
    }
  }

  const onCloseSurvey = () => {
    onClose()
    setStoreTest(undefined)
    setRecommendation((prev) => !prev)
    setSurvey((prev) => !prev)
  }

  return (
    <>
      <RecommendationModal
        data={data[num]}
        show={recommendation}
        storeTest={storeTest}
        onClose={onCloseRecommendation}
        recommendation={storeTest?.recommendation}
      />

      <SurveyModal show={survey} onClose={onCloseSurvey} id={id} data={dataSurvey} />

      <Modal
        open={show}
        maxWidth="md"
        onClose={() => {
          onClose()
          setNum(0)
          setSelect(0)
          setAnswers({ test_id: id, answers: [] })
        }}
      >
        <Box className={classes.content}>
          <Grid container justifyContent="center">
            <Grid item width={335}>
              {pyramid.map((k: Pyramid, i: number) => {
                const { width, text } = k
                return (
                  <Box key={`row-${i}`} sx={{ width, bgcolor: colors[i] }} className={classes.row}>
                    {HtmlReactParser(text)}
                  </Box>
                )
              })}
            </Grid>
          </Grid>
          <Box className={classes.title}>Вопрос {num + 1}</Box>
          <Box className={classes.text}>{HtmlReactParser(data[num].text)}</Box>
          <Box>
            {data[num].test_answers.map((k: TestAnswer, i: number) => (
              <Grid container alignItems="center" spacing={2} mb="5px">
                <Grid item className={classes.var}>
                  {i === 0 ? 'A' : i === 1 ? 'B' : 'C'}
                </Grid>
                <Grid item xs={11}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => setSelect(i + 1)}
                    sx={select === i + 1 ? sx.btnActive : sx.btn}
                  >
                    {k.text}
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Grid container justifyContent="center" mt="45px">
            <Grid item width={335}>
              <Button fullWidth color="secondary" variant="contained" onClick={onNextAnswer} disabled={disabled}>
                Далее
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  content: {
    width: '100%',
    padding: '30px 0',
    backgroundColor: palette.primary.main,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: '25px 0 10px',
    color: palette.primary.dark,
  },
  text: {
    fontSize: 18,
    fontWeight: 600,
    margin: '0 0 20px',
    color: palette.primary.contrastText,
  },
  row: {
    height: 40,
    fontSize: 12,
    fontWeight: 800,
    display: 'flex',
    lineHeight: 1.3,
    borderRadius: 50,
    textAlign: 'center',
    margin: '0 auto 4px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    color: palette.primary.main,
  },
  var: {
    fontSize: 15,
    fontWeight: 700,
    color: palette.primary.contrastText,
  },
}))

const sx = {
  btn: {
    fontSize: 15,
    minWidth: 280,
    width: '100%',
    minHeight: 60,
    lineHeight: 1.2,
    fontWeight: 600,
    color: '#4A5967',
    textAlign: 'left',
    borderRadius: '12px',
    border: '1px solid #F0F1F8',
    justifyContent: 'flex-start',
    boxShadow: '0px 4px 12px 0px #EBEDF4',
    '&:hover': { color: '#4A5967' },
  },
  btnActive: {
    fontSize: 15,
    minWidth: 280,
    minHeight: 60,
    lineHeight: 1.2,
    fontWeight: 600,
    color: '#FFFFFF',
    textAlign: 'left',
    borderRadius: '12px',
    backgroundColor: themeGl === 'calendar' ? '#006266' : '#B786B9',
    justifyContent: 'flex-start',
    boxShadow: '0px 4px 12px 0px #EBEDF4',
    '&:hover': { color: '#FFFFFF', backgroundColor: themeGl === 'calendar' ? '#006266' : '#B786B9' },
  },
}
