import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Theme } from '@mui/material'
import { Modal, TrackEvent } from '../../../../components'
import { StageOne, StageThree, StageTwo } from './stages'
import { getHabitAdditional, trackerStart } from '../../../../api'
import { AddHabit, MainHabit } from './types'
import { useReduxActions, useReduxDispatch } from '../../../../hooks'
import { getUser } from '../../../../store/auth/auth.service'

interface ISelectHabitsModal {
  show: boolean
  onClose: () => void
  updateInfo: () => void
}

const mainHabits: MainHabit[] = [
  {
    id: 20,
    need_note: 0,
    slug: 'water',
    name: 'Потреблять достаточно воды',
    description:
      'Восполнять потери жидкости очень важно для здорового метаболизма. Однако многие из нас не пьют ' +
      'достаточно воды. Сознательно увеличив ее потребление, вы заметите, как улучшаются состояние кожи, ' +
      'пищеварение, концентрация внимания. Вы ощутите прилив внутренней энергии и многие другие положительные ' +
      'изменения. Вскоре вам не придется напоминать себе пить больше воды — это просто войдет в привычку.',
  },
  {
    id: 30,
    need_note: 0,
    slug: 'sport',
    name: 'Поддерживать физическую активность',
    description:
      'Многие люди с радостью занимались бы спортом каждый день, но у них нет такой возможности из-за плотного ' +
      'графика работы и семейных обязанностей. Если вы начнете выделять время для физической активности, то ' +
      'почувствуете себя намного лучше. Неважно, что именно вы будете делать: гулять с собакой, тренироваться в ' +
      'спортзале, заниматься йогой — ваше физическое и эмоциональное состояние значительно улучшатся.',
  },
  {
    id: 40,
    need_note: 0,
    slug: 'sleep',
    name: 'Соблюдать здоровый режим сна',
    description:
      'Спокойный сон — один из самых недооцененных элементов здорового образа жизни. Возможно, вам потребуется ' +
      'избавиться от целого набора раздражающих факторов, чтобы спокойный сон прочно вошел в вашу жизнь. Уделяя ' +
      'отдыху достаточно времени, вы будете чувствовать себя намного лучше в течение дня. У вас появится больше ' +
      'энергии и мотивации для дальнейшего развития полезных привычек.',
  },
]

export const SelectHabitsModal: React.FC<ISelectHabitsModal> = ({
  show = true,
  onClose = () => {},
  updateInfo = () => {},
}): JSX.Element => {
  const classes = useStyles()
  const { setAlert } = useReduxActions()
  const dispatch = useReduxDispatch()

  const [stage, setStage] = useState<number>(1)

  const [mainHabit, setMainHabit] = useState<MainHabit[]>(mainHabits)
  const [addHabit, setAddHabit] = useState<AddHabit[]>([])
  const [addHabitAnswers, setAddHabitAnswers] = useState<number[][]>([])
  const [saveHabits, setSaveHabits] = useState<boolean>(false)

  const onChangeStageOne = () => {
    setStage(stage + 1)
  }

  const onChangeStageTwo = (answers: number[][]) => {
    let counter = 0

    for (let i = 0; answers.length > i; i++) {
      for (let j = 0; answers[i].length > j; j++) {
        if (answers[i][j] !== 0) {
          counter++
        }
      }
    }

    if (counter <= 2) {
      setAddHabitAnswers(answers)
      setStage(stage + 1)
    } else {
      setAlert({ alert: { message: { info: ['Для выбора доступно не более 2 привычек.'] } } })
    }
  }

  const onChangeStageThree = () => {
    setSaveHabits(true)

    const addHabitAnswersCurrent = []

    for (let i = 0; addHabitAnswers.length > i; i++) {
      for (let j = 0; addHabitAnswers[i].length > j; j++) {
        if (addHabitAnswers[i][j] !== 0) addHabitAnswersCurrent.push(addHabitAnswers[i][j])
      }
    }

    trackerStart({ habits: addHabitAnswersCurrent })
      .then((res) => {
        if (res.status === 1) {
          setSaveHabits(false)
          dispatch(getUser()).then(() => {
            onClose()
          })
          updateInfo()
          TrackEvent({
            eventCategory: 'Click',
            eventAction: 'btnclick',
            eventLabel: 'Начать выполнять привычки',
          })
        } else {
          setSaveHabits(false)
          setAlert({ alert: { message: { info: [res.message.alert] } } })
        }
      })
      .catch((err) => {
        setSaveHabits(false)
        setAlert({ alert: { message: { info: [err.response.data.message] } } })
      })
  }

  const onBackStage = () => {
    setStage(stage - 1)
  }

  const loadHabitAdditional = useCallback(() => {
    getHabitAdditional().then((res) => {
      if (res.status === 1) {
        setAddHabit(res.data)
      }
    })
  }, [])

  useEffect(() => {
    loadHabitAdditional()
  }, [])

  useEffect(() => {
    if (addHabit.length > 0) {
      const answerDefault = []

      for (let i = 0; addHabit.length > i; i++) {
        const answerDefaultRows = []

        for (let j = 0; addHabit[i].habits.length > j; j++) {
          answerDefaultRows.push(0)
        }

        answerDefault.push(answerDefaultRows)
      }

      setAddHabitAnswers(answerDefault)
    }
  }, [addHabit])

  return (
    <Modal maxWidth="md" open={show} backButton={stage > 1} onBack={onBackStage} onClose={onClose}>
      <Box className={classes.stage}>
        <span>Шаг {stage}</span> из 3
      </Box>
      {stage === 1 && <StageOne className={classes} items={mainHabit} onChangeStage={onChangeStageOne} />}
      {stage === 2 && (
        <StageTwo
          items={addHabit}
          className={classes}
          addHabitAnswers={addHabitAnswers}
          onChangeStage={(answers: number[][]) => onChangeStageTwo(answers)}
        />
      )}
      {stage === 3 && <StageThree className={classes} onChangeStage={onChangeStageThree} saveHabits={saveHabits} />}
    </Modal>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  stage: {
    fontSize: 16,
    width: '100%',
    fontWeight: 700,
    color: '#939CA4',
    margin: '30px 0 20px',
    '& span': {
      color: palette.primary.dark,
    },
  },
  title: {
    fontSize: 20,
    width: '100%',
    fontWeight: 700,
    margin: '20px 0',
    color: palette.primary.contrastText,
  },
  textTitle: {
    fontSize: 15,
    width: '100%',
    fontWeight: 500,
    marginBottom: 20,
    color: palette.primary.contrastText,
  },
  textNote: {
    fontSize: 13,
    width: '100%',
    fontWeight: 500,
    color: palette.primary.contrastText,
  },
  item: {
    padding: 20,
    marginBottom: 10,
    borderRadius: 12,
    border: '1px solid #F0F1F8',
    boxShadow: '0px 4px 12px #EBEDF4',
  },
  itemTitle: {
    fontSize: 15,
    width: '100%',
    fontWeight: 700,
    marginBottom: 5,
    color: palette.primary.contrastText,
  },
  itemDescription: {
    fontSize: 15,
    fontWeight: 500,
    color: palette.primary.dark,
    '-webkit-tap-highlight-color': 'transparent',
  },
}))
