import React from 'react'
import Countdown from 'react-countdown'
import moment from 'moment'
import { useReduxSelector } from '../../hooks'

interface ITimer {
  endTime: number
  onLoadData?: () => void
}

export const Timer: React.FC<ITimer> = ({ endTime = 0, onLoadData = () => {} }) => {
  return (
    <Countdown
      date={endTime}
      onComplete={() => {
        if (onLoadData) onLoadData()
        else window.location.reload()
      }}
    />
  )
}
