/* eslint-disable max-len */
import { definitionTheme } from '../../../../utils'

const color = definitionTheme({
  marathon: '#B786B9',
  test: '#BB2F2B',
  calendar: '#006266',
  test2: '#5A3624',
  challenge: '#E47E3D',
}) as string

export const TasksDrawerIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6134 7.99908C10.6134 9.97748 9.00955 11.5813 7.03115 11.5813C5.05275 11.5813 3.44894 9.97748 3.44894 7.99908C3.44894 6.02068 5.05275 4.41687 7.03115 4.41687C9.00955 4.41687 10.6134 6.02068 10.6134 7.99908Z"
      fill={color}
    />
    <path
      d="M14.7073 7.99904C14.7073 7.15115 15.4863 6.46381 16.4472 6.46381H30.3667C31.3276 6.46381 32.1066 7.15115 32.1066 7.99904C32.1066 8.84692 31.3276 9.53427 30.3667 9.53427H16.4472C15.4863 9.53427 14.7073 8.84692 14.7073 7.99904Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.03115 20.025C8.02035 20.025 8.82226 19.2231 8.82226 18.2339C8.82226 17.2447 8.02035 16.4428 7.03115 16.4428C6.04195 16.4428 5.24005 17.2447 5.24005 18.2339C5.24005 19.2231 6.04195 20.025 7.03115 20.025ZM7.03115 21.8162C9.00955 21.8162 10.6134 20.2123 10.6134 18.2339C10.6134 16.2555 9.00955 14.6517 7.03115 14.6517C5.05275 14.6517 3.44894 16.2555 3.44894 18.2339C3.44894 20.2123 5.05275 21.8162 7.03115 21.8162Z"
      fill={color}
    />
    <path
      d="M14.7073 17.7222C14.7073 16.5917 15.4863 15.6752 16.4472 15.6752H30.3667C31.3276 15.6752 32.1066 16.5917 32.1066 17.7222C32.1066 18.8527 31.3276 19.7692 30.3667 19.7692H16.4472C15.4863 19.7692 14.7073 18.8527 14.7073 17.7222Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.03115 29.2365C8.02035 29.2365 8.82226 28.4346 8.82226 27.4454C8.82226 26.4562 8.02035 25.6543 7.03115 25.6543C6.04195 25.6543 5.24005 26.4562 5.24005 27.4454C5.24005 28.4346 6.04195 29.2365 7.03115 29.2365ZM7.03115 31.0276C9.00955 31.0276 10.6134 29.4238 10.6134 27.4454C10.6134 25.467 9.00955 23.8632 7.03115 23.8632C5.05275 23.8632 3.44894 25.467 3.44894 27.4454C3.44894 29.4238 5.05275 31.0276 7.03115 31.0276Z"
      fill={color}
    />
    <path
      d="M14.7073 27.4453C14.7073 26.5974 15.4863 25.9101 16.4472 25.9101H30.3667C31.3276 25.9101 32.1066 26.5974 32.1066 27.4453C32.1066 28.2932 31.3276 28.9806 30.3667 28.9806H16.4472C15.4863 28.9806 14.7073 28.2932 14.7073 27.4453Z"
      fill={color}
    />
  </svg>
)
