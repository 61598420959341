/* eslint-disable max-len */
import { FC } from 'react'
import { themeGl } from '../../configs'
import { definitionTheme } from '../../utils'

interface IVkIcon {
  colorMarathon?: string
  colorCalendar?: string
  colorTest2?: string
  colorChallenge?: string
}

export const VkIcon: FC<IVkIcon> = ({
  colorChallenge = '#004438',
  colorTest2 = '#5A3624',
  colorMarathon = '#B786B9',
  colorCalendar = '#006266',
}) => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <path
        d="M19.1414 26.9498H20.4415C20.4415 26.9498 20.8342 26.9081 21.0347 26.6991C21.2193 26.5072 21.2134 26.1468 21.2134 26.1468C21.2134 26.1468 21.188 24.4597 21.9976 24.2112C22.7957 23.9665 23.8206 25.8418 24.9069 26.5628C25.7284 27.1086 26.3526 26.9889 26.3526 26.9889L29.2572 26.9498C29.2572 26.9498 30.7766 26.8593 30.0562 25.7039C29.9971 25.6094 29.6366 24.8492 27.8965 23.2872C26.0753 21.6523 26.3192 21.9168 28.5131 19.0888C29.8492 17.3666 30.3833 16.3152 30.2165 15.8648C30.0573 15.4359 29.0747 15.5493 29.0747 15.5493L25.8042 15.569C25.8042 15.569 25.5618 15.537 25.382 15.641C25.2063 15.7429 25.0932 15.9805 25.0932 15.9805C25.0932 15.9805 24.5756 17.3132 23.8853 18.4466C22.429 20.8382 21.8467 20.9644 21.6087 20.8159C21.0551 20.4698 21.1933 19.4255 21.1933 18.6836C21.1933 16.366 21.5568 15.3997 20.4855 15.1495C20.13 15.0664 19.8684 15.0116 18.9593 15.0027C17.7923 14.991 16.8047 15.0062 16.2454 15.2712C15.8734 15.4473 15.5864 15.84 15.7612 15.8626C15.9773 15.8905 16.4669 15.9902 16.7264 16.332C17.0615 16.7729 17.0497 17.7633 17.0497 17.7633C17.0497 17.7633 17.2423 20.4915 16.6 20.8305C16.1592 21.0629 15.5545 20.5883 14.2562 18.4186C13.5909 17.3072 13.0887 16.0787 13.0887 16.0787C13.0887 16.0787 12.9918 15.8491 12.8191 15.7263C12.6094 15.5776 12.3165 15.5302 12.3165 15.5302L9.20879 15.5499C9.20879 15.5499 8.74226 15.5624 8.571 15.7586C8.41864 15.9331 8.5589 16.294 8.5589 16.294C8.5589 16.294 10.9919 21.7991 13.7468 24.5733C16.2729 27.1168 19.1414 26.9498 19.1414 26.9498Z"
        fill={
          definitionTheme(
            { calendar: colorCalendar, test2: colorTest2, challenge: colorChallenge },
            colorMarathon
          ) as string
        }
      />
      <rect
        x="1.5"
        y="1"
        width="38"
        height="38"
        rx="19"
        stroke={
          definitionTheme(
            { calendar: colorCalendar, test2: colorTest2, challenge: colorChallenge },
            colorMarathon
          ) as string
        }
        strokeWidth="2"
      />
    </g>
  </svg>
)
