/* eslint-disable max-len */
export const ProgressIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="#B6BCC2" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3291 18.0687L28.3207 18.0669C28.3747 17.7581 28.4164 17.4478 28.446 17.137C28.6394 15.1048 28.312 13.0454 27.4798 11.1581C26.3867 8.67932 24.487 6.64335 22.0898 5.38142C19.6925 4.11949 16.9388 3.70585 14.2766 4.20779C11.6144 4.70973 9.20042 6.09773 7.4273 8.14596C5.65419 10.1942 4.62631 12.7821 4.5109 15.4888C4.39549 18.1954 5.19933 20.8615 6.79165 23.0532C8.38397 25.245 10.6711 26.8334 13.2809 27.5601C15.2679 28.1135 17.3529 28.1425 19.3365 27.66C19.9124 27.5199 20.4797 27.3367 21.0335 27.1107C22.8625 26.3596 24.4191 25.1803 25.6909 23.6394C26.9636 22.0973 27.8621 20.2338 28.2891 18.2544L28.3291 18.0687ZM18.1414 24.8104C18.2393 25.2288 17.973 25.6287 17.5712 25.6732C16.3445 25.809 15.0946 25.7112 13.8892 25.3756C11.7726 24.7861 9.9177 23.4979 8.6263 21.7203C7.33489 19.9428 6.68295 17.7805 6.77656 15.5854C6.87016 13.3902 7.70379 11.2914 9.14183 9.63019C10.5799 7.96902 12.5377 6.84333 14.6968 6.43624C16.8559 6.02915 19.0892 6.36462 21.0334 7.38808C22.9776 8.41154 24.5183 10.0628 25.4049 12.0731C25.9015 13.1993 26.1765 14.4011 26.2246 15.614C26.2413 16.0346 25.8562 16.3616 25.4122 16.2954L17.4646 15.1105C16.6724 14.9923 16.0108 15.7105 16.1934 16.4904L18.1414 24.8104Z"
    />
  </svg>
)
