import React from 'react'
import { Box, Button, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HtmlReactParser from 'html-react-parser'
import * as process from 'process'
import { Modal } from '../../../components'
import { Advice, ListUseful, Pdf, Products } from '../type'
import { MoreBackIcon } from '../../../imgs'
import { aHref, definitionTheme } from '../../../utils'

interface IInfoModal {
  show: boolean
  className: any
  item: ListUseful
  onClose: () => void
  onOpenInfoDetail: (info: Advice | Products, type: 'advice' | 'products', text: string) => void
}

export const InfoModal: React.FC<IInfoModal> = ({
  className,
  item,
  show = false,
  onClose = () => {},
  onOpenInfoDetail = () => {},
}): JSX.Element => {
  const classes = useStyles()

  const { REACT_APP_API_LINK: URL } = process.env

  const onPdf = (link: string) => {
    aHref(`${URL}${link}`)
  }

  return (
    <Modal maxWidth="md" open={show} onClose={onClose}>
      <Box className={classes.infoModal}>
        <Box className={classes.title}>{HtmlReactParser(item.text)}</Box>
        {item?.advice && (
          <Button
            fullWidth
            sx={{ padding: 0 }}
            onClick={() => {
              if (item?.advice) onOpenInfoDetail(item.advice, 'advice', item.text)
            }}
          >
            <Box className={className.contentBtnCurrentModal}>
              <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Box className={className.contentBtnTitleCurrentModal}>{HtmlReactParser(item.advice.name)}</Box>
                </Grid>
                <Grid item>
                  <Box className={className.moreIcon}>
                    <MoreBackIcon />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Button>
        )}

        {item?.products && (
          <Button
            fullWidth
            sx={{ padding: 0 }}
            onClick={() => {
              if (item?.products) onOpenInfoDetail(item.products, 'products', item.text)
            }}
          >
            <Box className={className.contentBtnCurrentModal}>
              <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Box className={className.contentBtnTitleCurrentModal}>{HtmlReactParser(item.products.name)}</Box>
                </Grid>
                <Grid item>
                  <Box className={className.moreIcon}>
                    <MoreBackIcon />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Button>
        )}

        {item.pdf.map((k: Pdf, i: number) => {
          return (
            <Button key={`info-${i}`} fullWidth sx={{ padding: 0 }} onClick={() => onPdf(k.link)}>
              <Box className={className.contentBtnCurrentModal}>
                <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Box className={className.contentBtnTitleCurrentModal}>{HtmlReactParser(k.title)}</Box>
                  </Grid>
                  <Grid item>
                    <Box className={className.moreIcon}>
                      <MoreBackIcon />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Button>
          )
        })}
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  infoModal: {
    minHeight: 'calc(100vh - 100px)',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: '30px 0 20px',
    color: definitionTheme({ challenge: palette.warning.main }, palette.primary.contrastText) as string,
  },
}))
