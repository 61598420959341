import React, { FC, ReactNode, useEffect, useState } from 'react'
import { AppBar, Box, Container, Grid, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useDetectDevice, useReduxDispatch, useReduxSelector } from '../../hooks'
import { definitionTheme, str } from '../../utils'
import {
  ProgressIcon,
  MenuIcon,
  HomeIcon,
  TaskIcon,
  InfoIcon,
  Test2Icon,
  ActivityIcon,
  ChallengeIcon,
  CardsIcon,
  CardsActiveIcon,
} from './icons'
import { DetectDevice } from '../../types'
import {
  UserIcon,
  HabitIcon,
  ActivityDrawerIcon,
  InfoDrawerIcon,
  TasksDrawerIcon,
  InfoMarathonIcon,
  ProgressDrawerIcon,
  Test2DrawerIcon,
  ChallengeDrawerIcon,
  CardsDrawerIcon,
  RulesIcon,
} from './icons/drawer'
import { InfoUserModal } from './info-user.modal'
import { TgLink } from '../tg-link'
import { Support } from '../support'
import { BenefitModal } from '../../pages/main/modals'
import { getUser } from '../../store/auth/auth.service'
import { TrackEvent } from '../GA'
import { themeGl } from '../../configs'
import { img } from '../../imgs'
import { ParticipateModal, useStylesChallenge } from '../../pages'
import { Reminder } from '../reminder'

type MenuItem = {
  key: string
  path?: string
  title: string
  icon: React.ReactNode
  target: '_self' | '_blank'
}

type MenuDrawer = {
  path: string
  title: string
  icon: React.ReactNode
}

const menu: MenuItem[] = [
  {
    key: definitionTheme(
      { test2: 'testabod', calendar: 'calendar', test: 'test', marathon: 'marathon', challenge: 'meta' },
      'main'
    ) as string,
    path: '/',
    target: '_self',
    icon: definitionTheme(
      { test: <Test2Icon />, test2: <Test2Icon />, calendar: <TaskIcon />, challenge: <ChallengeIcon /> },
      <HomeIcon />
    ) as ReactNode,
    title: definitionTheme(
      { marathon: 'Марафон', calendar: 'Календарь', test: 'Тест', test2: 'Тест', challenge: 'Челлендж' },
      '/'
    ) as string,
  },
  {
    key: 'cards',
    path: '/cards',
    target: '_self',
    icon: <CardsIcon />,
    title: 'Карточки',
  },
  {
    key: 'activity',
    path: '/activity',
    target: '_self',
    icon: <ActivityIcon />,
    title: 'Активности',
  },
  {
    title: themeGl === 'calendar' ? 'Тест' : 'Задания',
    key: themeGl === 'calendar' ? 'test' : 'tasks',
    path: themeGl === 'calendar' ? '/test' : '/tasks',
    icon: themeGl === 'calendar' ? <HomeIcon /> : <TaskIcon />,
    target: '_self',
  },
  {
    title: 'Прогресс',
    key: 'progress',
    path: '/progress',
    icon: <ProgressIcon />,
    target: '_self',
  },
  {
    title: 'Инфо',
    key: 'info',
    path: '/info',
    icon: <InfoIcon />,
    target: '_self',
  },
]

const menuDrawer: MenuDrawer[] = [
  {
    path: `/${definitionTheme({ challenge: 'rules' }, 'info-marathon')}`,
    title: definitionTheme({ challenge: 'Правила' }, 'О марафоне') as string,
    icon: definitionTheme({ challenge: <RulesIcon /> }, <InfoMarathonIcon />) as ReactNode,
  },
  {
    path: '/',
    title: definitionTheme(
      { marathon: 'Марафон', calendar: 'Календарь', test: 'Тест', test2: 'Тест', challenge: 'Челлендж' },
      '/'
    ) as string,
    icon: definitionTheme(
      { calendar: <TasksDrawerIcon />, test2: <Test2DrawerIcon />, challenge: <ChallengeDrawerIcon /> },
      <HabitIcon />
    ) as ReactNode,
  },
  {
    path: themeGl === 'calendar' ? '/test' : '/tasks',
    title: themeGl === 'calendar' ? 'Тест' : 'Задания',
    icon: themeGl === 'calendar' ? <HabitIcon /> : <TasksDrawerIcon />,
  },
  { path: '/cards', title: 'Карточки', icon: <CardsDrawerIcon /> },
  {
    path: '/activity',
    icon: <ActivityDrawerIcon />,
    title: 'Активности',
  },
  {
    path: '/progress',
    title: 'Прогресс',
    icon: <ProgressDrawerIcon />,
  },
  {
    path: '/info',
    title: 'Информация',
    icon: <InfoDrawerIcon />,
  },
]

export const MenuApp: React.FC = (): JSX.Element => {
  const classesChallenge = useStylesChallenge()
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { pathname } = useLocation()
  const detectDevice = useDetectDevice()

  const { breakpoints: points } = useTheme()

  const sm = useMediaQuery(points.down(600))
  const xs = useMediaQuery(points.down(350))

  const { auth, data: userData } = useReduxSelector((state) => state.auth)

  const [open, setOpen] = useState<boolean>(false)
  const [infoUser, setInfoUser] = useState<boolean>(false)
  const [benefitModal, setBenefitModal] = useState<boolean>(false)
  const [rulesModal, setRulesModal] = useState<boolean>(false)

  const onNavigate = (item: MenuItem) => {
    setOpen(false)
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'btnclick',
      eventLabel: item.title,
    })
    if (item.key === 'main') {
      navigate('../')
    } else {
      navigate(`../${item.key}`)
    }
    window.scrollTo(0, 0)
  }

  const onNavigateDrawer = (path: string) => {
    if (path === '/info-marathon') {
      setBenefitModal(true)
      TrackEvent({
        eventCategory: 'Click',
        eventAction: 'btnclick',
        eventLabel: 'О марафоне',
      })
    } else if (path === '/rules') {
      setRulesModal(true)
    } else {
      setOpen(false)
      navigate(`..${path}`)
    }
  }

  useEffect(() => {
    if (open) document.body.style.position = 'fixed'
    else document.body.style.position = 'relative'
  }, [open])

  return (
    <>
      {userData && <InfoUserModal show={infoUser} infoUser={userData} onClose={() => setInfoUser(false)} />}

      {userData && (
        <BenefitModal
          show={benefitModal}
          onOpenSelectHabitsModal={() => {}}
          onClose={() => setBenefitModal(false)}
          startMarathon={userData?.start_tracker_at !== null}
        />
      )}

      <ParticipateModal open={rulesModal} className={classesChallenge} onClose={() => setRulesModal(false)} />

      <Box className={clsx(classes.drawer, { [classes.drawerOpen]: open })}>
        <Container maxWidth="sm" sx={{ minHeight: '100vh' }}>
          <Grid container wrap="nowrap" direction="column" justifyContent="space-between" minHeight="100vh">
            <Grid item>
              <Box className={classes.drawerTitle}>Меню</Box>
              <Grid
                container
                m="0 auto"
                maxWidth={themeGl === 'calendar' ? 280 : '100%'}
                justifyContent={themeGl === 'calendar' ? 'space-evenly' : 'space-between'}
              >
                {menuDrawer.map((k: MenuDrawer, i: number) => {
                  return (k.path === '/info-marathon' || k.path === '/progress' || k.path === '/tasks') &&
                    (themeGl === 'calendar' || themeGl === 'test' || themeGl === 'test2' || themeGl === 'challenge') ? (
                    <></>
                  ) : (
                    <Grid key={`item-menu-${i}`} item maxWidth={107}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <IconButton
                            color="primary"
                            onClick={() => onNavigateDrawer(k.path)}
                            sx={{ p: `4px 4px ${themeGl === 'calendar' ? 20 : 8}px` }}
                          >
                            <Box className={classes.drawerItem}>
                              <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                              >
                                <Grid item>{k.icon}</Grid>
                                <Grid item>
                                  <Box
                                    color={
                                      (k.path === '/activity' || k.path === '/info') && themeGl === 'challenge'
                                        ? '#A1AAAD'
                                        : undefined
                                    }
                                    className={classes.drawerItemText}
                                  >
                                    {k.title}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
                <Grid item maxWidth={107}>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <IconButton
                        color="primary"
                        sx={{ p: '4px 4px 8px' }}
                        onClick={() => {
                          TrackEvent({
                            eventCategory: 'Click',
                            eventAction: 'btnclick',
                            eventLabel: 'Мои данные',
                          })
                          setInfoUser(true)
                        }}
                      >
                        <Box className={classes.drawerItem}>
                          <Grid container direction="column" justifyContent="center" alignItems="center" height="100%">
                            <Grid item>
                              <UserIcon />
                            </Grid>
                            <Grid item>
                              <Box
                                color={themeGl === 'challenge' ? '#A1AAAD' : undefined}
                                className={classes.drawerItemText}
                              >
                                Мои данные
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!userData?.notify_need && (
                <Box>
                  <TgLink />
                </Box>
              )}
              {userData?.notify_need && (
                <Box>
                  <Reminder />
                </Box>
              )}
            </Grid>
            <Grid item>
              <Box mb="100px">
                <Support type="menu" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {auth && (
        <AppBar
          position="fixed"
          sx={{
            top: 'auto',
            bottom: 0,
            height: {
              xs: detectDevice === DetectDevice.IOS ? 85 : 70,
              sm: detectDevice === DetectDevice.IOS ? 95 : 80,
            },
          }}
          className={classes.appBarBottom}
        >
          <Box sx={{ width: '100%', borderTop: themeGl === 'calendar' ? 'none' : '1px solid #EDEFF9!important' }}>
            <Box className={classes.containerBottom}>
              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                sx={{ height: 70 }}
                className={classes.appBarBottom}
                justifyContent={sm ? (themeGl === 'marathon' ? 'space-between' : 'space-evenly') : 'space-evenly'}
              >
                {menu.map((k: MenuItem, _: number) => {
                  return (k.path === '/progress' || k.path === '/tasks') &&
                    (themeGl === 'calendar' || themeGl === 'test' || themeGl === 'test2' || themeGl === 'challenge') ? (
                    <></>
                  ) : (
                    <Grid key={`item-menu-${k.key}`} item xs="auto" minWidth={xs ? 'auto' : 60} mb="4px">
                      <Grid container justifyContent="center">
                        <Grid item>
                          <IconButton color="primary" onClick={() => onNavigate(k)}>
                            <Grid container direction="column">
                              <Grid item>
                                <Box
                                  className={clsx({
                                    [classes.activeLink]: !open && pathname === k.path && pathname !== '/cards',
                                  })}
                                >
                                  {!open && pathname === '/cards' && pathname === k.path ? <CardsActiveIcon /> : k.icon}
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  className={classes.title}
                                  color={
                                    !open && pathname === k.path
                                      ? (definitionTheme(
                                          { calendar: '#FFFFFF', test2: '#5A3624', challenge: '#E47E3D' },
                                          '#B786B9'
                                        ) as string)
                                      : '#B6BCC2'
                                  }
                                >
                                  {str.normalize(k.title)}
                                </Box>
                              </Grid>
                            </Grid>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
                <Grid item xs="auto" minWidth={xs ? 'auto' : 60} mb="4px">
                  <Grid container justifyContent="center">
                    <Grid item>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          if (!open) dispatch(getUser())
                          setOpen(!open)
                          TrackEvent({
                            eventCategory: 'Click',
                            eventAction: 'btnclick',
                            eventLabel: 'Меню',
                          })
                        }}
                      >
                        <Grid container direction="column">
                          <Grid item>
                            <Box className={clsx({ [classes.activeLink]: open })}>
                              <MenuIcon />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              className={classes.title}
                              color={
                                open
                                  ? (definitionTheme(
                                      { calendar: '#FFFFFF', test2: '#5A3624', challenge: '#E47E3D' },
                                      '#B786B9'
                                    ) as string)
                                  : '#B6BCC2'
                              }
                            >
                              Меню
                            </Box>
                          </Grid>
                        </Grid>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </AppBar>
      )}
    </>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  drawer: {
    width: '100vw',
    position: 'fixed',
    height: '100vh',
    transition: '0.3s',
    overflow: 'scroll',
    backgroundSize: '85vw 200vw',
    backgroundRepeat: 'no-repeat',
    transform: 'translate(0, 100vh)',
    backgroundImage: themeGl === 'calendar' ? `url(${img.bgGreetCalendar})` : 'none',
    backgroundPosition: 'left top',
    backgroundColor: definitionTheme({ calendar: palette.background.default }, palette.primary.main) as string,
  },
  drawerOpen: {
    transform: 'translate(0, 0)',
  },
  drawerTitle: {
    fontSize: 24,
    fontWeight: 700,
    padding: '30px 0 20px',
    color: definitionTheme(
      { calendar: palette.primary.main, challenge: palette.warning.main },
      palette.primary.contrastText
    ) as string,
  },
  drawerItem: {
    width: 105,
    height: 93,
    borderRadius: 12,
    border: definitionTheme({ calendar: 'none' }, '1px solid #F0F1F8') as string,
    backgroundColor: definitionTheme({ calendar: palette.primary.dark }, 'transparent') as string,
    boxShadow: definitionTheme({ calendar: '0px 4px 12px #006266' }, '0px 4px 12px #EBEDF4') as string,
    [down(365)]: {
      width: definitionTheme({ calendar: 105 }, 85) as number,
      height: definitionTheme({ calendar: 93 }, 85),
    },
    [down(330)]: {
      width: definitionTheme({ calendar: 105 }, 75) as number,
      height: definitionTheme({ calendar: 93 }, 75) as number,
    },
  },
  drawerItemText: {
    fontSize: 13,
    fontWeight: 600,
    color: definitionTheme(
      { calendar: palette.primary.main, challenge: palette.warning.main },
      palette.primary.contrastText
    ) as string,
    [down(365)]: {
      fontSize: definitionTheme({ calendar: 13 }, 11) as number,
    },
    [down(330)]: {
      fontSize: definitionTheme({ calendar: 13 }, 10) as number,
    },
  },
  appBarBottom: {
    width: '100%',
    backgroundColor: `${definitionTheme({ calendar: palette.primary.dark }, palette.primary.main)}!important`,
    '& svg': {
      [down(365)]: {
        width: 30,
        height: 29,
      },
      [down(330)]: {
        width: 28,
        height: 27,
      },
    },
  },
  containerBottom: {
    padding: '0 10px!important',
    [down(330)]: {
      padding: '0 5px!important',
    },
  },
  gridBottom: {
    height: 80,
    [down('sm')]: {
      height: 70,
    },
  },
  title: {
    fontSize: 10,
    marginTop: -3,
    fontWeight: 600,
    [down(365)]: {
      fontSize: 9,
    },
  },
  activeLink: {
    '& svg': {
      fill: `${definitionTheme(
        { calendar: palette.primary.main, challenge: palette.warning.main },
        palette.primary.dark
      )}!important`,
      stroke: `${definitionTheme(
        { calendar: palette.primary.main, challenge: palette.warning.main },
        palette.primary.dark
      )}!important`,
    },
  },
}))
