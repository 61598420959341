import { ReactNode } from 'react'
import { themeGl, ThemeVariant } from '../configs'

export type definitionVariant = ReactNode | string | number

export const definitionTheme = <T>(
  variant: Partial<Record<ThemeVariant, T | definitionVariant>>,
  defaultVariant?: T | definitionVariant
): T | definitionVariant => {
  const theme = !!Object.keys(variant).find((key) => key === themeGl)

  switch (true) {
    case theme:
      return variant[themeGl]
    case !!defaultVariant:
      return defaultVariant
    default:
      return undefined
  }
}
