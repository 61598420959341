/* eslint-disable max-len */
import { themeGl } from '../../../configs'

export const InfoIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="#B6BCC2" stroke="#B6BCC2" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.625 5H10.125C9.56141 5 9.02091 5.23178 8.6224 5.64436C8.22388 6.05695 8 6.61652 8 7.2V24.8C8 25.3835 8.22388 25.9431 8.6224 26.3556C9.02091 26.7682 9.56141 27 10.125 27H22.875C23.4386 27 23.9791 26.7682 24.3776 26.3556C24.7761 25.9431 25 25.3835 25 24.8V11.6L18.625 5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={themeGl === 'calendar' ? '#006266' : '#FFFFFF'}
    />
    <path d="M18 5V12H25" />
    <path d="M21 17H12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21 21H12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 12H13H12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
