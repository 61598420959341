/* eslint-disable max-len */

import { FC, JSX } from 'react'

export const RulesIcon: FC = (): JSX.Element => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1372_9534)">
      <path
        d="M17.5036 20.16C17.4047 20.1807 17.3066 20.2068 17.2097 20.2382L1.82442 25.2343C1.1891 25.4406 1.15335 26.3422 1.77026 26.6L16.9944 32.9617C17.6919 33.2531 18.4743 33.2521 19.171 32.9587L34.2413 26.6132C34.8555 26.3546 34.8203 25.457 34.1879 25.249L23.1999 21.6367C22.9039 22.4273 22.7491 23.4095 22.7491 24.5398V24.5505L22.749 24.5611C22.7415 25.0799 22.5346 25.5737 22.1747 25.9381C21.815 26.3023 21.3309 26.5089 20.8256 26.5163L20.7944 26.5168L20.7632 26.5159C20.2629 26.501 19.786 26.2912 19.4324 25.9277C19.0786 25.564 18.8757 25.0745 18.8684 24.5607L18.8682 24.5418L18.8684 24.523C18.8861 22.957 18.5684 21.6157 17.963 20.7112C17.8292 20.5113 17.6774 20.326 17.5036 20.16Z"
        fill="#DB580F"
      />
      <path
        d="M15.0948 16.8088C14.958 16.8088 14.8269 16.8638 14.7301 16.9618C14.6334 17.0597 14.5791 17.1925 14.5791 17.331C14.5791 17.4695 14.6334 17.6023 14.7301 17.7002C14.8269 17.7981 14.958 17.8531 15.0948 17.8531C15.0948 17.8531 17.7656 17.8531 19.0509 19.4976C20.3363 21.142 20.2978 24.5399 20.2978 24.5399C20.2997 24.6765 20.3536 24.807 20.4483 24.9043C20.5429 25.0016 20.671 25.0581 20.8058 25.0621C20.9419 25.0601 21.0719 25.0044 21.1682 24.9069C21.2645 24.8095 21.3195 24.6778 21.3215 24.5399C21.3215 23.558 21.5677 20.7523 22.5068 19.5755C23.5206 18.4083 25.0455 17.8725 26.5475 17.8298C26.6843 17.8298 26.8155 17.7748 26.9122 17.6768C27.0089 17.5789 27.0632 17.4461 27.0632 17.3076C27.0632 17.1691 27.0089 17.0363 26.9122 16.9384C26.8155 16.8405 26.6843 16.7854 26.5475 16.7854C26.5475 16.7854 23.8845 16.7854 22.5683 15.1098C21.6755 13.9876 21.3676 11.0806 21.3215 10.0519C21.3155 9.91749 21.2586 9.7906 21.1625 9.69768C21.0664 9.60476 20.9386 9.55297 20.8058 9.5531C20.6717 9.55716 20.5444 9.61395 20.451 9.71144C20.3576 9.80893 20.3054 9.93944 20.3055 10.0753C20.2899 11.8324 20.0606 13.6374 19.0894 15.1332C17.7733 16.7854 15.1256 16.8166 15.1025 16.8166L15.0948 16.8088Z"
        fill="#DB580F"
      />
      <path
        d="M10.0685 8.17367C10.4982 8.17575 10.9233 8.26355 11.3195 8.43204C11.7157 8.60053 12.0752 8.84642 12.3776 9.15565C12.9933 9.93499 13.1472 11.6885 13.1472 12.273C13.1492 12.4109 13.2042 12.5425 13.3005 12.64C13.3968 12.7375 13.5268 12.7932 13.6629 12.7952C13.7878 12.7859 13.905 12.731 13.9928 12.6407C14.0806 12.5503 14.133 12.4308 14.1401 12.3042C14.1401 11.6885 14.2864 9.96617 14.8405 9.22579C15.1473 8.89826 15.5158 8.63639 15.9244 8.45567C16.3329 8.27495 16.773 8.17906 17.2188 8.17367C17.3556 8.17367 17.4867 8.11866 17.5835 8.02073C17.6802 7.92281 17.7345 7.78999 17.7345 7.65151C17.7345 7.51302 17.6802 7.38021 17.5835 7.28228C17.4867 7.18436 17.3556 7.12934 17.2188 7.12934C16.3491 7.1193 15.5188 6.76058 14.9098 6.13178C14.3864 5.45375 14.2017 3.63787 14.1401 3.01439C14.1361 2.87726 14.0789 2.74725 13.981 2.65246C13.883 2.55766 13.7522 2.5057 13.6167 2.50781C13.4825 2.51367 13.3555 2.5708 13.2613 2.66769C13.167 2.76457 13.1125 2.89403 13.1087 3.02998C13.1087 3.63787 12.9702 5.36802 12.3853 6.14737C12.0846 6.46048 11.7259 6.71046 11.3297 6.88297C10.9334 7.05549 10.5075 7.14716 10.0762 7.15272C9.93946 7.15272 9.8083 7.20774 9.71159 7.30566C9.61488 7.40359 9.56055 7.5364 9.56055 7.67489C9.56055 7.81337 9.61488 7.94619 9.71159 8.04411C9.8083 8.14204 9.93946 8.19705 10.0762 8.19705L10.0685 8.17367Z"
        fill="#DB580F"
      />
      <path
        d="M14.294 26.2701C14.2955 26.6104 14.3965 26.9426 14.5844 27.2248C14.7722 27.507 15.0384 27.7266 15.3493 27.8558C15.6603 27.9849 16.002 28.0179 16.3315 27.9506C16.6609 27.8832 16.9633 27.7186 17.2004 27.4774C17.4375 27.2362 17.5988 26.9293 17.6638 26.5954C17.7288 26.2615 17.6947 25.9156 17.5657 25.6014C17.4367 25.2871 17.2187 25.0186 16.9392 24.8297C16.6596 24.6407 16.331 24.5399 15.9949 24.5399C15.7709 24.5399 15.5491 24.5847 15.3422 24.6718C15.1353 24.7588 14.9475 24.8864 14.7894 25.0471C14.6314 25.2079 14.5063 25.3987 14.4212 25.6086C14.3362 25.8184 14.2929 26.0432 14.294 26.2701ZM16.6646 26.2701C16.6646 26.4042 16.6253 26.5353 16.5517 26.6468C16.4781 26.7583 16.3736 26.8452 16.2512 26.8965C16.1288 26.9478 15.9942 26.9612 15.8643 26.9351C15.7344 26.9089 15.6151 26.8443 15.5215 26.7495C15.4278 26.6547 15.364 26.5339 15.3382 26.4023C15.3124 26.2708 15.3256 26.1345 15.3763 26.0106C15.427 25.8867 15.5128 25.7808 15.6229 25.7063C15.733 25.6318 15.8625 25.592 15.9949 25.592C16.1725 25.592 16.3429 25.6635 16.4684 25.7906C16.594 25.9178 16.6646 26.0902 16.6646 26.2701Z"
        fill="white"
      />
      <path
        d="M22.9144 5.9993C23.222 6.00699 23.5248 5.92117 23.7838 5.7529C24.0428 5.58464 24.246 5.34165 24.3672 5.0553C24.4885 4.76895 24.5222 4.45238 24.464 4.14644C24.4059 3.8405 24.2585 3.55922 24.0409 3.33892C23.8233 3.11861 23.5456 2.96938 23.2434 2.91049C22.9413 2.85159 22.6286 2.88574 22.3458 3.00851C22.063 3.13128 21.8231 3.33705 21.6569 3.59927C21.4907 3.86148 21.406 4.16811 21.4136 4.47957C21.4233 4.87952 21.5846 5.26035 21.8639 5.54324C22.1433 5.82613 22.5194 5.98942 22.9144 5.9993ZM22.9144 4.00417C23.0389 4.00417 23.1584 4.05426 23.2464 4.14341C23.3345 4.23257 23.3839 4.35349 23.3839 4.47957C23.3839 4.60566 23.3345 4.72658 23.2464 4.81573C23.1584 4.90489 23.0389 4.95497 22.9144 4.95497C22.7899 4.95497 22.6705 4.90489 22.5824 4.81573C22.4944 4.72658 22.4449 4.60566 22.4449 4.47957C22.4449 4.41714 22.4571 4.35532 22.4807 4.29764C22.5043 4.23997 22.5388 4.18756 22.5824 4.14341C22.626 4.09927 22.6778 4.06425 22.7348 4.04036C22.7917 4.01647 22.8528 4.00417 22.9144 4.00417Z"
        fill="#DB580F"
      />
      <path
        d="M7.87535 19.7859C8.26696 19.7857 8.65163 19.6812 8.99073 19.4829C9.32983 19.2845 9.61142 18.9993 9.80723 18.6559C10.051 18.2263 10.1482 17.7273 10.0835 17.2362C10.0189 16.745 9.79614 16.2892 9.44975 15.9392C9.10335 15.5893 8.65266 15.3647 8.16747 15.3004C7.68229 15.236 7.18969 15.3355 6.76599 15.5834C6.34228 15.8312 6.01111 16.2136 5.82377 16.6713C5.63643 17.1291 5.60338 17.6366 5.72974 18.1152C5.85609 18.5939 6.1348 19.017 6.52269 19.3191C6.91059 19.6211 7.38602 19.7852 7.87535 19.7859ZM7.87535 16.3334C8.031 16.3324 8.18533 16.3624 8.32953 16.4218C8.47372 16.4812 8.60496 16.5687 8.71574 16.6794C8.82652 16.7902 8.91468 16.9219 8.97518 17.0671C9.03568 17.2124 9.06733 17.3682 9.06834 17.5258C9.06741 17.7358 9.01449 17.9421 8.91441 18.1259C8.81036 18.31 8.66029 18.4631 8.47928 18.5698C8.29828 18.6765 8.09272 18.7331 7.88328 18.7338C7.67383 18.7346 7.46788 18.6795 7.28613 18.5741C7.10438 18.4687 6.95324 18.3167 6.8479 18.1334C6.74256 17.9501 6.68674 17.7419 6.68604 17.5298C6.68535 17.3178 6.7398 17.1092 6.84394 16.9252C6.94807 16.7412 7.09821 16.5882 7.27926 16.4816C7.46032 16.375 7.6659 16.3185 7.87535 16.3178V16.3334Z"
        fill="#DB580F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1372_9534">
        <rect width="34" height="31" fill="white" transform="translate(1 2.5)" />
      </clipPath>
    </defs>
  </svg>
)
