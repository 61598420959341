export interface Font {
  primary: string
  secondary?: string
  tertiary?: string
  other?: string
}

export type Constants = {
  NOW: string
  END_DATE: string
  START_DATE: string
  CAPTCHA_SITEKEY?: string | null
}

export type messageVariants = 'success' | 'warning' | 'error' | 'info' | 'reset'
export type Message = {
  [variant in messageVariants]?: string[]
}

export type AlertProps = {
  message?: Message
  rootModal?: string
  onClose?(): void
  onAction?(): void
  emptyMessage?: boolean
  closeButton?: boolean
  title?: string
  fullScreen?: boolean
  actionButtonText?: string
}

export type PrimaryMedia = {
  src_link: string
  is_image?: boolean
  thumb_src_link?: string
  small_src_link?: string
  middle_src_link?: string
}
export enum ApiAnswerStatus {
  SUCCESS = 1,
  BAD_REQUEST = 400,
  UNPROCESSABLE = 422,
  UNAUTHENTICATED = 401,
  NEED_FULL_REGISTER = 402,
}

export enum TOKEN {
  PUBLIC = 'token_public',
  PRIVATE = 'token_private',
}
