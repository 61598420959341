import React, { FC, JSX, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { MeasurementTrackerList } from '../../../../types'
import { MoreBackIcon } from '../../../../imgs'
import { MeasurementModal } from '../modals'

interface IMyMeasurements {
  className: any
  update: () => void
  trackerList: MeasurementTrackerList[]
}

export const MyMeasurements: FC<IMyMeasurements> = ({ className, update, trackerList }): JSX.Element => {
  const [modal, setModal] = useState<boolean>(false)
  const [measures, setMeasures] = useState<MeasurementTrackerList | undefined>(undefined)

  const onOpenModal = (m: MeasurementTrackerList) => {
    setMeasures(m)
    setModal(true)
  }
  const onCloseModal = () => {
    setModal(false)
    setTimeout(() => setMeasures(undefined), 200)
  }

  return (
    <Box>
      {measures && <MeasurementModal open={modal} measures={measures} update={update} onClose={onCloseModal} />}

      <Box color="text.primary" fontSize={18} fontWeight={700} mb="30px" lineHeight={1.4}>
        Пришло время посмотреть на себя внимательнее. Снимите обмеры тела, чтобы не только чувствовать, но и видеть
        изменения, которые с вами происходят!
      </Box>
      {trackerList.map((k: MeasurementTrackerList, i: number) => (
        <Box key={`measures-${i}`} mb="10px">
          <Button fullWidth className={className.btn} sx={{ textTransform: 'none' }} onClick={() => onOpenModal(k)}>
            <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
              <Grid item>{k.name}</Grid>
              <Grid item>
                <Box className={className.moreIcon}>
                  <MoreBackIcon />
                </Box>
              </Grid>
            </Grid>
          </Button>
        </Box>
      ))}
    </Box>
  )
}
