import React, { Fragment } from 'react'
import moment from 'moment'
import { useReduxSelector } from '../../hooks'
import { StartPage } from '../../pages'

interface IStartPage {
  children: React.ReactNode
}

export const StartAction: React.FC<IStartPage> = ({ children }): JSX.Element => {
  const { data: constants, init: isConstants } = useReduxSelector((state) => state.constants)

  const { START_DATE = '', NOW } = constants || {}

  if (moment(NOW).isAfter(moment(START_DATE)) && isConstants) {
    return <Fragment>{children}</Fragment>
  }

  return <StartPage startTime={START_DATE} />
}
