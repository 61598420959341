import { FC, JSX, SyntheticEvent, useCallback, useState } from 'react'
import { Box, Button, FormControlLabel, Link, Radio, RadioGroup } from '@mui/material'
import moment from 'moment'
import { CheckIconTracker, NoCheckIconTracker } from '../../icons'
import { useReduxActions, useReduxDispatch, useReduxSelector } from '../../../../hooks'
import { Calendar, MainTrackerList, Trackers } from '../../../../types'
import { createObject } from '../../../../utils'
import { trackerStore } from '../../../../api'
import { getUser } from '../../../../store/auth/auth.service'
import { dateConfig } from '../../../../configs'

interface IMyTracker {
  readonly: boolean
  update: () => void
  calendarNow: Calendar
  targetList: MainTrackerList
}

const RatioCustom: JSX.Element = (
  <Radio
    color="warning"
    icon={<NoCheckIconTracker />}
    checkedIcon={<CheckIconTracker />}
    sx={{ p: 0, width: 24, height: 24, ml: '10px', mr: '15px' }}
  />
)

const LabelCustom: FC<{ text: any }> = ({ text }): JSX.Element => (
  <Box sx={{ fontSize: 16, fontWeight: 700, color: 'text.primary' }}>{text}</Box>
)

const createKey = (trackers: Trackers[]) => {
  const arr: number[] = []

  trackers.forEach((t: Trackers) => {
    arr.push(t.id)
  })

  return arr
}

const createValue = (trackers: Trackers[]) => {
  const arr: boolean[] = []

  trackers.forEach((t: Trackers) => {
    arr.push(t.change_at !== null)
  })

  return arr
}

export const MyTracker: FC<IMyTracker> = ({ calendarNow, readonly, targetList, update }): JSX.Element => {
  const defaultValue = createObject(createKey(targetList.trackers), createValue(targetList.trackers)) as Record<
    number,
    boolean
  >

  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()

  const { data: constants } = useReduxSelector((state) => state.constants)

  const { NOW = 0 } = constants || {}

  const [disabled, setDisabled] = useState<boolean>(false)
  const [value, setValue] = useState<Record<number, boolean>>(defaultValue)

  const onChangeValue = (event: SyntheticEvent) => {
    const id = Number((event.target as HTMLInputElement).value)

    if (id) {
      setValue((prev) => {
        return { ...prev, [id]: !prev[id] }
      })
    }
  }

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
  }, [])

  const onSubmit = () => {
    setDisabled(true)

    if (Object.values(value).find((v) => v)) {
      const trackers: { id: number }[] = []

      Object.keys(value).forEach((k) => {
        if (value[Number(k)]) trackers.push({ id: Number(k) })
      })

      trackerStore({ trackers }).then(({ status }) => {
        setDisabled(false)

        if (status === 1) {
          update()
          uploadUserInfo()
        } else {
          setAlert({
            alert: {
              message: { info: ['Мой трекер не сохранен'] },
            },
          })
        }
      })
    } else {
      setAlert({
        alert: {
          message: { info: ['Значение не выбрано'] },
        },
      })
    }
  }

  return (
    <>
      <Box sx={{ mb: '10px', width: '100%', '& .MuiTypography-root': { width: '100%!important' } }}>
        <RadioGroup sx={{ width: '100%' }}>
          {targetList.trackers.map((k: Trackers, i: number) => (
            <FormControlLabel
              key={i}
              value={k.id}
              control={RatioCustom}
              checked={value[k.id]}
              onClick={onChangeValue}
              sx={{ mb: '15px', width: '100%' }}
              label={
                <LabelCustom
                  text={
                    k.habit?.meta ? (
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecorationColor: '#004438' }}
                        href={k.habit.meta.find((n: any) => calendarNow.num === n.num)?.link}
                      >
                        {k.habit.name}
                      </Link>
                    ) : (
                      k.habit.name
                    )
                  }
                />
              }
              disabled={readonly || disabled || k.change_at !== null}
            />
          ))}
        </RadioGroup>
      </Box>

      {moment(NOW).valueOf() >= dateConfig.challengeDateStart && (
        <Box mb="30px" color="text.primary" fontSize={15}>
          Для перехода на урок дня вы должны быть авторизованы на платформе GetCourse
        </Box>
      )}

      {!readonly && (
        <Box mb="20px">
          <Button fullWidth variant="contained" color="secondary" disabled={disabled} onClick={onSubmit}>
            Сохранить
          </Button>
        </Box>
      )}
    </>
  )
}
