import './styles/app.scss'
import './styles/animation.scss'
import './styles/preloader.scss'
import 'react-toastify/dist/ReactToastify.css'

import './configs/yup.config'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import Moment from 'react-moment'
import moment from 'moment'
// eslint-disable-next-line import/order
import { store } from './store'
import 'moment/locale/ru'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ruLocale from 'date-fns/locale/ru'
import { App } from './app/app'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ErrorFallback } from './pages'
import { theme } from './theme/theme'
import { updateAppToast } from './toast'
import 'moment-timezone'

moment.locale('ru')
moment.defaultFormat = 'DD.MM.YYYY'
Moment.globalLocale = 'ru'
Moment.globalFormat = 'DD.MM.YYYY'

const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
            <App />
          </LocalizationProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)

serviceWorkerRegistration.register({ onUpdate: (reg) => updateAppToast(reg) })
