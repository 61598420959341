import React from 'react'
import { Box, Container, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { SigninForm } from './signin.form'
import { img, LogoBlackIcon, LogoWhiteIcon } from '../../imgs'
import { themeGl } from '../../configs'
import { definitionTheme } from '../../utils'
import { Support } from '../../components'

export const SigninPage: React.FC = (): JSX.Element => {
  const { REACT_APP_VERSION: VERSION } = process.env

  const navigate = useNavigate()
  const classes = useStyles()

  const onOpenSignup = () => {
    navigate('../signup')
  }

  return (
    <Box className={classes.page}>
      <Container maxWidth="sm" sx={{ height: '100%' }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
          <Grid item xs={12} maxWidth={320}>
            <Grid container justifyContent="center">
              <Grid item>{themeGl === 'calendar' ? <LogoWhiteIcon /> : <LogoBlackIcon />}</Grid>
            </Grid>
            <Box className={classes.title}>Авторизация</Box>
            <SigninForm className={classes} />
            <Box className={classes.textSignup}>Еще нет аккаунта?</Box>
            <Grid container justifyContent="center">
              <Grid item>
                <Box className={classes.signup} onClick={onOpenSignup}>
                  Зарегистрироваться
                </Box>
              </Grid>
            </Grid>
            <Support type="auth" />
            <Box className={classes.version}>версия {VERSION}</Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    width: '100%',
    padding: '55px 0',
    backgroundRepeat: 'no-repeat',
    minHeight: 'calc(100vh - 110px)',
    backgroundSize: themeGl === 'calendar' ? '85vw 200vw' : '345px 341px',
    backgroundImage: definitionTheme(
      { calendar: `url(${img.bgGreetCalendar})`, test2: 'none', challenge: 'none' },
      `url(${img.bgSign})`
    ) as string,
    backgroundPosition: themeGl === 'calendar' ? 'left top' : 'bottom -40px left -120px',
    backgroundColor: themeGl === 'calendar' ? palette.background.default : palette.primary.main,
  },
  title: {
    fontSize: 24,
    fontWeight: 800,
    textAlign: 'center',
    margin: '75px 0 25px',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  forgot: {
    fontSize: 16,
    fontWeight: 500,
    textDecoration: 'underline',
    transform: 'translate(0, -5px)',
    '-webkit-tap-highlight-color': 'transparent',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.dark,
  },
  textSignup: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    margin: '20px 0 5px',
    color: themeGl === 'calendar' ? palette.primary.main : '#707070',
  },
  signup: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    textDecoration: 'underline',
    '-webkit-tap-highlight-color': 'transparent',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.dark,
  },
  version: {
    opacity: 0,
    fontSize: 10,
    marginTop: 15,
    fontWeight: 400,
    userSelect: 'none',
    textAlign: 'center',
    color: palette.primary.dark,
  },
}))
