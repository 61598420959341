import { FC, JSX, useCallback, useEffect, useState } from 'react'
import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as ls from 'local-storage'
import moment from 'moment'
import { Modal } from '../../../../components'
import { trackerDescription } from '../../../../api'
import { useReduxActions, useReduxSelector } from '../../../../hooks'
import { dateConfig } from '../../../../configs'

type Cards = {
  description: string
  primary_media: PrimaryMedia
}

type PrimaryMedia = {
  src_link: string
  is_image: boolean
  thumb_src_link: string
  small_src_link: string
  middle_src_link: string
}

export const CardModal: FC = (): JSX.Element => {
  const classes = useStyles()
  const { setAlert } = useReduxActions()

  const { data: constants } = useReduxSelector((state) => state.constants)

  const { NOW = 0 } = constants || {}

  const [cards, setCards] = useState<Cards>()
  const [card, setCard] = useState<boolean>(false)

  const uploadCards = useCallback(() => {
    trackerDescription({ habit_type: 'card-2024' }).then((res) => {
      setCards(res.data.pop())
    })
  }, [])

  useEffect(() => {
    uploadCards()
  }, [])

  useEffect(() => {
    if (
      cards &&
      moment(NOW).valueOf() >= dateConfig.challengeDateStart &&
      (moment(NOW).isAfter(moment(ls.get('__news_challenge')).valueOf() + 3600 * 1000 * 24) ||
        !ls.get('__news_challenge'))
    ) {
      if (dateConfig.challengeDateOpen >= moment(NOW).valueOf()) {
        setAlert({
          alert: {
            title: 'Meta-новости!',
            message: {
              info: [
                `<strong>1 сентября стартует Розыгрыш призов в Челлендже.</strong><br /><br />` +
                  `<div>Закончите Челлендж не позднее 30-го сентября со 100% прогрессом – ` +
                  `и получите шанс выиграть ГОДОВОЙ ЗАПАС системы MetaPWR!</div><br />` +
                  `<div>Если вы УЖЕ закончили или закончите Челлендж раньше ` +
                  `с тем же прогрессом*– автоматически попадете в список участников ;)</div><br />` +
                  `*с максимальным результатом – 100%`,
              ],
            },
            onClose: () => {
              ls.set('__news_challenge', NOW)
            },
          },
        })
      } else {
        setAlert({
          alert: {
            title: 'Meta-новости!',
            message: {
              info: [
                `<strong>Стартовал Розыгрыш призов в Челлендже!</strong><br /><br />` +
                  `<div>Закончите Челлендж не позднее 30-го сентября со 100% прогрессом – и получите шанс ` +
                  `выиграть ГОДОВОЙ ЗАПАС системы MetaPWR!</div><br />` +
                  `<div>Если вы УЖЕ закончили или закончите  Челлендж раньше с тем же прогрессом* – ` +
                  `автоматически попадете в список участников ;)</div><br />` +
                  `*с максимальным результатом – 100%`,
              ],
            },
            onClose: () => {
              ls.set('__news_challenge', NOW)
              setTimeout(() => {
                if (cards && ls.get('__card_challenge') !== cards?.description.toLowerCase().trim()) setCard(true)
              }, 200)
            },
          },
        })
      }
    } else if (cards && ls.get('__card_challenge') !== cards?.description.toLowerCase().trim()) setCard(true)
  }, [cards])

  return (
    <Modal
      open={card}
      onClose={() => {
        setCard(false)
        ls.set('__card_challenge', cards?.description.toLowerCase().trim())
      }}
    >
      <Box className={classes.title}>Карточка</Box>
      <Box className={classes.subTitle}>{cards?.description}</Box>
      <Box className={classes.img}>
        <img src={cards?.primary_media.src_link} alt="" />
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 10,
    color: palette.warning.main,
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 10,
    color: palette.warning.main,
  },
  img: {
    '& img': {
      width: '100%',
      borderRadius: 12,
    },
  },
}))
