import { toast } from 'react-toastify'
import * as ls from 'local-storage'
import { TOAST } from '../types'
import { UpdateApp, SuccessApp, checkUpdateApp } from './content.toast'

export const updateAppToast = (registration?: any) => {
  ls.set('__success_update', new Date())

  toast.info(UpdateApp, {
    toastId: TOAST.UPDATE_AVAILABLE,
    containerId: TOAST.GLOBAL_CONTAINER_ID,
    position: toast.POSITION.TOP_CENTER,
    onClose: () => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })

        navigator.serviceWorker.addEventListener('controllerchange', () => {
          window.location.reload()
        })
      }
    },
    theme: 'colored',
    autoClose: false,
  })
}

export const successAppToast = () => {
  toast.success(SuccessApp, {
    toastId: TOAST.UPDATE_SUCCESS,
    containerId: TOAST.GLOBAL_CONTAINER_ID,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: false,
    theme: 'colored',
    autoClose: 5000,
  })
}

export const checkUpdateAppToast = () => {
  toast.info(checkUpdateApp, {
    toastId: TOAST.CHECK_UPDATE,
    containerId: TOAST.GLOBAL_CONTAINER_ID,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: false,
    theme: 'colored',
    autoClose: false,
  })
}
