import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Button, Container, Grid } from '@mui/material'
import HtmlReactParser from 'html-react-parser'
import { VKShareButton } from 'react-share'
import { Layout } from '../../components'
import { useStylesTest2 } from './test2.styles'
import { img, Test2Icon, VkIcon } from '../../imgs'
import { Test2Modal } from './test2.modal'
import { RecommendationModal } from './recommendation.modal'
import { Test } from '../test/test.types'
import { getTest, getTestResult, testStore } from '../../api'
import { TestResult, TestTypes } from '../../types'
import { colorTheme } from '../../utils'

export const Test2Page: FC = (): JSX.Element => {
  const classes = useStylesTest2()

  const textShare =
    'Мой дом — моя крепость! А ваш? ' +
    'Можно ли поддерживать в доме чистоту без помощи токсинов? Или она, как и красота, требует жертв? Как защитить ' +
    'себя и домашних от вредных веществ?' +
    'Пройдите тест и узнайте, что поможет вашему дому оставаться безупречно чистым и свободным от токсинов! '

  const [test, setTest] = useState<Test | undefined>(undefined)

  const [answerList, setAnswerList] = useState<{ id: number }[]>([])
  const [finish, setFinish] = useState<TestResult | undefined>(undefined)
  const [stage, setStage] = useState<number>(1)
  const [modal, setModal] = useState<boolean>(false)
  const [recommendation, setRecommendation] = useState<boolean>(false)
  const [testResult, setTestResult] = useState<TestResult | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)

  const uploadTest = useCallback(() => {
    getTest(TestTypes.TEST_APRIL_2024).then(({ data, status }) => {
      if (status === 1) {
        setTest(data)
      }
    })
  }, [])

  const uploadTestResult = useCallback(() => {
    if (!loading) setLoading(true)

    getTestResult(TestTypes.TEST_APRIL_2024).then(({ data, status }) => {
      if (data && status === 1) setTestResult(data)
      setLoading(false)
    })
  }, [])

  const onOpenTest = () => {
    setModal((prev) => !prev)
  }

  const onSaveAnswer = (answer?: number) => {
    if (answer && answer > 0) {
      setAnswerList((prev: { id: number }[]) => {
        const copyPrev: { id: number }[] = [...prev]
        copyPrev.push({ id: answer })

        return copyPrev
      })
    }
  }

  const onOpenRecommendation = (answer?: number) => {
    if (test) {
      if (stage < test?.test_questions?.length) {
        if (recommendation) {
          setStage((prev: number) => prev + 1)
          setRecommendation(false)
          setModal(true)
        } else {
          onSaveAnswer(answer)
          setRecommendation(true)
          setModal(false)
        }
      } else if (stage === test?.test_questions?.length) {
        if (recommendation) {
          setRecommendation(false)

          testStore({ test_id: test?.id, answers: answerList }).then((res) => {
            uploadTestResult() // если закоментировано окно результата теста
            // setTimeout(() => setFinish(res.data), 300)
          })
        } else if (finish) {
          uploadTestResult()
          setFinish(undefined)
        } else {
          onSaveAnswer(answer)

          setModal(false)
          setRecommendation(true)
        }
      }
    }
  }

  useEffect(() => {
    uploadTestResult()
    uploadTest()
  }, [])

  return (
    <Layout>
      {test && test?.test_questions.length > 0 && (
        <>
          <Test2Modal
            show={modal}
            stage={stage}
            onClose={onOpenTest}
            data={test.test_questions}
            onOpenRecommendation={(answer?: number) => onOpenRecommendation(answer)}
          />

          <RecommendationModal
            stage={stage}
            finish={finish}
            show={recommendation}
            data={test.test_questions}
            onClose={onOpenRecommendation}
            finishNoResult={stage === test?.test_questions?.length}
          />
        </>
      )}

      <Box className={classes.page} height={testResult ? 'auto' : 'calc(100vh - 100px)'}>
        <Container maxWidth="sm" sx={{ height: testResult ? 'auto' : '100%' }}>
          <Grid container direction="column" justifyContent="space-between" height="100%">
            <Grid item>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item className={classes.title}>
                  Чистый дом без токсинов
                </Grid>
                <Grid item>
                  <Test2Icon />
                </Grid>
              </Grid>
              {testResult ? (
                <>
                  {testResult.recommendation && <Box className={classes.textResult}>{testResult.recommendation}</Box>}
                  <Box className={classes.imgResult}>
                    {testResult.test_level?.primary_media?.src_link && (
                      <img src={testResult.test_level?.primary_media?.src_link} alt="" />
                    )}
                  </Box>
                  <Box mb="10px" fontSize={18} fontWeight={600} color="secondary.main">
                    {testResult.test_level.description}
                  </Box>
                  <Box className={classes.textResult}>{HtmlReactParser(testResult.test_level.content)}</Box>
                  <Box
                    pb="10px"
                    fontSize={15}
                    fontWeight={500}
                    color="primary.contrastText"
                    sx={{ '& ul': { pl: 3, mb: 0 }, '& a': { color: 'secondary.main' } }}
                  >
                    Больше, чем тест! Между всеми, кто пройдёт тест до 30 апреля, мы разыграем в этот день подарок из
                    двух наборов abōde!
                    <br />
                    <Box mt="10px" mb="15px">
                      Не упустите шанс выиграть сразу два набора-новинки:
                      <br />
                      <ul>
                        <li>
                          <a
                            href="https://www.doterra.com/RU/ru_RU/p/abode-kit-clean-hands"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Набор abōde: Чистые руки — пенящееся концентрированное мыло для рук + диспенсер
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.doterra.com/RU/ru_RU/p/abode-kit-clean-hohouse"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Набор abode: Чистый дом — концентрат для очистки поверхностей + диспенсер-распылитель
                          </a>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                </>
              ) : (
                !loading && (
                  <>
                    <Box width="100%" mt="12px" mb="15px">
                      <img src={img.test2} alt="test2" width="100%" />
                    </Box>
                    <Box fontSize="15px" fontWeight={500} lineHeight={1.6} color="primary.contrastText">
                      Чистота — это не только отсутствие грязи и пыли, но и токсинов!
                    </Box>
                    <Box fontSize="15px" fontWeight={600} lineHeight={1.4} mt="20px">
                      Узнайте, как сделать ваш дом чистым безопасно и эффективно, пройдя наш тест!
                    </Box>
                  </>
                )
              )}
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={12}>
                  {testResult ? (
                    <VKShareButton
                      id="test-share"
                      title={textShare}
                      className="test-share"
                      style={{ width: '100%' }}
                      image={testResult.test_level?.primary_media?.src_link || ''}
                      url="https://doterra-challenge.ru/share/vk/test2-share.html"
                    >
                      <Button fullWidth color="secondary" variant="outlined" sx={{ border: `2px solid ${colorTheme}` }}>
                        <Grid container justifyContent="center" alignItems="center" mt="-17px" height={40} spacing={1}>
                          <Grid item>
                            <Box mt="-5px">Поделиться</Box>
                          </Grid>
                          <Grid item height={54}>
                            <VkIcon />
                          </Grid>
                        </Grid>
                      </Button>
                    </VKShareButton>
                  ) : (
                    !loading && (
                      <Box mb={2}>
                        <Button fullWidth variant="contained" color="secondary" disabled={!test} onClick={onOpenTest}>
                          Пройти тест
                        </Button>
                      </Box>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}
