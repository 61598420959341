import React, { useState } from 'react'
import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Check } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { useReduxDispatch, useReduxSelector, useReduxActions } from '../../hooks'
import { FormProps } from '../../types'
import { CheckboxInput, formLocale } from '../form-control'
import { updateInfoUser } from '../../api'

type UpdateSubscribeProps = { subscribe_email?: boolean }

export const Subscribe: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const { setAlert, updateUser } = useReduxActions()

  const { data: user } = useReduxSelector((state) => state.auth)

  const defaultValues: UpdateSubscribeProps = { subscribe_email: user?.subscribe_email }

  const [form, setForm] = useState<FormProps<UpdateSubscribeProps>>({
    data: defaultValues,
    processed: false,
  })

  const hookForm = useForm({ defaultValues })

  const onSubmit = async (data: UpdateSubscribeProps) => {
    setForm({ ...form, processed: true })

    updateInfoUser({ ...data }).then((res) => {
      if (res.status === 1 && !data.subscribe_email) {
        setAlert({
          alert: {
            message: { info: ['Вы отписались от рассылки информации о мероприятии по e-mail '] },
            onClose: () => {
              updateUser({ subscribe_email: data.subscribe_email })
              setForm({ ...form, processed: false })
            },
          },
        })
      } else {
        setForm({ ...form, processed: false })
      }
    })

    // subscribeChange({ ...user, ...data })
    //   .then(({ status, message: messages }) => {
    //     if (status === 1) {
    //       dispatch(
    //         modalsAction.alert({
    //           message: messages,
    //           onClose: () => {
    //             uploadDataUser(data)
    //             setForm({ ...form, processed: false })
    //           },
    //         })
    //       )
    //     } else {
    //       uploadDataUser(data)
    //       setForm({ ...form, processed: false })
    //     }
    //   })
    //   .catch((e) => {
    //     setForm({ ...form, processed: false })
    //   })
  }

  const handleChange = (checked: boolean) => {
    onSubmit({ subscribe_email: checked })
  }

  const check = {
    icon: <span className={classes.icon} />,
    checked: (
      <span className={classes.iconCheck}>
        <Check className={classes.check} />
      </span>
    ),
  }

  return (
    <Box>
      <CheckboxInput
        form={hookForm}
        color="primary"
        icon={check.icon}
        name="subscribe_email"
        checked={check.checked}
        disabled={form.processed}
        label={formLocale.subscribeEmailCheckbox}
        onChange={(_, checked) => handleChange(checked)}
      />
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  icon: {
    width: 22,
    height: 22,
    borderRadius: 4,
    backgroundColor: '#F5F5F5',
    border: '1px solid #E5E5E5',
  },
  iconCheck: {
    width: 22,
    height: 22,
    borderRadius: 4,
    backgroundColor: palette.primary.dark,
    border: `1px solid ${palette.primary.dark}`,
  },
  check: {
    fontSize: '22px!important',
    color: `${palette.primary.main}!important`,
  },
}))
