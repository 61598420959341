import React, { useCallback, useEffect } from 'react'
import * as ls from 'local-storage'
import { RoutesRoot } from './routes'
import { useReduxActions, useReduxDispatch, useReduxSelector } from '../hooks'
import { getUser } from '../store/auth/auth.service'
import { getConst } from '../store/constants/constants.service'
import { Preloader, StartAction } from '../components'
import { ModalRoot } from './modal-root'

export const AppPwa: React.FC = (): JSX.Element => {
  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()

  const { init: isConstants, data: constants } = useReduxSelector((state) => state.constants)

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
    setTimeout(() => uploadUserInfo(), 1000 * 60 * 30) // 1 час
  }, [])

  useEffect(() => {
    dispatch(getConst())
    uploadUserInfo()
  }, [])

  // useEffect(() => {
  //   if (isConstants && constants && !ls.get('__winners')) {
  //     const now = new Date(constants?.NOW)
  //     if (now.valueOf() > 1686841200000) {
  //       dispatch(
  //         setAlert({
  //           alert: {
  //             message: {
  //               info: [
  //                 'Розыгрыш Главного приза проведен 15 июня 2023 года!<br />' +
  //                   'Победителем стала Екатерина, ekt****h@mail.ru!',
  //               ],
  //             },
  //             onClose: () => {
  //               ls.set('__winners', 'true')
  //             },
  //           },
  //         })
  //       )
  //     }
  //   }
  // }, [isConstants])

  if (!isConstants) {
    return <Preloader />
  }

  return (
    <StartAction>
      <ModalRoot />
      <RoutesRoot />
    </StartAction>
  )
}
