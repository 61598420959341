/* eslint-disable max-len */
import { Box } from '@mui/material'

export const OpenDayIcon = () => (
  <Box position="relative" top={1.5} width={98} height={98}>
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_996_8799)">
        <rect x="4" y="2" width="90" height="90" rx="14" fill="white" />
      </g>
      <circle opacity="0.6" cx="39" cy="53.5988" r="1" fill="#9EBFBB" />
      <circle opacity="0.6" cx="46.2152" cy="68.1899" r="1.5" transform="rotate(15 46.2152 68.1899)" fill="#9EBFBB" />
      <circle opacity="0.6" cx="60.8445" cy="31.5042" r="1.5" fill="#9EBFBB" />
      <circle opacity="0.6" cx="19.1631" cy="46.8446" r="1.5" fill="#9EBFBB" />
      <circle opacity="0.6" cx="53.0542" cy="14.7838" r="1" fill="#9EBFBB" />
      <circle opacity="0.6" cx="72.489" cy="9.05408" r="2" fill="#9EBFBB" />
      <circle cx="36.5" cy="77.7162" r="1" stroke="#9EBFBB" />
      <circle cx="50.7743" cy="85.0089" r="2.57435" stroke="#9EBFBB" />
      <g clipPath="url(#clip0_996_8799)">
        <path d="M11.6653 34.5272L0.633476 26.8118" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M5.56665 37.3751L6.73248 23.9636" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M6.28467 29.1454L6.73241 23.9636" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M0.0654297 33.5105L12.2631 27.8144" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M8.93872 35.5332L9.1114 32.7407" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M3.33203 35.5168L5.83678 34.2701" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M4.20508 25.4747L6.45713 27.1349" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M9.69092 26.3807L9.37916 29.1612" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M11.6753 31.6206L9.11148 32.7408" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M8.08862 35.9304L5.83657 34.2702" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M8.96167 25.8882L6.45693 27.1349" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M11.7112 30.7069L9.37912 29.1611" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M10.2126 35.1001L10.3068 33.5769" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M4.34375 36.4037L5.70997 35.7237" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M5.35449 24.7758L6.58288 25.6813" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M10.8713 27.0272L10.7013 28.5438" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M11.7053 32.9658L10.3069 33.5769" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M6.93848 36.629L5.71008 35.7235" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M7.94946 25.0012L6.58324 25.6812" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M11.9729 29.387L10.7009 28.5438" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M46.9548 44.2643L40.2186 39.5531" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M43.231 46.0033L43.9428 37.8139" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M43.6694 40.978L43.9428 37.8139" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M39.8718 43.6436L47.32 40.1654" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M45.29 44.8787L45.3955 43.1735" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M41.8665 44.8687L43.3959 44.1074" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M40.1724 41.8942L41.5964 42.8381" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M40.2458 41.3512L41.8114 40.6672" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M42.3994 38.7367L43.7746 39.7505" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M45.7493 39.2899L45.5589 40.9877" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M46.9609 42.4895L45.3954 43.1735" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M44.7708 45.1212L43.3956 44.1075" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M41.406 44.536L41.5964 42.8382" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M41.917 38.9619L41.8115 40.6671" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M45.304 38.9891L43.7745 39.7504" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M46.9829 41.9315L45.5589 40.9876" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M46.0679 44.6141L46.1254 43.684" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M42.4841 45.4102L43.3184 44.9949" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M40.0122 42.7002L40.7889 43.2151" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M40.2275 40.5297L41.0815 40.1566" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M43.1013 38.3099L43.8514 38.8629" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M46.47 39.6847L46.3661 40.6108" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M46.9792 43.3109L46.1253 43.684" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M44.0686 45.5477L43.3185 44.9948" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M40.6851 44.1412L40.7889 43.2152" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M41.1389 39.2264L41.0814 40.1565" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M44.6858 38.4475L43.8515 38.8627" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M47.1426 41.1256L46.3658 40.6108" stroke="#9EBFBB" strokeWidth="0.5" strokeLinecap="round" />
        <g clipPath="url(#clip1_996_8799)">
          <path
            d="M72.4037 24.6823C70.4837 24.6823 70.4955 22.04 70.4896 20.9578C70.4837 19.8755 70.4896 17.2332 72.4037 17.2332H100.578C102.498 17.2332 102.499 19.8122 102.493 20.9578C102.487 22.1033 102.493 24.6881 100.578 24.6823H72.4037Z"
            fill="#255C56"
          />
          <path
            d="M111 59.5104C111.038 63.1484 110.506 66.7707 109.423 70.2525C108.455 73.3574 106.847 76.237 104.696 78.7148C102.551 81.1204 99.8691 83.0166 96.8566 84.2586C93.3954 85.6422 89.6793 86.3182 85.9389 86.2446C82.3468 86.3273 78.7716 85.7412 75.4051 84.5176C72.5271 83.4284 69.9599 81.6811 67.9198 79.4229C65.8565 77.0617 64.3393 74.2953 63.4712 71.3117C62.4414 67.7836 61.9459 64.1279 62.0001 60.4603C61.9644 56.8241 62.4881 53.2032 63.5539 49.7183C64.4965 46.6144 66.0898 43.7348 68.2329 41.2616C70.3683 38.8606 73.0429 36.9715 76.0491 35.741C79.5212 34.3682 83.2443 33.7002 86.9905 33.7779C90.5825 33.6947 94.1579 34.2808 97.5242 35.5049C100.404 36.594 102.973 38.3412 105.015 40.5996C107.083 42.9664 108.613 45.7326 109.505 48.7166C110.547 52.2151 111.051 55.8449 111 59.4874M101.695 60.0631C101.705 57.6057 101.455 55.154 100.951 52.7463C100.519 50.6018 99.6898 48.5516 98.5049 46.696C97.3615 44.9569 95.7708 43.5398 93.8909 42.5857C91.6365 41.5092 89.1451 40.9892 86.636 41.0717C84.2595 41.011 81.9005 41.4837 79.7415 42.4533C77.8582 43.3496 76.2338 44.689 75.0152 46.3506C73.7124 48.1518 72.7722 50.1775 72.2444 52.3203C71.6194 54.7833 71.3137 57.313 71.3346 59.8501C71.3042 62.3447 71.5418 64.8356 72.0435 67.282C72.4691 69.4231 73.307 71.4663 74.513 73.3035C75.68 75.0277 77.278 76.4342 79.1566 77.3908C81.4115 78.4653 83.9029 78.9833 86.4115 78.8991C88.7914 78.9495 91.1517 78.4691 93.3119 77.4944C95.1921 76.6014 96.8159 75.2683 98.0382 73.6144C99.3435 71.828 100.277 69.8097 100.785 67.6735C101.398 65.1892 101.696 62.641 101.672 60.0861"
            fill="#6AB7A9"
          />
        </g>
        <g opacity="0.5">
          <path d="M24.1223 57.0496L-3.77563 78.8768" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M26.5994 74.5867L-6.25253 61.34" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M6.44214 66.4512L-6.25294 61.3398" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M12.6443 85.4569L7.69034 50.383" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M24.5574 64.6852L17.6631 62.1033" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M20.2043 78.7811L18.9932 71.5196" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M5.81396 82.0159L11.4971 77.3362" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M3.66846 81.0287L2.82104 73.7158" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M2.10474 55.7538L8.86163 58.6764" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M16.8157 54.7903L17.6631 62.1032" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M24.9026 67.1293L18.9931 71.5196" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M18.2542 80.2588L11.4973 77.3362" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M14.5447 53.9967L8.86155 58.6764" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M24.4473 61.146L20.6868 59.7377" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M23.2141 76.9161L22.5535 72.9553" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M8.93408 83.6902L12.034 81.1376" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M4.63916 53.2811L8.32474 54.8753" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M20.2246 55.7489L20.6868 59.7378" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M25.7771 70.5604L22.5537 72.9552" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M15.7197 82.7317L12.0341 81.1376" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path d="M11.4246 52.3225L8.32467 54.875" stroke="#255C56" strokeWidth="0.812202" strokeLinecap="round" />
          <path
            d="M4.8245 61.6083C4.69772 61.2428 5.10539 60.9238 5.42965 61.1348L9.37878 63.7044C9.54888 63.8151 9.77456 63.7832 9.90735 63.6297L12.9903 60.0669C13.2435 59.7744 13.7235 59.968 13.7029 60.3543L13.4522 65.0591C13.4414 65.2617 13.5819 65.4413 13.7811 65.4795L18.4081 66.3681C18.788 66.441 18.8604 66.9536 18.5156 67.1289L14.3157 69.2641C14.1348 69.3561 14.0496 69.5675 14.1161 69.7592L15.6601 74.2106C15.7868 74.5761 15.3792 74.895 15.0549 74.684L11.1058 72.1145C10.9357 72.0038 10.71 72.0357 10.5772 72.1891L7.49422 75.7519C7.24107 76.0445 6.76102 75.8509 6.78161 75.4646L7.03234 70.7598C7.04314 70.5571 6.90269 70.3776 6.7034 70.3393L2.07644 69.4508C1.69652 69.3778 1.62413 68.8653 1.96898 68.69L6.16885 66.5547C6.34975 66.4627 6.43498 66.2514 6.36848 66.0596L4.8245 61.6083Z"
            stroke="#255C56"
            strokeWidth="0.812202"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_996_8799"
          x="0"
          y="0"
          width="98"
          height="98"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_996_8799" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_996_8799" result="shape" />
        </filter>
        <clipPath id="clip0_996_8799">
          <rect x="4" y="2" width="90" height="90" rx="14" fill="white" />
        </clipPath>
        <clipPath id="clip1_996_8799">
          <rect width="49" height="69" fill="white" transform="translate(62 17.2446)" />
        </clipPath>
      </defs>
    </svg>
  </Box>
)
