import { FC, JSX, useCallback, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { JournalTrackerList } from '../tracker-challenge'
import { SubmitButton, TextInput } from '../../../../components'
import { useReduxActions, useReduxDispatch } from '../../../../hooks'
import { trackerStore } from '../../../../api'
import { getUser } from '../../../../store/auth/auth.service'

interface IMyJournal {
  readonly: boolean
  update: () => void
  journalList: JournalTrackerList
}

type MyJournalForm = { note: string }

export const MyJournal: FC<IMyJournal> = ({ readonly, update, journalList }): JSX.Element => {
  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()

  const [disabled, setDisabled] = useState<boolean>(false)

  const hookForm = useForm<any>({
    defaultValues: { note: '' },
    mode: 'onBlur',
  })

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
  }, [])

  const onSubmitForm = ({ note }: MyJournalForm) => {
    setDisabled(true)

    trackerStore({ trackers: [{ id: journalList.trackers[0].id, note }] }).then(({ status }) => {
      setDisabled(false)

      if (status === 1) {
        update()
        uploadUserInfo()
      } else {
        setAlert({
          alert: {
            message: { info: ['Мой трекер не сохранен'] },
          },
        })
      }
    })
  }

  return (
    <Box>
      <Box sx={{ color: 'text.primary', fontWeight: 700, fontSize: 18, mb: '10px' }}>
        Записывайте ваши мысли или отвечайте на наши вопросы.
      </Box>
      <Box sx={{ color: 'text.primary', fontWeight: 500, fontSize: 16, mb: '20px' }}>
        {journalList.trackers[0].habit.description}
      </Box>
      <Box>
        {journalList.trackers[0].note === null && !readonly && (
          <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
            <Box height={310}>
              <TextInput
                multiline
                rows={10}
                name="note"
                form={hookForm}
                label="Сообщение"
                placeholder="Ваше сообщение"
                disabled={readonly || disabled}
              />
            </Box>
            {!readonly && (
              <Box mt="20px">
                <Button fullWidth type="submit" variant="contained" color="secondary" disabled={disabled}>
                  Сохранить
                </Button>
              </Box>
            )}
          </form>
        )}
        {readonly && (
          <Box sx={{ color: 'text.primary', fontWeight: 700, fontSize: 16 }}>
            {journalList.trackers[0].note === null ? 'Дневник не заполнен в этот день.' : journalList.trackers[0].note}
          </Box>
        )}
      </Box>
    </Box>
  )
}
