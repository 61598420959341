/* eslint-disable max-len */
import { FC, JSX } from 'react'

export const DropIcon: FC = (): JSX.Element => (
  <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 14.4909C17.5 18.586 13.9719 22 9.5 22C5.02813 22 1.5 18.586 1.5 14.4909C1.5 13.823 1.74617 12.8915 2.23319 11.7488C2.71141 10.6268 3.38367 9.38759 4.14838 8.13185C5.67711 5.62149 7.52798 3.12153 8.79756 1.48372C9.16203 1.01353 9.83797 1.01353 10.2024 1.48372C11.472 3.12153 13.3229 5.62149 14.8516 8.13185C15.6163 9.38759 16.2886 10.6268 16.7668 11.7488C17.2538 12.8915 17.5 13.823 17.5 14.4909Z"
      fill="#DB580F"
      stroke="#DB580F"
      strokeWidth="2"
    />
  </svg>
)
