import React, { FC } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { VKShareButton } from 'react-share'
import HtmlReactParser from 'html-react-parser'
import { Modal } from '../../components'
import { VkIcon } from '../../imgs'
import { colorTheme } from '../../utils'
import { TestQuestion } from '../test/test.types'
import { TestResult } from '../../types'
import { useStylesTest2 } from './test2.styles'

interface IRecommendationModal {
  show: boolean
  stage: number
  onClose: () => void
  data?: TestQuestion[]
  finishNoResult: boolean
  finish: TestResult | undefined
}

export const RecommendationModal: FC<IRecommendationModal> = ({
  show,
  stage,
  onClose,
  data = [],
  finish,
  finishNoResult = false,
}): JSX.Element => {
  const classes = useStylesTest2()

  const textShare =
    'Мой дом — моя крепость! А ваш? ' +
    'Можно ли поддерживать в доме чистоту без помощи токсинов? Или она, как и красота, требует жертв? Как защитить ' +
    'себя и домашних от вредных веществ?' +
    'Пройдите тест и узнайте, что поможет вашему дому оставаться безупречно чистым и свободным от токсинов! '

  return (
    <Modal maxWidth="md" open={show} onClose={onClose}>
      <Grid container direction="column" justifyContent="space-between" minHeight="calc(100vh - 30px)" spacing={5}>
        <Grid item>
          {finish ? (
            <>
              <Box className={classes.imgResult}>
                {finish.test_level?.primary_media?.src_link && (
                  <img src={finish.test_level?.primary_media?.src_link} alt="" />
                )}
              </Box>

              <Box mb="10px" fontSize={18} fontWeight={600} color="secondary.main">
                Поздравляем!
                <br />
                Вы ответили на все вопросы.
              </Box>
              <Box mb="10px" fontSize={15} fontWeight={600} color="primary.contrastText">
                {finish.test_level.description}
              </Box>
              <Box className={classes.textResult}>{HtmlReactParser(finish.test_level.content)}</Box>
              <Box fontSize={15} fontWeight={500} color="primary.contrastText" sx={{ '& ul': { pl: 3, mb: 0 } }}>
                Больше, чем тест! Между всеми, кто пройдёт тест до 30 апреля, мы разыграем в этот день подарок из двух
                наборов abōde!
                <br />
                <Box mt="10px" mb="15px">
                  Не упустите шанс выиграть сразу два набора-новинки:
                  <br />
                  <ul>
                    <li>
                      <a
                        href="https://www.doterra.com/RU/ru_RU/p/abode-kit-clean-hands"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Набор abōde: Чистые руки — пенящееся концентрированное мыло для рук + диспенсер
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.doterra.com/RU/ru_RU/p/abode-kit-clean-hohouse"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Набор abode: Чистый дом — концентрат для очистки поверхностей + диспенсер-распылитель
                      </a>
                    </li>
                  </ul>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box m="17px 0 15px" borderRadius="12px" overflow="hidden">
                <img
                  width="100%"
                  alt="recommendation"
                  style={{ display: 'block' }}
                  src={data[stage - 1]?.media[0].src_link}
                />
              </Box>
              <Box className={classes.textResult}>{HtmlReactParser(data[stage - 1]?.description)}</Box>
            </>
          )}
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={12} mb="15px">
              {!!finish && (
                <VKShareButton
                  id="test-share"
                  title={textShare}
                  style={{ width: '100%' }}
                  image={finish.test_level?.primary_media?.src_link || ''}
                  url="https://doterra-challenge.ru/share/vk/test2-share.html"
                >
                  <Button fullWidth color="secondary" variant="outlined" sx={{ border: `2px solid ${colorTheme}` }}>
                    <Grid container justifyContent="center" alignItems="center" mt="-17px" height={40} spacing={1}>
                      <Grid item>
                        <Box mt="-5px">Поделиться</Box>
                      </Grid>
                      <Grid item height={54}>
                        <VkIcon />
                      </Grid>
                    </Grid>
                  </Button>
                </VKShareButton>
              )}
            </Grid>
          </Grid>
          <Button fullWidth variant="contained" color="secondary" onClick={onClose}>
            {finishNoResult ? 'Завершить' : 'Продолжить'}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}
