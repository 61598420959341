import React from 'react'
import { Box, Container, Grid, IconButton, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { ArrowBackIcon, img, LogoBlackIcon, LogoWhiteIcon } from '../../imgs'
import { ForgotForm } from './forgot.form'
import { themeGl } from '../../configs'
import { definitionTheme } from '../../utils'

export const ForgotPage: React.FC = (): JSX.Element => {
  const { REACT_APP_VERSION: VERSION } = process.env

  const navigate = useNavigate()
  const classes = useStyles()

  const onOpenSignin = () => {
    navigate('../signin')
  }

  return (
    <Box className={classes.page}>
      <Container maxWidth="sm" sx={{ height: '100%' }}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
          <Grid item>
            <IconButton color="primary" onClick={onOpenSignin}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Box className={classes.logo}>
              <Grid item>
                {themeGl === 'calendar' ? (
                  <LogoWhiteIcon width={139} height={25} />
                ) : (
                  <LogoBlackIcon width={139} height={25} />
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box width={48} height={48} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 188px)' }}>
          <Grid item xs={12} maxWidth={320}>
            <Box className={classes.title}>Восcтановление пароля</Box>
            <Box className={classes.text}>
              Укажи e-mail, с которым Вы зарегистрированы.
              <Box mt="10px" lineHeight={1.3}>
                Пароль будет отправлен на указанный E-mail.
              </Box>
            </Box>
            <ForgotForm />
            <Box className={classes.version}>версия {VERSION}</Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    width: '100%',
    padding: '55px 0',
    backgroundRepeat: 'no-repeat',
    minHeight: 'calc(100vh - 110px)',
    backgroundSize: themeGl === 'calendar' ? '85vw 200vw' : '345px 341px',
    backgroundImage: definitionTheme(
      { calendar: `url(${img.bgGreetCalendar})`, test2: 'none', challenge: 'none' },
      `url(${img.bgSign})`
    ) as string,
    backgroundPosition: themeGl === 'calendar' ? 'left top' : 'bottom -40px left -120px',
    backgroundColor: themeGl === 'calendar' ? palette.background.default : palette.primary.main,
  },
  header: {
    marginBottom: 75,
  },
  logo: {
    marginTop: 5,
  },
  title: {
    fontSize: 24,
    fontWeight: 800,
    textAlign: 'center',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  text: {
    fontSize: 16,
    lineHeight: 1.3,
    fontWeight: 500,
    textAlign: 'center',
    margin: '10px 0 30px',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  version: {
    opacity: 0,
    fontSize: 10,
    marginTop: 15,
    fontWeight: 400,
    userSelect: 'none',
    textAlign: 'center',
    color: palette.primary.dark,
  },
}))
