import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import { Modal } from '../index'
import { useDetectDevice } from '../../hooks'
import { DetectDevice } from '../../types'
import { img } from '../../imgs'

interface IInstallModal {
  show: boolean
  className: any
  onClose: () => void
  onInstallApp: () => void
}

export const InstallModal: React.FC<IInstallModal> = ({
  className,
  show = false,
  onClose = () => {},
  onInstallApp = () => {},
}): JSX.Element => {
  const detectDevice = useDetectDevice()

  return (
    <Modal open={show} onClose={onClose} closeButton={false}>
      {detectDevice === DetectDevice.ANDROID && (
        <>
          <Box className={className.textInstall}>
            Установите приложение
            <br />
            Doterra 30-Day Challenge себе
            <br />
            на устройство.
          </Box>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={onInstallApp}
                sx={{ fontSize: 16, minHeight: 50, minWidth: 250 }}
              >
                Установить
              </Button>
            </Grid>
            <Grid item className={className.primaryBtnModal}>
              <Button
                color="primary"
                onClick={onClose}
                variant="contained"
                sx={{ fontSize: 16, minHeight: 50, minWidth: 250 }}
              >
                Отмена
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {detectDevice === DetectDevice.IOS && (
        <>
          <Box className={className.titleInstall}>Установите иконку приложения на экран телефона.</Box>
          <Box className={className.textInstall} fontSize={16} lineHeight={1.3}>
            <Box>1. Нажмите на кнопку Поделиться (если вы не видите нижнего меню, нажмите внизу экрана).</Box>
            <Grid container justifyContent="center" my="15px">
              <Grid item xs={8}>
                <img src={img.stageInstallIos1} alt="" width="100%" />
              </Grid>
            </Grid>
            <Box>2. В появившемся меню выберите На экран «Домой».</Box>
            <Grid container justifyContent="center" my="15px">
              <Grid item xs={8}>
                <img src={img.stageInstallIos2} alt="" width="100%" />
              </Grid>
            </Grid>
            <Box>
              3. Нажмите кнопку Добавить. На экране вашего телефона появится ярлык приложения. Достаточно нажать на него
              — и вы сразу же перейдете к марафону.
            </Box>
            <Grid container justifyContent="center" my="15px">
              <Grid item xs={8}>
                <img src={img.stageInstallIos3} alt="" width="100%" />
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item onClick={onClose}>
              <Button variant="contained" sx={{ fontSize: 16, minHeight: 50, minWidth: 250 }} color="secondary">
                Продолжить
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Modal>
  )
}
