import React, { forwardRef, ReactElement, Ref, useEffect, useState } from 'react'
import {
  Box,
  Slide,
  Theme,
  Button,
  Dialog,
  Collapse,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from '@mui/material'
import { useReduxActions } from '../../hooks'
import { AlertProps, Message } from '../../types'
import { str } from '../../utils'
import { withPlanner, WithPlannerProps } from '../hoc'
import { CloseIcon } from '../../imgs'

const Transition: React.ForwardRefExoticComponent<any> = forwardRef(
  (props: any & { children: ReactElement<any, any> }, ref: Ref<unknown>): JSX.Element => (
    <Slide {...props} ref={ref} direction="up" easing="ease-out" timeout={{ enter: 300, exit: 300 }}>
      {props.children}
    </Slide>
  )
)

export const Alert: React.FC<AlertProps & WithPlannerProps> = withPlanner((props): JSX.Element => {
  const {
    rootModal,
    title = 'Информация',
    fullScreen = false,
    closeButton = false,
    emptyMessage = false,
    onClose,
    onAction,
    actionButtonText = 'Продолжить',
    message = undefined,
  } = props

  const { setModal, setAlert } = useReduxActions()
  const [text, setText] = useState<Message>({})
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  const handleClose = () => {
    if (onClose) onClose()
    setAlert({ alert: {} })
  }

  useEffect(() => {
    if (message) {
      setText(message)
      if (rootModal) setModal({ modal: { [rootModal]: { show: false, params: undefined } } })
    } else handleClose()
  }, [message])

  return (
    <Dialog
      {...props}
      scroll="body"
      maxWidth="sm"
      open={!!message}
      title={undefined}
      fullScreen={fullScreen || sm}
      aria-labelledby="global-alert"
      TransitionComponent={Transition}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') handleClose()
      }}
    >
      {title && <DialogTitle id="alert-title">{str.normalize(title)}</DialogTitle>}

      {closeButton && (
        <Box position="absolute" top={0} right={0} style={{ zIndex: 2 }}>
          <IconButton color="secondary" disabled={false} onClick={handleClose} style={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      {!emptyMessage && (
        <DialogContent id="alert-content">
          <Collapse in={!!message}>
            <Box textAlign="center" px={{ xs: 0, sm: 3 }} lineHeight={1.3} boxSizing="border-box">
              {!!text &&
                Object.entries(text).map(([_, val], idx) => (
                  <Box key={`alert-message-${idx}`}>
                    {Array.isArray(val) ? (
                      val.map((v, i) => <p key={i}>{str.normalize(v, true)}</p>)
                    ) : (
                      <p>{str.normalize(val, true)}</p>
                    )}
                  </Box>
                ))}
            </Box>
          </Collapse>
        </DialogContent>
      )}

      {actionButtonText.length > 0 && (
        <DialogActions id="alert-actions">
          <Box mx="auto" maxWidth={250} width="100%">
            <Button
              sx={btn}
              fullWidth
              onClick={() => {
                if (onAction) onAction()
                handleClose()
              }}
              variant="contained"
              color="secondary"
            >
              {actionButtonText}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
})

const btn = {
  fontSize: 16,
  minHeight: 50,
  minWidth: 250,
}
