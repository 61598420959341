/* eslint-disable max-len */
import { FC, JSX } from 'react'
import { Box } from '@mui/material'

export const Test2Icon: FC = (): JSX.Element => (
  <Box sx={{ '& svg': { stroke: 'none!important' } }}>
    <svg width="33" height="32" viewBox="0 0 33 32" fill="#B6BCC2" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5276 17.1413C16.4584 17.1555 16.3897 17.1734 16.3219 17.195L5.55219 20.6299C5.10747 20.7717 5.08244 21.3916 5.51428 21.5688L16.1711 25.9424C16.6594 26.1428 17.2071 26.1421 17.6948 25.9404L28.244 21.5778C28.674 21.4 28.6493 20.7829 28.2066 20.64L20.5151 18.1565C20.3078 18.7 20.1995 19.3753 20.1995 20.1524V20.1597L20.1994 20.167C20.1941 20.5237 20.0493 20.8632 19.7974 21.1137C19.5456 21.3641 19.2067 21.5061 18.853 21.5112L18.8312 21.5116L18.8093 21.5109C18.4591 21.5007 18.1253 21.3565 17.8778 21.1066C17.6301 20.8565 17.4881 20.5199 17.483 20.1667L17.4828 20.1538L17.483 20.1408C17.4954 19.0642 17.273 18.1421 16.8492 17.5202C16.7555 17.3827 16.6493 17.2554 16.5276 17.1413Z" />
      <path d="M14.841 14.8373C14.7452 14.8373 14.6534 14.8751 14.5857 14.9425C14.518 15.0098 14.48 15.1011 14.48 15.1963C14.48 15.2915 14.518 15.3828 14.5857 15.4501C14.6534 15.5175 14.7452 15.5553 14.841 15.5553C14.841 15.5553 16.7105 15.5553 17.6102 16.6858C18.51 17.8164 18.4831 20.1525 18.4831 20.1525C18.4844 20.2463 18.5221 20.3361 18.5884 20.4029C18.6546 20.4698 18.7443 20.5087 18.8386 20.5114C18.934 20.5101 19.025 20.4718 19.0924 20.4048C19.1598 20.3377 19.1982 20.2472 19.1996 20.1525C19.1996 19.4773 19.372 17.5485 20.0293 16.7394C20.7391 15.937 21.8065 15.5686 22.8579 15.5392C22.9536 15.5392 23.0454 15.5014 23.1131 15.4341C23.1808 15.3667 23.2189 15.2754 23.2189 15.1802C23.2189 15.085 23.1808 14.9937 23.1131 14.9264C23.0454 14.8591 22.9536 14.8212 22.8579 14.8212C22.8579 14.8212 20.9937 14.8212 20.0724 13.6693C19.4475 12.8977 19.232 10.8992 19.1996 10.1919C19.1955 10.0995 19.1556 10.0123 19.0884 9.9484C19.0211 9.88451 18.9317 9.84891 18.8386 9.849C18.7448 9.85179 18.6557 9.89084 18.5903 9.95786C18.525 10.0249 18.4884 10.1146 18.4884 10.208C18.4775 11.416 18.317 12.657 17.6372 13.6853C16.7159 14.8212 14.8625 14.8427 14.8463 14.8427L14.841 14.8373Z" />
      <path d="M11.3229 8.90063C11.6237 8.90206 11.9212 8.96242 12.1986 9.07826C12.4759 9.1941 12.7276 9.36314 12.9392 9.57574C13.3702 10.1115 13.478 11.3171 13.478 11.7189C13.4794 11.8137 13.5178 11.9042 13.5852 11.9713C13.6526 12.0383 13.7436 12.0765 13.8389 12.0779C13.9263 12.0716 14.0084 12.0338 14.0699 11.9717C14.1313 11.9096 14.168 11.8274 14.173 11.7404C14.173 11.3171 14.2753 10.133 14.6633 9.62396C14.878 9.39879 15.136 9.21875 15.422 9.09451C15.7079 8.97026 16.016 8.90433 16.3281 8.90063C16.4238 8.90063 16.5156 8.86281 16.5833 8.79548C16.651 8.72816 16.689 8.63685 16.689 8.54164C16.689 8.44643 16.651 8.35512 16.5833 8.2878C16.5156 8.22048 16.4238 8.18265 16.3281 8.18265C15.7193 8.17575 15.138 7.92913 14.7118 7.49683C14.3454 7.03068 14.2161 5.78226 14.173 5.35362C14.1702 5.25935 14.1301 5.16997 14.0616 5.1048C13.993 5.03962 13.9014 5.0039 13.8066 5.00535C13.7127 5.00938 13.6238 5.04865 13.5578 5.11527C13.4918 5.18188 13.4537 5.27088 13.451 5.36434C13.451 5.78226 13.354 6.97174 12.9446 7.50754C12.7341 7.72281 12.483 7.89467 12.2057 8.01327C11.9283 8.13188 11.6301 8.1949 11.3283 8.19873C11.2325 8.19873 11.1407 8.23655 11.073 8.30387C11.0053 8.3712 10.9673 8.46251 10.9673 8.55772C10.9673 8.65292 11.0053 8.74423 11.073 8.81156C11.1407 8.87888 11.2325 8.9167 11.3283 8.9167L11.3229 8.90063Z" />
      <path
        d="M14.2808 21.3419C14.2818 21.5759 14.3526 21.8043 14.4841 21.9983C14.6155 22.1924 14.8019 22.3433 15.0195 22.4321C15.2372 22.5209 15.4764 22.5436 15.707 22.4973C15.9377 22.451 16.1493 22.3378 16.3153 22.172C16.4813 22.0062 16.5941 21.7952 16.6396 21.5656C16.6852 21.3361 16.6613 21.0983 16.571 20.8822C16.4807 20.6662 16.3281 20.4815 16.1324 20.3517C15.9367 20.2218 15.7067 20.1525 15.4715 20.1525C15.3146 20.1525 15.1594 20.1833 15.0146 20.2431C14.8697 20.303 14.7382 20.3907 14.6276 20.5012C14.517 20.6117 14.4294 20.7429 14.3699 20.8872C14.3103 21.0314 14.2801 21.186 14.2808 21.3419ZM15.9402 21.3419C15.9402 21.4341 15.9127 21.5243 15.8612 21.6009C15.8097 21.6776 15.7365 21.7373 15.6508 21.7726C15.5652 21.8079 15.4709 21.8171 15.38 21.7991C15.2891 21.7811 15.2056 21.7368 15.14 21.6716C15.0745 21.6064 15.0298 21.5233 15.0117 21.4329C14.9936 21.3425 15.0029 21.2487 15.0384 21.1636C15.0739 21.0784 15.134 21.0056 15.211 20.9544C15.2881 20.9031 15.3788 20.8758 15.4715 20.8758C15.5958 20.8758 15.715 20.9249 15.8029 21.0123C15.8908 21.0997 15.9402 21.2183 15.9402 21.3419Z"
        fill="white"
      />
      <path d="M20.3151 7.40574C20.5304 7.41103 20.7424 7.35203 20.9237 7.23635C21.1049 7.12066 21.2472 6.95361 21.3321 6.75674C21.4169 6.55988 21.4405 6.34224 21.3998 6.1319C21.3591 5.92156 21.2559 5.72819 21.1036 5.57673C20.9513 5.42527 20.7569 5.32267 20.5454 5.28218C20.3339 5.24169 20.115 5.26517 19.9171 5.34957C19.7191 5.43398 19.5511 5.57545 19.4348 5.75572C19.3185 5.93599 19.2592 6.1468 19.2645 6.36093C19.2713 6.63589 19.3842 6.89772 19.5798 7.0922C19.7753 7.28669 20.0386 7.39895 20.3151 7.40574ZM20.3151 6.03409C20.4023 6.03409 20.4859 6.06853 20.5475 6.12982C20.6091 6.19111 20.6437 6.27425 20.6437 6.36093C20.6437 6.44761 20.6091 6.53075 20.5475 6.59204C20.4859 6.65333 20.4023 6.68777 20.3151 6.68777C20.2279 6.68777 20.1443 6.65333 20.0827 6.59204C20.0211 6.53075 19.9864 6.44761 19.9864 6.36093C19.9864 6.31801 19.9949 6.27551 20.0115 6.23585C20.028 6.1962 20.0522 6.16017 20.0827 6.12982C20.1132 6.09947 20.1495 6.0754 20.1893 6.05897C20.2292 6.04254 20.2719 6.03409 20.3151 6.03409Z" />
      <path d="M9.78764 16.8841C10.0618 16.8839 10.331 16.8121 10.5684 16.6757C10.8058 16.5393 11.0029 16.3433 11.14 16.1072C11.3106 15.8118 11.3786 15.4688 11.3334 15.1311C11.2881 14.7934 11.1322 14.48 10.8897 14.2394C10.6472 13.9988 10.3318 13.8445 9.99213 13.8003C9.65251 13.756 9.30769 13.8244 9.01109 13.9948C8.7145 14.1652 8.48268 14.4281 8.35154 14.7428C8.22041 15.0575 8.19727 15.4064 8.28572 15.7355C8.37417 16.0645 8.56926 16.3554 8.84079 16.5631C9.11231 16.7707 9.44512 16.8836 9.78764 16.8841ZM9.78764 14.5105C9.8966 14.5098 10.0046 14.5304 10.1056 14.5712C10.2065 14.612 10.2984 14.6722 10.3759 14.7484C10.4535 14.8245 10.5152 14.915 10.5575 15.0149C10.5999 15.1147 10.622 15.2219 10.6227 15.3302C10.6221 15.4746 10.585 15.6164 10.515 15.7428C10.4422 15.8694 10.3371 15.9746 10.2104 16.048C10.0837 16.1213 9.93981 16.1602 9.7932 16.1607C9.64659 16.1613 9.50242 16.1234 9.3752 16.0509C9.24797 15.9785 9.14217 15.874 9.06843 15.7479C8.9947 15.6219 8.95562 15.4788 8.95513 15.333C8.95464 15.1872 8.99276 15.0438 9.06566 14.9173C9.13855 14.7908 9.24365 14.6856 9.37039 14.6123C9.49712 14.539 9.64103 14.5002 9.78764 14.4998V14.5105Z" />
    </svg>
  </Box>
)
