import { ChangeEvent, FC, JSX, useState } from 'react'
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { Modal } from '../../components'
import { useReduxActions } from '../../hooks'
import { colorTheme } from '../../utils'
import { CheckIcon, NoCheckIcon } from './icons'
import { Target } from '../../types/target'

interface ITargetModal {
  open: boolean
  className: any
  targetList: Target[]
  onClose: () => void
  onSelect: (target: number) => void
}

const RatioCustom: JSX.Element = (
  <Radio
    color="warning"
    icon={<NoCheckIcon />}
    checkedIcon={<CheckIcon />}
    sx={{ p: 0, width: 17, height: 23, ml: '10px', mr: '15px' }}
  />
)

const LabelCustom: FC<{ text: string; src: string }> = ({ src, text }): JSX.Element => {
  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <Box
          width={74}
          height={74}
          display="flex"
          overflow="hidden"
          borderRadius="8px"
          alignItems="center"
          justifyContent="center"
        >
          <img src={src} alt="" height="100%" style={{ display: 'block' }} />
        </Box>
      </Grid>
      <Grid item>
        <Box p="8px 15px" fontSize={17} fontWeight={700} color={colorTheme}>
          {text}
        </Box>
      </Grid>
    </Grid>
  )
}

export const TargetModal: FC<ITargetModal> = ({ open, targetList, onSelect, className, onClose }): JSX.Element => {
  const { setAlert } = useReduxActions()

  const [value, setValue] = useState<number>(0)

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(Number((event.target as HTMLInputElement).value))
  }

  const onSubmit = () => {
    if (value === 0) {
      setAlert({
        alert: {
          message: { info: ['Цель челенджа не выбрана'] },
        },
      })
    } else {
      onSelect(value)
    }
  }

  return (
    <Modal open={open} maxWidth="md" onClose={onClose}>
      <Box className={className.title} mt="15px">
        Для начала выберите цель
      </Box>
      <Box fontSize={16} fontWeight={700} mt="10px" mb="20px">
        Хочу добавить в свою жизнь больше…
      </Box>

      <Box sx={{ mb: '40px', width: '100%', '& .MuiTypography-root': { width: '100%!important' } }}>
        <RadioGroup value={value} onChange={onChangeValue} sx={{ width: '100%' }}>
          {targetList.map((k: any, i: number) => (
            <FormControlLabel
              key={i}
              value={k.id}
              control={RatioCustom}
              sx={{ mb: '15px', width: '100%' }}
              label={<LabelCustom src={`${k.primary_media.src_link}`} text={k.name} />}
            />
          ))}
        </RadioGroup>
      </Box>

      <Box mb="20px">
        <Button fullWidth variant="contained" color="secondary" onClick={onSubmit}>
          Выбрать
        </Button>
      </Box>
    </Modal>
  )
}
