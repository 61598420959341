import React from 'react'
import moment from 'moment'
import { useReduxSelector } from '../../hooks'

interface IStopPage {
  completed?: boolean
  children: React.ReactNode
  stopText?: React.ReactNode | string
}

export const StopAction: React.FC<IStopPage> = ({ completed = false, children, stopText }): JSX.Element => {
  const { data: constants, init: isConstants } = useReduxSelector((state) => state.constants)

  const { END_DATE = '', NOW } = constants || {}

  return <>{moment(NOW).isAfter(moment(END_DATE)) && !completed ? <>{stopText || undefined}</> : <>{children}</>}</>
}
