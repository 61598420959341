import { Font } from '../types'

const font: Font = {
  primary: 'Raleway',
  secondary: '',
  tertiary: '',
  other: '',
}

export default font
