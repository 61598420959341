import React from 'react'
import { Box, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useLocation } from 'react-router-dom'
import { img } from '../../imgs'
import { aHref } from '../../utils'
import { TrackEvent } from '../GA'

export const TgLink: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const location = useLocation()

  const onTgNavigate = () => {
    aHref('https://t.me/doterra_challenge_bot')
    if (location.pathname === '/tasks') {
      TrackEvent({
        eventCategory: 'Click',
        eventAction: 'btnclick',
        eventLabel: 'Подпишитесь на наш чат-бот',
      })
    }
  }

  return (
    <Box className={classes.tg} onClick={onTgNavigate}>
      <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing="12px">
            <Grid item>
              <Box className={classes.imgTg}>
                <img src={img.tg} alt="" />
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.tgText}>
                Подпишитесь на наш
                <br />
                чат-бот, чтобы не пропустить
                <br />
                важные уведомления
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box className={classes.arrow}>
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1L9 9C9 9 1.72776 16.2722 1 17"
                stroke="#B6BCC2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  tg: {
    marginTop: 20,
    borderRadius: 18,
    padding: '10px 15px',
    backgroundColor: '#EFF2F3',
    '-webkit-tap-highlight-color': 'transparent',
    [down(365)]: {
      padding: '10px 10px',
    },
  },
  imgTg: {
    transform: 'translate(0, 2px)',
    '& img': {
      width: 42,
      height: 42,
      [down(330)]: {
        width: 38,
        height: 38,
      },
    },
  },
  tgText: {
    fontSize: 14,
    fontWeight: 600,
    color: palette.primary.contrastText,
    [down(365)]: {
      fontSize: 13,
    },
    [down(330)]: {
      fontSize: 11,
    },
  },
  arrow: {
    transform: 'translate(-10px, 0)',
  },
}))
