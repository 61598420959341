import React from 'react'
import { Box, Button, CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TgLink } from '../../../../../components'

interface IStageOne {
  className: any
  saveHabits: boolean
  onChangeStage: () => void
}

export const StageThree: React.FC<IStageOne> = ({
  className,
  saveHabits = false,
  onChangeStage = () => {},
}): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.content}>
      <Box className={className.title}>
        Отлично! Не забывайте отслеживать развитие привычек в трекере на вкладке «Привычки»
      </Box>
      <Box className={className.textTitle}>
        Во время 30-дневного марафона каждый вечер делайте краткую запись в дневнике о том, как прошел день. Честно
        оцените, насколько успешно вы придерживались новых привычек, чтобы увидеть прогресс и скорректировать свой план.
      </Box>
      <Box className={className.title} pt="15px">
        Начать формировать привычки
      </Box>
      <Box className={className.textTitle}>
        Чтобы помочь вам сформировать новые привычки, мы будем присылать ежедневные задания во время всего марафона.
        Каждое из них потребует от вас несложного действия, которое будет направлено на поддержание здоровья. Это
        поможет вам подняться на новый уровень и вести образ жизни, о котором вы всегда мечтали.
      </Box>
      <Box m="30px auto 0" maxWidth={350}>
        <Button fullWidth variant="contained" color="secondary" disabled={saveHabits} onClick={onChangeStage}>
          Начать выполнять привычки
          {saveHabits && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Box>
      <TgLink />
    </Box>
  )
}

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  content: {},
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: `${palette.primary.main}!important`,
    marginTop: spacing(-3 / 2),
    marginLeft: spacing(-3 / 2),
  },
}))
