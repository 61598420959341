const { REACT_APP_RULES_URL: RULES, REACT_APP_AGREEMENTS_URL: AGREEMENTS } = process.env

export const formLocale = {
  success: {},

  badRequest: {},

  serverError: ['Сервер временно недоступен. Пожалуйста, попробуйте позже'],

  notValid: {
    warning: [
      `Внимание! Одно или несколько полей не заполнены или заполнены неверно.
		 	<br/>Проверьте корректность заполнения полей.`,
    ],
  },

  signin: {
    invalidCredentials: { error: ['Неверный логин или пароль.'] },
  },

  // rulesCheckbox: (
  //   <span>
  //     Я прочитал и согласен с{' '}
  //     <a href={AGREEMENTS} target="_blank" rel="noreferrer">
  //       Пользовательским соглашением
  //     </a>{' '}
  //     и{' '}
  //     <a href={RULES} target="_blank" rel="noreferrer">
  //       Правилами мероприятия
  //     </a>
  //     , согласен на обработку персональных данных
  //   </span>
  // ),

  rulesCheckbox: (
    <span>
      Я прочитал и согласен с{' '}
      <a href={AGREEMENTS} target="_blank" rel="noreferrer">
        Пользовательским соглашением
      </a>{' '}
      и, согласен на обработку персональных данных
    </span>
  ),

  adventRulesCheckbox: (
    <span>
      Я согласен с{' '}
      <a href={RULES} target="_blank" rel="noreferrer">
        Правилами мероприятия
      </a>
    </span>
  ),

  subscribeSmsCheckbox: <span>Я согласен на sms рассылку</span>,

  // subscribeEmailCheckbox: (
  //   <span>
  //     Я согласен получать информацию о мероприятии по <span style={{ whiteSpace: 'nowrap' }}>e-mail</span>
  //   </span>
  // ),

  subscribeEmailCheckbox: (
    <span>
      Я согласен получать информацию по <span style={{ whiteSpace: 'nowrap' }}>e-mail</span>
    </span>
  ),

  subscribeSmsEmailCheckbox: (
    <span>
      Я согласен на смс и <span style={{ whiteSpace: 'nowrap' }}>e-mail</span> рассылку
    </span>
  ),
}
