import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { Check } from '@mui/icons-material'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom'
import { CheckboxInput, EmailInput, formLocale, PhoneInput, SubmitButton, TextInput } from '../../components'
import { FormProps } from '../../types'
import { useReduxActions, useReduxSelector } from '../../hooks'
import { registerUser } from '../../api'
import { errorFormToast } from '../../toast'

interface ISignupTwoForm {
  className: any
  dataOneForm: any
  onChangeStage: () => void
}

type SignupTwoProps = { phone: string; email: string; rules: boolean; subscribe_email: boolean }

const schema = yup.object().shape({
  // phone: yup
  //   .string()
  //   .required()
  //   .transform((value) => value.replace(/\s|\(|\)|\-|\+7/g, ''))
  //   .matches(/^\d{10}$/, 'Указан некорректный номер телефона'),
  phone: yup
    .string()
    .required()
    .test('length', 'Длина номера должна быть 11 цифр', (val: any) => {
      let str: string[] = []
      if (val) str = Array.from(val)
      if (`+${str[1]}${str[2]}` === '+79') {
        return `${val}`.length === 12 && `+${str[1]}${str[2]}` === '+79'
      } else {
        return true
      }
    })
    .min(8)
    .max(20),
  email: yup.string().required().email(),
  rules: yup.boolean().oneOf([true], 'Необходимо согласиться с условиями').default(false),
})

export const SignupTwoForm: React.FC<ISignupTwoForm> = ({
  className,
  dataOneForm,
  onChangeStage = () => {},
}): JSX.Element => {
  const navigate = useNavigate()
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const { serverError, rulesCheckbox, subscribeEmailCheckbox } = formLocale

  const { setAlert } = useReduxActions()

  const {
    auth: { auth },
    constants: { data: constants },
  } = useReduxSelector((state) => state)

  const hookForm = useForm<any>({
    defaultValues: { phone: '', email: '', rules: false },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<SignupTwoProps>>({
    data: { phone: '', email: '', rules: false, subscribe_email: false },
    processed: false,
  })

  const onRecaptchaChange = async (recaptcha: string) => {
    if (!auth) {
      registerUser({ ...formProps.data, ...dataOneForm, recaptcha })
        .then((res) => {
          setFormProps({ ...formProps, processed: false })

          if (res.status === 1) {
            setAlert({
              alert: {
                title: 'Подтверждение данных',
                message: {
                  info: [
                    'Уважаемый участник!<br />На указанный E‑mail отправлена ссылка для завершения регистрации.' +
                      '<br /><br />Для завершения регистрации перейдите по ссылке из письма.',
                  ],
                },
                onClose: () => {
                  navigate('../greet')
                },
              },
            })
          } else {
            errorFormToast(res.message)
          }
        })
        .catch((err) => {
          setFormProps({ ...formProps, processed: false })

          if (err.response.data.status === -1 || err.response.data.status === 0) {
            errorFormToast(err.response.message)
          } else {
            errorFormToast(serverError)
          }
        })
        .finally(() => {
          recaptchaRef.current?.reset()
        })
    }
  }

  const onRecaptchaExpired = () => {
    setFormProps({
      ...formProps,
      processed: false,
    })
  }

  const onSubmitForm = async (data: SignupTwoProps) => {
    setFormProps({ ...formProps, data: { ...formProps.data, ...data, phone: data.phone.slice(1) }, processed: true })
    recaptchaRef.current?.execute()
  }

  const check = {
    icon: <span className={className.icon} />,
    checked: (
      <span className={className.iconCheck}>
        <Check className={className.check} />
      </span>
    ),
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      {constants?.CAPTCHA_SITEKEY && (
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          onExpired={onRecaptchaExpired}
          sitekey={constants?.CAPTCHA_SITEKEY}
          onErrored={() => console.error('onRecaptchaErrored')}
          onChange={(tokenRecaptcha) => onRecaptchaChange(tokenRecaptcha ?? '')}
        />
      )}

      <Box width="100%" height={72}>
        <PhoneInput variant="outlined" name="phone" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box width="100%" height={72}>
        <EmailInput variant="outlined" form={hookForm} name="email" disabled={formProps.processed} />
      </Box>
      <Box width="100%" minHeight={70} mt="15px" pb="15px">
        <CheckboxInput
          required
          name="rules"
          form={hookForm}
          color="secondary"
          icon={check.icon}
          label={rulesCheckbox}
          checked={check.checked}
        />
      </Box>
      <Box width="100%">
        <CheckboxInput
          required
          form={hookForm}
          color="secondary"
          icon={check.icon}
          name="subscribe_email"
          checked={check.checked}
          label={subscribeEmailCheckbox}
        />
      </Box>
      <Box width="100%" mt="40px">
        <SubmitButton
          fullWidth
          title="Зарегистрироваться"
          disabled={formProps.processed}
          processed={formProps.processed}
        />
      </Box>
    </form>
  )
}
