import React, { FC, JSX, useCallback, useState } from 'react'
import { Box, Button, Grid, Theme } from '@mui/material'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import { Modal, NumberFormatInput } from '../../../../components'
import { MeasurementTracker, MeasurementTrackerList } from '../../../../types'
import { useReduxActions, useReduxDispatch } from '../../../../hooks'
import { getUser } from '../../../../store/auth/auth.service'
import { trackerStore } from '../../../../api'

interface IMeasurementModal {
  open: boolean
  update: () => void
  onClose: () => void
  measures: MeasurementTrackerList | undefined
}

const defaultMeasures = (measures?: MeasurementTrackerList): Record<number, string> => {
  const notes: Record<number, string> = {}

  if (measures) {
    measures.trackers.forEach((k, i: number) => {
      if (k.note !== null) notes[`${k.id}`] = `${k.note}`
    })
  }

  console.log(notes)

  return notes
}

export const MeasurementModal: FC<IMeasurementModal> = ({ open, measures, update, onClose }): JSX.Element => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()

  const [disabled, setDisabled] = useState<boolean>(false)

  const defaultValues = defaultMeasures(measures)

  const hookForm = useForm<any>({
    defaultValues,
    mode: 'onBlur',
  })

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
  }, [])

  const onSubmitForm = (data: Record<string, string>) => {
    setDisabled(true)
    const keys = Object.keys(data)
    const key = keys[keys.length - 1]

    const trackers: { id: number; note: string }[] = []

    trackers.push({ id: Number(key), note: data[key].replace(' см', '') })

    trackerStore({ trackers }).then(({ status }) => {
      setDisabled(false)

      if (status === 1) {
        update()
        onClose()
        uploadUserInfo()
      } else {
        setAlert({
          alert: {
            message: { info: ['Мой трекер не сохранен'] },
          },
        })
      }
    })
  }

  return (
    <Modal open={open} maxWidth="md" onClose={onClose}>
      <Box color="warning.main" fontSize={20} fontWeight={700}>
        {measures?.name}
      </Box>
      <Box color="text.primary" fontSize={18} fontWeight={700} my="20px" lineHeight={1.4}>
        {measures?.description}
      </Box>
      <Box className={classes.input}>
        <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
          {measures?.trackers.map((k: MeasurementTracker, i: number) => (
            <Grid
              container
              alignItems="center"
              key={`weekend-${i}`}
              mt={i === 0 ? 0 : '10px'}
              justifyContent="space-between"
            >
              <Grid item sx={{ fontSize: 18, fontWeight: 700 }}>
                {k.num} неделя
              </Grid>
              <Grid item maxWidth={150}>
                {measures && measures.trackers.length - 1 === i ? (
                  <NumberFormatInput form={hookForm} name={`${k.id}`} placeholder="0 см" disabled={disabled} />
                ) : (
                  <Box
                    sx={{
                      width: 131,
                      height: 56,
                      pl: '17px',
                      fontSize: 18,
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '12px',
                      color: 'secondary.main',
                      border: '1px solid #DDE0E3',
                    }}
                  >
                    {k.note} см
                  </Box>
                )}
              </Grid>
            </Grid>
          ))}

          <Box mt="30px">
            <Button fullWidth type="submit" variant="contained" color="secondary" disabled={disabled}>
              Сохранить
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  input: {
    '& .MuiOutlinedInput-input': {
      fontSize: 18,
      fontWeight: 700,
      paddingTop: '8px!important',
      color: `${palette.secondary.main}!important`,
    },
    '& input::placeholder': {
      color: `${palette.text.primary}!important`,
    },
  },
}))
