export const createObject = (key: Array<string | number>, value: unknown | Array<unknown>) => {
  const obj: Record<string, unknown> = {}

  key.forEach((k, i) => {
    if (Array.isArray(value)) {
      if (value !== null) obj[k] = value[i]
    } else {
      obj[k] = value
    }
  })

  return obj
}
