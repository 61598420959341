/* eslint-disable max-len */
export const UnsuccessDayIcon = () => (
  <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="90" height="90" rx="14" fill="#006266" />
    <g opacity="0.34">
      <circle cx="6.34616" cy="34.8609" r="1.42233" stroke="#D9D9D9" strokeWidth="1.5" />
      <circle cx="23.7248" cy="63.1013" r="2.62055" stroke="#D9D9D9" strokeWidth="2" />
      <circle cx="23.0007" cy="44.9984" r="2.17233" fill="#D9D9D9" />
      <circle cx="5.62205" cy="52.9636" r="1.44822" fill="#D9D9D9" />
      <circle cx="7.07028" cy="75.4111" r="2.17233" fill="#D9D9D9" />
      <circle cx="19.3801" cy="82.6522" r="1.44822" fill="#D9D9D9" />
      <circle cx="41.8276" cy="83.3763" r="1.44822" fill="#D9D9D9" />
      <circle cx="64.2749" cy="84.8244" r="1.44822" fill="#D9D9D9" />
      <circle cx="36.7588" cy="70.3423" r="2.17233" fill="#D9D9D9" />
      <circle cx="82.0158" cy="45.3604" r="1.81028" fill="#D9D9D9" />
      <circle cx="57.0339" cy="29.068" r="1.44822" fill="#D9D9D9" />
      <circle cx="43.2759" cy="17.4823" r="1.44822" fill="#D9D9D9" />
      <circle cx="70.0681" cy="16.034" r="0.724111" fill="#D9D9D9" />
      <circle cx="78.7573" cy="5.89644" r="2.89644" fill="#D9D9D9" />
      <circle cx="75.8608" cy="31.2403" r="2.62055" stroke="#D9D9D9" strokeWidth="2" />
      <circle cx="81.6538" cy="61.653" r="1.42233" stroke="#D9D9D9" strokeWidth="1.5" />
      <circle cx="54.8615" cy="7.3447" r="1.42233" stroke="#D9D9D9" strokeWidth="1.5" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.9933 36.1886C62.671 35.6354 58.7185 38.6909 58.1653 43.0132L57.414 48.883L55.5892 48.6494C53.5811 48.3924 51.7044 49.6971 51.2458 51.669L47.3039 69.0599C46.7891 71.2736 48.2464 73.4634 50.4873 73.8431L72.3425 77.4653C74.6778 77.861 76.8329 76.1242 76.9425 73.7582L77.8838 55.1583C77.9789 53.1055 76.4822 51.3235 74.4438 51.0626L73.0667 50.8864L73.818 45.0166C74.3712 40.6942 71.3157 36.7418 66.9933 36.1886ZM69.1535 50.3855L69.9048 44.5158C70.1814 42.3546 68.6537 40.3784 66.4925 40.1017C64.3313 39.8251 62.3551 41.3529 62.0785 43.5141L61.3272 49.3838L69.1535 50.3855ZM65.5109 63.3077C66.7672 62.7808 67.716 61.6169 67.9013 60.1684C68.1779 58.0073 66.6502 56.031 64.489 55.7544C62.3278 55.4778 60.3516 57.0056 60.075 59.1667C59.8896 60.6152 60.5147 61.9805 61.5977 62.8068L61.1551 66.2652C61.0168 67.3458 61.7807 68.3339 62.8613 68.4722C63.9419 68.6105 64.93 67.8467 65.0683 66.7661L65.5109 63.3077Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.2761 41.6733C56.9604 36.3268 61.9401 32.559 67.3987 33.2576C72.8572 33.9563 76.7275 38.8568 76.0432 44.2033L75.3006 49.284C77.9715 49.6797 79.9242 51.9933 79.8058 54.647L78.9318 74.8417C78.7935 77.9411 75.9187 80.2092 72.7958 79.6827L49.1423 75.6949C46.1456 75.1897 44.1899 72.3157 44.8704 69.4171L49.3135 50.6397C49.992 47.7495 52.7706 46.3993 55.5336 46.7529L56.2761 41.6733Z"
      stroke="white"
      strokeLinejoin="bevel"
      strokeDasharray="1 1"
    />
  </svg>
)
