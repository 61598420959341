/* eslint-disable max-len */
import { FC, JSX } from 'react'

export const CardsDrawerIcon: FC = (): JSX.Element => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="16.6936"
      y="9.12375"
      width="14.7166"
      height="22.0749"
      rx="1.08732"
      transform="rotate(11.3147 16.6936 9.12375)"
      stroke="#DB580F"
      strokeWidth="2.17463"
      strokeLinejoin="round"
    />
    <rect
      x="18.5535"
      y="12.7631"
      width="9.63066"
      height="3.5856"
      rx="1.08732"
      transform="rotate(11.2793 18.5535 12.7631)"
      fill="#DB580F"
    />
    <rect
      x="17.7664"
      y="16.7144"
      width="9.63066"
      height="3.5856"
      rx="1.08732"
      transform="rotate(11.2793 17.7664 16.7144)"
      fill="#DB580F"
    />
    <rect
      x="16.9775"
      y="20.6657"
      width="9.63066"
      height="3.5856"
      rx="1.08732"
      transform="rotate(11.2793 16.9775 20.6657)"
      fill="#DB580F"
    />
    <rect
      x="16.1892"
      y="24.6171"
      width="9.63066"
      height="3.5856"
      rx="1.08732"
      transform="rotate(11.2793 16.1892 24.6171)"
      fill="#DB580F"
    />
    <path
      d="M6.90891 5.2762C5.44602 5.66818 4.57787 7.17185 4.96985 8.63474L9.61225 25.9604C9.5833 25.5909 9.6042 25.2113 9.68051 24.8299L12.9086 8.69627C13.3544 6.46868 15.5215 5.02418 17.7491 5.46989L21.3487 6.19012L20.8626 4.37628C20.4707 2.91339 18.967 2.04525 17.5041 2.43723L6.90891 5.2762Z"
      fill="#DB580F"
    />
    <path
      d="M10.8417 28.5822C10.5192 28.267 10.2505 27.901 10.0466 27.5002C10.2217 27.9321 10.4988 28.2998 10.8417 28.5822Z"
      fill="#DB580F"
    />
  </svg>
)
