import React from 'react'
import { Box, Button, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MainHabit } from '../types'
import { img } from '../../../../../imgs'
import { useReduxActions } from '../../../../../hooks'

interface IStageOne {
  className: any
  items: MainHabit[]
  onChangeStage: () => void
}

export const StageOne: React.FC<IStageOne> = ({ items = [], className, onChangeStage = () => {} }): JSX.Element => {
  const classes = useStyles()
  const { setAlert } = useReduxActions()

  const imgSrc = (slug: string) => {
    switch (slug) {
      case 'water':
        return img.habitMainOne
      case 'sport':
        return img.habitMainTwo
      case 'sleep':
        return img.habitMainThree
      default:
        return ''
    }
  }

  const onDescriptionHabit = (title: string, description: string) => {
    setAlert({ alert: { title, message: { info: [`${description}`] } } })
  }

  return (
    <Box className={classes.content}>
      <Box className={className.title}>Предлагаем вам список из трех обязательных привычек</Box>
      <Box className={className.textTitle}>
        <Box mb="10px">Данные привычки нужно будет развивать и поддерживать на протяжении 30-ти дней.</Box>
        Спустя это время вашей новой нормой станут хорошее самочувствие и отличное настроение, а также полезные
        привычки, которые прочно войдут в вашу жизнь.
      </Box>
      {items.length > 0 &&
        items.map((k: MainHabit, i: number) => {
          if (i > 2) return <></>

          return (
            <Box key={`main-habit-${i}`} className={className.item}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <img src={imgSrc(k.slug)} alt="" className={classes.img} />
                </Grid>
                <Grid item>
                  <Box className={className.itemTitle}>{k.name}</Box>
                  <Box className={className.itemDescription} onClick={() => onDescriptionHabit(k.name, k.description)}>
                    Подробнее о привычке
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )
        })}
      <Box m="25px auto 0" maxWidth={350}>
        <Button fullWidth variant="contained" color="secondary" onClick={onChangeStage}>
          Продолжить
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  content: {},
  img: {
    width: 40,
    height: 40,
  },
}))
