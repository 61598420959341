import { FC, JSX, useCallback, useState } from 'react'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { ControlTrackerList } from '../../../../types'
import { str } from '../../../../utils'
import { DropIcon, MinusIcon, PlusIcon } from '../../icons'
import { trackerStore } from '../../../../api'
import { getUser } from '../../../../store/auth/auth.service'
import { useReduxActions, useReduxDispatch } from '../../../../hooks'

interface IMyWell {
  className: any
  update: () => void
  trackerList: ControlTrackerList[]
}

const defaultWell = (wells: ControlTrackerList[]): Record<string, number> => {
  const notes: Record<string, number> = {}

  wells.forEach((k: ControlTrackerList, i: number) => {
    if (k.trackers[k.trackers.length - 1].note === null) {
      notes[`${k.trackers[k.trackers.length - 1].id}`] = 1
    } else {
      notes[`${k.trackers[k.trackers.length - 1].id}`] = Number(k.trackers[k.trackers.length - 1].note)
    }
  })

  return notes
}

const save = (trackerList: ControlTrackerList[]): boolean => {
  let value = false

  trackerList.forEach((k) => {
    value = !!k.trackers.find((n) => n.note === null)
  })

  return value
}

export const MyWell: FC<IMyWell> = ({ className, update, trackerList }): JSX.Element => {
  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()

  const [disabled, setDisabled] = useState<boolean>(false)
  const [values, setValues] = useState<Record<string, number>>(defaultWell(trackerList))

  const onIncrement = (key: number, max: number) => {
    if (values[`${key}`] < max) {
      setValues((prev) => {
        return { ...prev, [`${key}`]: prev[`${key}`] + 1 }
      })
    }
  }

  const onDecrement = (key: number, min: number) => {
    if (values[`${key}`] > min) {
      setValues((prev) => {
        return { ...prev, [`${key}`]: prev[`${key}`] - 1 }
      })
    }
  }

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
  }, [])

  const onSave = () => {
    const trackers: { id: number; note: string }[] = []

    Object.keys(values).forEach((k: string) => {
      trackers.push({
        id: Number(k),
        note: values[`${k}`].toString(),
      })
    })

    trackerStore({ trackers }).then(({ status }) => {
      setDisabled(false)

      if (status === 1) {
        update()
        uploadUserInfo()
      } else {
        setAlert({
          alert: {
            message: { info: ['Мой трекер не сохранен'] },
          },
        })
      }
    })
  }

  return (
    <Box>
      <Box color="text.primary" fontSize={18} fontWeight={700} mb="20px" lineHeight={1.4}>
        Раз в неделю сосредоточьтесь на своих ощущениях. Сделайте три глубоких вдоха и отметьте, как вы себя чувствуете.
        Будьте честны с собой. Эти пятиминутные проверки очень полезны!
      </Box>
      <Box>
        {trackerList.map((k: ControlTrackerList, i: number) => (
          <Box key={`well-${i}`}>
            <Box color="text.primary" fontSize={16} fontWeight={700} mb="5px">
              {i + 1}. {k.name}
            </Box>
            <Box color="text.primary" fontSize={14} fontWeight={600} lineHeight={1.4}>
              {str.normalize((k.description || '').replace(/[\r\n]+/g, '<br/>'))}
            </Box>
            <Grid container wrap="nowrap" alignItems="center" spacing={2} mt="-5px" mb="30px">
              {k.trackers[k.trackers.length - 1].note === null && (
                <Grid item>
                  <IconButton
                    sx={{ p: 0 }}
                    color="secondary"
                    onClick={() => onDecrement(k.trackers[k.trackers.length - 1].id, k.meta.options[0])}
                  >
                    <MinusIcon />
                  </IconButton>
                </Grid>
              )}

              <Grid item width={64}>
                <Grid container wrap="nowrap" justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                    <Box position="relative" top={5}>
                      <DropIcon />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box color="secondary.main" fontSize={24} fontWeight={700} minWidth={15}>
                      {values[`${k.trackers[k.trackers.length - 1].id}`]}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {k.trackers[k.trackers.length - 1].note === null && (
                <Grid item>
                  <IconButton
                    sx={{ p: 0 }}
                    color="secondary"
                    onClick={() => {
                      onIncrement(k.trackers[k.trackers.length - 1].id, k.meta.options[k.meta.options.length - 1])
                    }}
                  >
                    <PlusIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Box>
        ))}

        {save(trackerList) && (
          <Box mt="40px">
            <Button fullWidth variant="contained" color="secondary" disabled={disabled} onClick={onSave}>
              Сохранить
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
