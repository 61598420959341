/* eslint-disable max-len */
import { FC, JSX } from 'react'

export const CardsIcon: FC = (): JSX.Element => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="15.4641"
      y="8.961"
      width="11.6705"
      height="17.5057"
      rx="1.08732"
      transform="rotate(11.3147 15.4641 8.961)"
      stroke="#B6BCC2"
      strokeWidth="2.17463"
      strokeLinejoin="round"
    />
    <rect
      x="16.939"
      y="11.847"
      width="7.63726"
      height="2.84343"
      rx="1.08732"
      transform="rotate(11.2793 16.939 11.847)"
      fill="#B6BCC2"
    />
    <rect
      x="16.3146"
      y="14.9805"
      width="7.63726"
      height="2.84343"
      rx="1.08732"
      transform="rotate(11.2793 16.3146 14.9805)"
      fill="#B6BCC2"
    />
    <rect
      x="15.6892"
      y="18.114"
      width="7.63726"
      height="2.84343"
      rx="1.08732"
      transform="rotate(11.2793 15.6892 18.114)"
      fill="#B6BCC2"
    />
    <rect
      x="15.0638"
      y="21.2474"
      width="7.63726"
      height="2.84343"
      rx="1.08732"
      transform="rotate(11.2793 15.0638 21.2474)"
      fill="#B6BCC2"
    />
    <path
      d="M7.70456 5.90985C6.54447 6.22069 5.85602 7.41313 6.16686 8.57322L9.84835 22.3127C9.82539 22.0197 9.84197 21.7187 9.90248 21.4162L12.4624 8.62201C12.8159 6.8555 14.5345 5.70999 16.301 6.06345L19.1555 6.6346L18.7701 5.1962C18.4592 4.03611 17.2668 3.34765 16.1067 3.6585L7.70456 5.90985Z"
      fill="#B6BCC2"
    />
    <path
      d="M10.8234 24.3918C10.5675 24.1419 10.3545 23.8516 10.1928 23.5338C10.3316 23.8763 10.5514 24.1679 10.8234 24.3918Z"
      fill="#B6BCC2"
    />
  </svg>
)

export const CardsActiveIcon: FC = (): JSX.Element => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="15.4641"
      y="8.961"
      width="11.6705"
      height="17.5057"
      rx="1.08732"
      transform="rotate(11.3147 15.4641 8.961)"
      stroke="#DB580F"
      strokeWidth="2.17463"
      strokeLinejoin="round"
    />
    <rect
      x="16.939"
      y="11.847"
      width="7.63726"
      height="2.84343"
      rx="1.08732"
      transform="rotate(11.2793 16.939 11.847)"
      fill="#DB580F"
    />
    <rect
      x="16.3146"
      y="14.9805"
      width="7.63726"
      height="2.84343"
      rx="1.08732"
      transform="rotate(11.2793 16.3146 14.9805)"
      fill="#DB580F"
    />
    <rect
      x="15.6892"
      y="18.114"
      width="7.63726"
      height="2.84343"
      rx="1.08732"
      transform="rotate(11.2793 15.6892 18.114)"
      fill="#DB580F"
    />
    <rect
      x="15.0638"
      y="21.2474"
      width="7.63726"
      height="2.84343"
      rx="1.08732"
      transform="rotate(11.2793 15.0638 21.2474)"
      fill="#DB580F"
    />
    <path
      d="M7.70456 5.90985C6.54447 6.22069 5.85602 7.41313 6.16686 8.57322L9.84835 22.3127C9.82539 22.0197 9.84197 21.7187 9.90248 21.4162L12.4624 8.62201C12.8159 6.8555 14.5345 5.70999 16.301 6.06345L19.1555 6.6346L18.7701 5.1962C18.4592 4.03611 17.2668 3.34765 16.1067 3.6585L7.70456 5.90985Z"
      fill="#DB580F"
    />
    <path
      d="M10.8234 24.3918C10.5675 24.1419 10.3545 23.8516 10.1928 23.5338C10.3316 23.8763 10.5514 24.1679 10.8234 24.3918Z"
      fill="#DB580F"
    />
  </svg>
)
