import React, { FC } from 'react'
import { Box, Button, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HtmlReactParser from 'html-react-parser'
import { VKShareButton } from 'react-share'
import { TestQuestion, TestStore } from './test.types'
import { Modal } from '../../components'
import { VkIcon } from '../../imgs'
import { themeGl } from '../../configs'

interface IRecommendationModal {
  show: boolean
  data: TestQuestion
  onClose: () => void
  storeTest?: TestStore
  recommendation?: string
}

export const RecommendationModal: FC<IRecommendationModal> = ({
  storeTest,
  show = false,
  data,
  onClose = () => {},
  recommendation = '',
}): JSX.Element => {
  const classes = useStyles()

  const textShare = 'Я знаю, что такое пирамида здорового образа жизни и как взобраться на самый верх! Хочешь со мной?'
  const urlShare = 'https://vk.com/share.php?url=https://doterra-challenge.ru'

  return (
    <Modal open={show} onClose={onClose} maxWidth="md">
      {!storeTest ? (
        <>
          <Box className={classes.img}>
            {data?.primary_media?.src_link && <img src={data?.primary_media?.src_link} alt="" />}
          </Box>
          <Box className={classes.text}>{data.description}</Box>
        </>
      ) : (
        <>
          <Box className={classes.title}>
            Поздравляем!
            <br />
            Вы ответили на все вопросы.
          </Box>
          <Box className={classes.text}>{recommendation}</Box>
          <Box className={classes.img}>
            {storeTest.test_level?.primary_media?.src_link && (
              <img src={storeTest.test_level?.primary_media?.src_link} alt="" />
            )}
          </Box>
          <Box className={classes.title}>{storeTest.test_level.description}</Box>
          <Box className={classes.text}>
            {HtmlReactParser(storeTest.test_level.content)}
            <Box mt="15px">
              Узнайте{' '}
              <a href="https://media.doterra.com/ru-otg/ru/brochures/live-guide.pdf" target="_blank" rel="noreferrer">
                больше
              </a>{' '}
              о пирамиде здорового образа жизни!
            </Box>
          </Box>
          <Grid container justifyContent="center" mb="20px">
            <Grid item width={335}>
              <VKShareButton
                title={textShare}
                style={{ width: '100%' }}
                image={storeTest.test_level?.primary_media?.src_link || ''}
                url="https://doterra-challenge.ru/share/vk/test-share.html"
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  sx={{ border: themeGl === 'calendar' ? '2px solid #006266' : '2px solid #B786B9' }}
                >
                  <Grid container justifyContent="center" alignItems="center" mt="-17px" height={40} spacing={1}>
                    <Grid item>
                      <Box mt="-5px">Поделиться</Box>
                    </Grid>
                    <Grid item height={54}>
                      <VkIcon />
                    </Grid>
                  </Grid>
                </Button>
              </VKShareButton>
              {/* <Button */}
              {/*  fullWidth */}
              {/*  color="primary" */}
              {/*  component={Link} */}
              {/*  variant="contained" */}
              {/*  sx={{ border: '2px solid #B786B9' }} */}
              {/*  href={`${urlShare}${textShare}&image=${storeTest.test_level.primary_media.src_link}`} */}
              {/* > */}
              {/*  <Grid container justifyContent="center" alignItems="center" mt="-17px" height={40} spacing={1}> */}
              {/*    <Grid item> */}
              {/*      <Box mt="-5px">Поделиться</Box> */}
              {/*    </Grid> */}
              {/*    <Grid item height={54}> */}
              {/*      <VkIcon /> */}
              {/*    </Grid> */}
              {/*  </Grid> */}
              {/* </Button> */}
            </Grid>
          </Grid>
        </>
      )}
      <Grid container justifyContent="center">
        <Grid item width={335}>
          <Button fullWidth color="secondary" variant="contained" onClick={onClose}>
            {!storeTest ? 'Далее' : 'Получить подарок'}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  img: {
    width: '100%',
    minHeight: 250,
    margin: '30px 0 20px',
    // backgroundColor: '#D9D9D9',
    '& img': { width: '100%' },
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    margin: '20px 0 10px',
    color: palette.primary.contrastText,
  },
  text: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 30,
    color: palette.primary.contrastText,
    '& a': { color: palette.primary.dark },
  },
}))
