import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { Box, TextField, FormHelperText } from '@mui/material'

type Props = {
  name?: string
  label?: string
  disabled?: boolean
  required?: boolean
  fullWidth?: boolean
  placeholder?: string
  form: UseFormReturn<any>
  variant?: 'standard' | 'filled' | 'outlined'
}

export const PhoneInput: React.FC<Props> = ({
  form,
  name = 'phone',
  variant = 'outlined',
  placeholder,
  fullWidth = true,
  required = false,
  disabled = false,
  label = 'Телефон',
}): JSX.Element => {
  const { control, formState } = form

  return (
    <Box>
      <Controller
        name={name}
        defaultValue=""
        control={control}
        render={({ field: { onChange, value, onBlur, name: namePattern } }) => (
          <NumericFormat
            prefix="+"
            value={value}
            label={label}
            onBlur={onBlur}
            variant={variant}
            name={namePattern}
            onChange={(e) => {
              onChange()
              let str: string[] = []
              if (e.target.value) str = Array.from(e.target.value)
              if (e.target.value.length === 3 && `+${str[1]}` === '+7') {
                form.setValue(`${name}`, `+79`)
              } else if (`+${str[1]}${str[2]}` === '+89') {
                form.setValue(`${name}`, `+7${e.target.value.slice(2)}`)
              } else {
                form.setValue(`${name}`, e.target.value)
              }
            }}
            required={required}
            disabled={disabled}
            fullWidth={fullWidth}
            customInput={TextField}
            placeholder={placeholder}
          />
        )}
      />
      <FormHelperText>
        <>{formState.errors[name]?.message}</>
      </FormHelperText>
    </Box>
  )
}
