import { toast } from 'react-toastify'
import { TOAST } from '../types'
import { ErrorForm } from './content.toast'

export const errorFormToast = (message: any) => {
  let alert: string[] = []

  Object.entries(message).map(([_, val], idx) => {
    if (Array.isArray(val)) {
      alert = val
      return alert
    }
    return null
  })

  toast.error(() => ErrorForm({ alert: alert.length > 0 ? alert : [`${message}`] }), {
    toastId: TOAST.ERROR_FORM,
    containerId: TOAST.GLOBAL_CONTAINER_ID,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: false,
    theme: 'colored',
    autoClose: 5000,
  })
}
