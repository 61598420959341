/* eslint-disable max-len */
import { FC, JSX } from 'react'

export const ChallengeIcon: FC = (): JSX.Element => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="#B6BCC2" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.311 16.5432C11.6202 16.0573 12.3295 16.0573 12.6387 16.5432L18.2053 25.2907C18.5386 25.8145 18.1623 26.5 17.5414 26.5H6.40827C5.78735 26.5 5.41106 25.8145 5.74441 25.2907L11.311 16.5432Z" />
      <path d="M17.9748 6C17.9748 5.72386 18.1987 5.5 18.4748 5.5C18.751 5.5 18.9748 5.72386 18.9748 6V12.5H17.9748V6Z" />
      <path d="M18.2458 5.89375H22.5736V8.64782H18.2458V5.89375Z" />
      <path d="M21.7858 6.68031H24.9333L23.9497 8.05734L24.9333 9.43438H21.7858V6.68031Z" />
      <path d="M19.1514 11.6395C18.8465 11.126 18.1031 11.126 17.7982 11.6395L14.965 16.4112L20.9703 26.5H26.5925C27.2025 26.5 27.5805 25.8359 27.2691 25.3114L19.1514 11.6395Z" />
    </svg>
  )
}
