/* eslint-disable max-len */
import { FC } from 'react'

export const Test2Icon: FC = (): JSX.Element => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1116_7553)">
      <path
        d="M31 61.7193C47.9658 61.7193 61.7193 47.9658 61.7193 31C61.7193 14.0342 47.9658 0.280701 31 0.280701C14.0343 0.280701 0.280762 14.0342 0.280762 31C0.280762 47.9658 14.0343 61.7193 31 61.7193Z"
        stroke="#C6C6C6"
        strokeWidth="0.6"
      />
      <path
        d="M45.653 18.1621H25.2733C23.8263 18.1621 22.6533 19.3351 22.6533 20.7821V20.8102C22.6533 22.2571 23.8263 23.4301 25.2733 23.4301H45.653C47.1 23.4301 48.273 22.2571 48.273 20.8102V20.7821C48.273 19.3351 47.1 18.1621 45.653 18.1621Z"
        fill="#F0DFED"
      />
      <path
        d="M45.653 12.108H27.912C26.465 12.108 25.292 13.281 25.292 14.728V14.7561C25.292 16.2031 26.465 17.3761 27.912 17.3761H45.653C47.1 17.3761 48.273 16.2031 48.273 14.7561V14.728C48.273 13.281 47.1 12.108 45.653 12.108Z"
        fill="#DBEFF6"
      />
      <path
        d="M45.6531 24.2161H22.9715C21.5246 24.2161 20.3516 25.3891 20.3516 26.8361V26.8642C20.3516 28.3112 21.5246 29.4842 22.9715 29.4842H45.6531C47.1001 29.4842 48.2731 28.3112 48.2731 26.8642V26.8361C48.2731 25.3891 47.1001 24.2161 45.6531 24.2161Z"
        fill="#BB2F2B"
      />
      <path
        d="M45.653 30.2701H20.8006C19.3537 30.2701 18.1807 31.4432 18.1807 32.8901V32.9182C18.1807 34.3652 19.3537 35.5382 20.8006 35.5382H45.653C47.1 35.5382 48.273 34.3652 48.273 32.9182V32.8901C48.273 31.4432 47.1 30.2701 45.653 30.2701Z"
        fill="#DCDFF0"
      />
      <path
        d="M45.6531 36.3242H18.5643C17.1173 36.3242 15.9443 37.4972 15.9443 38.9441V38.9722C15.9443 40.4192 17.1173 41.5922 18.5643 41.5922H45.6531C47.1 41.5922 48.273 40.4192 48.273 38.9722V38.9441C48.273 37.4972 47.1 36.3242 45.6531 36.3242Z"
        fill="#FDEEDC"
      />
      <path
        d="M45.6534 42.3782H16.347C14.9001 42.3782 13.7271 43.5512 13.7271 44.9982V45.0263C13.7271 46.4733 14.9001 47.6463 16.347 47.6463H45.6534C47.1004 47.6463 48.2734 46.4733 48.2734 45.0263V44.9982C48.2734 43.5512 47.1004 42.3782 45.6534 42.3782Z"
        fill="#D9E5D4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1116_7553">
        <rect width="62" height="62" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
