import React, { useState } from 'react'
import { Box, Container, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { img, LogoBlackIcon, LogoWhiteIcon } from '../../imgs'
import { SignupOneForm } from './signup-one.form'
import { SignupTwoForm } from './signup-two.form'
import { themeGl } from '../../configs'
import { definitionTheme } from '../../utils'

type SignupOneDataForm = { last_name: string; first_name: string; external_id: string }

export const SignupPage: React.FC = (): JSX.Element => {
  const { REACT_APP_VERSION: VERSION } = process.env

  const navigate = useNavigate()
  const classes = useStyles()

  const [stage, setStage] = useState<number>(1)
  const [dataOneForm, setDataOneForm] = useState<SignupOneDataForm>({
    last_name: '',
    first_name: '',
    external_id: '',
  })

  const onChangeStage = () => {
    if (stage === 1) setStage(2)
    else if (stage === 2) setStage(1)
  }

  const onOpenSignin = () => {
    navigate('../signin')
  }

  return (
    <Box className={classes.page}>
      <Container maxWidth="sm" sx={{ height: '100%' }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
          <Grid item xs={12} maxWidth={320}>
            <Grid container justifyContent="center">
              <Grid item>{themeGl === 'calendar' ? <LogoWhiteIcon /> : <LogoBlackIcon />}</Grid>
            </Grid>
            <Box className={classes.title}>Регистрация</Box>

            {stage === 1 && (
              <SignupOneForm
                onChangeStage={onChangeStage}
                onChangeDataForm={(data: SignupOneDataForm) => setDataOneForm(data)}
              />
            )}
            {stage === 2 && (
              <SignupTwoForm dataOneForm={dataOneForm} onChangeStage={onChangeStage} className={classes} />
            )}

            <Box className={classes.textSignin}>Уже зарегистрированы?</Box>
            <Grid container justifyContent="center">
              <Grid item>
                <Box className={classes.signin} onClick={onOpenSignin}>
                  Авторизоваться
                </Box>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" justifyContent="center" spacing="6px" mt={1}>
              <Grid item>
                <Box sx={{ fontSize: 20, color: themeGl === 'calendar' ? '#FFFFFF' : '#4A5967', height: 20 }}>*</Box>
              </Grid>
              <Grid item>
                <Box sx={{ fontSize: 11, color: themeGl === 'calendar' ? '#FFFFFF' : '#4A5967' }}>
                  необязательно для заполнения
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.version}>версия {VERSION}</Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    width: '100%',
    padding: '55px 0',
    backgroundRepeat: 'no-repeat',
    minHeight: 'calc(100vh - 110px)',
    backgroundSize: themeGl === 'calendar' ? '85vw 200vw' : '345px 341px',
    backgroundImage: definitionTheme(
      { calendar: `url(${img.bgGreetCalendar})`, test2: 'none', challenge: 'none' },
      `url(${img.bgSign})`
    ) as string,
    backgroundPosition: themeGl === 'calendar' ? 'left top' : 'bottom -40px left -120px',
    backgroundColor: themeGl === 'calendar' ? palette.background.default : palette.primary.main,
  },
  title: {
    fontSize: 24,
    fontWeight: 800,
    textAlign: 'center',
    margin: '75px 0 25px',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  textSignin: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    margin: '20px 0 5px',
    color: themeGl === 'calendar' ? palette.primary.main : '#707070',
  },
  signin: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    textDecoration: 'underline',
    '-webkit-tap-highlight-color': 'transparent',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.dark,
  },
  version: {
    opacity: 0,
    fontSize: 10,
    marginTop: 15,
    fontWeight: 400,
    userSelect: 'none',
    textAlign: 'center',
    color: palette.primary.dark,
  },
  icon: {
    width: 22,
    height: 22,
    borderRadius: 4,
    backgroundColor: '#F5F5F5',
    border: '1px solid #E5E5E5',
  },
  iconCheck: {
    width: 22,
    height: 22,
    borderRadius: 4,
    backgroundColor: palette.primary.dark,
    border: `1px solid ${palette.primary.dark}`,
  },
  check: {
    fontSize: '22px!important',
    color: `${palette.primary.main}!important`,
  },
}))
