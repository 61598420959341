import { createTheme, Theme } from '@mui/material/styles'
import palette from './palette'
import font from './font'
import { size, themeGl } from '../configs'

const {
  spacing,
  breakpoints: { up, down, values },
} = createTheme()

export const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette,
  font,
  typography: {
    fontFamily: font.primary,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundImage: 'none',
          backgroundColor: 'transparent',
        },
      },
    },
    // start - стили контейнера
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 20px!important',
          [down(350)]: {
            padding: '0 15px!important',
          },
        },
        maxWidthSm: {
          maxWidth: 430,
        },
      },
    },
    // end - стили контейнера
    // start - стили бокового меню
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: 613 / size.scale,
        },
        paper: {
          width: '100%',
          maxWidth: 613 / size.scale,
          backgroundColor: `${palette.primary.main}!important`,
          backgroundImage: 'none',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, .7)',
        },
      },
    },
    // end - стили бокового меню
    // start - стили модального окна
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 0,
          width: '100%',
          borderRadius: 24,
          padding: '30px 0',
          color: palette.text.primary,
          background: palette.primary.main,
        },
        paperFullScreen: {
          height: 'auto',
        },
        paperWidthMd: {
          maxWidth: '500px!important',
        },
        paperWidthSm: {
          maxWidth: '345px!important',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 22,
          lineHeight: 1.4,
          fontWeight: 800,
          display: 'flex',
          textAlign: 'center',
          position: 'relative',
          justifyContent: 'center',
          padding: '0 35px!important',
          backgroundColor: 'transparent',
          color: palette.primary.contrastText,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 500,
          overflow: 'hidden',
          fontFamily: font.primary,
          padding: '5px 20px!important',
          color: palette.primary.contrastText,
          '& ul': {
            textAlign: 'left',
            lineHeight: 1.4,
          },
          '& a': {
            color: palette.text.secondary,
            transition: '0.3s',
            '&:hover': {
              color: palette.text.primary,
            },
          },
        },
      },
    },
    // end - стили модального окна
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 10,
          marginTop: -2,
          marginLeft: 10,
          fontWeight: 600,
          color: palette.error.main,
        },
      },
    },
    // start - стили линейной загрузки
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
        },
      },
    },
    // end - стили линейной загрузки
    // start - стили поля ввода
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: 26 / size.scale,
          minHeight: 90 / size.scale,
          '&.MuiIconButton-root': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
        },
        input: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 17,
          minHeight: 54,
          paddingRight: 8,
          borderRadius: 12,
          borderColor: '#DDE0E3',
          fontFamily: font.primary,
          transition: '0.3s!important',
          backgroundColor: palette.primary.main,
          '& svg': {
            fill: '#878787',
            transition: '.3s',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 2,
            borderColor:
              themeGl === 'calendar' ? `${palette.primary.main}!important` : `${palette.primary.dark}!important`,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: '#DDE0E3',
          },
        },
        notchedOutline: { borderColor: '#DDE0E3' },
        input: {
          fontSize: 16,
          paddingTop: 15,
          paddingLeft: 17,
          fontWeight: 600,
          paddingBottom: 9,
          color: palette.primary.contrastText,
          '&:focus': {
            color: palette.primary.contrastText,
            '&:-internal-autofill-selected': { '-webkit-text-fill-color': '#4A5967!important' },
            '&:-webkit-autofill': { '-webkit-text-fill-color': '#4A5967!important' },
          },
          '&:-internal-autofill-selected': {
            background: 'transparent',
            '-webkit-text-fill-color': '#4A5967!important',
            '-webkit-box-shadow': 'inset 0 0 0 50px #ffffff!important',
          },
          '&:-webkit-autofill': {
            background: 'transparent',
            '-webkit-text-fill-color': '#4A5967!important',
            '-webkit-box-shadow': 'inset 0 0 0 50px #ffffff!important',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          minHeight: 90 / size.scale,
          minWidth: 573 / size.scale,
          fontFamily: font.secondary,
          color: palette.primary.dark,
          paddingRight: 16 / size.scale,
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
          '&:before': {
            height: 0,
          },
          '&.Mui-focused': {
            backgroundColor: palette.primary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.primary.main,
          },
        },
        input: {
          fontWeight: 400,
          fontSize: 26 / size.scale,
          color: palette.primary.dark,
          transform: 'translate(0px, 45%)',
          padding: `0 ${24 / size.scale}px!important`,
          '&:-internal-autofill-selected': {
            background: 'transparent',
            '-webkit-box-shadow': 'inset 0 0 0 50px #FFFFFF!important',
            '-webkit-text-fill-color': '#000000!important',
          },
          '&:-webkit-autofill': {
            background: 'transparent',
            '-webkit-box-shadow': 'inset 0 0 0 50px #FFFFFF!important',
            '-webkit-text-fill-color': '#000000!important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
        filled: {
          fontWeight: 500,
          color: palette.primary.dark,
          backgroundColor: palette.primary.main,
          '&.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
          '&.MuiInputLabel-shrink': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
        },
        outlined: {
          fontWeight: 500,
          borderRadius: 50,
          color: 'rgba(40, 40, 40, 0.6)',
          // padding: themeGl === 'calendar' ? '0 7px' : 0,
          // background: 'linear-gradient(0deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%)',
          '&.Mui-focused': { color: 'rgba(40, 40, 40, 0.6)' },
          '&.MuiInputLabel-shrink': {
            fontSize: themeGl === 'calendar' ? 11 : 12,
            transform: themeGl === 'calendar' ? 'translate(17px, 1%)' : 'translate(17px, -50%)',
          },
        },
      },
    },
    // end - стили поля ввода
    // start - стили поля со списком
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: 26 / size.scale,
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
    // end - стили поля со списком
    // start - стили поля с автодополнением
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: '#000000!important',
          '& .MuiFilledInput-input': {
            transform: 'translate(0px, 20%)',
            paddingLeft: '5px!important',
          },
        },
        listbox: {
          backgroundColor: palette.primary.main,
        },
        endAdornment: {
          marginTop: 4,
          marginRight: 4,
          transition: '0.3s',
          '& .MuiSvgIcon-root': {
            fontSize: 18,
            fill: 'rgba(0, 0, 0, 0.6)!important',
          },
        },
        inputRoot: {
          paddingTop: 10,
          paddingRight: 30,
        },
        paper: {
          marginLeft: -0.5,
          width: 573 / size.scale,
          backgroundColor: palette.primary.main,
        },
        loading: {
          fontSize: 12,
          fontWeight: 500,
          color: palette.primary.dark,
        },
        noOptions: {
          fontSize: 12,
          fontWeight: 500,
          color: palette.primary.dark,
        },
      },
    },
    // end - стили поля с автодополнением
    // start - стили кнопки
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          fontSize: 18,
          minHeight: 54,
          minWidth: 280,
          fontWeight: 700,
          borderRadius: 60,
          textTransform: 'none',
          boxShadow: 'none!important',
          color: palette.primary.dark,
          backgroundColor: palette.primary.main,
          '&:hover': {
            color: palette.primary.dark,
            backgroundColor: palette.primary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: `${palette.primary.main}!important`,
            color: `${palette.action.disabled}!important`,
          },
        },
        containedSecondary: {
          fontSize: 18,
          minHeight: 54,
          minWidth: 280,
          fontWeight: 700,
          borderRadius: 60,
          textTransform: 'none',
          boxShadow: 'none!important',
          color: palette.secondary.dark,
          backgroundColor: palette.secondary.main,
          '&:hover': {
            color: palette.secondary.dark,
            backgroundColor: palette.secondary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: `${palette.secondary.main}!important`,
            color: `${palette.action.disabled}!important`,
          },
        },
        outlinedSecondary: {
          fontSize: 18,
          minHeight: 54,
          minWidth: 280,
          fontWeight: 700,
          borderRadius: 60,
          textTransform: 'none',
          boxShadow: 'none!important',
          color: palette.text.primary,
          // '&:hover': {
          //   color: palette.secondary.dark,
          //   backgroundColor: palette.secondary.main,
          // },
          '&.Mui-disabled': {
            backgroundColor: `${palette.secondary.main}!important`,
            color: `${palette.action.disabled}!important`,
          },
        },
      },
    },
    // end - стили кнопки
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 5,
          marginLeft: -9,
          '& .MuiTypography-root': {
            fontWeight: 500,
            lineHeight: 1.2,
            fontSize: '14px!important',
            color: themeGl === 'calendar' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.9)',
            '&.Mui-disabled': {
              color: 'rgba(74, 89, 103, 0.8)!important',
            },
            '& a': {
              transition: '0.2s',
              fontWeight: '700!important',
              textDecoration: 'uppercase!important',
              color: `${themeGl === 'calendar' ? '#FFFFFF' : palette.primary.dark}!important`,
              '&:hover': {
                color: `${themeGl === 'calendar' ? '#FFFFFF' : palette.primary.dark}!important`,
              },
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {},
        paper: {
          padding: 15,
          fontSize: 14,
          fontWeight: 700,
          maxWidth: 300,
          lineHeight: 1.4,
          borderRadius: 8,
          backgroundColor: '#FFFFFF',
          backgroundImage: 'none',
          color: palette.text.primary,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          minWidth: 570 / size.scale,
          borderRadius: 0,
          marginLeft: 3,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          width: 570 / size.scale,
          borderRadius: 0,
          background: palette.primary.main,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          transition: '0.1s',
          fontSize: `${22 / size.scale}px!important`,
          fontFamily: `${font.secondary}!important`,
          color: `${palette.primary.dark}!important`,
          backgroundColor: 'transparent!important',
          '&.Mui-selected': {
            backgroundColor: 'rgb(0, 0, 0, 0.2)!important',
          },
          '&:hover': {
            backgroundColor: 'transparent!important',
          },
        },
      },
    },
  },
})
