import { Box, Button } from '@mui/material'
import React from 'react'
import { colorTheme, str } from '../utils'
import { themeGl } from '../configs'

export const UpdateApp = () => (
  <Box>
    <Box textAlign="center" mb="10px">
      Доступны новые обновления.
    </Box>
    <Box display="flex" justifyContent="center">
      <Button
        style={{
          fontSize: 10,
          fontWeight: 700,
          borderRadius: 12,
          color: colorTheme,
          padding: '3px 20px',
          backgroundColor: '#fff',
          border: '1px solid #fff',
        }}
      >
        Обновить
      </Button>
    </Box>
  </Box>
)

export const checkUpdateApp = () => (
  <Box>
    <Box textAlign="center" mb="10px">
      Проверьте доступные обновления.
    </Box>
    <Box display="flex" justifyContent="center">
      <Button
        style={{
          fontSize: 10,
          fontWeight: 700,
          borderRadius: 12,
          color: colorTheme,
          padding: '3px 20px',
          backgroundColor: '#fff',
          border: '1px solid #fff',
        }}
      >
        Проверить
      </Button>
    </Box>
  </Box>
)

export const SuccessApp = () => <Box textAlign="center">Обновления успешно установлено.</Box>

export const ErrorNetwork = () => (
  <Box>Интернет-соединение не найдено. Проверьте подключение вашего устройства к сети.</Box>
)

export const ErrorForm = ({ alert }: { alert: string[] }) => (
  <Box>
    {alert.map((k: string, i: number) => (
      <Box key={`alert-message-${i}`}>{str.normalize(k, true)}</Box>
    ))}
  </Box>
)
