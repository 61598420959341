/* eslint-disable max-len */
import { definitionTheme } from '../../../../utils'

const color = definitionTheme({
  marathon: '#B786B9',
  test: '#BB2F2B',
  calendar: '#006266',
  test2: '#5A3624',
  challenge: '#E47E3D',
}) as string

export const ProgressDrawerIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5074 20.3622L31.4977 20.3602C31.5594 20.0075 31.6071 19.6532 31.6409 19.2983C31.8617 16.9777 31.4879 14.6262 30.5376 12.4711C29.2894 9.64069 27.1202 7.31587 24.3828 5.8749C21.6455 4.43392 18.5011 3.9616 15.4612 4.53476C12.4213 5.10791 9.66478 6.69283 7.6401 9.03166C5.61543 11.3705 4.44172 14.3256 4.30993 17.4163C4.17814 20.5069 5.09603 23.5512 6.91426 26.0539C8.73249 28.5566 11.3441 30.3704 14.3241 31.2003C16.5931 31.8321 18.9739 31.8652 21.2389 31.3142C21.8965 31.1543 22.5443 30.9451 23.1767 30.687C25.2651 29.8294 27.0427 28.4828 28.4949 26.7232C29.9482 24.9623 30.9741 22.8344 31.4617 20.5743L31.5074 20.3622ZM19.8742 28.0604C19.9861 28.5381 19.6819 28.9947 19.2231 29.0455C17.8224 29.2006 16.3952 29.089 15.0188 28.7057C12.6019 28.0327 10.4838 26.5616 9.0092 24.5319C7.53457 22.5022 6.79015 20.0332 6.89703 17.5266C7.00391 15.02 7.95582 12.6233 9.59788 10.7265C11.2399 8.82962 13.4755 7.54422 15.941 7.07937C18.4064 6.61453 20.9566 6.99759 23.1766 8.16626C25.3967 9.33492 27.1559 11.2204 28.1683 13.516C28.7353 14.8019 29.0493 16.1742 29.1043 17.5593C29.1234 18.0395 28.6836 18.4129 28.1767 18.3373L19.1014 16.9843C18.1969 16.8494 17.4414 17.6695 17.6499 18.56L19.8742 28.0604Z"
      fill={color}
    />
  </svg>
)
