import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { themeGl } from '../../configs'

export const useStylesTest2 = makeStyles(({ palette }: Theme) => ({
  page: {
    padding: '30px 0 90px',
    color: palette.secondary.main,
  },
  title: {
    fontSize: 24,
    maxWidth: 200,
    fontWeight: 700,
  },
  imgResult: {
    width: '100%',
    margin: '30px 0 20px',
    '& img': { width: '100%' },
  },
  titleResult: {
    fontSize: 18,
    fontWeight: 700,
    margin: '20px 0 10px',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  textResult: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 10,
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
    '& a': { color: `${themeGl === 'calendar' ? palette.primary.main : palette.primary.dark}!important` },
  },
}))
