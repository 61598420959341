/* eslint-disable max-len */
export const TaskIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="#B6BCC2" stroke="#B6BCC2" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="8" r="3" />
    <rect x="13.5" y="6.5" width="15" height="3" rx="1.5" />
    <circle cx="7.5" cy="16" r="2.25" strokeWidth="1.5" />
    <rect x="13.5" y="14.5" width="15" height="3" rx="1.5" />
    <circle cx="7.5" cy="24" r="2.25" strokeWidth="1.5" />
    <rect x="13.5" y="22.5" width="15" height="3" rx="1.5" />
  </svg>
)
