import { NumericFormat, NumericFormatProps } from 'react-number-format'
import React, { forwardRef, useCallback } from 'react'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { GenericTextInput } from './generic-text-input'
import { autocompleteInputOffProps } from './props-autocomplete'

type Props = {
  form: UseFormReturn<any>
  name: string
  label?: string
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  disabled?: boolean
  required?: boolean
  focused?: boolean
  options?: RegisterOptions
  inputProps?: any
  multiline?: boolean
  rows?: number
  onChange?: (e: any) => void
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  value: string
  name: string
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, value, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      defaultValue={value}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isAllowed={({ formattedValue, floatValue = 0 }) => {
        return formattedValue === '' || floatValue <= 1000
      }}
      valueIsNumericString
      suffix=" см"
    />
  )
})

export const NumberFormatInput: React.FC<Props> = ({
  form,
  name,
  inputProps,
  rows = 1,
  label,
  onChange = () => {},
  placeholder,
  disabled = false,
  required = true,
  focused,
  options,
  multiline = false,
  variant = 'outlined',
}): JSX.Element => {
  const { register, formState, getValues } = form

  const NumericFormatCallback = useCallback(
    (props: any, context: any) => <NumericFormatCustom {...props} {...context} name={name} value={getValues(name)} />,
    []
  )

  return (
    <GenericTextInput
      InputProps={{ inputComponent: NumericFormatCallback, ...(autocompleteInputOffProps as any), ...inputProps }}
      register={register(name, { required, ...options })}
      helperText={formState.errors[name]?.message}
      placeholder={placeholder}
      multiline={multiline}
      disabled={disabled}
      onChange={onChange}
      variant={variant}
      focused={focused}
      label={label}
      rows={rows}
    />
  )
}
