/* eslint-disable max-len */
import { colorTheme, definitionTheme } from '../../utils'

export const MoreBackIcon = () => (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="8" transform="matrix(-1 0 0 1 28 0.5)" fill="#EFF2F3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 8.79289C10.9024 9.18342 10.9024 9.81658 11.2929 10.2071L15.5858 14.5L11.2929 18.7929C10.9024 19.1834 10.9024 19.8166 11.2929 20.2071C11.6834 20.5976 12.3166 20.5976 12.7071 20.2071L17.7071 15.2071C18.0976 14.8166 18.0976 14.1834 17.7071 13.7929L12.7071 8.79289C12.3166 8.40237 11.6834 8.40237 11.2929 8.79289Z"
      fill={definitionTheme({ challenge: '#DB580F' }, colorTheme) as string}
    />
  </svg>
)
