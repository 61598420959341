import React from 'react'
import { Box, Container, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { keyframes } from '@mui/system'
import { Layout } from '../../components'
import { img } from '../../imgs'
import { useReduxSelector } from '../../hooks'
import { themeGl } from '../../configs'

export const ProgressPage: React.FC = (): JSX.Element => {
  const classes = useStyles()

  const animateProgress = keyframes`0% { height: 0; }`

  const animation = `${animateProgress} 1s ease-in-out`

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { progress = { value: 0 } } = userData || {}

  return (
    <Layout>
      <Box className={classes.page}>
        <Box className={classes.progress}>
          <Box className={classes.title}>
            Прогресс прохождения
            <br />
            марафона
          </Box>
          <Grid container justifyContent="center">
            <Grid item>
              <Box className={classes.percentContainer}>
                <Box height={370 * (progress.value / 100)} sx={{ animation }} className={classes.bgPercent} />
                <Box className={classes.percent}>
                  <Grid container justifyContent="center" alignItems="center" height="100%">
                    <Grid item>
                      <Box mt="60px">{progress.value.toFixed(0)}%</Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.content}>
          <Container maxWidth="sm">
            <Box>Постарайтесь наполнить свой флакон на 100% за всё время марафона.</Box>
            <Box mt="15px">У вас обязательно всё получится!</Box>
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {},
  progress: {
    height: 630,
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: `url(${img.bgProgress})`,
  },
  title: {
    fontSize: 20,
    paddingTop: 30,
    fontWeight: 700,
    textAlign: 'center',
  },
  content: {
    fontSize: 15,
    marginTop: -50,
    fontWeight: 600,
    padding: '20px 0 100px',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main,
  },
  percentContainer: {
    width: 160,
    height: 370,
    marginTop: 60,
    position: 'relative',
  },
  bgPercent: {
    bottom: 0,
    width: 160,
    transition: '0.5s',
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundImage: `url(${img.bgPercentProgress})`,
  },
  percent: {
    bottom: 0,
    width: 160,
    height: 370,
    fontSize: 42,
    fontWeight: 700,
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img.percentProgress})`,
  },
}))
