import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { SubmitButton, formLocale, EmailInput } from '../../components'
import { FormProps } from '../../types'
import { forgotPasswordUser } from '../../api'
import { useReduxActions, useReduxSelector } from '../../hooks'
import { errorFormToast } from '../../toast'

type ForgotProps = { email: string }

const schema = yup.object().shape({
  email: yup.string().required().email(),
})

export const ForgotForm: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const { serverError } = formLocale

  const { data: constants } = useReduxSelector((state) => state.constants)

  const hookForm = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<ForgotProps>>({
    data: { email: '' },
    processed: false,
  })

  const onRecaptchaChange = async (recaptcha: string) => {
    const { email = '' } = formProps.data || {}

    forgotPasswordUser({ email, recaptcha })
      .then((res) => {
        setFormProps({ ...formProps, processed: false })

        if (res.status === 1) {
          setAlert({
            alert: {
              message: {
                info: [
                  'Уважаемый участник!<br />На указанный E‑mail отправлена ссылка для восстановления пароля' +
                    '<br /><br />Для восстановления пароля перейдите по ссылке из письма.',
                ],
              },
              onClose: () => {
                navigate('../greet')
              },
            },
          })
        } else {
          errorFormToast(res.message)
        }
      })
      .catch((err) => {
        setFormProps({ ...formProps, processed: false })

        if (err.response.data.status === -1 || err.response.data.status === 0) {
          errorFormToast(err.response.message)
        } else {
          errorFormToast(serverError)
        }
      })
      .finally(() => {
        recaptchaRef.current?.reset()
      })
  }

  const onRecaptchaExpired = () => {
    setFormProps({ ...formProps, processed: false })
  }

  const onSubmitForm = async (data: ForgotProps) => {
    setFormProps({ ...formProps, data, processed: true })
    recaptchaRef.current?.execute()
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      {constants?.CAPTCHA_SITEKEY && (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={constants?.CAPTCHA_SITEKEY}
          onChange={(token) => onRecaptchaChange(token ?? '')}
          onErrored={() => console.error('onRecaptchaErrored')}
          onExpired={onRecaptchaExpired}
        />
      )}

      <Box width="100%" height={72}>
        <EmailInput variant="outlined" form={hookForm} name="email" disabled={formProps.processed} />
      </Box>
      <Box width="100%" mt="30px">
        <SubmitButton
          fullWidth
          title="Восстановить пароль"
          disabled={formProps.processed}
          processed={formProps.processed}
        />
      </Box>
    </form>
  )
}
