/* eslint-disable max-len */
export const MenuIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="#B6BCC2" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="5.5" width="5" height="5" rx="2.5" />
    <rect x="6" y="13.5" width="5" height="5" rx="2.5" />
    <rect x="6" y="21.5" width="5" height="5" rx="2.5" />
    <rect x="14" y="5.5" width="5" height="5" rx="2.5" />
    <rect x="14" y="13.5" width="5" height="5" rx="2.5" />
    <rect x="14" y="21.5" width="5" height="5" rx="2.5" />
    <rect x="22" y="5.5" width="5" height="5" rx="2.5" />
    <rect x="22" y="13.5" width="5" height="5" rx="2.5" />
    <rect x="22" y="21.5" width="5" height="5" rx="2.5" />
  </svg>
)
