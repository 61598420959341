import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { definitionTheme } from '../../utils'

export const useStylesActivity = makeStyles(({ palette }: Theme) => ({
  page: { color: definitionTheme({ challenge: palette.warning.main }, palette.secondary.main) as string },
  card: {
    height: 160,
    width: '100%',
    marginBottom: 8,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '12px',
    '&::after': {
      left: 0,
      bottom: 0,
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: 'linear-gradient(360deg, #1C1C1C 0%, rgba(28, 28, 28, 0) 73.08%)',
    },
  },
}))
