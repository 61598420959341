import React, { FC, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Button, Container, Grid, Link, Theme } from '@mui/material'
import moment from 'moment/moment'
import { Layout, TgLink } from '../../components'
import { img } from '../../imgs'
import { ParticipateModal } from './participate.modal'
import { colorTheme, definitionTheme } from '../../utils'
import { TargetModal } from './target.modal'
import { getTarget, trackerCalendar2024, trackerStart2024 } from '../../api'
import { Target } from '../../types/target'
import { MyTargetModal } from './my-target.modal'
import { useReduxActions, useReduxDispatch, useReduxSelector } from '../../hooks'
import { getUser } from '../../store/auth/auth.service'
import { TrackerChallenge } from './tracker-challenge'
import { Calendar } from '../../types'
import { CalendarModal } from '../main/modals'
import { dateConfig } from '../../configs'

const { REACT_APP_API_LINK: API_LINK, REACT_APP_START_DATE_CHALLENGE: START_CHALLENGE } = process.env

export const ChallengePage: FC = () => {
  const classes = useStylesChallenge()
  const { setAlert } = useReduxActions()
  const dispatch = useReduxDispatch()

  const { data: constants } = useReduxSelector((state) => state.constants)
  const { data: user } = useReduxSelector((state) => state.auth)

  const { NOW = 0 } = constants || {}

  const [upload, setUpload] = useState<boolean>(false)
  const [startProcess, setStartProcess] = useState<boolean>(false)
  const [participate, setParticipate] = useState<boolean>(false)
  const [target, setTarget] = useState<boolean>(false)
  const [myTarget, setMyTarget] = useState<boolean>(false)
  const [startChallenge, setStartChallenge] = useState<boolean>(false)
  const [endChallenge, setEndChallenge] = useState<boolean>(false)
  const [targetList, setTargetList] = useState<Target[]>([])
  const [selectedTarget, setSelectedTarget] = useState<Target | undefined>(undefined)
  const [calendarModal, setCalendarModal] = useState<boolean>(false)
  const [calendarList, setCalendarList] = useState<Calendar[]>([])
  const [calendarNow, setCalendarNow] = useState<Calendar>({
    date_at: '',
    num: 0,
    now: false,
    result: {
      all: 0,
      progress: 0,
      completed: 0,
    },
  })

  const onOpenParticipateModal = () => setParticipate((prev) => !prev)

  const onOpenTargetModal = () => setTarget((prev) => !prev)

  const onOpenMyTargetModal = () => setMyTarget((prev) => !prev)

  const onSelectTarget = (t: number) => {
    setSelectedTarget(targetList.find((k: Target) => k.id === t))
    onOpenMyTargetModal()
  }

  const onSaveTarget = () => {
    onOpenParticipateModal()
    onOpenTargetModal()
    onOpenMyTargetModal()
    dispatch(getUser()).then(() => {
      setStartChallenge(false)
      setEndChallenge(false)
    })
  }

  const onStartChallenge = () => {
    setStartProcess(true)

    trackerStart2024().then(({ status }) => {
      setStartProcess(false)
      uploadCalendar()

      if (status !== 1) {
        setAlert({
          alert: {
            title: 'Старт челленджа',
            message: { info: [`${status === 1 ? 'Вы успешно начали Челлендж!' : 'Челлендж не начат!'}`] },
            onClose: () => {
              uploadUserInfo()
            },
          },
        })
      }
    })
  }

  const onSelectDay = (currentDay: Calendar) => {
    setCalendarNow(currentDay)
    setCalendarModal(false)
  }

  const uploadTarget = useCallback(() => {
    getTarget().then((res) => {
      setTargetList(res.data)
    })
  }, [])

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
  }, [])

  const uploadCalendar = useCallback(() => {
    trackerCalendar2024().then((res) => {
      if (res.status === 1) {
        const calendar: Calendar[] = Object.values(res.data)
        const now = calendar.find((k: Calendar) => k.now)

        setCalendarList(calendar)
        setCalendarNow(now as Calendar)

        if (calendar.length > 0) setStartChallenge(true)

        if (user?.target_current) {
          if (!now && moment(user?.target_current?.created_at).isBefore(calendar[0].date_at)) {
            setEndChallenge(!now)
          } else if (!now && moment(user?.target_current?.created_at).isAfter(calendar[0].date_at)) {
            setStartChallenge(false)
          }
        }

        setUpload(true)
      }
    })
  }, [])

  const updateCalendar = useCallback((now: Calendar) => {
    trackerCalendar2024().then((res) => {
      if (res.status === 1) {
        setCalendarList(res.data)
        setCalendarNow(res.data.find((k: Calendar) => k.num === now.num))
      }
    })
  }, [])

  useEffect(() => {
    uploadCalendar()
    uploadTarget()
  }, [])

  return (
    <Layout>
      <ParticipateModal
        open={participate}
        className={classes}
        openTarget={onOpenTargetModal}
        onClose={onOpenParticipateModal}
      />

      {targetList.length > 0 && (
        <TargetModal
          open={target}
          className={classes}
          targetList={targetList}
          onSelect={onSelectTarget}
          onClose={onOpenTargetModal}
        />
      )}

      {selectedTarget && (
        <MyTargetModal
          open={myTarget}
          className={classes}
          target={selectedTarget}
          onSaveTarget={onSaveTarget}
          onClose={onOpenMyTargetModal}
        />
      )}

      {startChallenge ? (
        <>
          <CalendarModal
            show={calendarModal}
            calendarList={calendarList}
            onClose={() => setCalendarModal(false)}
            onSelectDay={(currentDay: Calendar) => {
              onSelectDay(currentDay)
              setCalendarModal(false)
            }}
          />

          {endChallenge ? (
            <Container maxWidth="md">
              <Grid container direction="column" justifyContent="space-between" mt="15vh" height="70vh">
                <Grid item>
                  <Box color="warning.main" fontWeight={700} textAlign="center">
                    <Box mb="10px">Поздравляем!</Box>
                    <Box mb="10px">Вы прошли 30-дневный Челлендж</Box>«Метаболическое здоровье с dōTERRA MetaPWR»!
                  </Box>
                  <Box mt="5vh" color="text.primary" fontSize={18} fontWeight={700} textAlign="center">
                    Надеемся, вы ощутили на себе суперсилу продуктов MetaPWR, которые помогли сделать вашу жизнь ярче и
                    энергичнее!
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    disabled={startProcess}
                    onClick={onOpenParticipateModal}
                  >
                    Пройти челлендж еще раз
                  </Button>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <TrackerChallenge
              calendarNow={calendarNow}
              updateCalendar={() => updateCalendar(calendarNow)}
              onOpenCalendar={() => setCalendarModal(true)}
            />
          )}
        </>
      ) : (
        upload && (
          <Box className={classes.page}>
            {user?.target_current === null ? (
              <Container
                maxWidth="sm"
                sx={({ breakpoints: { down } }: Theme) => ({
                  pb: '100px!important',
                  [down(350)]: { pb: '100px!important' },
                })}
              >
                <Grid container spacing={5} direction="column" minHeight="100vh" justifyContent="space-between">
                  <Grid item>
                    <Box className={classes.title}>О Челлендже</Box>
                    <img src={img.startChallenge} alt="challenge" className={classes.img} />
                    <Box className={classes.text}>
                      30-дневный Челлендж «Метаболическое здоровье с dōTERRA MetaPWR» поможет вывести самочувствие на
                      новый уровень!
                      <Box mt="10px">
                        30 простых уроков здорового образа жизни и 30 ежедневных заданий, сфокусированных на питании,
                        пищеварении, физической активности и метаболизме сделают вашу жизнь ярче и активнее.
                      </Box>
                      <Box
                        hidden={
                          dateConfig.challengeDateStart >= moment(NOW).valueOf() &&
                          moment(NOW).valueOf() >= dateConfig.challengeDateOpen
                        }
                      >
                        <Box my="10px" fontWeight={600}>
                          Meta-новости!
                        </Box>
                        1 сентября стартует Розыгрыш призов в Челлендже!
                        <br />
                        Закончите Челлендж не позднее 30-го сентября со 100% прогрессом – и получите шанс выиграть
                        ГОДОВОЙ ЗАПАС системы MetaPWR! Если вы УЖЕ закончили или закончите Челлендж раньше с тем же
                        прогрессом* – автоматически попадете в список участников ;) Челлендж можно проходить столько
                        раз, сколько хочется, до 1 июня 2025 года – все ради настройки метаболического здоровья!
                        <br />
                        <br />
                        *с максимальным результатом – 100%
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      disabled={startProcess}
                      onClick={onOpenParticipateModal}
                    >
                      Начать
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            ) : (
              <Container maxWidth="sm">
                {moment(NOW).isAfter(moment(Number(START_CHALLENGE))) ? (
                  <>
                    <Box className={classes.title}>Отлично! Теперь вы можете выполнять задания Челленджа!</Box>
                    {dateConfig.challengeDateStart >= moment(NOW).valueOf() ? (
                      <Box className={classes.text} mt="20px" mb="30px" lineHeight="1.4">
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ fontSize: '120%' }}>1.</span>{' '}
                          <Link
                            target="_blank"
                            rel="noreferrer noopener"
                            href={`${API_LINK}/media/doc/products_challenge`}
                          >
                            Прочитайте полезные материалы
                          </Link>{' '}
                          о продуктах, участвующих в Челлендже и оформите заказ.
                        </Box>
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ fontSize: '120%' }}>2.</span>{' '}
                          <Link
                            href="https://doterratraining.ru/30-day-support"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Зарегистрируйтесь
                          </Link>{' '}
                          в Челлендже на платформе Get Course.
                        </Box>
                        <Box mb="10px">
                          <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>3</span>. В течение
                          30-дневного Челленджа вам потребуется отслеживать прогресс. Каждый день отмечайте пункты
                          трекера и делайте краткую запись в дневнике о том, как прошел день. Еженедельно отмечайте в
                          приложении обмеры тела и состояние самочувствия.
                        </Box>
                        <Box mb="10px">
                          <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>4</span>. Не забудьте
                          настроить напоминания чат-бота для того, чтобы не пропустить прием MetaPWR и LLV, а также
                          задания.
                        </Box>
                        {/* <Box> */}
                        {/* eslint-disable-next-line max-len */}
                        {/*  <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>5</span>. Набрав в Челлендже */}
                        {/* eslint-disable-next-line max-len */}
                        {/*  с 5 июня по 4 июля 100% общего прогресса, вы становитесь участником розыгрыша призов – выиграйте */}
                        {/*  годовой запас системы dōTERRA MetaPWR! */}
                        {/* </Box> */}
                      </Box>
                    ) : (
                      <Box className={classes.text} mt="20px" mb="30px" lineHeight="1.4">
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ fontSize: '120%' }}>1.</span>{' '}
                          <Link
                            target="_blank"
                            rel="noreferrer noopener"
                            href={`${API_LINK}/media/doc/products_challenge`}
                            sx={{ color: '#004438', textDecorationColor: '#004438' }}
                          >
                            Прочитайте о продуктах Челленджа
                          </Link>{' '}
                          и о том,{' '}
                          <Link
                            target="_blank"
                            rel="noreferrer noopener"
                            href={`${API_LINK}/media/doc/how_go_challenge`}
                            sx={{ color: '#004438', textDecorationColor: '#004438' }}
                          >
                            как он проходит
                          </Link>
                          .
                        </Box>
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ fontSize: '120%' }}>2.</span> Закажите нужные продукты.
                        </Box>
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>3.</span>{' '}
                          Зарегистрируйтесь в Челлендже на GetCourse – авторизуйтесь на платформе и нажмите{' '}
                          <Link
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://doterratraining.ru/30-day-support"
                            sx={{ color: '#004438', textDecorationColor: '#004438' }}
                          >
                            тут
                          </Link>{' '}
                          на кнопку «Вступить в челлендж».
                        </Box>
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>4.</span> Не забудьте
                          настроить напоминания чат-бота, чтобы не пропустить прием MetaPWR и LLV, а также уроки и
                          задания.
                        </Box>
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>5.</span> Ищите уроки в
                          личном кабинете на GetCourse, проходите и выполняйте задания, указанные в pdf к каждому уроку.
                          Мы дублируем ссылки на нужные уроки в ежедневном трекере!
                        </Box>
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>6.</span> В течение 30
                          дней отслеживайте прогресс. Каждый день фиксируйте в приложении пункты трекера и делайте
                          краткую запись в дневнике о том, как прошел день.
                        </Box>
                        <Box className={classes.linkTarget} mb="10px">
                          <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>7.</span> Еженедельно
                          фиксируйте замеры в приложении – об этом вам напомнят задания GetCourse.
                        </Box>
                        {dateConfig.challengeDateOpen >= moment(NOW).valueOf() && (
                          <Box className={classes.linkTarget} mb="10px">
                            <span style={{ position: 'relative', bottom: 2, fontSize: '110%' }}>8.</span> Набрав в
                            Челлендже до 30 сентября 100% общего прогресса, вы становитесь участником розыгрыша годового
                            запаса системы MetaPWR!Если вы УЖЕ закончили или закончите Челлендж раньше со 100%
                            прогрессом – автоматически попадете в список участников розыгрыша.
                          </Box>
                        )}
                      </Box>
                    )}

                    {!user?.notify_need && (
                      <Box mb="35px">
                        <TgLink />
                      </Box>
                    )}
                    <Box mb="80px">
                      <Button fullWidth variant="contained" color="secondary" onClick={onStartChallenge}>
                        Продолжить
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box className={classes.title}>Отлично! Теперь вы можете подготовиться к Челленджу!</Box>
                    <Box className={classes.text} my="30px" lineHeight="1.4">
                      <Box className={classes.linkTarget} mb="20px">
                        <span style={{ fontSize: '125%' }}>1.</span> Прочитайте полезные материалы о самом{' '}
                        <Link href={`${API_LINK}/media/doc/how_go_challenge`} target="_blank" rel="noreferrer noopener">
                          Челлендже
                        </Link>{' '}
                        и{' '}
                        <Link
                          href={`${API_LINK}/media/doc/products_challenge`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          продуктах
                        </Link>
                        , участвующих в нём. Оформите заказ.
                      </Box>
                      <Box className={classes.linkTarget}>
                        <span style={{ fontSize: '125%' }}>2.</span> Зарегистрируйтесь в Челлендже на{' '}
                        <Link
                          href="https://doterratraining.ru/30-day-support"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          платформе Get Course.
                        </Link>
                      </Box>
                    </Box>
                    <Box mb="115px">
                      <Box textAlign="center" className={classes.targetWarning}>
                        <Box mb="10px">
                          Будьте готовы стартовать
                          <br />с нами 1 июня!
                        </Box>
                        Ждите уведомлений
                        <br />о старте Челленджа.
                      </Box>
                      {!user?.notify_need && (
                        <Box mt="50px">
                          <TgLink />
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Container>
            )}
          </Box>
        )
      )}
    </Layout>
  )
}

export const useStylesChallenge = makeStyles(({ palette }: Theme) => ({
  page: {
    padding: '20px 0',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: palette.warning.main,
  },
  img: {
    width: '100%',
    margin: '15px 0 20px',
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    color: palette.text.primary,
  },
  link: {
    '& a': {
      fontSize: 17,
      fontWeight: 700,
      cursor: 'pointer',
      color: colorTheme,
      textDecoration: 'underline',
      '&:hover': { color: `${colorTheme}!important` },
    },
  },
  linkTarget: {
    '& a': {
      fontSize: 16,
      fontWeight: 700,
      cursor: 'pointer',
      color: palette.text.primary,
      textDecoration: 'underline',
      '&:hover': { color: `${palette.text.primary}!important` },
    },
  },
  target: {
    lineHeight: 1.4,
    color: definitionTheme(
      { calendar: palette.primary.main, challenge: palette.warning.main },
      palette.text.primary
    ) as string,
  },
  targetWarning: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.4,
    padding: '12px 0',
    borderRadius: '18px',
    backgroundColor: '#F1F1F1',
    color: definitionTheme({ challenge: palette.warning.main }, colorTheme) as string,
  },
}))
