/* eslint-disable max-len */
import { definitionTheme } from '../../../../utils'

const color = definitionTheme({
  marathon: '#B786B9',
  test: '#BB2F2B',
  calendar: '#006266',
  test2: '#5A3624',
  challenge: '#E47E3D',
}) as string

export const HabitIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.6597 28.3906C27.3495 32.6248 22.4255 33.6822 18.2315 31.631C14.4558 28.8131 12.8591 23.772 15.1693 19.5378C17.4795 15.3035 22.7661 14.6903 25.1335 18.9808C30.3732 19.1153 31.9699 24.1564 29.6597 28.3906Z"
      fill={color}
    />
    <path
      d="M5.35837 6.3623C5.29516 5.79038 5.75744 5.29159 6.3507 5.29159H18.9106C19.5039 5.29159 19.9661 5.79038 19.9029 6.36231L19.0661 13.935C19.2387 13.9129 19.4119 13.8958 19.5857 13.8838C20.0842 13.8496 20.583 13.8583 21.0763 13.9102L21.8876 6.56848C22.0772 4.85269 20.6904 3.35632 18.9106 3.35632H6.3507C4.57091 3.35632 3.18409 4.85269 3.37369 6.56847L5.86069 29.0738C6.0237 30.5489 7.3076 31.6674 8.8377 31.6674H14.2541C11.2887 28.6989 9.8799 24.4778 11.3075 20.4281H6.91274L5.35837 6.3623Z"
      fill={color}
    />
    <path
      d="M25.745 18.342C25.683 18.4067 25.724 18.5159 25.8134 18.5211C26.7997 18.5786 28.6052 18.6368 29.9648 17.9035C31.3715 17.1447 32.1601 16.1542 32.6153 15.2907C32.6475 15.2296 32.611 15.1559 32.5428 15.1453C31.4224 14.9716 29.509 15.0689 28.6407 15.5008C27.7905 15.9235 26.6113 17.4377 25.745 18.342Z"
      fill={color}
    />
  </svg>
)
