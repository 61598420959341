import React, { useEffect, useState } from 'react'
import { Container, Grid, Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LogoWhiteIcon } from '../../imgs'

export const DesktopPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const [text, setText] = useState<boolean>(false)

  useEffect(() => setText(true), [])

  return (
    <Box className={classes.page}>
      <Container maxWidth="md" sx={{ height: '100%' }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Grid item>
            {text && (
              <>
                <Grid container justifyContent="center">
                  <Grid item>
                    <LogoWhiteIcon />
                  </Grid>
                </Grid>
                <Box className={classes.text}>
                  Открой приложение в браузере и установи на Android или IOS устройстве
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    height: '100vh',
  },
  text: {
    fontSize: 20,
    marginTop: 30,
    fontWeight: 500,
    textAlign: 'center',
  },
}))
