/* eslint-disable max-len */
import { FC, JSX } from 'react'
import { Box } from '@mui/material'
import { colorTheme, definitionTheme } from '../../../utils'

export const NoCheckIcon: FC = (): JSX.Element => (
  <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 15.3455C16 19.2581 12.6816 22.5 8.5 22.5C4.31836 22.5 1 19.2581 1 15.3455C1 14.7192 1.22488 13.8446 1.67377 12.768C2.11446 11.7111 2.73514 10.5426 3.44413 9.35497C4.8615 6.98077 6.58612 4.60568 7.79658 3.01584C8.15968 2.53893 8.84032 2.53893 9.20342 3.01583C10.4139 4.60568 12.1385 6.98077 13.5559 9.35497C14.2649 10.5426 14.8855 11.7111 15.3262 12.768C15.7751 13.8446 16 14.7192 16 15.3455Z"
      stroke="#DB580F"
      strokeWidth="2"
    />
  </svg>
)

export const NoCheckIconTracker: FC = (): JSX.Element => (
  <Box
    width={20}
    height={20}
    borderRadius="50%"
    sx={{ border: `2px solid ${definitionTheme({ challenge: '#DB580F' }, colorTheme)}` }}
  />
)
