import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Button, Grid, Theme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../modal'
import { User } from '../../types'
import { TgLink } from '../tg-link'
import { Support } from '../support'
import { TimePush } from '../time-push'
import { Subscribe } from '../subscribe'
import { definitionTheme } from '../../utils'

interface IInfoUserModal {
  show: boolean
  infoUser: User
  onClose: () => void
}

export const InfoUserModal: React.FC<IInfoUserModal> = ({
  infoUser,
  show = false,
  onClose = () => {},
}): JSX.Element => {
  const navigate = useNavigate()
  const classes = useStyles()

  const phoneFormat = (phone: string) =>
    phone !== null && phone && phone.length > 10
      ? `+${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)} ${phone.substring(7, 9)} ` +
        `${phone.substring(9)}`
      : phone

  return (
    <Modal open={show} maxWidth="md" onClose={onClose}>
      <Box className={classes.info}>
        <Grid container wrap="nowrap" direction="column" justifyContent="space-between" minHeight="calc(100vh - 70px)">
          <Grid item>
            <Box className={classes.title}>Мои данные</Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box className={classes.label}>Фамилия</Box>
              </Grid>
              <Grid item xs={9}>
                <Box className={classes.infoText}>{infoUser.last_name}</Box>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.label}>Имя</Box>
              </Grid>
              <Grid item xs={9}>
                <Box className={classes.infoText}>{infoUser.first_name}</Box>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.label}>E-mail</Box>
              </Grid>
              <Grid item xs={9}>
                <Box className={classes.infoText}>{infoUser.email}</Box>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.label}>Телефон</Box>
              </Grid>
              <Grid item xs={9}>
                <Box className={classes.infoText}>{phoneFormat(infoUser.phone)}</Box>
              </Grid>
            </Grid>
            <Box width="100%" mt="15px" mb="20px">
              <Subscribe />
            </Box>
            <Box width="100%" mb="-5px">
              <Button
                fullWidth
                sx={btnLogout}
                color="primary"
                variant="contained"
                onClick={() => {
                  document.body.style.position = 'relative'
                  navigate('../logout')
                }}
              >
                Выйти
              </Button>
            </Box>
            <Box>{infoUser?.notify_need && <TimePush />}</Box>
            {!infoUser.notify_need && <TgLink />}
          </Grid>
          {/* <Grid item> */}
          {/*  <Support /> */}
          {/* </Grid> */}
        </Grid>
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  info: {
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    padding: '15px 0 20px',
    color: definitionTheme({ challenge: palette.warning.main }, palette.primary.contrastText) as string,
  },
  label: {
    fontSize: 15,
    fontWeight: 500,
    color: '#939CA4',
  },
  infoText: {
    fontSize: 15,
    fontWeight: 600,
    color: palette.primary.contrastText,
  },
}))

const btnLogout = {
  fontSize: 16,
  fontWeight: 600,
  color: '#CE732B!important',
  minHeight: '50px!important',
  backgroundColor: 'rgba(246, 229, 214, 0.5)!important',
}
