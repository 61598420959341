import React from 'react'
import { Box, Theme } from '@mui/material'
import * as process from 'process'
import { makeStyles } from '@mui/styles'
import { definitionTheme } from '../../utils'

export const Support: React.FC<{ type: 'auth' | 'menu' }> = ({ type }): JSX.Element => {
  const classes = useStyles()

  const { REACT_APP_EMAIL: EMAIL, REACT_APP_RULES_URL: RULES, REACT_APP_AGREEMENTS_URL: AGREEMENTS } = process.env

  return (
    <Box className={classes.support}>
      <Box className={classes.supportTitle}>
        {type === 'auth' && 'Есть вопросы?'}
        {type === 'menu' && 'Есть вопросы по прохождению челленджа?'}
      </Box>
      <Box className={classes.supportText}>
        Пишите нам:{' '}
        <a href={`mailto:${EMAIL}`} target="_blank" rel="noopener noreferrer">
          support@doterra-challenge.ru
        </a>
      </Box>
      {/* <Box className={classes.rules}> */}
      {/*  <a href={RULES} target="_blank" rel="noopener noreferrer"> */}
      {/*    Правила */}
      {/*  </a> */}
      {/* </Box> */}
      {/* <Box className={classes.agreement}> */}
      {/*  <a href={AGREEMENTS} target="_blank" rel="noopener noreferrer"> */}
      {/*    Пользовательское соглашение */}
      {/*  </a> */}
      {/* </Box> */}
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  support: {
    marginTop: 30,
    borderRadius: 18,
    padding: '15px 20px',
    backgroundColor: '#EFF2F3',
    [down(365)]: {
      padding: 15,
    },
    [down(330)]: {
      padding: 10,
    },
  },
  supportTitle: {
    fontSize: 15,
    fontWeight: 500,
    color: '#000000',
    marginBottom: 10,
    textAlign: 'center',
    [down(365)]: {
      fontSize: 14,
      marginBottom: 5,
    },
    [down(330)]: {
      fontSize: 12,
      marginBottom: 5,
    },
  },
  supportText: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.7)',
    '& a': {
      fontWeight: 700,
      whiteSpace: 'nowrap',
      textDecoration: 'none!important',
      color: `${definitionTheme({ challenge: palette.warning.main }, palette.primary.dark)}!important`,
      '-webkit-tap-highlight-color': 'transparent',
    },
    [down(365)]: {
      fontSize: 13,
    },
    [down(330)]: {
      fontSize: 11,
    },
  },
  rules: {
    fontSize: 14,
    marginTop: 5,
    textAlign: 'center',
    '& a': {
      fontWeight: 700,
      textDecoration: 'none!important',
      color: `${palette.primary.dark}!important`,
      '-webkit-tap-highlight-color': 'transparent',
    },
    [down(365)]: {
      fontSize: 13,
    },
    [down(330)]: {
      fontSize: 11,
    },
  },
  agreement: {
    fontSize: 14,
    marginTop: 5,
    textAlign: 'center',
    '& a': {
      fontWeight: 700,
      textDecoration: 'none!important',
      color: `${palette.primary.dark}!important`,
      '-webkit-tap-highlight-color': 'transparent',
    },
    [down(365)]: {
      fontSize: 13,
    },
    [down(330)]: {
      fontSize: 11,
    },
  },
}))
