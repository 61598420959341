import { FC, useCallback, useEffect, useState } from 'react'
import { Box, Container, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../../components'
import { useStylesActivity } from './activity.styles'
import { getActivity } from '../../api'
import { Activity } from '../../types'
import { useReduxActions } from '../../hooks'

interface ICard extends Activity {
  className: any
}

const Card: FC<ICard> = ({ className, primary_media, source, is_active }): JSX.Element => {
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()

  const { result, slug } = source

  const onOpenActivity = () => {
    let route = ''

    switch (true) {
      case slug === 'april-2024':
        route = 'testabod'
        break
      case slug === '2023-2024':
        route = 'calendar'
        break
      case slug === '2023':
        route = 'test'
        break
      default:
        break
    }

    if (is_active) {
      navigate(`../${route}`)
    } else if (result) navigate(`../${route}?completed=true`)
    else setAlert({ alert: { message: { info: ['Вы не принимали<br />участие в активности'] } } })
  }

  return (
    <Box className={className.card} onClick={onOpenActivity}>
      <img src={primary_media.src_link} alt="" width="100%" />
      <Box position="absolute" bottom={30} left={20} fontSize={18} fontWeight={700} color="#FFFFFF" zIndex={2}>
        {source.name}
      </Box>
    </Box>
  )
}

export const ActivityPage: FC = (): JSX.Element => {
  const classes = useStylesActivity()

  const [tab, setTab] = useState<number>(1)

  const [activity, setActivity] = useState<Activity[]>([])

  const uploadActivity = useCallback(() => {
    getActivity().then(({ data }) => {
      setActivity(data)
    })
  }, [])

  useEffect(() => {
    uploadActivity()
  }, [])

  return (
    <Layout>
      <Box className={classes.page}>
        <Container maxWidth="md">
          <Box mt="20px" fontSize={24} fontWeight={700}>
            Активности
          </Box>
          <Box m="8px 0 20px" fontSize={15} fontWeight={500} maxWidth={300} color="#4A5967">
            Данный раздел содержит каталог активностей Doterra
          </Box>
          <Stack
            mb={1}
            fontSize={20}
            spacing="20px"
            direction="row"
            flexWrap="wrap"
            fontWeight={700}
            justifyContent="flex-start"
          >
            <Box sx={{ opacity: tab === 1 ? 1 : 0.5, transition: '0.2s' }} onClick={() => setTab(1)}>
              Активные
            </Box>
            <Box sx={{ opacity: tab === 2 ? 1 : 0.5, transition: '0.2s' }} onClick={() => setTab(2)}>
              Завершенные
            </Box>
          </Stack>
          {activity.length > 0 && (
            <>
              <Box hidden={tab !== 1}>
                {activity
                  .filter((i: Activity) => i.is_active)
                  .map((k: Activity, i: number) => (
                    <Card key={i} className={classes} {...k} />
                  ))}
              </Box>

              <Box hidden={tab !== 2}>
                {activity
                  .filter((i: Activity) => !i.is_active)
                  .map((k: Activity, i: number) => (
                    <Card key={i} className={classes} {...k} />
                  ))}
              </Box>
            </>
          )}
        </Container>
      </Box>
    </Layout>
  )
}
