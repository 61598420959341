import foodMeta from './food-meta.png'
import cleanHand from './clean-hand.png'
import cleanHome from './clean-home.png'
import cleanDish from './clean-dish.png'
import foodLemon from './food-lemon.png'
import foodPrime from './food-prime.png'
import foodCardamom from './food-cardamom.png'
import foodVitality from './food-vitality.png'
import foodTurmeric from './food-turmeric.png'
import physicalLemon from './physical-lemon.png'
import stressBalance from './stress-balance.png'
import stressSerenity from './stress-serenity.png'
import stressLavender from './stress-lavender.png'
import toxicityAssist from './toxicity-assist.png'
import awarenessGuard from './awareness-guard.png'
import awarenessIncense from './awareness-incense.png'
import physicalDeepBlue from './physical-deep-blue.png'
import awarenessLifelong from './awareness-lifelong.png'
import toxicityDigestzen from './toxicity-digestzen.png'
import toxicityZendocrine from './toxicity-zendocrine.png'
import toxicityGrapefruit from './toxicity-grapefruit.png'
import physicalStickDeepBlue from './physical-stick-deep-blue.png'

export const assets = {
  foodMeta,
  foodPrime,
  foodLemon,
  cleanHand,
  cleanHome,
  cleanDish,
  foodCardamom,
  foodVitality,
  foodTurmeric,
  physicalLemon,
  stressBalance,
  stressSerenity,
  stressLavender,
  toxicityAssist,
  awarenessGuard,
  awarenessIncense,
  physicalDeepBlue,
  awarenessLifelong,
  toxicityDigestzen,
  toxicityZendocrine,
  toxicityGrapefruit,
  physicalStickDeepBlue,
}
