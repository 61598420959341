/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, Container, Grid, Theme } from '@mui/material'
import ReactPlayer from 'react-player/youtube'
import { makeStyles } from '@mui/styles'
import HtmlReactParser from 'html-react-parser'
import { Layout, Modal, TgLink, TimePush } from '../../components'
import { getTask } from '../../api'
import { aHref } from '../../utils'
import { useReduxDispatch, useReduxSelector } from '../../hooks'
import { getUser } from '../../store/auth/auth.service'

type TaskList = {
  id: number
  task: Task
  date_at: string
  notify_at?: any
  created_at: string
}

type Task = {
  id: number
  slug: string
  name: string
  link: string
  description: string
  type_link: 'local_video' | 'external_link'
}

export const TasksPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()

  const { data: userData } = useReduxSelector((state) => state.auth)

  const [videoModal, setVideoModal] = useState<boolean>(false)
  const [taskList, setTaskList] = useState<TaskList[]>([])

  const onClickLink = (link: string, type: 'local_video' | 'external_link' | null) => {
    if (type === 'local_video') {
      setVideoModal(true)
    } else {
      aHref(link)
    }
  }

  const loadTasksList = useCallback(() => {
    getTask().then((res) => {
      setTaskList(res.data.reverse())
    })
  }, [])

  useEffect(() => {
    loadTasksList()
    dispatch(getUser())
  }, [])

  return (
    <Layout>
      <Box className={classes.page}>
        <Container maxWidth="sm">
          <Box className={classes.text}>Выполняйте новое задание каждый день и наблюдайте за своими успехами!</Box>
          {!userData?.notify_need && <TgLink />}
          {userData?.start_tracker_at !== null ? (
            <>
              {taskList.length > 0 ? (
                <Box className={classes.list}>
                  {taskList.map((k: TaskList, i: number) => {
                    return (
                      <Box key={`task-${i}`} className={classes.item}>
                        {k.task.type_link === 'local_video' && (
                          <Modal maxWidth="md" open={videoModal} onClose={() => setVideoModal(false)}>
                            <Box className={classes.player}>
                              <ReactPlayer url={k.task.link} playing controls width="100%" />
                            </Box>
                          </Modal>
                        )}
                        <Box className={classes.itemTitle}>{HtmlReactParser(k.task.name)}</Box>
                        <Box className={classes.itemText}>{HtmlReactParser(k.task.description)}</Box>
                        {k.task.type_link && (
                          <Box className={classes.link} onClick={() => onClickLink(k.task.link, k.task.type_link)}>
                            Посмотреть {k.task.type_link === 'local_video' && 'видео'}
                            {k.task.type_link === 'external_link' && 'информацию'}
                          </Box>
                        )}
                      </Box>
                    )
                  })}
                </Box>
              ) : (
                <Grid container justifyContent="center" alignItems="center" height={300}>
                  <Grid item>
                    <CircularProgress color="secondary" />
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Grid container justifyContent="center" alignItems="center" height={150}>
              <Grid item>
                <Box className={classes.noStart}>
                  Здесь будут отображаться
                  <br />
                  ежедневные задания
                  <br />
                  после начала марафона.
                </Box>
              </Grid>
            </Grid>
          )}
          <Box sx={{ marginTop: '-10px' }}>{userData?.notify_need && <TimePush />}</Box>
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    paddingTop: 35,
    paddingBottom: 100,
    backgroundColor: palette.primary.main,
  },
  text: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: -10,
    color: palette.primary.contrastText,
  },
  list: {
    marginTop: 30,
  },
  item: {
    padding: 20,
    marginBottom: 10,
    borderRadius: 12,
    border: '1px solid #F0F1F8',
    boxShadow: '0px 4px 12px #EBEDF4',
    '& ul': {
      paddingLeft: 30,
    },
    '& li': {
      paddingBottom: 5,
    },
  },
  itemTitle: {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: 10,
    color: palette.primary.contrastText,
  },
  itemText: {
    fontSize: 15,
    fontWeight: 500,
    color: palette.primary.contrastText,
  },
  link: {
    fontSize: 15,
    marginTop: 15,
    fontWeight: 500,
    color: palette.primary.dark,
    '-webkit-tap-highlight-color': 'transparent',
  },
  player: {
    marginTop: 30,
  },
  noStart: {
    fontSize: 15,
    marginTop: 15,
    fontWeight: 700,
    textAlign: 'center',
    color: palette.primary.contrastText,
  },
}))
