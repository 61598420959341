import * as ls from 'local-storage'
import axios from 'axios'
import { ForgotPasswordUser, RegisterUser, ResetPasswordUser, TOKEN, TrackerStartProps } from '../types'
import { fetchPrivateAPIToken, withPrivateAPIToken, withPublicAPIToken } from './service'
import { Answer, Survey } from '../pages/test/test.types'

export const constants = async () =>
  withPublicAPIToken({
    url: '/v1/constant',
    method: 'get',
  })

export const signin = async (username: string, password: string) => fetchPrivateAPIToken(username, password)

export const logout = async () => {
  return withPrivateAPIToken({
    url: '/private/user/logout',
    method: 'post',
  }).then((_r) => {
    ls.remove(TOKEN.PRIVATE)
  })
}

export const infoUser = async () =>
  withPrivateAPIToken({
    url: '/v1/user',
    method: 'get',
  })
export const updateInfoUser = async (data: any) =>
  withPrivateAPIToken({
    url: '/v1/user',
    method: 'put',
    data: { ...data },
  })

export const registerUser = async (data: RegisterUser) =>
  withPublicAPIToken({
    url: '/v1/user',
    method: 'post',
    data: { ...data },
  })

export const resetPasswordUser = async (data: ResetPasswordUser) =>
  withPublicAPIToken({
    url: '/v1/user/reset-password',
    method: 'post',
    data: { ...data },
  })

export const forgotPasswordUser = async (data: ForgotPasswordUser) =>
  withPublicAPIToken({
    url: '/v1/user/user-forgot-password',
    method: 'post',
    data: { ...data },
  })

export const getHabitMain = async () =>
  withPrivateAPIToken({
    url: '/v1/habit/main',
    method: 'get',
  })

export const getHabitAdditional = async () =>
  withPrivateAPIToken({
    url: '/v1/habit/additional',
    method: 'get',
  })

export const getHabitJournal = async () =>
  withPrivateAPIToken({
    url: '/v1/habit/journal',
    method: 'get',
  })

export const getHabitOil = async () =>
  withPrivateAPIToken({
    url: '/v1/habit/oil',
    method: 'get',
  })

export const getTask = async () =>
  withPrivateAPIToken({
    url: '/v1/task',
    method: 'get',
  })

export const trackerStart = async (data: TrackerStartProps) =>
  withPrivateAPIToken({
    url: '/v1/tracker/start',
    method: 'post',
    data: { ...data },
  })

export const trackerStart2024 = async () =>
  withPrivateAPIToken({
    url: '/v1/tracker/start/2024',
    method: 'post',
  })

export const trackerNow = async (data: { date: string; habit_type: 'main-2024' | 'journal-2024' }) =>
  withPrivateAPIToken({
    url: '/v1/tracker/now',
    method: 'post',
    data: { ...data },
  })

export const trackerNow2024 = async ({ habit_type }: { habit_type: 'control' | 'measurement' }) =>
  withPrivateAPIToken({
    url: `/v1/tracker/now/2024/${habit_type}`,
    method: 'post',
  })

export const trackerNowMain = async (data: { date: string }) =>
  withPrivateAPIToken({
    url: '/v1/tracker/now/main',
    method: 'post',
    data: { ...data },
  })

export const trackerNowOil = async (data: { date: string }) =>
  withPrivateAPIToken({
    url: '/v1/tracker/now/oil',
    method: 'post',
    data: { ...data },
  })

export const trackerNowJournal = async (data: { date: string }) =>
  withPrivateAPIToken({
    url: '/v1/tracker/now/journal',
    method: 'post',
    data: { ...data },
  })

export const trackerNowMeasurement = async (data: { date: string }) =>
  withPrivateAPIToken({
    url: '/v1/tracker/now/measurement',
    method: 'post',
    data: { ...data },
  })

export const trackerCalendar = async () =>
  withPrivateAPIToken({
    url: '/v1/tracker/calendar',
    method: 'post',
  })

export const trackerDescription = async (data: { habit_type: 'card-2024' }) =>
  withPrivateAPIToken({
    url: '/v1/tracker/description',
    method: 'post',
    data: { ...data },
  })

export const trackerCalendar2024 = async () =>
  withPrivateAPIToken({
    url: '/v1/tracker/calendar/2024',
    method: 'post',
  })

export const trackerStore = async (data: { trackers: any[] }) =>
  withPrivateAPIToken({
    url: '/v1/tracker/store',
    method: 'post',
    data: { ...data },
  })

export const getTestResult = async (slug: string) =>
  withPrivateAPIToken({
    url: `/v1/test/result?slug=${slug}`,
    method: 'get',
  })

export const getTest = async (slug: string) =>
  withPrivateAPIToken({
    url: `/v1/test?slug=${slug}`,
    method: 'get',
  })

export const testStore = async (data: Answer) =>
  withPrivateAPIToken({
    url: '/v1/test/store',
    method: 'post',
    data: { ...data },
  })

export const getTestSurveyQuestion = async () =>
  withPrivateAPIToken({
    url: '/v1/test/survey/question',
    method: 'get',
  })

export const testSurveyStore = async (data: Survey) =>
  withPrivateAPIToken({
    url: '/v1/test/survey/store',
    method: 'post',
    data: { ...data },
  })

export const getCalendar = async () =>
  withPrivateAPIToken({
    url: '/v1/calendar',
    method: 'get',
  })

export const openCalendar = async (data: { calendar_id: number }) =>
  withPrivateAPIToken({
    url: '/v1/calendar/open',
    method: 'post',
    data: { ...data },
  })

export const getAdvent = async (data: { calendar_id: number }) =>
  withPrivateAPIToken({
    url: '/v1/advent',
    method: 'post',
    data: { ...data },
  })

export const openAdvent = async (data: { advent_id: number }) =>
  await withPrivateAPIToken({
    url: '/v1/advent/open',
    method: 'post',
    data: { ...data },
  })

export const getActivity = async () =>
  await withPrivateAPIToken({
    url: '/v1/activity',
    method: 'post',
  })

export const getTarget = async () =>
  withPrivateAPIToken({
    url: '/v1/target',
    method: 'get',
  })

export const storeTarget = async (data: { target_id: number }) =>
  await withPrivateAPIToken({
    url: '/v1/target/store',
    method: 'post',
    data: { ...data },
  })

export const getPlan = async (data: { task_type: '2024' }) =>
  await withPrivateAPIToken({
    url: '/v1/plan',
    method: 'post',
    data: { ...data },
  })

export const storePlan = async (data: { plan: { task_id: number; notify_at: string | null }[] }) =>
  await withPrivateAPIToken({
    url: '/v1/plan/store',
    method: 'post',
    data: { ...data },
  })

export const apiGetNoCache = (url: string) =>
  axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  })
