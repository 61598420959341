import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDetectDevice } from '../../hooks'
import { DetectDevice as DetectDevices } from '../../types'

interface IDetectDevice {
  desktopPage: React.FC
  children: React.ReactNode
}

export const DetectDevice: React.FC<IDetectDevice> = ({ desktopPage: DesktopPage, children }): JSX.Element => {
  const detectDevice = useDetectDevice()
  const [searchParams, _] = useSearchParams()

  if (detectDevice === DetectDevices.OTHER && !searchParams.get('gtm_debug')) {
    return <DesktopPage />
  }

  return <>{children}</>
}
