/* eslint-disable max-len */
import { FC } from 'react'

export const ActivityIcon: FC = (): JSX.Element => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="#B6BCC2" stroke="#B6BCC2" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5 5.62549C18.5 5.28004 18.22 5 17.8745 5C17.6439 5 17.432 5.12686 17.3231 5.33012L10.7887 17.5278C10.4318 18.1939 10.9144 19 11.6702 19H16C16.2761 19 16.5 19.2239 16.5 19.5V26.382C16.5 26.7233 16.7767 27 17.118 27C17.3521 27 17.5661 26.8677 17.6708 26.6584L23.2764 15.4472C23.6088 14.7823 23.1253 14 22.382 14H19C18.7239 14 18.5 13.7761 18.5 13.5V5.62549Z" />
    <path d="M27.9141 6.58578L25.0856 9.41421" strokeWidth="2" strokeLinecap="round" />
    <path d="M27.9141 9.41422L25.0856 6.58579" strokeWidth="2" strokeLinecap="round" />
    <path d="M8.16406 22.5858L5.33564 25.4142" strokeWidth="2" strokeLinecap="round" />
    <path d="M8.16406 25.4142L5.33564 22.5858" strokeWidth="2" strokeLinecap="round" />
  </svg>
)
