/* eslint-disable max-len */
import { FC } from 'react'
import { definitionTheme } from '../../../../utils'

const color = definitionTheme({
  marathon: '#B786B9',
  test: '#BB2F2B',
  calendar: '#006266',
  test2: '#5A3624',
  challenge: '#A1AAAD',
}) as string

export const ActivityDrawerIcon: FC = (): JSX.Element => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.3865 4.79608C20.3865 4.35642 20.0301 4 19.5905 4C19.297 4 19.0273 4.16146 18.8887 4.42015L10.4556 20.1619C10.0542 20.9114 10.5971 21.8182 11.4473 21.8182H17.2786C17.5892 21.8182 17.8411 22.07 17.8411 22.3807V31.2134C17.8411 31.6478 18.1933 32 18.6277 32C18.9256 32 19.198 31.8317 19.3312 31.5652L26.5725 17.0827C26.9465 16.3346 26.4026 15.4545 25.5663 15.4545H20.949C20.6384 15.4545 20.3865 15.2027 20.3865 14.892V4.79608Z"
      fill={color}
    />
    <path d="M32.3672 6.01825L28.7674 9.61807" stroke={color} strokeWidth="2.25" strokeLinecap="round" />
    <path d="M32.3672 9.61804L28.7674 6.01823" stroke={color} strokeWidth="2.25" strokeLinecap="round" />
    <path d="M7.23145 26.3819L3.63163 29.9817" stroke={color} strokeWidth="2.25" strokeLinecap="round" />
    <path d="M7.23145 29.9817L3.63163 26.3819" stroke={color} strokeWidth="2.25" strokeLinecap="round" />
  </svg>
)
