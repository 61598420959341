import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PasswordInput, SubmitButton, formLocale, TrackEvent, EmailInput } from '../../components'
import { FormProps } from '../../types'
import { signin } from '../../api'
import { getUser } from '../../store/auth/auth.service'
import { useReduxDispatch } from '../../hooks'
import { errorFormToast } from '../../toast'
import { definitionTheme } from '../../utils'

interface ISigninForm {
  className: any
}

type SigninProps = { email: string; password: string }

const schema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required().min(8).max(20),
})

export const SigninForm: React.FC<ISigninForm> = ({ className }): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { serverError } = formLocale

  const hookForm = useForm<any>({
    defaultValues: { email: searchParams.get('email') || '', password: '' },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<SigninProps>>({
    data: { email: '', password: '' },
    processed: false,
  })

  const onSubmitForm = async ({ email, password }: SigninProps) => {
    setFormProps({ ...formProps, data: { ...formProps.data, email, password }, processed: true })
    return await signin(email, password.replace(/\s/g, ''))
      .then(() => {
        TrackEvent({
          eventCategory: 'Authorization',
          eventAction: 'send_r_form',
          eventLabel: 'success',
        })
        setFormProps({ ...formProps, processed: false })
        dispatch(getUser()).then(() => navigate('../'))
      })
      .catch((err) => {
        setFormProps({ ...formProps, processed: false })

        if (err.response) {
          TrackEvent({
            eventCategory: 'Authorization',
            eventAction: 'send_r_form',
            eventLabel: 'unsuccess',
            eventContext: err.response.data.message,
          })
          errorFormToast(err.response.data.message)
        } else {
          errorFormToast(serverError)
        }
      })
  }

  const onOpenForgot = () => {
    navigate('../forgot')
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      <Box width="100%" height={72}>
        <EmailInput variant="outlined" form={hookForm} name="email" disabled={formProps.processed} />
      </Box>
      <Box width="100%" height={72}>
        <PasswordInput variant="outlined" name="password" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Box className={className.forgot} onClick={onOpenForgot}>
            Забыл пароль?
          </Box>
        </Grid>
      </Grid>
      <Box width="100%" mt="30px">
        <SubmitButton fullWidth disabled={formProps.processed} processed={formProps.processed} title="Войти" />
      </Box>
    </form>
  )
}
