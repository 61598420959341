import { FC, JSX, useState } from 'react'
import { Box, Stack } from '@mui/material'
import {
  Title,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { ControlTracker, ControlTrackerList, MeasurementTracker, MeasurementTrackerList } from '../../../../types'

interface IMyAchievement {
  wells: ControlTrackerList[]
  measures: MeasurementTrackerList[]
}

type Datasets = {
  label: string
  data: number[]
  borderColor: string
  backgroundColor: string
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const getOrCreateLegendList = (chart: any, id: any) => {
  const legendContainer = document.getElementById(id) as HTMLElement
  let listContainer = legendContainer.querySelector('ul') as HTMLElement

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.flexWrap = 'wrap'
    if (id === 'legend-well') listContainer.style.flexDirection = 'column'
    listContainer.style.margin = '0px'
    listContainer.style.padding = '0px'

    legendContainer.appendChild(listContainer)
  }

  return listContainer
}

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: any, args: any, options: any) {
    const ul = getOrCreateLegendList(chart, options.containerID)

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)

    items.forEach((item: any) => {
      const li = document.createElement('li')
      li.style.alignItems = 'center'
      li.style.cursor = 'pointer'
      li.style.display = 'flex'
      li.style.flexDirection = 'row'
      li.style.marginLeft = '9px'
      if (options.containerID === 'legend-well') li.style.marginLeft = '4px'
      li.style.marginBottom = '8px'

      li.onclick = () => {
        const { type } = chart.config
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index)
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        }
        chart.update()
      }

      // Color box
      const boxSpan = document.createElement('span') as HTMLElement
      boxSpan.style.background = item.fillStyle
      boxSpan.style.borderColor = item.strokeStyle
      boxSpan.style.borderWidth = `${item.lineWidth}px`
      boxSpan.style.display = 'inline-block'
      boxSpan.style.flexShrink = '0px'
      boxSpan.style.height = '3px'
      boxSpan.style.marginRight = '10px'
      boxSpan.style.width = '50px'

      // Text
      const textContainer = document.createElement('p') as HTMLElement
      textContainer.style.color = item.fontColor
      textContainer.style.margin = '0px'
      textContainer.style.padding = '0px'
      textContainer.style.fontSize = '12px'
      textContainer.style.fontWeight = 'bold'
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

      const text = document.createTextNode(item.text)
      textContainer.appendChild(text)

      li.appendChild(boxSpan)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  },
}

export const optionsMeasure = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    htmlLegend: {
      containerID: 'legend-measure',
    },
    legend: {
      display: false,
      align: 'center' as const,
      position: 'bottom' as const,
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        font: { size: 12, weight: 'bold' as const, family: 'Raleway' as const },
      },
    },
    title: { display: false },
  },
  scales: { y: { min: 0, ticks: { stepSize: 20 }, beginAtZero: true } },
}

export const optionsWell = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    htmlLegend: {
      containerID: 'legend-well',
    },
    legend: {
      display: false,
      itemWidth: 150,
      align: 'start' as const,
      position: 'bottom' as const,
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        font: { size: 11, weight: 'bold' as const, family: 'Raleway' as const },
      },
    },
    title: { display: false },
  },
  scales: { y: { min: 0, ticks: { stepSize: 1 }, beginAtZero: true } },
}

const labels = ['Неделя 1', 'Неделя 2', 'Неделя 3', 'Неделя 4', 'Неделя 5']
const colorsMeasures = ['#FFDB58', '#DB580F', '#BB2F2B', '#B786B9', '#006266']
const colorsWells = ['#FFDB58', '#DB580F', '#BB2F2B', '#B786B9', '#6d14b8', '#4169E1', '#006266', '#000080']

const dataFormatMeasures = (trackers: MeasurementTracker[]): number[] => {
  const data: number[] = []

  trackers.forEach((k: MeasurementTracker) => {
    if (k.note !== null) data.push(Number(k.note))
  })

  return data
}

const formatDatasetMeasures = (measures: MeasurementTrackerList[]): Datasets[] => {
  const datasets: Datasets[] = []

  measures.forEach((k: MeasurementTrackerList, i: number) => {
    datasets.push({
      label: k.name,
      borderColor: colorsMeasures[i],
      backgroundColor: colorsMeasures[i],
      data: dataFormatMeasures(k.trackers),
    })
  })

  return datasets
}

const dataFormatWells = (trackers: ControlTracker[]): number[] => {
  const data: number[] = []

  trackers.forEach((k: ControlTracker) => {
    if (k.note !== null) data.push(Number(k.note))
  })

  return data
}

const formatDatasetWells = (wells: ControlTrackerList[]): Datasets[] => {
  const datasets: Datasets[] = []

  wells.forEach((k: ControlTrackerList, i: number) => {
    datasets.push({
      label: `${k.meta.label}`,
      borderColor: colorsWells[i],
      backgroundColor: colorsWells[i],
      data: dataFormatWells(k.trackers),
    })
  })

  return datasets
}

export const MyAchievement: FC<IMyAchievement> = ({ wells, measures }): JSX.Element => {
  const dataMeasures = { labels, datasets: formatDatasetMeasures(measures) }
  const dataWells = { labels, datasets: formatDatasetWells(wells) }

  const [tab, setTab] = useState<number>(1)

  return (
    <Box>
      <Stack
        mb={2}
        p="5px"
        height={40}
        spacing={0}
        fontSize={16}
        direction="row"
        flexWrap="nowrap"
        fontWeight={700}
        bgcolor="#EFF2F3"
        borderRadius="8px"
        justifyContent="center"
      >
        <Box
          width="50%"
          display="flex"
          borderRadius="4px"
          alignItems="center"
          justifyContent="center"
          onClick={() => setTab(1)}
          color={tab === 1 ? 'warning.main' : '#6F7B87'}
          sx={{ backgroundColor: tab === 1 ? '#FFFFFF' : 'transparent', transition: '0.2s' }}
        >
          Обмеры
        </Box>
        <Box
          width="50%"
          display="flex"
          borderRadius="4px"
          alignItems="center"
          justifyContent="center"
          onClick={() => setTab(2)}
          color={tab === 2 ? 'warning.main' : '#6F7B87'}
          sx={{ backgroundColor: tab === 2 ? '#FFFFFF' : 'transparent', transition: '0.2s' }}
        >
          Самочувствие
        </Box>
      </Stack>
      <Box hidden={tab !== 1}>
        <Box height="calc(100vh - 360px)">
          <Line redraw options={optionsMeasure} data={dataMeasures} plugins={[htmlLegendPlugin]} />
          <Box id="legend-measure" mt="10px" />
        </Box>
      </Box>
      <Box hidden={tab !== 2}>
        <Box height="calc(100vh - 400px)">
          <Line redraw options={optionsWell} data={dataWells} plugins={[htmlLegendPlugin]} />
        </Box>
        <Box id="legend-well" mt="10px" />
      </Box>
    </Box>
  )
}
