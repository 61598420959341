/* eslint-disable max-len */
export const PdfIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 3.67564C6 3.45559 6.04334 3.2377 6.12755 3.0344C6.21176 2.8311 6.33518 2.64638 6.49078 2.49078C6.64638 2.33519 6.8311 2.21176 7.0344 2.12755C7.2377 2.04334 7.45559 2 7.67564 2H19.9636L26.9455 7.86473V28.2516C26.9455 28.696 26.7689 29.1222 26.4547 29.4365C26.1404 29.7507 25.7142 29.9273 25.2698 29.9273H7.67564C7.23123 29.9273 6.80502 29.7507 6.49078 29.4365C6.17654 29.1222 6 28.696 6 28.2516V3.67564Z"
      fill="#DB580F"
    />
    <path
      d="M19.9636 2L19.9639 6.18909C19.9639 6.6335 20.1404 7.0597 20.4546 7.37395C20.7689 7.68819 21.1951 7.86473 21.6395 7.86473H26.9455"
      fill="#004438"
    />
    <path
      d="M11.2551 19.7072H10.6005V21.7145H9.15137V15.666H11.0631C12.713 15.666 13.6555 16.3118 13.6555 17.6384C13.6555 19.0618 12.6863 19.7072 11.2551 19.7072ZM11.0805 16.6954H10.6005V18.659H11.1329C11.7874 18.659 12.1627 18.3449 12.1627 17.6379C12.1627 17.0009 11.8049 16.6954 11.0805 16.6954Z"
      fill="white"
    />
    <path
      d="M15.941 21.7153H14.2998V15.666H15.8362C17.3901 15.666 18.8738 16.1635 18.8738 18.6595C18.8738 21.1214 17.4508 21.7153 15.941 21.7153ZM16.0453 16.7133H15.7485V20.6676H16.0453C16.8394 20.6676 17.3722 20.24 17.3722 18.6599C17.3722 17.0454 16.805 16.7133 16.0453 16.7133Z"
      fill="white"
    />
    <path
      d="M21.3954 16.6954V18.2838H23.5078V19.3236H21.3954V21.7149H19.9375V15.666H23.9267L23.7784 16.6958L21.3954 16.6954Z"
      fill="white"
    />
  </svg>
)
