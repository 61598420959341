import React, { FC, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Button, Container, Grid, Grow, IconButton, Theme } from '@mui/material'
import { VKShareButton } from 'react-share'
import HtmlReactParser from 'html-react-parser'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Check } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import { themeGl } from '../../configs'
import { img, LogoBlackIcon, LogoWhiteIcon, VkIcon } from '../../imgs'
import { CheckboxInput, formLocale, Layout, Modal } from '../../components'
import { OpenDayIcon, SuccessOneDayIcon, SuccessThreeDayIcon, SuccessTwoDayIcon, UnsuccessDayIcon } from './icon'
import { useReduxActions } from '../../hooks'
import { getAdvent, getCalendar, openAdvent, openCalendar } from '../../api'
import { AdventItem, AdventRecommendation, CalendarAdvent } from '../../types'
import { colorTheme } from '../../utils'

type DayProps = {
  id: number
  open: boolean
  className: any
  access: boolean
  disabled: boolean
  onClick: () => void
}

const schema = yup.object().shape({
  rules: yup.boolean().oneOf([true], 'Необходимо согласиться с условиями').default(false),
})

const Day: FC<DayProps> = ({ id, className, open, access, disabled, onClick = () => {} }): JSX.Element => {
  return (
    <IconButton color="primary" sx={{ p: '0' }} disabled={disabled} onClick={onClick}>
      <Box className={className.day}>
        {!access && <UnsuccessDayIcon />}
        {access &&
          !open &&
          (id % 3 === 0 ? <SuccessThreeDayIcon /> : (id + 1) % 2 === 0 ? <SuccessTwoDayIcon /> : <SuccessOneDayIcon />)}
        {open && <OpenDayIcon />}
        <Box className={className.num} color={open ? '#2A7F7E' : '#FFFFFF'}>
          {id.toString().padStart(2, '0')}
        </Box>
      </Box>
    </IconButton>
  )
}

export const AdventCalendarPage: FC = (): JSX.Element => {
  const classes = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setAlert } = useReduxActions()

  const { adventRulesCheckbox } = formLocale

  const textShare =
    'Что такое адвент-календарь от doTERRA? То, что наполняет настроением праздника и ' +
    'помогает взять с собой из уходящего года в новый только лучшее! ' +
    '15 дней, 15 мотивирующих пожеланий, 15 заданий от карьерного эксперта и ' +
    'специалистов по эфирным маслам ждут каждого, кто готов снова поверить в то, что ' +
    'чудеса случаются.'

  const hookForm = useForm<any>({
    defaultValues: { rules: false },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [completed, setCompleted] = useState<boolean>(false)

  const [calendar, setCalendar] = useState<CalendarAdvent | undefined>(undefined)
  const [listAdvent, setListAdvent] = useState<AdventItem[] | undefined>(undefined)
  const [participation, setParticipation] = useState<boolean>(false)
  const [task, setTask] = useState<AdventRecommendation | undefined>(undefined)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [openAll, setOpenAll] = useState<boolean>(false)

  const uploadCalendar = useCallback(() => {
    getCalendar().then((res) => {
      if (res.status === 1) {
        setCalendar(res.data)
        setParticipation(res.data.open)

        if (res.data.open) uploadAdvent(res.data.id)

        setSearchParams({})
      } else {
        setAlert({
          alert: {
            message: {
              info: [res.message],
            },
          },
        })
      }
    })
  }, [])

  const uploadOpenCalendar = useCallback((calendar_id: number) => {
    openCalendar({ calendar_id }).then((res) => {
      if (res.status === 1) {
        setParticipation((prev) => !prev)
        uploadAdvent(calendar_id)
      } else {
        setAlert({
          alert: {
            message: {
              info: [res.message],
            },
          },
        })
      }
    })
  }, [])

  const uploadAdvent = useCallback((calendar_id: number) => {
    getAdvent({ calendar_id }).then((res) => {
      if (res.status === 1) {
        let all = true

        setListAdvent(res.data)

        res.data.forEach((k: AdventItem) => {
          if (!k.open) all = false
        })

        setOpenAll(all)
      } else {
        setAlert({
          alert: {
            message: {
              info: [res.message],
            },
          },
        })
      }
    })
  }, [])

  const uploadOpenAdvent = useCallback((data: AdventItem & { calendar_id: number }) => {
    setDisabled((prev) => !prev)

    openAdvent({ advent_id: data.id })
      .then((res) => {
        setDisabled((prev) => !prev)

        if (res.status === 1) {
          setTask(res.data)
          uploadAdvent(data.calendar_id)
        } else {
          setAlert({
            alert: {
              message: {
                info: [res.message],
              },
            },
          })
        }
      })
      .catch((err) => {
        setDisabled((prev) => !prev)

        setAlert({
          alert: {
            message: {
              error: [err.response.data.message],
            },
          },
        })
      })
  }, [])

  const onSubmitForm = (data: { rules: boolean }) => {
    if (data.rules && calendar) uploadOpenCalendar(calendar?.id)
  }

  const check = {
    icon: <span className={classes.icon} />,
    checked: (
      <span className={classes.iconCheck}>
        <Check className={classes.check} />
      </span>
    ),
  }

  useEffect(() => {
    if (searchParams.get('completed')) {
      setCompleted(!!searchParams.get('completed'))
    }
  }, [searchParams])

  useEffect(() => {
    uploadCalendar()
  }, [])

  return (
    <Layout>
      {task && (
        <Modal maxWidth="md" open={!!task} onClose={() => setTask(undefined)}>
          <Box className={classes.titleModal}>{HtmlReactParser(task.title)}</Box>
          <Box className={classes.subTextModal}>{HtmlReactParser(task.intro)}</Box>
          <Box className={classes.img}>
            <img src={task.primary_media.src_link} alt="" />
          </Box>
          <Box className={classes.textModal}>{HtmlReactParser(task.content)}</Box>
        </Modal>
      )}

      {calendar ? (
        <Box className={classes.page} pb={participation ? '150px' : '115px'}>
          {!participation ? (
            <Container maxWidth="sm" sx={{ height: '100%' }}>
              <Grid container direction="column" justifyContent="space-between" height="100%">
                <Grid item>
                  <Grid container justifyContent="center">
                    <Grid item>{themeGl === 'calendar' ? <LogoWhiteIcon /> : <LogoBlackIcon />}</Grid>
                  </Grid>
                  <Box className={classes.title}>Адвент-календарь</Box>
                  <Box className={classes.img}>
                    <img src={img.advent} alt="" />
                  </Box>
                  <Box className={classes.text} pb="50px">
                    <Box mb="10px">
                      Как ощутить праздничное настроение и с пользой завершить этот год? С адвент-календарём от doTERRA
                      желания сбываются!
                    </Box>
                    Откройте все задания и получите шанс выиграть ценный приз!
                  </Box>
                </Grid>
                <Grid item>
                  <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
                    <Grid container justifyContent="center" maxWidth={335} width="100%" m="0 auto">
                      <Grid item height={70}>
                        <CheckboxInput
                          required
                          name="rules"
                          form={hookForm}
                          color="secondary"
                          icon={check.icon}
                          checked={check.checked}
                          label={adventRulesCheckbox}
                        />
                      </Grid>
                      <Grid item width="100%">
                        <Button type="submit" fullWidth color="secondary" variant="contained">
                          Участвовать
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Container maxWidth="sm" sx={{ height: '100%' }}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Grid item>
                    {themeGl === 'calendar' ? (
                      <LogoWhiteIcon width={138} height={25} />
                    ) : (
                      <LogoBlackIcon width={138} height={25} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Box className={classes.text} p="30px 0 20px">
                {openAll ? (
                  <>
                    <Box mb="10px">Поздравляем!</Box>
                    <Box mb="10px">
                      Вы открыли все окошки адвент-календаря! Надеемся, с нами вы завершили этот год легко и
                      продуктивно.
                    </Box>
                    {!completed && (
                      <>
                        <Box mb="25px">Хотите поделиться с друзьями адвент-календарем в ВК?</Box>
                        <Grid container justifyContent="center" mb="23px">
                          <Grid item width={335}>
                            <VKShareButton
                              title={textShare}
                              image={img.advent}
                              style={{ width: '100%' }}
                              url="https://doterra-challenge.ru/share/vk/advent.html"
                            >
                              <Button fullWidth color="secondary" variant="contained">
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  mt="-17px"
                                  height={40}
                                  spacing={1}
                                >
                                  <Grid item>
                                    <Box mt="-5px">Поделиться</Box>
                                  </Grid>
                                  <Grid item height={54}>
                                    <VkIcon colorCalendar="#FFFFFF" colorTest2="#FFFFFF" colorMarathon="#FFFFFF" />
                                  </Grid>
                                </Grid>
                              </Button>
                            </VKShareButton>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <Box fontWeight={700} fontSize="115%" mb="25px">
                      До встречи в новом, 2024 году!
                    </Box>
                  </>
                ) : (
                  'Открывайте окошки и выполняйте ежедневные задания!'
                )}
              </Box>
              <Grid container justifyContent="center" spacing={1}>
                {listAdvent?.map((k: AdventItem, i: number) => (
                  <Grow
                    in
                    key={`advent-${i}`}
                    style={{ transformOrigin: '0 0 0' }}
                    {...{ timeout: 600 + Math.trunc(i / 3) * 300 }}
                  >
                    <Grid item>
                      <Day
                        id={i + 1}
                        open={k.open}
                        access={k.access}
                        className={classes}
                        disabled={disabled}
                        onClick={() => uploadOpenAdvent({ ...k, calendar_id: calendar?.id })}
                      />
                    </Grid>
                  </Grow>
                ))}
              </Grid>
            </Container>
          )}
        </Box>
      ) : (
        <></>
      )}
    </Layout>
  )
}

const useStyles = makeStyles(({ palette, font }: Theme) => ({
  page: {
    width: '100%',
    minHeight: '100vh',
    paddingTop: '30px',
    fontFamily: font.primary,
    backgroundRepeat: 'no-repeat',
    backgroundSize: themeGl === 'calendar' ? '85vw 200vw' : '345px 341px',
    backgroundImage: `url(${
      themeGl === 'calendar' ? img.bgGreetCalendar : themeGl === 'test' || themeGl === 'marathon' ? img.bgSign : ''
    })`,
    backgroundPosition: themeGl === 'calendar' ? 'left top' : 'bottom -40px left -120px',
    backgroundColor: themeGl === 'calendar' ? palette.background.default : palette.primary.main,
  },
  title: {
    fontSize: 24,
    marginTop: 50,
    fontWeight: 800,
    color: themeGl === 'calendar' ? '#D9D9D9' : colorTheme,
    textAlign: 'center',
  },
  img: {
    width: '100%',
    maxWidth: 350,
    margin: '20px auto',
    '& img': {
      width: '100%',
      borderRadius: 20,
    },
  },
  text: {
    fontSize: 17,
    maxWidth: 350,
    fontWeight: 500,
    margin: '0 auto',
    textAlign: 'center',
    color: themeGl === 'calendar' ? palette.primary.main : palette.primary.contrastText,
  },
  day: {
    width: 90,
    height: 90,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
  },
  num: {
    top: 3,
    left: 8,
    fontSize: 25,
    fontWeight: 600,
    position: 'absolute',
    fontFamily: font.primary,
  },
  titleModal: {
    fontSize: 22,
    marginTop: 30,
    fontWeight: 700,
    textAlign: 'center',
    color: palette.primary.contrastText,
  },
  subTextModal: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    margin: '15px 0 25px',
    color: palette.primary.contrastText,
  },
  textModal: {
    fontSize: 16,
    fontWeight: 500,
    margin: '25px 0 40px',
    color: palette.primary.contrastText,
    '& .green': {
      fontWeight: 700,
      color: palette.secondary.main,
    },
  },
  icon: {
    width: 22,
    height: 22,
    borderRadius: 4,
    backgroundColor: '#F5F5F5',
    border: '1px solid #E5E5E5',
  },
  iconCheck: {
    width: 22,
    height: 22,
    borderRadius: 4,
    backgroundColor: palette.primary.dark,
    border: `1px solid ${palette.primary.dark}`,
  },
  check: {
    fontSize: '22px!important',
    color: `${palette.primary.main}!important`,
  },
}))
