import React, { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { PrivateRoute, PublicRoute } from '../components'
import {
  InfoPage,
  TestPage,
  GreetPage,
  MainPage,
  TasksPage,
  Test2Page,
  CardsPage,
  SigninPage,
  SignupPage,
  RepeatPage,
  ForgotPage,
  LogoutPage,
  ProgressPage,
  NotFoundPage,
  ActivityPage,
  ChallengePage,
  AdventCalendarPage,
} from '../pages'
import { themeGl } from '../configs'
import { useReduxSelector } from '../hooks'
import { definitionTheme } from '../utils'

export const RoutesRoot: React.FC = (): JSX.Element => {
  const location = useLocation()

  const { auth } = useReduxSelector((state) => state.auth)

  if (
    auth &&
    location.pathname ===
      `/${definitionTheme({
        test: 'test',
        test2: 'testabod',
        challenge: 'meta',
        calendar: 'calendar',
        marathon: 'marathon',
      })}`
  ) {
    return <Navigate to="/" replace />
  }

  return (
    <Routes location={location}>
      <Route
        key="index"
        path="/"
        element={
          <PrivateRoute
            component={
              definitionTheme({
                test: TestPage,
                test2: Test2Page,
                marathon: MainPage,
                challenge: ChallengePage,
                calendar: AdventCalendarPage,
              }) as FC
            }
          />
        }
      />
      <Route key="notfound" path="*" element={<NotFoundPage />} />
      <Route key="test" path="/test" element={<PrivateRoute component={TestPage} />} />
      <Route key="info" path="/info" element={<PrivateRoute component={InfoPage} />} />
      <Route key="greet" path="/greet" element={<PublicRoute component={GreetPage} />} />
      <Route key="forgot" path="/forgot" element={<PublicRoute component={ForgotPage} />} />
      <Route key="signin" path="/signin" element={<PublicRoute component={SigninPage} />} />
      <Route key="signup" path="/signup" element={<PublicRoute component={SignupPage} />} />
      <Route key="logout" path="/logout" element={<PrivateRoute component={LogoutPage} />} />
      <Route key="testabod" path="/testabod" element={<PrivateRoute component={Test2Page} />} />
      <Route key="marathon" path="/marathon" element={<PrivateRoute component={MainPage} />} />
      {themeGl === 'challenge' && <Route key="cards" path="/cards" element={<PrivateRoute component={CardsPage} />} />}
      <Route key="activity" path="/activity" element={<PrivateRoute component={ActivityPage} />} />

      <Route key="challenge" path="/meta" element={<PrivateRoute component={ChallengePage} />} />
      <Route key="calendar" path="/calendar" element={<PrivateRoute component={AdventCalendarPage} />} />
      {themeGl === 'marathon' && (
        <Route key="progress" path="/progress" element={<PrivateRoute component={ProgressPage} />} />
      )}
      <Route key="reset-password" path="/reset-password" element={<PublicRoute component={RepeatPage} />} />
      <Route key="register-password" path="/register-password" element={<PublicRoute component={RepeatPage} />} />
      {themeGl === 'marathon' && <Route key="tasks" path="/tasks" element={<PrivateRoute component={TasksPage} />} />}
    </Routes>
  )
}
