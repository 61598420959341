/* eslint-disable max-len */
import { FC, JSX } from 'react'

export const InfoIcon: FC = (): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" transform="matrix(-1 0 0 1 32 0)" fill="#EFF2F3" />
    <path
      d="M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26Z"
      stroke="#DB580F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16 12V16" stroke="#DB580F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 20H16.01" stroke="#DB580F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
