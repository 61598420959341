import { FC, JSX, useState } from 'react'
import { Box, Button } from '@mui/material'
import { Modal } from '../../components'
import { Target } from '../../types/target'
import { storeTarget } from '../../api'
import { useReduxActions } from '../../hooks'

interface IMyTargetModal {
  open: boolean
  target: Target
  className: any
  onClose: () => void
  onSaveTarget: () => void
}

export const MyTargetModal: FC<IMyTargetModal> = ({ open, className, target, onSaveTarget, onClose }): JSX.Element => {
  const { setAlert } = useReduxActions()

  const [disabled, setDisabled] = useState(false)

  const onSubmit = () => {
    setDisabled(true)

    storeTarget({ target_id: target.id })
      .then(({ status, message }) => {
        if (status === 1) {
          onSaveTarget()
        } else {
          setAlert({
            alert: { message: { error: [`${message}`] } },
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setDisabled(false)
      })
  }

  return (
    <Modal open={open} maxWidth="md" onClose={onClose}>
      <Box className={className.title} my="15px">
        Моя цель
      </Box>
      <Box height={335} borderRadius="18px" overflow="hidden" display="flex" justifyContent="center">
        <img src={target.primary_media.src_link} alt={target.name} />
      </Box>
      <Box fontSize={18} fontWeight={700} textAlign="center" mt="20px" className={className.target}>
        Хочу добавить в свою жизнь больше:
        <Box textTransform="uppercase">{target.name}</Box>
      </Box>
      <Box mt="50px" mb="20px">
        <Button fullWidth variant="contained" color="secondary" onClick={onSubmit}>
          Продолжить
        </Button>
      </Box>
    </Modal>
  )
}
